import React, {Component} from 'react';
import {connect} from 'react-redux';
import {questionActions} from '../store/actions/QuestionActions';
import {eventActions} from '../store/actions/EventActions';
import Options from './Options';
import AnswerText from './AnswerText';
import AnswetTextLive from './AnswetTextLive';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import RatingResponse from './RatingResponse';


class SingleQuestionComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parentInfo: 'default',
            currentQuestion: '',
            optionsArray: [],
            sortedVotingSessionsArray: [],
            eventSessionIndex: '',
            currentVotingSessionSelected: '',
            votingSession: '',
            responsesArray: [],
            deleteModalType: '',
            currentEvent: 'default',
            showResults: 'default',
            liveSessionInfo: 'default',
            responsesArrayLive: [],
            showCorrectAnswerColor: 'default',
            currentVotingSessionStatus: 'default',
            newTabOneScreen: false,
            newTabTwoScreen: false,
            preSelectedvotingSessions: [],
            unCheck: true,
            selectedVotingSessionsIds: [],
            sessionModalStatus: "default",
            validVotingSessionsArray: [],
            totalRating: 0,
            totalLiveRating: 0

        };

        this.goPreviousVotingSession = this.goPreviousVotingSession.bind(this)
        this.goNextVotingSession = this.goNextVotingSession.bind(this)
        this.goLive = this.goLive.bind(this);
        this.sendToBigScreen = this.sendToBigScreen.bind(this);
        this.sendToBigScreenArchive = this.sendToBigScreenArchive.bind(this);
        this.stopLive = this.stopLive.bind(this);
        this.deleteSession = this.deleteSession.bind(this);
        this.showResults = this.showResults.bind(this);
        this.showCorrectAnswerColorFunction = this.showCorrectAnswerColorFunction.bind(this);
        this.hideCorrectAnswerColorFunction = this.hideCorrectAnswerColorFunction.bind(this);
        this.showModalSession = this.showModalSession.bind(this);
        this.getUserPreferences = this.getUserPreferences.bind(this);
        this.compareSingleQuestionResults = this.compareSingleQuestionResults.bind(this);
        this.closeSessionsCompareModal = this.closeSessionsCompareModal.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        var votingSessionSelected;
        var eventSessionIndex;
        var votingSesssionsList = [];

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.props.currentEvent.id, (callbackEventInfo, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.props.currentEvent.id, this.props.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                if (parentInfo.currentQuestion && parentInfo.currentQuestion !== "default") {
                    this.setState({
                        ...this.state,
                        currentEvent: callbackEventInfo,
                        parentInfo: parentInfo
                    });
                    if (this.state.currentQuestion.id !== parentInfo.currentQuestion) {
                        //user changed the current question so we need to reset the state of some elements which are local such as show results
                        this.setState({
                            ...this.state,
                            showResults: 'default',
                            liveSessionInfo: 'default',
                            sortedVotingSessionsArray: [],
                            eventSessionIndex: '',
                            currentVotingSessionSelected: '',
                            votingSession: '',
                        });
                    }

                    this.props.onGetQuestionInfo(callbackEventInfo.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, questionData => {
                        this.setState({
                            ...this.state,
                            currentQuestion: questionData,
                            responsesArrayLive: [],
                            responsesArray: []
                        });

                        if (questionData.type === 'MultipleChoice' || questionData.type === "SimpleTextAndMultipleChoice") {
                            this.props.onInitQuestionOptions(callbackEventInfo.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, callbackOptionsArray => {
                                this.setState({
                                    ...this.state,
                                    optionsArray: callbackOptionsArray,
                                    showCorrectAnswerColor: callbackEventInfo.showCorrectAnswerColor
                                });
                            });
                        }

                        if (questionData.firstVotingSessionInitialized === false) {
                            // the user can update the question as long as the first voting session has not been initialized so we receive the question changes
                            //otherwise we only fetch the question data without snapshot
                            if (this.unsubscribeGetQuestionInfoOnChange) {
                                this.unsubscribeGetQuestionInfoOnChange();
                            }
                            this.props.onGetQuestionInfoOnChange(callbackEventInfo.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (questionData, unsubscribe) => {
                                this.unsubscribeGetQuestionInfoOnChange = unsubscribe;
                                this.setState({
                                    ...this.state,
                                    currentQuestion: questionData,
                                });
                            });
                        } else {

                            // if(this.state.currentVotingSessionStatus === 'default'){
                            this.props.onGetAllVotingSessions(callbackEventInfo.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, votingListCallback => {
                                //receiving the last status of the play btn on refreshing the page
                                //we check if the status of the live session was on stop or a live session was still going on 
                                if (parentInfo.currentLiveSession.status !== 'default' && this.state.liveSessionInfo === 'default') {
                                    for (var index = 0; index < votingListCallback.length; index++) {
                                        if (votingListCallback[index].id === parentInfo.currentLiveSession.id) {
                                            this.setState({
                                                ...this.state,
                                                liveSessionInfo: votingListCallback[index],

                                            });
                                        }
                                    }
                                }

                                if (this.unsubscribeGetResponses) {
                                    this.unsubscribeGetResponses();
                                }
                                this.props.onGetResponses(callbackEventInfo.id, parentInfo.currentQuestionElement, questionData, parentInfo.currentLiveSession.id, (callbackResponses, unsubscribe) => {
                                    this.unsubscribeGetResponses = unsubscribe
                                    //The screen will be updated with the new response received from the client if the live question is available
                                    if (questionData.type === "RatingQuestion") {
                                        let totalRating = 0;
                                        for (var i = 0; i < callbackResponses.length; i++) {
                                            totalRating = totalRating + (parseInt(callbackResponses[i].ratingNumber, 10));
                                        }
                                        var inv = 1.0 / 0.5;
                                        var valueCalculated
                                        if (totalRating > 0) {
                                            totalRating = totalRating / callbackResponses.length;
                                            valueCalculated = Math.round(totalRating * inv) / inv;
                                        } else {
                                            valueCalculated = 0;
                                        }
                                        this.setState({
                                            ...this.state,
                                            totalLiveRating: valueCalculated,
                                            responsesArrayLive: callbackResponses,
                                        });

                                    } else {
                                        this.setState({
                                            ...this.state,
                                            responsesArrayLive: callbackResponses,
                                            // optionsArray: optionsArray,
                                        });
                                    }
                                });


                                if (votingListCallback.length > 0) {
                                    //we check if the current live session is already stopped we will show it in the list otherwise we remove it from the list archive otherwise we show the next item of the list
                                    // if (this.state.liveSessionInfo !== 'default') {
                                    if (parentInfo.currentLiveSession.status === 'live') {
                                        votingSesssionsList = votingListCallback.filter(votingSession => votingSession.id !== this.state.liveSessionInfo.id)
                                    } else {
                                        votingSesssionsList = votingListCallback;
                                    }
                                }

                                // if(this.props.currentEvent.currentQuestion === this.state.currentQuestion.id && this.props.currentEvent.currentSelectedLiveSession !== 'default' ){
                                if (votingSesssionsList.length > 0) {
                                    //we added callbackEventInfo.currentQuestion!== 'default' for the case that we have deleted the question
                                    if (parentInfo.currentQuestion !== 'default' && parentInfo.currentSelectedLiveSession !== 'default') {
                                        votingSesssionsList.sort(function (a, b) {
                                            return a.timeStamp > b.timeStamp ? -1 : 1
                                        });

                                        for (var index1 = 0; index1 < votingSesssionsList.length; index1++) {

                                            if (votingSesssionsList[index1].id === parentInfo.currentSelectedLiveSession) {
                                                votingSessionSelected = votingSesssionsList[index1];
                                                eventSessionIndex = index1;
                                            }
                                        }
                                    } else {

                                        votingSesssionsList.sort(function (a, b) {
                                            return a.timeStamp > b.timeStamp ? -1 : 1
                                        });
                                        eventSessionIndex = 0;
                                        votingSessionSelected = votingSesssionsList[0];
                                    }

                                    //We set the state one time when we received all the information about the question and its sessions
                                    this.setState({
                                        ...this.state,
                                        user: user,
                                        // currentEvent: callbackEventInfo,
                                        currentQuestion: questionData,
                                        sortedVotingSessionsArray: votingSesssionsList,
                                        voteCounterStatus: 'loaded',
                                        currentVotingSessionSelected: votingSessionSelected,
                                        eventSessionIndex: eventSessionIndex,
                                    });

                                } else {
                                    //We set the state one time when we received all the information about the question and its sessions
                                    this.setState({
                                        ...this.state,
                                        user: user,
                                        // currentEvent: callbackEventInfo,
                                        currentQuestion: questionData,
                                        sortedVotingSessionsArray: votingSesssionsList,
                                        voteCounterStatus: 'loaded'
                                    });
                                }
                                if (parentInfo.currentSelectedLiveSession === "default" && votingSesssionsList.length > 0) {
                                    this.props.onGetArchivedResponses(callbackEventInfo.id, parentInfo.currentQuestionElement, questionData, votingSesssionsList[0].id, callbackResponses => {
                                        if (questionData.type === 'RatingQuestion') {
                                            let totalRating = 0;
                                            callbackResponses.forEach(response => totalRating += parseInt(response.ratingNumber));
                                            totalRating = callbackResponses.length === 0 ? 0 : totalRating / callbackResponses.length;
                                            this.setState({
                                                ...this.state,
                                                responsesArray: callbackResponses,
                                                totalRating
                                            });
                                        } else {
                                            this.setState({...this.state, responsesArray: callbackResponses});
                                        }
                                    });
                                } else {
                                    this.props.onGetArchivedResponses(callbackEventInfo.id, parentInfo.currentQuestionElement, questionData, parentInfo.currentSelectedLiveSession, callbackResponses => {
                                        if (questionData.type === 'RatingQuestion') {
                                            let totalRating = 0;
                                            callbackResponses.forEach(response => totalRating += parseInt(response.ratingNumber));
                                            totalRating = callbackResponses.length === 0 ? 0 : totalRating / callbackResponses.length;
                                            this.setState({
                                                ...this.state,
                                                responsesArray: callbackResponses,
                                                totalRating
                                            });
                                        } else {
                                            this.setState({...this.state, responsesArray: callbackResponses});
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                //We get responses for the live session as soon as the user starts a live session and we attach the on change to the firebase
                if (this.state.liveSessionInfo.id !== 'default' && parentInfo.currentLiveSession.status !== "default") {
                    if (this.unsubscribeGetResponses) {
                        this.unsubscribeGetResponses();
                    }
                    this.props.onGetResponses(callbackEventInfo.id, parentInfo.currentQuestionElement, this.state.currentQuestion, parentInfo.currentLiveSession.id, callbackResponses => {
                        //The screen will be updated with the new response received from the client if the live question is available
                        this.setState({
                            ...this.state,
                            responsesArrayLive: callbackResponses,
                            parentInfo: parentInfo
                        });
                    });
                }
            });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetResponses) {
            this.unsubscribeGetResponses();
        }

        if (this.unsubscribeGetQuestionInfoOnChange) {
            this.unsubscribeGetQuestionInfoOnChange();
        }
    }

    goLive() {
        this.props.onSaveVotingSession(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.parentInfo.currentQuestion, callbackVotingId => {
            this.props.onGetCurrentStoredSession(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.parentInfo.currentQuestion, callbackVotingId, callbackSessionInfo => {
                this.setState({
                    liveSessionInfo: callbackSessionInfo,
                    responsesArray: []
                });
                //we update the current field in the question so the participants will receive the options on their devices to vote
                let value = {
                    currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                    questionId: this.state.parentInfo.currentQuestion,
                    currentLiveSession: {
                        id: callbackVotingId,
                        status: 'live',
                        liveQuestionId: this.state.parentInfo.currentQuestion,
                        liveQuestionElement: this.state.parentInfo.currentQuestionElement
                    }
                }

                this.props.onUpdateElementData(this.props.currentEvent.id, "updateLiveQuestionSession", this.state.parentInfo.id, value, callback => {
                });
            });
        });

        if (this.state.currentQuestion.firstVotingSessionInitialized === false) {
            // we update the first voting session status if the user starts the first voting session
            this.props.onUpdateQuestionField("firstVotingSession", this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.currentQuestion, true, callback => {
            });
        }

    }

    stopLive() {
        //we change the status of the live session to stop-livesession so the session will remain on the big screen and we can show the correct answer too
        let value = {
            idQuestionEl: this.state.parentInfo.currentQuestionElement,
            questionId: this.state.currentQuestion.id,
            bigScreenStatus: {status: 'liveQuestion', questionId: this.state.parentInfo.currentQuestion},
            currentLiveSession: {
                id: this.state.liveSessionInfo.id,
                status: 'stop',
                liveQuestionId: this.state.parentInfo.currentQuestion,
                liveQuestionElement: this.state.parentInfo.currentQuestionElement
            }
        };
        this.props.onUpdateElementData(this.props.currentEvent.id, "updateLiveQuestionSession", this.state.parentInfo.id, value, callback => {
        });

        this.state.sortedVotingSessionsArray.push(this.state.liveSessionInfo);
        if (this.state.sortedVotingSessionsArray.length > 1) {
            // if there is other elements in the array we need to sort them
            this.state.sortedVotingSessionsArray.sort(function (a, b) {
                // var result = a.timeStamp < b.timeStamp ? -1 : 1;
                return a.timeStamp > b.timeStamp ? -1 : 1
            });
        }
        var updateLiveSessionInfo = {id: 'stop', status: 'stop'}
        this.setState({...this.state, liveSessionInfo: updateLiveSessionInfo, responsesArrayLive: []});
    }

    setDeleteModalTypeSession() {
        this.setState({...this.state, deleteModalType: 'session'});
    }

    deleteSession() {
        // the current voting session is already on the big screen
        this.props.onDeleteQuestionSession(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.parentInfo.currentQuestion, this.state.currentVotingSessionSelected.id, callback => {
            //we check if there is only one session available after deleting it we need to back to the default status of the sessions
            if (this.state.sortedVotingSessionsArray.length === 1) {
                this.setState({
                    sortedVotingSessionsArray: [],
                    currentVotingSessionSelected: '',
                    eventSessionIndex: 0,
                    questionCreatedId: this.state.currentQuestion.id,
                    questionCreated: true,
                });

                //we need also change the status of the  firstVotingSessionInitialized in the question in firebase so in refreshing the page the user will have
                //the possibility to initialize the first voting session again same as what we did for creating a new question
                this.props.onUpdateQuestionField("resetFirstVotingsession",
                    this.props.currentEvent.id,
                    this.state.parentInfo.currentQuestionElement,
                    this.state.currentQuestion, false, callback => {
                        this.setState({
                            showSessionsStatus: false,
                            currentQuestion: '',
                            deleteModalType: ''
                        });
                    });
                this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentQuestionOnDelete", this.state.parentInfo.id, "", callback => {
                });
            } else {
                let indexToRemove;
                for (var index = 0; index < this.state.sortedVotingSessionsArray.length; index++) {
                    if (this.state.sortedVotingSessionsArray[index].id === this.state.currentVotingSessionSelected.id) {
                        indexToRemove = index;
                    }
                }
                this.state.sortedVotingSessionsArray.splice(indexToRemove, 1);

                //if only one element remains in the list after removing an item or we have removed the first item we go to the previous item
                if (this.state.sortedVotingSessionsArray.length === 1 || indexToRemove === 0) {
                    this.props.onGetArchivedResponses(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement,
                        this.state.currentQuestion,
                        this.state.sortedVotingSessionsArray[0].id, callbackResponses => {
                            this.setState({
                                ...this.state,
                                currentVotingSessionSelected: this.state.sortedVotingSessionsArray[0],
                                eventSessionIndex: 0,
                                responsesArray: callbackResponses,
                                deleteModalType: ''
                            });
                            //store the current selected session so on browser refresh or coming back from the screen we will have access to the same live session
                            this.props.storeSelectedLiveSession(this.props.currentEvent.id, this.state.sortedVotingSessionsArray[0]);
                        });
                    if (this.state.parentInfo.bigScreenStatus.status === 'archivedQuestion' && this.state.parentInfo.currentSelectedLiveSession === this.state.currentVotingSessionSelected.id) {
                        let value = {
                            selectedVotingSessionId: this.state.sortedVotingSessionsArray[0].id,
                            bigScreenStatus: 'default'
                        }
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentQuestionElementDelete", this.props.idElement, value, callback => {
                        });
                    }
                } else {
                    //we are in the end or middle of the list so after removing an item we will go to the next item in the list
                    this.props.onGetArchivedResponses(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement,
                        this.state.currentQuestion,
                        this.state.sortedVotingSessionsArray[indexToRemove - 1].id, callbackResponses => {
                            this.setState({
                                ...this.state,
                                currentVotingSessionSelected: this.state.sortedVotingSessionsArray[indexToRemove - 1],
                                eventSessionIndex: indexToRemove - 1,
                                responsesArray: callbackResponses,
                                deleteModalType: ''
                            });
                            //store the current selected session so on browser refresh or coming back from the screen we will have access to the same live session
                            this.props.storeSelectedLiveSession(this.props.currentEvent.id, this.state.sortedVotingSessionsArray[indexToRemove - 1]);
                        });
                    if (this.state.parentInfo.bigScreenStatus.status === 'archivedQuestion' && this.state.parentInfo.currentSelectedLiveSession === this.state.currentVotingSessionSelected.id) {
                        let value = {
                            selectedVotingSessionId: this.state.sortedVotingSessionsArray[indexToRemove - 1].id,
                            bigScreenStatus: 'default'
                        }
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentQuestionElementDelete", this.props.idElement, value, callback => {
                        });
                    }
                }
            }
        });
    }

    goNextVotingSession() {
        if (this.state.eventSessionIndex > 0) {
            this.props.onGetArchivedResponses(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement,
                this.state.currentQuestion,
                this.state.sortedVotingSessionsArray[this.state.eventSessionIndex - 1].id, callbackResponses => {
                    if (this.state.currentQuestion.type === "RatingQuestion") {
                        var totalRating = 0;
                        for (var i = 0; i < callbackResponses.length; i++) {
                            totalRating = totalRating + (parseInt(callbackResponses[i].ratingNumber, 10));
                        }
                        var inv = 1.0 / 0.5;
                        var valueCalculated
                        if (totalRating > 0) {
                            totalRating = totalRating / callbackResponses.length;
                            valueCalculated = Math.round(totalRating * inv) / inv;
                        } else {
                            valueCalculated = 0;
                        }
                        this.setState({
                            ...this.state,
                            totalRating: valueCalculated,
                            currentVotingSessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex - 1],
                            eventSessionIndex: this.state.eventSessionIndex - 1,
                            responsesArray: callbackResponses
                        });

                        let value = {votingsessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex].id}
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
                        });
                    } else {

                        this.setState({
                            ...this.state,
                            currentVotingSessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex - 1],
                            eventSessionIndex: this.state.eventSessionIndex - 1,
                            responsesArray: callbackResponses
                        });
                        //store the current selected session so on browser refresh or coming back from the screen we will have access to the same live session
                        //     this.props.storeSelectedLiveSession(this.props.currentEvent.id, this.state.sortedVotingSessionsArray[this.state.eventSessionIndex]);

                        let value = {votingsessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex].id}
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
                        });
                    }
                });
        }
    }

    goPreviousVotingSession() {
        if (this.state.eventSessionIndex < this.state.sortedVotingSessionsArray.length - 1) {
            this.props.onGetArchivedResponses(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.currentQuestion,
                this.state.sortedVotingSessionsArray[this.state.eventSessionIndex + 1].id, callbackResponses => {


                    if (this.state.currentQuestion.type === "RatingQuestion") {
                        var totalRating = 0;
                        for (var i = 0; i < callbackResponses.length; i++) {
                            totalRating = totalRating + (parseInt(callbackResponses[i].ratingNumber, 10));
                        }
                        var inv = 1.0 / 0.5;
                        var valueCalculated
                        if (totalRating > 0) {
                            totalRating = totalRating / callbackResponses.length;
                            valueCalculated = Math.round(totalRating * inv) / inv;
                        } else {
                            valueCalculated = 0;
                        }
                        this.setState({
                            ...this.state,
                            totalRating: valueCalculated,
                            currentVotingSessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex + 1],
                            eventSessionIndex: this.state.eventSessionIndex + 1,
                            responsesArray: callbackResponses
                        });

                        let value = {votingsessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex].id}
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
                        });
                    } else {


                        this.setState({
                            ...this.state,
                            currentVotingSessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex + 1],
                            eventSessionIndex: this.state.eventSessionIndex + 1,
                            responsesArray: callbackResponses
                        });
                        //store the current selected session so on browser refresh or coming back from the screen we will have access to the same live session
                        //     this.props.storeSelectedLiveSession(this.props.currentEvent.id, this.state.sortedVotingSessionsArray[this.state.eventSessionIndex]);

                        let value = {votingsessionSelected: this.state.sortedVotingSessionsArray[this.state.eventSessionIndex].id}
                        this.props.onUpdateElementData(this.props.currentEvent.id, "updateCurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
                        });
                    }

                });
        }
    }

    sendToBigScreen() {

        if (this.props.currentEvent.screenNumber === "Two") {

            //we update the current field to remove the current live tweet session so the only live session will become the question
            let value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                liveSession: 'default',
                statusLiveSession: 'sentToBigScreen',
                bigScreenStatus: {status: 'liveQuestion', questionId: this.state.parentInfo.currentQuestion}
            }


            this.props.onUpdateElementData(this.props.currentEvent.id, "TwoUpdateBigScreenStatus&CurrentLiveSession", this.state.parentInfo.id, value, callback => {
            });

            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }

            if (this.props.updatedCurrentFiledstatus === 'success') {
                if (this.state.newTabTwoScreen === false) {
                    var win = window.open("/event/" + this.props.currentEvent.id + "/element/" + this.props.idElement + "/liveQuestion", '_blank');
                    win.focus();
                    this.setState({...this.state, newTabTwoScreen: true});
                }
            }
        } else {

            //we update the current field to remove the current live tweet session so the only live session will become the question
            let value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                liveSession: 'default',
                statusLiveSession: 'sentToBigScreen',
                bigScreenStatus: {status: 'liveQuestion', questionId: this.state.parentInfo.currentQuestion},
                questionId: this.state.parentInfo.currentQuestion
            }

            this.props.onUpdateElementData(this.props.currentEvent.id, "OneUpdateBigScreenStatus&CurrentLiveSession", this.state.parentInfo.id, value, callback => {
            });

            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }

            if (/*this.props.updatedCurrentFiledstatus === 'success'*/true) {

                if (this.state.newTabOneScreen === false) {
                    //Open the big screen page on the new Tab   
                    var win1 = window.open("/event/" + this.props.currentEvent.id + "/element/" + this.props.idElement + "/live", '_blank');
                    win1.focus();
                    this.setState({...this.state, newTabOneScreen: true});
                }
            }
        }

    }

    sendToBigScreenArchive() {

        if (this.props.currentEvent.screenNumber === "Two") {
            //Open the big screen page on the new Tab
            if (this.state.newTabTwoScreen === false) {
                var win = window.open("/event/" + this.props.currentEvent.id + "/element/" + this.props.idElement + "/liveQuestion", '_blank');

                win.focus();
                this.setState({...this.state, newTabTwoScreen: true});
            }
            //we update the currentSelectedLiveSessin if it is default
            let value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                questionId: this.state.parentInfo.currentQuestion,
                votingsessionSelected: this.state.currentVotingSessionSelected.id,
                bigScreenStatus: {status: 'archivedQuestion', questionId: this.state.parentInfo.currentQuestion}
            }


            this.props.onUpdateElementData(this.props.currentEvent.id, "TwoUpdateBigScreenStatus&CurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
            });
            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }

        } else {
            //we update the current field to remove the current live tweet session so the only live session will become the question
            let value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                votingsessionSelected: this.state.currentVotingSessionSelected.id,
                bigScreenStatus: {status: 'archivedQuestion', questionId: this.state.parentInfo.currentQuestion}
            }


            this.props.onUpdateElementData(this.props.currentEvent.id, "OneUpdateBigScreenStatus&CurrentSelectedLiveSession", this.state.parentInfo.id, value, callback => {
            });

            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }


            if (this.props.updatedCurrentFiledstatus === 'success') {
                //Open the big screen page on the new Tab   
                if (this.state.newTabOneScreen === false) {
                    var win2 = window.open("/event/" + this.props.currentEvent.id + "/element/" + this.props.idElement + "/live", '_blank');
                    win2.focus();
                    this.setState({...this.state, newTabOneScreen: true});
                }
            }
        }
    }

    showResults() {
        if (this.state.showResults === "show") {
            this.setState({...this.state, showResults: "hide"});
        } else {
            this.setState({...this.state, showResults: "show"});
        }
    }

    showCorrectAnswerColorFunction() {
        // if(this.state.showCorrectAnswerColor === "show"){
        //     this.setState({...this.state, showCorrectAnswerColor:'default'});
        this.props.onUpdateCurrentField("showCorrectAnswerColor", this.props.currentEvent.id, 'show', callback => {
        });

    }

    hideCorrectAnswerColorFunction() {
        //this.setState({...this.state, showCorrectAnswerColor:'show'});
        this.props.onUpdateCurrentField("showCorrectAnswerColor", this.props.currentEvent.id, 'default', callback => {
        });
    }


    setAnswerTextstatus(option, status) {
        this.props.setAnswerTextOptionStatus(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.parentInfo.currentQuestion, this.state.parentInfo.currentLiveSession.id, option, status, callback => {
        });
    }

    showModalSession() {
        var validVotingSessionsArray = [];
        for (var i = 0; i < this.state.sortedVotingSessionsArray.length; i++) {
            //Keep voting session with no voted parameter for back compatibility with old events.    
            if ((this.state.sortedVotingSessionsArray[i].voted == undefined) || (this.state.sortedVotingSessionsArray[i].voted)) {
                validVotingSessionsArray.push(this.state.sortedVotingSessionsArray[i]);
            }
        }
        document.getElementById("selctedSessionsCheckBox-" + validVotingSessionsArray[0].id).setAttribute("checked", true);
        document.getElementById("selctedSessionsCheckBox-" + validVotingSessionsArray[1].id).setAttribute("checked", true);
        this.setState({
            ...this.state,
            validVotingSessionsArray
        })
    }

    getUserPreferences(item, type) {
        if (this.state.sessionModalStatus === "default") {
            document.getElementById("selctedSessionsCheckBox-" + this.state.validVotingSessionsArray[0].id).removeAttribute('checked');
            document.getElementById("selctedSessionsCheckBox-" + this.state.validVotingSessionsArray[1].id).removeAttribute('checked');
            this.setState({
                ...this.state,
                sessionModalStatus: "edited",
                // selectedVotingSessionsIds:[]
            });
        }

        switch (type) {
            case "selctedSessionsCheckBox":
                var sessionCheckboxStatus = document.getElementById("selctedSessionsCheckBox-" + item.id).checked;
                var selectedVotingSessionsIds = this.state.selectedVotingSessionsIds;
                var sessionNotExists = true;
                if (sessionCheckboxStatus === true) {

                    for (var i = 0; i < this.state.selectedVotingSessionsIds.length; i++) {
                        if (this.state.selectedVotingSessionsIds[i].id === item.id) {
                            sessionNotExists = false;
                            return;
                        }
                    }
                    if (this.state.selectedVotingSessionsIds.length > 1) {
                        alert('Pick Just Two Please')
                        document.getElementById("selctedSessionsCheckBox-" + item.id).checked = false;
                        return false;
                    } else {
                        if (sessionNotExists) {
                            selectedVotingSessionsIds.push({id: item.id, timeStamp: item.timeStamp});
                            this.setState({
                                ...this.state,
                                selectedVotingSessionsIds,
                            });
                        }
                    }
                } else {
                    for (var i = 0; i < this.state.selectedVotingSessionsIds.length; i++) {
                        if (this.state.selectedVotingSessionsIds[i].id === item.id) {
                            selectedVotingSessionsIds.pop(this.state.selectedVotingSessionsIds[i]);
                            return;
                        }
                    }


                    this.setState({
                        ...this.state,
                        selectedVotingSessionsIds
                    });
                }
                break;


            default:
                return true;
        }
    }

    compareSingleQuestionResults() {

        if (document.getElementById('sessioncompareModalsForm')) {
            document.getElementById('sessioncompareModalsForm').reset();
        }

        var value;
        if (this.state.selectedVotingSessionsIds.length === 0) {
            var selectedVotingSessionsIds = [];
            selectedVotingSessionsIds.push({
                id: this.state.validVotingSessionsArray[0].id,
                timeStamp: this.state.validVotingSessionsArray[0].timeStamp
            });
            selectedVotingSessionsIds.push({
                id: this.state.validVotingSessionsArray[1].id,
                timeStamp: this.state.validVotingSessionsArray[1].timeStamp
            });
            value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                bigScreenStatus: {status: 'compareResults', questionId: this.state.parentInfo.currentQuestion},
                selectedSessionsToCompare: selectedVotingSessionsIds
            }
        } else {
            value = {
                currentQuestionElement: this.state.parentInfo.currentQuestionElement,
                bigScreenStatus: {status: 'compareResults', questionId: this.state.parentInfo.currentQuestion},
                selectedSessionsToCompare: this.state.selectedVotingSessionsIds
            }
        }


        this.props.onUpdateElementData(this.props.currentEvent.id, "OneUpdateBigScreenStatus-compareResults", this.state.parentInfo.id, value, callback => {
        });

        this.setState({
            ...this.state,
            selectedVotingSessionsIds: [],
            sessionModalStatus: "default"
        });


        if (this.props.currentEvent.showIntroductionPage !== "No") {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
            });
        }

        //this.props.onUpdateQuestionField("selectedSessionsToCompare", this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, this.state.currentQuestion, selectedSessionsList, callback => {
        //});

            //Open the big screen page on the new Tab   
            if (this.state.newTabOneScreen === false) {
                var win = window.open("/event/" + this.props.currentEvent.id + "/element/" + this.props.idElement + "/live", '_blank');
                win.focus();
                this.setState({
                    ...this.state,
                    newTabOneScreen: true,
                    selectedVotingSessionsIds: [],
                    sessionModalStatus: "default"
                });

            }
    }

    closeSessionsCompareModal() {
        if (document.getElementById('sessioncompareModalsForm')) {
            document.getElementById('sessioncompareModalsForm').reset();
        }
        this.setState({
            ...this.state,
            selectedVotingSessionsIds: [],
            modalStatus: "default"
        });
    }


    render() {
        var sessionItemsModal = this.state.sortedVotingSessionsArray.map((item, i) => {
            if ((item.voted == undefined) || item.voted) {
                return (
                    <div className="custom-control custom-checkbox  pb-1" key={item.id} onClick={() => {
                        this.getUserPreferences(item, 'selctedSessionsCheckBox')
                    }}>
                        <input type="checkbox" className="custom-control-input"
                               id={"selctedSessionsCheckBox-" + item.id}/>
                        <label className="custom-control-label"
                               htmlFor={"selctedSessionsCheckBox-" + item.id}>{item.time}</label>
                    </div>
                )
            }
        });


        var selectedLanguageStrings;
        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }
        let sessionName;
        let liveSection;
        let totalVote = 0;
        // we remove the repetitive participants id that may happen because of the multiple choices selection
        var uniqueResponses = [];
        for (var index = 0; index < this.state.responsesArray.length; index++) {
            if (!uniqueResponses.includes(this.state.responsesArray[index].participantsId)) {
                //if the list of the participants does not have this participants id we add it to the list 
                uniqueResponses.push(this.state.responsesArray[index].participantsId);
            }
        }
        totalVote += uniqueResponses.length;
        if (this.state.sortedVotingSessionsArray.length > 0) {
            sessionName = (
                <div className="card mb-2" id="session-card-header">
                    <div className="row justify-content-center mt-1">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">
                            {(this.state.eventSessionIndex === this.state.sortedVotingSessionsArray.length - 1) ? (
                                <div>
                                </div>
                            ) : (
                                <Tooltip
                                    // options
                                    title="Go to the previous archived session"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button type="button" className="rounded btn-primary border-0 sessionSingleIcon"
                                            onClick={this.goPreviousVotingSession}>
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 px-0 text-center">
                            {/* <h6 className="mr-2 mt-1" > {moment(this.state.currentVotingSessionSelected.time.toDate()).format("DD MMMM YYYY HH:mm:ss")} </h6> */}
                            {(this.state.currentVotingSessionSelected) ? (
                                <div
                                    className="questionSessionTitle"> {this.state.currentVotingSessionSelected.time} </div>
                            ) : (
                                <div className="questionSessionTitle"> {this.state.currentVotingSessionSelected} </div>
                            )}

                            <div className="text-center questionSessionSubTitle">
                                Session No.
                                : {this.state.sortedVotingSessionsArray.length - this.state.eventSessionIndex} / {this.state.sortedVotingSessionsArray.length}
                            </div>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-left">
                            {(this.state.eventSessionIndex === 0) ? (
                                <div>
                                </div>
                            ) : (
                                <Tooltip
                                    // options
                                    title="Go to the next archived session"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button type="button" className="rounded btn-primary  border-0 sessionSingleIcon "
                                            onClick={this.goNextVotingSession}>
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {(this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice") ? (
                        <div className="row mt-2 mb-2">
                            <div className="col-5 text-left pl-4">
                                <div className="pt-1"><strong>Votes No.: {totalVote}</strong></div>
                            </div>

                            <div className="col-7 text-right pr-4">
                                {(this.state.sortedVotingSessionsArray.length >= 2) ? (
                                    <Tooltip
                                        // options
                                        title="Compare results"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}
                                    >
                                        <button className="rounded border-0 bg-warning closeBtnSession" id="live-button"
                                                question-id={this.state.currentQuestion.id}
                                                onClick={this.showModalSession} data-toggle="modal"
                                                data-target="#sessionsModalToCompareCurrentQuestion">
                                            <i className="far fa-chart-bar text-white"></i>
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <span>&nbsp;</span>
                                )}


                                {(this.state.showResults === 'show') ? (
                                    <Tooltip
                                        // options
                                        title="Hide results"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}
                                    >
                                        <button className="rounded border-0 mr-1 bg-secondary" id="live-button"
                                                question-id={this.state.currentQuestion.id} onClick={this.showResults}>
                                            <i className="far fa-eye-slash text-white"></i>
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        // options
                                        title="Show results"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}
                                    >
                                        <button className="rounded border-0 bg-info mr-1" id="live-button"
                                                question-id={this.state.currentQuestion.id} onClick={this.showResults}>
                                            <i className="far fa-eye text-white "></i>
                                        </button>
                                    </Tooltip>
                                )}

                                {(this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice") ? (
                                    (this.state.showCorrectAnswerColor === "show") ? (
                                        <Tooltip
                                            // options
                                            title="Hide Correct Answer"
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="10"
                                            animation={"fade"}
                                            arrow={true}
                                            duration={1200}>
                                            <button type="button"
                                                    className="rounded border-0 mr-1 mb-2 bg-secondary sessionSingleIcon"
                                                    onClick={this.hideCorrectAnswerColorFunction}>
                                                <i className="fas fa-times iconClose"></i>
                                            </button>
                                        </Tooltip>

                                    ) : (
                                        <Tooltip
                                            // options
                                            title="Show Correct Answer"
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="10"
                                            animation={"fade"}
                                            arrow={true}
                                            duration={1200}>
                                            <button type="button"
                                                    className="rounded border-0 mr-1 mb-2 bg-success sessionSingleIcon"
                                                    onClick={this.showCorrectAnswerColorFunction}>
                                                <i className="fas fa-check text-white resultIcon"></i>
                                            </button>
                                        </Tooltip>
                                    )

                                ) : (
                                    <span>&nbsp;</span>
                                )}


                                <Tooltip
                                    // options
                                    title="See the big screen view"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button type="button"
                                            className="rounded border-0 btn-primary mr-1 sessionSingleIcon"
                                            onClick={this.sendToBigScreenArchive}>
                                        <i className="fas fa-desktop archiveDesktopIcon"></i></button>
                                </Tooltip>
                                <Tooltip
                                    // options
                                    title="Delete this session"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button type="button" className="rounded border-0 btn-danger sessionSingleIcon"
                                            data-toggle="modal" data-target="#deleteSessionModal">
                                        <i className="fas fa-trash"></i></button>
                                </Tooltip>
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-2 mb-2">
                            <div className="col-6 text-left pl-4">
                                <div className="pt-1"><strong>Responses No.: {totalVote}</strong></div>
                            </div>

                            <div className="col-6 text-right pr-4">
                                {(this.state.showResults === 'show') ? (
                                    <Tooltip
                                        // options
                                        title="Hide results"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}>
                                        <button className="rounded border-0 closeBtnSession" id="live-button"
                                                question-id={this.state.currentQuestion.id} onClick={this.showResults}>

                                            <i className="far fa-eye-slash text-white"></i>
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        // options
                                        title="Show results"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}>
                                        <button className="rounded border-0 bg-info closeBtnSession" id="live-button"
                                                question-id={this.state.currentQuestion.id} onClick={this.showResults}>
                                            <i className="far fa-eye text-white"></i>
                                        </button>
                                    </Tooltip>
                                )}

                                {(this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice") ? (
                                    (this.state.showCorrectAnswerColor === "show") ? (
                                        <Tooltip
                                            // options
                                            title="Hide Correct Answer"
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="10"
                                            animation={"fade"}
                                            arrow={true}
                                            duration={1200}>
                                            <button type="button"
                                                    className="rounded border-0 mr-1 mb-2 bg-secondary sessionSingleIcon"
                                                    onClick={this.hideCorrectAnswerColorFunction}>
                                                <i className="fas fa-times iconClose"></i>
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            // options
                                            title="Show Correct Answer"
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="10"
                                            animation={"fade"}
                                            arrow={true}
                                            duration={1200}>
                                            <button type="button"
                                                    className="rounded border-0 mr-1 mb-2 bg-success sessionSingleIcon"
                                                    onClick={this.showCorrectAnswerColorFunction}>
                                                <i className="fas fa-check text-white resultIcon"></i>
                                            </button>
                                        </Tooltip>
                                    )
                                ) : (
                                    <span></span>
                                )}

                                <Tooltip
                                    // options
                                    title="See the big screen view"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}>
                                    <button type="button"
                                            className="rounded border-0 btn-primary mr-1 sessionSingleIcon"
                                            onClick={this.sendToBigScreenArchive}>
                                        <i className="fas fa-desktop archiveDesktopIcon"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    // options
                                    title="Delete this session"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}>
                                    <button type="button" className="rounded border-0 btn-danger sessionSingleIcon"
                                            data-toggle="modal" data-target="#deleteSessionModal">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>

            )
        } else {
            sessionName = (<div className="card">
                <div className="card-body">
                    <p className="card-text">No archived session yet.</p>
                </div>
            </div>);
        }

        let totalLiveVote = 0;
        // we remove the repetitive participants id that may happen because of the multiple choices selection
        var uniqueLiveResponses = [];
        for (var index = 0; index < this.state.responsesArrayLive.length; index++) {
            if (!uniqueLiveResponses.includes(this.state.responsesArrayLive[index].participantsId)) {
                //if the list of the participants does not have this participants id we add it to the list 
                uniqueLiveResponses.push(this.state.responsesArrayLive[index].participantsId);
            }
        }
        totalLiveVote += uniqueLiveResponses.length;
        liveSection = (
            <div className="card mb-2" id="session-card-header">
                {(this.state.liveSessionInfo === 'default' || this.state.parentInfo.currentLiveSession.status !== 'live') ? (
                    <div className="row mt-2 mb-1">
                        <div className="col-12 text-right pr-4">
                            {(this.state.parentInfo.currentLiveSession && this.state.parentInfo.currentLiveSession.status === "stop" && this.state.currentQuestion.type === 'MultipleChoice') ? (
                                (this.props.currentEvent.showCorrectAnswerColor === "show") ? (
                                    <Tooltip
                                        // options
                                        title="Hide Correct Answer"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}
                                    >
                                        <button type="button"
                                                className="rounded border-0 mr-1 mb-2 bg-secondary sessionSingleIcon"
                                                onClick={this.hideCorrectAnswerColorFunction}>
                                            <i className="fas fa-times iconClose"></i>
                                        </button>
                                    </Tooltip>

                                ) : (
                                    <Tooltip
                                        // options
                                        title="Show Correct Answer"
                                        position="top"
                                        trigger={"mouseenter"}
                                        distance="10"
                                        animation={"fade"}
                                        arrow={true}
                                        duration={1200}
                                    >
                                        <button type="button"
                                                className="rounded border-0 mr-1 mb-2 bg-success sessionSingleIcon"
                                                onClick={this.showCorrectAnswerColorFunction}>
                                            <i className="fas fa-check text-white resultIcon"></i>
                                        </button>
                                    </Tooltip>
                                )

                            ) : (
                                <div></div>
                            )}
                            <Tooltip
                                // options
                                title="Start a new live session"
                                position="top"
                                trigger={"mouseenter"}
                                distance="10"
                                animation={"fade"}
                                arrow={true}
                                duration={1200}
                            >
                                <button className="rounded btn-success border-0 mr-1 sessionSingleIcon" id="live-button"
                                        question-id={this.state.currentQuestion.id} onClick={this.goLive}>
                                    <i className="fas fa-play-circle"></i>
                                </button>
                            </Tooltip>
                            <Tooltip
                                // options
                                title="See the big screen view"
                                position="top"
                                trigger={"mouseenter"}
                                distance="10"
                                animation={"fade"}
                                arrow={true}
                                duration={1200}
                            >
                                <button type="button" className="rounded border-0 btn-primary mr-1 sessionSingleIcon"
                                        onClick={this.sendToBigScreen}>
                                    <i className="fas fa-desktop archiveDesktopIcon"></i></button>
                            </Tooltip>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="row mt-1 justify-content-center">
                            <div className="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 px-0 text-center">
                                <label className="questionSessionTitle"> {this.state.liveSessionInfo.time} </label>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6 text-left pl-4">
                                {(this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice" || this.state.currentQuestion.type === "RatingQuestion") ? (
                                    <div>
                                        <strong>Votes No.: {totalLiveVote}</strong>
                                    </div>
                                ) : (
                                    <div>
                                        <strong>Responses No.: {totalLiveVote}</strong>
                                    </div>
                                )}
                            </div>
                            <div className="col-6 text-right pr-4">
                                <Tooltip
                                    // options
                                    title="Stop this live session"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button className="rounded border-0 stopLiveButton mr-1 sessionSingleIcon"
                                            id="live-button" question-id={this.state.currentQuestion.id}
                                            onClick={this.stopLive}>
                                        <i className="fas fa-stop-circle"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    // options
                                    title="See the big screen view"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                >
                                    <button type="button"
                                            className="rounded border-0 btn-primary mr-1 sessionSingleIcon"
                                            onClick={this.sendToBigScreen}>
                                        <i className="fas fa-desktop archiveDesktopIcon"></i>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )

        var responseArchiveComponent;
        if (this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice") {
            responseArchiveComponent = (<Options
                question={this.state.currentQuestion}
                optionsList={this.state.optionsArray}
                responsesArrayArchive={this.state.responsesArray}
                primaryColor={this.props.currentEvent.primaryColor}
                textColor={this.props.currentEvent.textColor}
                voteResultsDisplay={this.props.currentEvent.voteResultsDisplay}
                optionsOrderStatus={this.props.currentEvent.optionsOrderStatus}
                optionsFor={"ArchivedSessions"}
                parentInfo={this.state.parentInfo}
            />)
        } else if (this.state.currentQuestion.type === "SimpleTextQuestion") {
            responseArchiveComponent =
                <AnswerText
                    question={this.state.currentQuestion}
                    responsesArray={this.state.responsesArray}
                    event={this.props.currentEvent}
                    setAnswerTextstatus={this.setAnswerTextstatus.bind(this)}
                    parentInfo={this.state.parentInfo}
                    page={"SingleQuestionComponent"}
                />
        } else if (this.state.currentQuestion.type === "RatingQuestion") {
            responseArchiveComponent =
                <RatingResponse
                    question={this.state.currentQuestion}
                    responsesArray={this.state.responsesArray}
                    event={this.props.currentEvent}
                    totalRating={this.state.totalRating}
                    componentType={"archiveComponent"}
                />
        }

        var responseLiveComponent;
        if (this.state.currentQuestion.type === "MultipleChoice" || this.state.currentQuestion.type === "SimpleTextAndMultipleChoice") {
            responseLiveComponent = <Options
                question={this.state.currentQuestion}
                optionsList={this.state.optionsArray}
                responsesArrayArchive={this.state.responsesArrayLive}
                primaryColor={this.props.currentEvent.primaryColor}
                textColor={this.props.currentEvent.textColor}
                voteResultsDisplay={this.props.currentEvent.voteResultsDisplay}
                optionsOrderStatus={this.props.currentEvent.optionsOrderStatus}
                optionsFor={"LiveSession"}
                parentInfo={this.state.parentInfo}
            />
        } else if (this.state.currentQuestion.type === "SimpleTextQuestion") {
            responseLiveComponent = <AnswetTextLive
                question={this.state.currentQuestion}
                responsesArrayLive={this.state.responsesArrayLive}
                event={this.props.currentEvent}
                setAnswerTextstatus={this.setAnswerTextstatus.bind(this)}
                parentInfo={this.state.parentInfo}
                page={"SingleQuestionComponent"}
            />
        } else if (this.state.currentQuestion.type === "RatingQuestion") {
            responseLiveComponent = <RatingResponse
                question={this.state.currentQuestion}
                responsesArray={this.state.responsesArrayLive}
                event={this.props.currentEvent}
                totalLiveRating={this.state.totalLiveRating}
                componentType={"liveComponent"}
            />
        }

        return (
            <div>
                <div className="row mb-4">
                    {(this.state.currentQuestion.text) ? (
                        <div className="col-12">
                            <div className="card">
                                <div className="card-title p-1">
                                    <div className="lead questionLabel"><h5>{this.state.currentQuestion.text}</h5></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-2"><b>{selectedLanguageStrings.live_session} : </b></h2>
                    </div>
                </div>
                <div>
                    {liveSection}
                </div>
                <div className="row">
                    <div className="col-sm-12 col-12 col-lg-12 col-md-12">
                        {responseLiveComponent}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-2"><b>{selectedLanguageStrings.archived_sessions} : </b></h2>
                    </div>
                </div>
                <div>
                    {sessionName}
                </div>
                <div className="row mb-4">
                    {(this.state.showResults === "show") ? (
                        <div className="col-sm-12 col-12 col-lg-12 col-md-12">
                            {responseArchiveComponent}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>

                <div className="modal fade" id="deleteSessionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="exampleModalLabel">{selectedLanguageStrings.delete_question_session_title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {selectedLanguageStrings.delete_question_session_text}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{selectedLanguageStrings.cancel}</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal"
                                        onClick={this.deleteSession}>{selectedLanguageStrings.yes}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <form id='sessioncompareModalsForm'>
                        <div className="modal fade" id="sessionsModalToCompareCurrentQuestion" tabIndex="-1"
                             role="dialog" aria-labelledby="sessionsModalToCompareCurrentQuestion" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Sessions</h5>
                                    </div>
                                    <div className="modal-body modalSessions">
                                        <p>Select Sessions To Compare Results </p>
                                        <div className="pt-2 pl-2">
                                            {sessionItemsModal}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                                onClick={this.closeSessionsCompareModal}>Close
                                        </button>
                                        <button type="button" className="btn btn-warning" data-dismiss="modal"
                                                onClick={this.compareSingleQuestionResults}>Compare Results
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

// export default OptionsArchive;
const mapStateToProps = state => {

    return {
        questionList: state.questionReducer.questionList,
        currentEvent: state.eventReducer.currentEvent,
        currentLiveQustionId: state.eventReducer.currentLiveQustionId,
        updatedCurrentFiledstatus: state.eventReducer.updatedCurrentFiledstatus,
    };

};

const mapDispatchToProps = dispatch => {

    return {
        onInitQuestionOptions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllQuestionOptions(eventId, idQuestionEl, questionId, callback)),
        onSaveVotingSession: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.saveVotingSession(eventId, idQuestionEl, questionId, callback)),
        onGetAllVotingSessions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllVotingSessions(eventId, idQuestionEl, questionId, callback)),
        onGetResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        onUpdateQuestionField: (option, eventId, idQuestionEl, question, value, callback) => dispatch(questionActions.updateQuestionField(option, eventId, idQuestionEl, question, value, callback)),
        onGetQuestionInfoOnChange: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfoOnChange(eventId, idQuestionEl, questionId, callback)),
        onGetQuestionInfo: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfo(eventId, idQuestionEl, questionId, callback)),
        storeSelectedLiveSession: (eventId, liveSession) => dispatch(questionActions.storeSelectedLiveSession(eventId, liveSession)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onInitHeader: (eventId, callback) => dispatch(eventActions.getEventInfo(eventId, callback)),
        onGetCurrentStoredSession: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getCurrentStoredSession(eventId, idQuestionEl, questionId, votingId, callback)),
        onDeleteQuestionSession: (eventId, idQuestionEl, questionId, currentVotingSessionSelected, callback) => dispatch(questionActions.deleteQuestionSession(eventId, idQuestionEl, questionId, currentVotingSessionSelected, callback)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        setAnswerTextOptionStatus: (eventId, idQuestionEl, questionId, votingId, option, status, callback) => dispatch(questionActions.setAnswerTextOptionStatus(eventId, idQuestionEl, questionId, votingId, option, status, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(SingleQuestionComponent);
import React, { Component } from 'react';
import moment from 'moment';


class AnswerText extends Component {

    render() {

        let answerListComponents = [];
        if (this.props.responsesArray) {
            answerListComponents = (this.props.responsesArray || []).map((option, i) => {

                return (
                    <div>
                        {(this.props.page === "LiveScreen")?(

                            <div className="card cardAnswetText mt-3" key={option.id}>
                                <div className="card-header cardHeaderAnswer" style={{ backgroundColor: this.props.event.primaryColor }}>
                                    <div className="row pt-2" >
                                        <div className="col-6 text-left" >
                                            {(option.sName && option.sName !== "") ? (

                                                <h4 style={{ color: this.props.event.primaryColor,}}> 
                                                    <i className="fas fa-user mr-2" style={{ color: this.props.event.textColor }}></i>
                                                    <b>{option.sName}</b>
                                                </h4>

                                            ) : (

                                                <h4>
                                                    <i className="fas fa-user mr-2" style={{ color: this.props.event.textColor }}></i>
                                                </h4>

                                            )}
                                        </div>
                                        <div className="col-6 text-right">
                                            {(option.dtmCreated) ? (

                                                <label  htmlFor="inlineCheckbox1" style={{ color: this.props.event.textColor }}>
                                                    <b>{moment(option.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</b>
                                                </label>

                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-text cardBodyAnswer" style={{ color: this.props.event.primaryColor }}>
                                    <h3>{option.sAnswer}</h3>
                                </div>
                            </div>

                        ):(

                            <div className="card cardAnswetText mt-3" key={option.id}>
                                <div className="card-header cardHeaderAnswer"  style={{ backgroundColor: this.props.event.primaryColor }}>
                                    <div className="row pt-1" >
                                        <div className="col-6 text-left" >
                                            {(option.sName && option.sName !== "") ? (

                                                <h5 style={{ color: this.props.event.textColor,}}> 
                                                    <i className="fas fa-user mr-2" style={{ color: this.props.event.textColor }}>
                                                    </i><b>{option.sName}</b>
                                                </h5>

                                            ) : (

                                                <h4>
                                                    <i className="fas fa-user mr-2" style={{ color: this.props.event.textColor }}></i>
                                                </h4>

                                            )}
                                        </div>
                                        <div className="col-6 text-right">
                                            {(option.dtmCreated) ? (

                                                <label  htmlFor="inlineCheckbox1" style={{ color: this.props.event.textColor }}>
                                                    <b>{moment(option.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</b>
                                                </label>

                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                </div>                               
                                <div className="card-text cardBodyAnswer" style={{ color: this.props.event.primaryColor }}>
                                    <h6>{option.sAnswer}</h6>
                                </div>
                            </div>
                            
                        )}
                    </div>                        
                )
            });

            return (
                <div className="container-tweet-live">
                    {answerListComponents}
                </div>
            );
        }
    }
}

export default AnswerText;






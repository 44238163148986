import React, { Component } from 'react';
import Workbook from 'react-excel-workbook';
import { questionActions } from '../store/actions/QuestionActions';
import { eventActions } from '../store/actions/EventActions';
import { connect } from 'react-redux';
import moment from 'moment';



class ExportQuestionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedQuestionsList: [],
            openSessionModal: "sessionsModal",
            selectedQuestionsToExportinExcel: [],

            currentquestion: "default",
            currentQuestionOptions: [],
            selectedSessionsList: "default",
        };
        this.getUserPreferences = this.getUserPreferences.bind(this);
        this.saveSessionsModal = this.saveSessionsModal.bind(this);
        this.onSelectAllLastSession = this.onSelectAllLastSession.bind(this);
        this.exportClicked = this.exportClicked.bind(this);
    }

    componentWillUnmount() {
        if (this.unsubscribeInitQuestionOptions) {
            this.unsubscribeInitQuestionOptions();
        }
    }

    getUserPreferences(item, type) {
        switch (type) {
            case "questionCheckBox":
                var question = item;
                var checkboxStatus = document.getElementById("questionCheckBox-" + question.id).checked;
                var selectedQuestionsList = this.state.selectedQuestionsList;
                var questionNotExists = true;
                if (checkboxStatus === true) {
                    for (var i = 0; i < this.state.selectedQuestionsList.length; i++) {
                        if (this.state.selectedQuestionsList[i].id === question.id) {
                            questionNotExists = false;
                        }
                    }

                    if (questionNotExists) {
                        if (this.unsubscribeInitQuestionOptions) {
                            this.unsubscribeInitQuestionOptions();
                        }
                        this.props.onInitQuestionOptions(this.props.event.id, this.props.idQuestionEl, question.id, (optionsCallback, unsubscribe) => {
                            this.unsubscribeInitQuestionOptions = unsubscribe;
                            question.options = optionsCallback;
                            selectedQuestionsList.push(question);
                            this.setState({
                                ...this.state,
                                openSessionModal: "default",
                                selectedQuestionsList,
                                currentquestion: question,
                                currentQuestionOptions: optionsCallback
                            });
                        });
                    }

                    this.props.getVotingSessions(question);


                } else {
                    for (var i = 0; i < this.state.selectedQuestionsList.length; i++) {
                        if (this.state.selectedQuestionsList[i].id === question.id) {
                            selectedQuestionsList.pop(question);
                        }
                    }
                    this.setState({
                        ...this.state,
                        openSessionModal: "sessionsModal",
                        selectedQuestionsList,
                        currentquestion: "default",
                    });
                }
                break;

            case "sessionCheckBox":
                var sessionCheckboxStatus = document.getElementById("sessionCheckBox-" + item.session.id).checked;
                var selectedSessionsList;

                for (var index = 0; index < this.props.validVotingSessions.length; index++) {
                    if (this.props.validVotingSessions[index].session.id === item.session.id) {
                        this.props.validVotingSessions[index].session.selected = sessionCheckboxStatus;
                    }
                }
                break;

            default:
                return true;
        }
    }

    saveSessionsModal() {
        
        let questionType ="";

        let selectedQuestionsToExportinExcel = this.state.selectedQuestionsToExportinExcel;
        for (var index = 0; index < this.props.validVotingSessions.length; index++) {

            if (this.props.validVotingSessions[index].session.selected && this.props.validVotingSessions[index].session.selected === true) {

                if (this.props.validVotingSessions[index].question.type === 'MultipleChoice' || this.props.validVotingSessions[index].question.type === "SimpleTextAndMultipleChoice") {
                    var item = this.props.validVotingSessions[index];

                    for (var m = 0; m < this.state.currentQuestionOptions.length; m++) {
    
    
                        var itemVote = 0;
                        var option = this.state.currentQuestionOptions[m];
    
                        for (var j = 0; j < item.responses.length; j++) {
                            var response = item.responses[j];
                            if (option.id === response.option) {
                                itemVote += 1;
                            }
                        }
    
                        selectedQuestionsToExportinExcel.push({
                            questionText: this.state.currentquestion.text,
                            option: option.text,
                            // votes: itemVote,
                            votesNo: itemVote,
                            votesPercent: Math.round((itemVote/item.responses.length)*100),
                            session: item.session.time
                        });
                    }
                }
                else {
                    questionType = "Simple Text";


                    for (var m = 0; m < this.props.validVotingSessions[index].responses.length; m++) {
                        selectedQuestionsToExportinExcel.push({
                            questionText: this.props.validVotingSessions[index].question.text,
                            questionType: questionType,
                            // option: option.text,
                            response: this.props.validVotingSessions[index].responses[m].sAnswer,
                            session: this.props.validVotingSessions[index].session.time
                        });
                    }
                }
            }

            this.setState({
                ...this.state,
                selectedQuestionsToExportinExcel,
                currentquestion: "default"
            });
        }
    }

    onSelectAllLastSession() {

        var selectedQuestionsToExportinExcel = [];
        var questionType;
        for (var i = 0; i < this.props.questionsList.length; i++) {
            if (this.props.questionsList[i] && this.props.questionsList[i].firstVotingSessionInitialized === true) {
                if (this.props.questionsList[i].type === 'MultipleChoice' || this.props.questionsList[i].type === "SimpleTextAndMultipleChoice") {    
                    this.props.onGetLastVotingSession(this.props.event.id, this.props.idQuestionEl, this.props.questionsList[i], lastVotingSessionCallback => {
                        this.props.onGetArchivedResponses(this.props.event.id, this.props.idQuestionEl, lastVotingSessionCallback.question, lastVotingSessionCallback.id, archivedResponsesList => {
                            if (this.unsubscribeInitQuestionOptions) {
                                this.unsubscribeInitQuestionOptions();
                            }
                            this.props.onInitQuestionOptions(this.props.event.id, this.props.idQuestionEl, lastVotingSessionCallback.question.id, (optionsCallback, unsubscribe) => {
                                this.unsubscribeInitQuestionOptions = unsubscribe;
                                for (var m = 0; m < optionsCallback.length; m++) {
                                    var itemVote = 0;
                                    var option = optionsCallback[m];

                                    for (var j = 0; j < archivedResponsesList.length; j++) {
                                        var response = archivedResponsesList[j];
                                        if (option.id === response.option) {
                                            itemVote += 1;
                                        }
                                    }
 
                                    if(lastVotingSessionCallback.question.type === 'MultipleChoice'){
                                        questionType ="Multiple Choice";
                                    }else if(lastVotingSessionCallback.question.type === "SimpleTextAndMultipleChoice"){
                                        questionType = "Simple Text And Multiple Choice";
                                    }else if(lastVotingSessionCallback.question.type === "SimpleTextQuestion"){
                                        questionType = "Simple Text";
                                    }else{
                                        questionType = "Rating";
                                    }

                                    selectedQuestionsToExportinExcel.push({
                                        questionText: lastVotingSessionCallback.question.text,
                                        questionType: questionType,
                                        option: option.text,
                                        votesNo: itemVote,
                                        votesPercent: Math.round((itemVote/archivedResponsesList.length)*100),
                                        session: lastVotingSessionCallback.time,
                                    });
                                    document.getElementById("questionCheckBox-" + lastVotingSessionCallback.question.id).setAttribute("checked", true);
                                }
                                if (i === this.props.questionsList.length) {
                                    this.setState({
                                        ...this.state,
                                        selectedQuestionsToExportinExcel
                                    });
                                }
                            });
                        });
                    });
                } else {
                    this.props.onGetLastVotingSession(this.props.event.id, this.props.idQuestionEl, this.props.questionsList[i], lastVotingSessionCallback => {
                        this.props.onGetArchivedResponses(this.props.event.id, this.props.idQuestionEl, lastVotingSessionCallback.question, lastVotingSessionCallback.id, archivedResponsesList => {
                            for (var m = 0; m < archivedResponsesList.length; m++) {
                                if(lastVotingSessionCallback.question.type === 'MultipleChoice'){
                                    questionType ="Multiple Choice";
                                }else if(lastVotingSessionCallback.question.type === "SimpleTextAndMultipleChoice"){
                                    questionType = "Simple Text And Multiple Choice";
                                }else{
                                    questionType = "Simple Text";
                                }

                                selectedQuestionsToExportinExcel.push({
                                    questionText: lastVotingSessionCallback.question.text,
                                    questionType: questionType,
                                    // option: option.text,
                                    response: archivedResponsesList[m].sAnswer,
                                    session: lastVotingSessionCallback.time
                                });
                                document.getElementById("questionCheckBox-" + lastVotingSessionCallback.question.id).setAttribute("checked", true);
                            }

                            if (i === this.props.questionsList.length) {
                                this.setState({
                                    ...this.state,
                                    selectedQuestionsToExportinExcel
                                });
                            }
                        });
                    });
                }
            }
        }
    }

    exportClicked() {

        if (document.getElementById('questionModalsForm')) {
            document.getElementById('questionModalsForm').reset();
            for(var i=0; i<this.props.questionsList.length; i++){
                if(this.props.questionsList[i] && document.getElementById("questionCheckBox-" + this.props.questionsList[i].id) && document.getElementById("questionCheckBox-" + this.props.questionsList[i].id).getAttribute('checked')){
                    document.getElementById("questionCheckBox-" + this.props.questionsList[i].id).removeAttribute('checked')
                }
            }
           
            this.setState({
                ...this.state,
                selectedQuestionsList: [],
                openSessionModal: "sessionsModal",
                selectedQuestionsToExportinExcel: [],

                currentquestion: "default",
                currentQuestionOptions: [],
                selectedSessionsList: "default",
            });
        }
    }


    render() {
        var questionsListComponent = this.props.questionsList.map((question, i) => {
            if (question.firstVotingSessionInitialized === true) {
                return (
                    <div key={i} className="custom-control custom-checkbox" id="questionsListComponent" onClick={() => { this.getUserPreferences(question, 'questionCheckBox') }} >
                        <input type="checkbox" className="custom-control-input" id={"questionCheckBox-" + question.id} data-toggle="modal" data-target="#sessionsModal" />
                        <label className="custom-control-label" htmlFor={"questionCheckBox-" + question.id}>{question.text}</label>
                    </div>
                )
            }
        })

        var sessionItemsModal;
        if (this.props.validVotingSessions && this.props.validVotingSessions.length !== 0) {
            sessionItemsModal = this.props.validVotingSessions.map((item, i) => {
                if (item.question.id === this.state.currentquestion.id) {
                    return (
                        <div key={i} className="custom-control custom-checkbox  pb-1" onClick={() => { this.getUserPreferences(item, 'sessionCheckBox') }} >
                            <input type="checkbox" className="custom-control-input" id={"sessionCheckBox-" + item.session.id} data-toggle="modal" data-target="#sessionsModal" />
                            <label className="custom-control-label" htmlFor={"sessionCheckBox-" + item.session.id}>{item.session.time}</label>
                        </div>
                    )
                }
            });
        } else {
            sessionItemsModal = <p> No Session initialized for this question. </p>

        }

        return (
            <div>
                <div className="modal fade" id="exportQuestionsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <form id='questionModalsForm'>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Select Questions To Export</h5>
                                </div>
                                <div className="modal-body">
                                    {questionsListComponent}
                                </div>
                                <div className="modal-footer">
                                    {(this.state.selectedQuestionsToExportinExcel && this.state.selectedQuestionsToExportinExcel.length > 0) ? (
                                        <div>
                                            <Workbook filename={"4meet-Questions-" + this.props.event.title + "-" + this.props.parentInfo.title + ".xlsx"}
                                                element={<button className="btn btn-success ml-3" data-dismiss="modal" id="exportInExcel" style={{ fontSize: "5px;" }} onClick={this.exportClicked}>
                                                            Export Questions in Excel &nbsp;&nbsp;
                                                            <i className="fas fa-download"></i>
                                                        </button>}>
                                                <Workbook.Sheet data={this.state.selectedQuestionsToExportinExcel} name={"All Questions - " + this.props.parentInfo.title}>

                                                    <Workbook.Column label="Questions" value="questionText" />
                                                    <Workbook.Column label="Questions Type" value="questionType" />
                                                    <Workbook.Column label="Sessions" value="session" />
                                                    <Workbook.Column label="Options" value="option" />
                                                    <Workbook.Column label="Number of Responses" value="votesNo" />
                                                    <Workbook.Column label="Percentage of Responses" value="votesPercent" />
                                                    <Workbook.Column label="Answer" value="response" />
                                                    {/* <Workbook.Column label="Option Vote" value={response => response.votes} /> */}
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </div>
                                    ) : (
                                            <button type="button" className="btn btn-primary" onClick={this.onSelectAllLastSession}> Select Last Session of All </button>
                                        )}
                                            <button type="button" className="btn btn-secondary px-4" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="modal fade" id={this.state.openSessionModal} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Question: {this.state.currentquestion.text}</h5>
                            </div>
                            <div className="modal-body modalSessions">
                                <p>Choose Sessions to export for this question: </p>
                                <div className="pt-2 pl-2">
                                    {sessionItemsModal}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveSessionsModal} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitQuestionOptions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllQuestionOptions(eventId, idQuestionEl, questionId, callback)),
        onSaveVotingSession: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.saveVotingSession(eventId, idQuestionEl, questionId, callback)),
        onGetAllVotingSessions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllVotingSessions(eventId, idQuestionEl, questionId, callback)),
        onGetResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetLastVotingSession: (eventId, idQuestionEl, question, callback) => dispatch(questionActions.getLastVotingSession(eventId, idQuestionEl, question, callback)),
    };
};

export default connect(null, mapDispatchToProps)(ExportQuestionsModal);
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import HeaderTab from '../Components/HeaderTab';
import EditModal from '../Modals/EditModal';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {Tooltip} from 'react-tippy';


var QRCode = require('qrcode.react');

class LibraryScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            libraryId: this.props.match.params.idItem,
            user: 'default',
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
            sortedFilesList: [],
            editingItem: 'default',
            currentEvent: 'default',
            parentInfo: 'default',
            libraryInfo: '',
            qrCodeLink: "default",
            currenFile: "default",
            saveLinkStatus: "default",
            editingLibraryItem: "default"
        };

        this.handleDropFile = this.handleDropFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.saveLink = this.saveLink.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.editFileName = this.editFileName.bind(this);
        this.saveEditedFile = this.saveEditedFile.bind(this);
        this.cancelFile = this.cancelFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        //if( document.getElementById('downloadLinkLibrary')){
        //}
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({...this.state, user: user});

        if (this.unsubscribeGetLibraryFiles) {
            this.unsubscribeGetLibraryFiles();
        }
        this.props.onGetLibraryFiles(this.state.eventId, this.state.libraryId, (filesList, unsubscribe) => {
            this.unsubscribeGetLibraryFiles = unsubscribe;
            this.setState({...this.state, sortedFilesList: filesList});
        });
        // document.getElementById('downloadLinkLibrary').addEventListener('click', this.download, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
        });

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, parentInfo => {
            this.setState({...this.state, parentInfo: parentInfo});
        });

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }
        this.props.onGetLibraryInfoOnChange(this.state.eventId, this.state.libraryId, (libraryInfo, unsubscribe) => {
            this.setState({...this.state, libraryInfo: libraryInfo});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let fileNameInput = document.getElementById("fileInputName");
        let libraryNameInput = document.getElementById("libraryNameInput");
        if (fileNameInput) fileNameInput.focus();
        if (libraryNameInput) libraryNameInput.focus();
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }

        if (this.unsubscribeGetLibraryFiles) {
            this.unsubscribeGetLibraryFiles();
        }
    }

    downloadFile(file) {
        const canvas = document.getElementById('qrCodeImageLibrary' + file.id);
        var dt = canvas.toDataURL('image/jpeg', {pixelRatio: 3});
        this.setState({...this.state, qrCodeLink: dt, currenFile: file}, () => {
            document.getElementById("downloadLinkLibrary").click();
            // this.href = dt;
        });

    }

    handleUploadFile(e) {

        let filePath = "/files/" + e.target.files[0].name;

        var previewFile = document.getElementById('filePreview');
        var file = document.getElementById('inputFilePreview').files[0];
        // var FileSize = file.size / 1024 / 1024; // in MB
        var FileSize = Math.round(file.size / 1024); // 
        var validFileExtensions = ["All image formats", "All Video Formats", "All Audio Formats", ".pdf", ".txt"];
        var fileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/bmp",
            "image/gif",
            "image/png",
            "video/*",
            "audio/*",
            "audio/mp3",
            "video/mp4",
            "video/flv",
            "application/pdf",
            "text/plain",
        ]
        // var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png",
        //     "video/*",
        //     "audio/*",
        //     "audio/mp3",
        //     "video/mp4",
        //     "video/flv",
        //     "application/pdf",
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //     "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        //     "application/vnd.ms-powerpoint",
        //     "text/plain",
        // ]

        if (!fileTypes.includes(file.type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                previewFile.src = reader.result;
            }, false);

            if (file) {
                reader.readAsDataURL(file);
                // this.props.uploadLogo(file);
                this.setState({
                    ...this.state,
                    filePath: filePath,
                    filePreviewStatus: 'selectedFile',
                    uploadFiles: file,
                    selectedFileToUpload: file
                });
            }
        }

    }


    handleDropFile(files) {

        var filesFiltered = [];
        var fileSizeLimit = 5000;
        var validFileExtensions = ["All image formats", "All Video Formats", "All Audio Formats", ".pdf", ".txt"];
        var fileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/bmp",
            "image/gif",
            "image/png",
            "audio/mp3",
            "video/mp4",
            "video/flv",
            "audio/*",
            "application/pdf",
            "text/plain",
        ]
        files.map(file => {
            var FileSize = Math.round(file.size / 1024);
            if (!fileTypes.includes(file.type)) {
                //alert('The selected file is not an image. Please upload an image with the correct type.');
                alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
            } else if (FileSize > fileSizeLimit) {
                alert('The size of this file (' + file.name + ') is: ' + FileSize + " KB, please choose a file less than" + fileSizeLimit + "KB.");
            } else {
                filesFiltered.push(file);
                /* if (filesFiltered) {
                    this.setState({
                        ...this.state,
                        filePath: fileLocalPath,
                        // uploadFiles: files[0],
                        uploadFiles: files.map(file => ({
                            ...file,
                            preview: URL.createObjectURL(file)
                        })),
                        filePreviewStatus: 'drag&drop',
                        selectedFileToUpload: filesFiltered
                    });
                } */
            }
        });
        this.saveFile(filesFiltered);

    }

    saveFile(files) {

        //this.props.onSaveEvent(this.props.user.userId, eventData, callback => {
        //const file = this.state.selectedFileToUpload;
        var addOrderIndex = this.state.sortedFilesList.length;
        files.map(file => {
            let fileData = {
                name: file.name,
                type: file.type,
                sParentId: this.state.idElement,
                fileOrderIndex: addOrderIndex
            }
            addOrderIndex++;
            this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, fileData, 'newFile', savedItem => {
                if (savedItem.message === "success") {
                    this.props.onUploadLibraryFile(this.state.user.id, this.state.eventId, this.state.libraryId, savedItem.data.id, "ImageFile", file, callback => {
                        if (callback === 'success') {
                            this.setState({
                                ...this.state,
                                filePreviewStatus: 'default',
                                uploadFiles: [],
                                fileStatus: 'default',
                                filePath: 'default',
                                selectedFileToUpload: [],
                            })
                        }
                    });
                }
            })
        });

        //we need also to update the firebase if the user has been removed the images and going back to the default status there
        // if (this.state.imagePreviewStatus === "removePhoto") {
        //     this.props.onUploadLibraryFile(userId, eventId, elementId, itemId, "removePhoto", file, callback =>{
        //      });
        // }
        // });

    }

    saveLink() {

        let LinkAddressLibrary = document.getElementById('LinkAddressLibrary').value;
        const file = this.state.selectedFileToUpload;
        var addOrderIndex = this.state.sortedFilesList.length;
        let fileData = {
            name: 'External Link ' + addOrderIndex,
            type: "link",
            sParentId: this.state.idElement,
            fileOrderIndex: addOrderIndex,
            fileUrl: LinkAddressLibrary
        }
        addOrderIndex++;
        this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, fileData, 'newFile', savedItem => {
            if (savedItem.message === "success") {
                this.props.onUploadLibraryFile(this.state.user.id, this.state.eventId, this.state.libraryId, savedItem.data.id, "ImageFile", file, callback => {
                    if (callback === 'success') {
                        this.setState({
                            ...this.state,
                            filePreviewStatus: 'default',
                            uploadFiles: [],
                            fileStatus: 'default',
                            filePath: 'default',
                            selectedFileToUpload: [],
                        });
                    }
                });
            }
        });

    }

    handleText = (e) => {

        switch (e.target.name) {
            case "LinkAddressLibrary":
                if (e.value !== "") {
                    this.setState({...this.state, saveLinkStatus: "available", [e.LinkAddressLibrary]: e.value});
                }
                break;

            default:
                return true;
        }

    }

    cancelFile() {

        this.setState({
            ...this.state,
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
        })

    }

    removeFile(file) {
        this.props.onRemoveLibraryFile(this.state.eventId, this.state.libraryId, file.id);
    }

    onSortEnd = ({oldIndex, newIndex}) => {

        const {sortedFilesList} = this.state;
        var newSortedList = arrayMove(sortedFilesList, oldIndex, newIndex);
        this.props.onUpdateFilesIndexes(this.state.eventId, this.state.libraryId, newSortedList);
        this.setState({
            ...this.state, sortedFilesList: arrayMove(sortedFilesList, oldIndex, newIndex)
        });

    };

    editFileName(file) {

        if (this.state.editingItem === "default") {
            this.setState({
                ...this.state, editingItem: file
            });
        } else {
            var fileInputName = document.getElementById("fileInputName").value;
            let fileData = file;
            fileData.name = fileInputName;
            this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, fileData, 'nameEdited', savedItem => {
                this.setState({
                    ...this.state, editingItem: 'default'
                });
            });
        }

    }

    saveEditedFile(e) {

        e.preventDefault();
        if (e.keyCode === 13) {
            var fileInputName = document.getElementById("fileInputName").value;
            let fileData = this.state.editingItem;
            fileData.name = fileInputName;
            this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, fileData, 'nameEdited', savedItem => {
                this.setState({
                    ...this.state, editingItem: 'default'
                });
            });
        }

    }

    goToTweetScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    goToQuestionScreen() {
        if (this.state.parentInfo.currentQuestionElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }
    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentSurveyElement.id !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);
        }
    }

    handleEditEvent() {
        /* this.props.setCurrentEventId(this.state.currentEvent.id, callback => {
            var img = document.getElementById('imagePreview');
            if(img){
                if (this.state.currentEvent.eventLogoUrl !== 'default') {
                    img.src = this.state.currentEvent.eventLogoUrl;
                } else {
                    img.src = "/files/LogoTemp.png";
                }
            }
        }); */

        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    getUserPreference(file, type) {

        switch (type) {
            case "showLibraryInMenu":
                this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, file, 'showLibraryInMenu', savedItem => {
                });
                break;
            case "hideLibraryInMenu":
                this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, file, 'hideLibraryInMenu', savedItem => {
                });
                break;
            case "editLibraryName":
                this.setState({
                    ...this.state, editingLibraryItem: ''
                });
                break;
            case "saveEditedLibraryName":
                var libraryNameInput = document.getElementById("libraryNameInput").value;
                this.props.onSaveLibraryFile(this.state.eventId, this.state.libraryId, libraryNameInput, 'saveEditedLibraryName', savedItem => {
                    this.setState({
                        ...this.state, editingLibraryItem: 'default'
                    });
                });
                break;
            case "cancelEditedLibraryName":
                this.setState({
                    ...this.state, editingLibraryItem: 'default'
                });
                break;
            default:
                return true;
        }
    }

    goIntroductionPage() {
        if (this.state.tabScreenOpened === false) {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/live", '_blank');
                win.focus();
                this.setState({...this.state, tabScreenOpened: true})
            });
        } else {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                this.setState({...this.state, tabScreenOpened: true})
            });
        }
    }

    render() {

        var selectedLanguageStrings;
        if (this.state.currentEvent && this.state.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        const {uploadFiles} = this.state;
        let filePreviewComponent;

        if (this.state.filePreviewStatus === 'drag&drop') {
            filePreviewComponent = (
                <div>{uploadFiles.map((file) => <img src={file.preview} id="filePreview"
                                                     className='img-fluid img-thumbnail logoEditModalImage'
                                                     alt=''/>)}</div>
            )
        } else {
            filePreviewComponent = (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <img src={"/files/file-upload.png"} id="filePreview"
                                 className='img-fluid img-thumbnail logoEditModalImageAgenda'
                                 style={{backgroundColor: '#e6e6e6'}} alt=''/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <label className="px-2 agenda-upload-Text">{selectedLanguageStrings.drag_and_drop} </label>
                        </div>
                    </div>
                </div>
            )
        }

        var sortedFilesList = this.state.sortedFilesList;
        var SortableList;
        var fileTypeComponent;
        let imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];

        if (this.state.sortedFilesList && this.state.sortedFilesList.length > 0) {
            SortableList = SortableContainer(({sortedFilesList}) => {
                return (
                    <ul className="pl-0">
                        {sortedFilesList.map((file, index) => (
                            <SortableItem key={`item-${index}`} index={index} file={file}/>
                        ))}
                    </ul>
                );
            });

            const SortableItem = SortableElement(({file}) => {
                if (imageTypes.includes(file.type)) {
                    fileTypeComponent = <i className="fas fa-file-image text-success"></i>
                } else if (file.type === "application/pdf") {
                    fileTypeComponent = <i className="fas fa-file-pdf text-danger"></i>
                } else if (file.type === "video/mp4" || file.type === "video/flv") {
                    fileTypeComponent = <i className="fas fa-file-video text-warning"></i>
                } else if (file.type === "text/plain") {
                    fileTypeComponent = <i className="fas fa-file-alt text-info"></i>
                } else {
                    fileTypeComponent = <i className="fas fa-file-download text-secondary"></i>
                }
                return (
                    <div className="row align-items-center pb-3">
                        <div className="col-1 col-sm-1 text-center pr-0 card-body-files-icons pl-2">
                            {fileTypeComponent}
                        </div>
                        {(this.state.editingItem.id === file.id) ? (

                            <div className="col-10 col-lg-5 col-md-5 col-sm-5 labelFolder pl-0 ">
                                <div className="input-group pr-3 ml-3">
                                    <input type="text" className="form-control" onKeyUp={this.saveEditedFile}
                                           id="fileInputName" defaultValue={file.name} aria-label="Title"
                                           aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button> */}
                                    </div>
                                </div>
                            </div>

                        ) : (

                            <div className="col-10 col-lg-5 col-md-5 col-sm-5">
                                <div className="card card-body-files">
                                    <div className="row">
                                        <div className="col-11 col-lg-8 col-md-9 col-sm-10">
                                            <a href={file.fileUrl} target="_blank">
                                                {file.name}
                                            </a>
                                        </div>
                                        <div className="col-1 col-lg-4 col-md-3 col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                        )}
                        <div className="col-1">
                            <DragHandle
                                file={file}
                            />
                        </div>
                        <div className="col-3 col-lg-1 col-md-1 col-sm-1 text-center pl-0 pr-0">
                            {/* <QRCode value={"https://4meet.in/event/"+this.state.currentEvent.id +"/dashboard/"+ this.state.idElement +"/"+this.state.libraryId+"/"+ file.id } id="qrCodeImage" /> */}
                            <QRCode className={"qrCodeImageLibrary"}
                                    value={"https://4meet.in/event/" + this.state.currentEvent.id + "/dashboard/" + this.state.libraryId + "/show/" + file.id}
                                    id={"qrCodeImageLibrary" + file.id} size={2000}/>
                        </div>
                        {(this.state.currentEvent.eventStatus === "Archived") ? (

                            <div className="col col-lg-4 col-md-4 col-sm-4 text-left pl-0">
                                <Tooltip
                                    // options
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                    html={(
                                        <p className="text-left" style={{width: 200}}>
                                            Download the QR code of the file.
                                        </p>
                                    )}
                                >
                                    <button type="button" className="btn btn-primary mb-1 fileBtnTexts mr-2"
                                            id="QrButtonDownload" onClick={() => {
                                        this.downloadFile(file)
                                    }}>
                                        &nbsp;{selectedLanguageStrings.download_qr_code}&nbsp;
                                    </button>
                                </Tooltip>
                            </div>

                        ) : (

                            <div className="col col-lg-4 col-md-4 col-sm-4 text-left pl-0">
                                <Tooltip
                                    // options
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                    html={(
                                        <p className="text-left" style={{width: 210}}>
                                            Download the QR code of the file
                                        </p>
                                    )}
                                >
                                    <button type="button" className="btn bg-success text-white mb-1 fileBtnTexts mr-2"
                                            id="QrButtonDownload" onClick={() => {
                                        this.downloadFile(file)
                                    }}>
                                        &nbsp;{selectedLanguageStrings.download_qr_code}&nbsp;
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    // options
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                    html={(
                                        <p className="text-left" style={{width: 110}}>
                                            Change file name
                                        </p>
                                    )}
                                >
                                    <button className="btn bg-primary mr-2 mb-1 text-white fileBtnTexts px-3"
                                            onClick={() => {
                                                this.editFileName(file)
                                            }}>
                                        <i className="fas fa-pencil-alt"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    // options
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="10"
                                    animation={"fade"}
                                    arrow={true}
                                    duration={1200}
                                    html={(
                                        <p className="text-left" style={{width: 65}}>
                                            Delete file
                                        </p>
                                    )}
                                >
                                    <button className="btn bg-danger mb-1 text-white fileBtnTexts px-3" onClick={() => {
                                        this.removeFile(file)
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </Tooltip>
                            </div>

                        )}
                    </div>
                )
            });

            const DragHandle = SortableHandle(({file}) => {
                return (
                    <div className="flipped">
                        <i className="fas fa-arrows-alt-v moveIconElements p-2 m-auto"></i>
                    </div>
                )
            });
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    <div>
                        <Header
                            page="LibraryScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            goIntroductionPage={this.goIntroductionPage.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"library"}
                        />
                        <HeaderTab
                            page="LibraryScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            goToTweetScreen={this.goToTweetScreen.bind(this)}
                            activeScreen={'library'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        />
                        <main role="main">
                            <div className="container">
                                {(this.state.currentEvent.eventStatus === "Archived") ? (
                                    <div></div>
                                ) : (

                                    <div className="row  pb-3  align-items-center">
                                        <div className="col-12 col-sm-auto text-center text-sm-left pr-sm-0">
                                            <h3>Library Title:</h3>
                                        </div>
                                        {(this.state.editingLibraryItem === "default") ? (

                                            <div
                                                className="col-12 col-sm-auto text-center text-sm-left text-primary pt-3 pt-sm-0">
                                                <h3>{this.state.libraryInfo.title}</h3>
                                            </div>

                                        ) : (

                                            <div className="col text-left">
                                                <input type="text" className="form-control" id="libraryNameInput"
                                                       defaultValue={this.state.libraryInfo.title} aria-label="Title"
                                                       aria-describedby="basic-addon2"/>
                                            </div>

                                        )}
                                        <div className="col-12 col-sm-3 text-center text-sm-left pt-1 pt-sm-0">
                                            {(this.state.editingLibraryItem === "default") ? (

                                                <button
                                                    className="col-auto btn bg-primary mr-2 mb-1 text-white fileBtnTexts px-3"
                                                    onClick={() => {
                                                        this.getUserPreference("", "editLibraryName")
                                                    }}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                </button>

                                            ) : (

                                                <span>
                                                    <button
                                                        className="col-auto btn bg-secondary text-white mr-2 mb-1 fileBtnTexts px-3"
                                                        onClick={() => {
                                                            this.getUserPreference("", "cancelEditedLibraryName")
                                                        }}>
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                    <button
                                                        className="col-auto btn bg-success text-white mr-2 mb-1 fileBtnTexts px-3"
                                                        onClick={() => {
                                                            this.getUserPreference("", "saveEditedLibraryName")
                                                        }}>
                                                        <i className="fas fa-check"></i>
                                                    </button>
                                                </span>

                                            )}
                                            {(this.state.libraryInfo.showLibraryInMenu === true) ? (

                                                <button style={{backgroundColor: "#6e7177"}}
                                                        className="col-auto btn text-white mr-2 mb-1" onClick={() => {
                                                    this.getUserPreference(this.state.LibraryFile, "hideLibraryInMenu")
                                                }}>
                                                    <i className="fas fa-eye-slash mr-1"></i> Hide
                                                </button>

                                            ) : (

                                                <button className="col-auto btn btn-warning mr-2 mb-1"
                                                        style={{color: "#6e7177"}} onClick={() => {
                                                    this.getUserPreference(this.state.LibraryFile, "showLibraryInMenu")
                                                }}>
                                                    <i className="fas fa-eye mr-1"></i> Show
                                                </button>

                                            )}
                                        </div>
                                        {(this.state.selectedFileToUpload.length === 0) ? (
                                            <div className="col-12 text-center text-sm-left pt-3 pt-sm-0">
                                                <Dropzone
                                                    className="inputFileDropZone d-flex text-center"
                                                    onDrop={this.handleDropFile}
                                                    multiple
                                                >
                                                    <div className="text-center p-0 my-auto">
                                                        {/* {(uploadFiles.type === "application/pdf") ? ( */}
                                                        <div>
                                                            {filePreviewComponent}
                                                        </div>
                                                    </div>
                                                </Dropzone>
                                                <div className="form-group row mt-3">
                                                    <div
                                                        className="col-12 col-sm-2 col-md-2 col-lg-2 text-center text-sm-left pr-sm-0">
                                                        <label htmlFor="LinkAddressLibrary"
                                                               className="col-form-label">{selectedLanguageStrings.file_url}</label>
                                                    </div>
                                                    <div className="col-9 col-sm-8 col-md-8 col-lg-8">
                                                        <input type="text" className="form-control"
                                                               id="LinkAddressLibrary" name="LinkAddressLibrary"
                                                               placeholder="URL" onChange={this.handleText}/>
                                                    </div>
                                                    {(this.state.saveLinkStatus === "default") ? (

                                                        <div className="col-3 col-sm-auto">
                                                            <button type="button"
                                                                    className="btn bg-secondary text-white px-sm-4"
                                                                    disabled>
                                                                Save
                                                            </button>
                                                        </div>

                                                    ) : (

                                                        <div className="col-3 col-sm-auto">
                                                            <button type="button"
                                                                    className="btn bg-success text-white px-sm-4"
                                                                    onClick={this.saveLink}>
                                                                Save
                                                            </button>
                                                        </div>

                                                    )}
                                                </div>
                                                {/* </div> */}
                                                {/* <input type="file" accept="image/png, image/jpeg, image/gif" className="form-control" id="inputLogo" hidden={true} placeholder="Logo" onChange={this.handleUploadFile.bind(this)} /> */}
                                                <input type="file" className="form-control" id="inputFilePreview"
                                                       hidden={true} placeholder="Logo"
                                                       onChange={this.handleUploadFile}/>
                                            </div>

                                        ) : (

                                            <div className="col-12">
                                                {/* <div>
                                                    <b>{selectedLanguageStrings.file_name}: </b>{this.state.selectedFileToUpload.name} 
                                                </div>   */}
                                                <div className="pt-2">
                                                    <button type="button"
                                                            className="btn bg-secondary text-white px-2 pt-2 mr-3 px-3"
                                                            onClick={this.cancelFile}>
                                                        <i className="fas fa-times pr-2 pl-1 text-white"></i> {selectedLanguageStrings.cancel}
                                                    </button>

                                                    <button type="button"
                                                            className="btn bg-success text-white pt-2 px-2"
                                                            onClick={this.saveFile}>
                                                        <i className="fas fa-arrow-up pr-2 pl-1 text-white "></i> {selectedLanguageStrings.upload_file}
                                                    </button>
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                )}
                                <div className="row">
                                    <h3 className="col-12 pb-3 text-center text-sm-left fileListHeader">
                                        {selectedLanguageStrings.title_uploaded_files}
                                    </h3>
                                </div>
                                {(this.state.sortedFilesList.length === 0) ? (

                                    <div className="card">
                                        <div className="card-body text-center">
                                            No Uploaded file.
                                        </div>
                                    </div>

                                ) : (

                                    <div className="row">
                                        <div className="col-12">
                                            {(this.state.sortedFilesList && this.state.sortedFilesList.length > 0) ? (
                                                <SortableList sortedFilesList={sortedFilesList}
                                                              onSortEnd={this.onSortEnd} useDragHandle={true}/>
                                            ) : (
                                                <div>
                                                    {/* <h5> No uploaded File Yet.</h5> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                )}
                            </div>
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"LibraryScreen"}
                        />
                    </div>
                )}
                <a id="downloadLinkLibrary" download={"4meet-Event-" + this.state.currenFile.name + ".jpg"}
                   href={this.state.qrCodeLink}></a>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onUploadLibraryFile: (userId, eventId, elementId, itemId, type, file, callback) => dispatch(eventActions.uploadLibraryFile(userId, eventId, elementId, itemId, type, file, callback)),
        onSaveLibraryFile: (eventId, libraryId, fileData, type, callback) => dispatch(eventActions.saveLibraryFile(eventId, libraryId, fileData, type, callback)),
        onGetLibraryFiles: (eventId, libraryId, callback) => dispatch(eventActions.getLibraryFiles(eventId, libraryId, callback)),
        onRemoveLibraryFile: (eventId, libraryId, fileId) => dispatch(eventActions.removeLibraryFile(eventId, libraryId, fileId)),
        onUpdateFilesIndexes: (eventId, libraryId, newSortedList) => dispatch(eventActions.updateLibraryFileIndexes(eventId, libraryId, newSortedList)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onGetLibraryInfoOnChange: (eventId, libraryId, callback) => dispatch(eventActions.getLibraryInfoOnChange(eventId, libraryId, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
    };
};

const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
        libraryFilesList: state.eventReducer.libraryFilesList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryScreen);
import { actionTypes } from '../actions/actionTypes';

export function alertReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case actionTypes.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case actionTypes.CLEAR:
      return {};
    case actionTypes.INVALID:
      return{
        type: 'alert-invalid',
        message: action.message.message
    }
    default:
      return state
  }
}

import React, { Component } from 'react';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
var QRCode = require('qrcode.react');


class IntroductionPage extends Component {
    constructor(props) {
        super(props);
        this.state={
           
        }
    }

    render() {

        var selectedLanguageStrings;
        if(this.props.currentEventToIntroduction && this.props.currentEventToIntroduction.selectedLanguage === 'English'){
            selectedLanguageStrings = englishStrings;
        }else{
            selectedLanguageStrings = italianStrings;
	    }

        return(
               
            <div className=" col-12 introductionContainer" style={{color:this.props.currentEventToIntroduction.primaryColor}}>
                <div className="introductionTitle">{selectedLanguageStrings.join_event_through}: </div>
                <div className="introductionContent pt-1">4meet.in</div>     
                <div className="introductionContent2 pt-1">{selectedLanguageStrings.using_event_code}:</div>
                <div className="introductionContent3 pt-1">#{this.props.currentEventToIntroduction.eventCode}</div>
                <div className="row rowQrCode"> 
                    <div className="col-12 col-12 text-center">
                        <QRCode value={"https://4meet.in/" + this.props.currentEventToIntroduction.eventCode} id="qrCodeImage2" />
                    </div>
                </div>
            </div>
            
        )
    }
}

export default IntroductionPage;
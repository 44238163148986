import React, { Component } from 'react';

class SimpleTextModal extends Component {

    render() {

        return (
             <div className="modal fade" id="SimpleTextQuestion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered questionModalsDialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Simple Text (Open Text) Question</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="card-body text-center">
                                <form id='simpleQuestionModalsForm'>
                                    <div className="form-row align-items-center">

                                        <div className="col-12 my-1 " id="inlineFormInputName2">
                                            <input type="text" id="simpleTextQuestion" className="form-control text-dark mb-2 mr-sm-2" placeholder="What would you like to ask?" defaultValue={this.props.question.text} />
                                        </div>

                                        {/* <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                            <label className="custom-control-label" htmlFor="customControlInline">Let Participants select multiple choices</label>
                                        </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { this.props.saveQuestionFunction('SimpleTextQuestion', this.props.question, '', '','',"default") }} >{this.props.saveBtnTitle}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SimpleTextModal;

























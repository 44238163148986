import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';
import EventInfoComponent from '../Components/EventInfoComponent';


class EventInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            parentId: this.props.match.params.idElement,
            eventInfoElementId: this.props.match.params.idItem,
            user: 'default',
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
            sortedFilesList: [],
            editingItem: 'default',
            showEventInfoComponent: false,
            sortedEventInfoFilesList: []
        };

        this.handleDropFile = this.handleDropFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.goToEventInfoComponent = this.goToEventInfoComponent.bind(this);
        // this.saveFile = this.saveFile.bind(this);
        // this.removeFile = this.removeFile.bind(this);
        // this.editFileName = this.editFileName.bind(this);
        // this.saveEditedFile = this.saveEditedFile.bind(this);
    }

    componentDidMount() {
        if (this.unsubscribeGetEventInfoFiles) {
            this.unsubscribeGetEventInfoFiles();
        }
        this.props.onGetEventInfoFiles(this.state.eventId, this.state.eventInfoElementId, (eventInfoFilesList, unsubscribe) => {
            this.unsubscribeGetEventInfoFiles = unsubscribe;
            this.setState({...this.state, sortedEventInfoFilesList: eventInfoFilesList});
        })
    }

    componentWillUnmount() {
        if (this.unsubscribeGetEventInfoFiles) {
            this.unsubscribeGetEventInfoFiles();
        }
    }

    handleUploadFile(e) {
        let filePath = "/files/" + e.target.files[0].name;
        let targetFile = e.target.files[0].name;

        var previewFile = document.getElementById('filePreview');
        var file = document.getElementById('inputFilePreview').files[0];
        var FileSize = Math.round(file.size / 1024); // 
        var validFileExtensions = ["All image formats", ".pdf"];
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png", "applicayion/pdf"]

        if (!fileTypes.includes(file.type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                previewFile.src = reader.result;
            }, false);

            if (file) {
                reader.readAsDataURL(file);
                this.setState({
                    ...this.state,
                    filePath: filePath,
                    filePreviewStatus: 'selectedFile',
                    uploadFiles: file,
                    selectedFileToUpload: file
                });
            }
        }

    }


    handleDropFile(files) {

        var validFileExtensions = ["All image formats", ".pdf"];
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png", "application/pdf"]
        var FileSize = Math.round(files[0].size / 1024);
        let fileLocalPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            if (files[0]) {
                this.setState({
                    ...this.state,
                    filePath: fileLocalPath,
                    // uploadFiles: files[0],
                    uploadFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    filePreviewStatus: 'drag&drop',
                    selectedFileToUpload: files[0]
                });
            }
        }

    }

    goToEventInfoComponent() {
        this.setState({...this.state, showEventInfoComponent: true});
    }

    cancelEventInfo() {
        this.setState({...this.state, showEventInfoComponent: false});
    }

    saveEventInfoFile(file) {

        var title = document.getElementById("titleInput").value;
        var option = document.getElementById("emailAddressInfo").value;
        var emailAddress = document.getElementById("optionInput").value;
        var eventInfoData = {
            title: title,
            option: option,
            emailAddress: emailAddress,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            sParentId: this.state.eventInfoElementId
        }

        this.props.onSaveEventInfoFile(this.state.eventId, this.state.eventInfoElementId, eventInfoData, file, 'saveNewInfo', savedItem => {
            this.setState({...this.state, showEventInfoComponent: false});
        });

    }

    render() {
        const {uploadFiles} = this.state;
        let filePreviewComponent;
        if (this.state.filePreviewStatus === 'drag&drop') {
            filePreviewComponent = (
                <div>{uploadFiles.map((file) => <img src={file.preview} id="imagePreview"
                                                     className='img-fluid img-thumbnail logoEditModalImage'/>)}</div>
            )
        } else {
            filePreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreview"
                     className='img-fluid img-thumbnail logoEditModalImage' style={{backgroundColor: '#e6e6e6'}}/>
            )
        }
        var eventInfoListComponent;
        if (this.state.sortedEventInfoFilesList && this.state.sortedEventInfoFilesList.length > 0) {
            eventInfoListComponent = this.state.sortedEventInfoFilesList.map(file => {
                return (
                    <div>{file.title}</div>
                )
            })
        } else {
            eventInfoListComponent = (<div>No File</div>)
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    <div>
                        <Header
                            page="LibraryScreen"
                        />
                        {/* <HeaderTab
                            page="LibraryScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            navItemTweet={"border-bottom"}
                            activeScreen={'tweet'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        /> */}

                        <main role="main">
                            <div className="container">
                                {(this.state.showEventInfoComponent === false) ? (

                                    <div>
                                        <button className="btn btn-primary" onClick={this.goToEventInfoComponent}>
                                            <i className="fas fa-plus pr-2 pl-1 text-white"></i> Add New Event Info file
                                        </button>
                                        <div>
                                            {eventInfoListComponent}
                                        </div>
                                    </div>

                                ) : (

                                    <EventInfoComponent
                                        saveEventInfoFile={this.saveEventInfoFile.bind(this)}
                                        cancelEventInfo={this.cancelEventInfo.bind(this)}
                                    />

                                )}
                            </div>
                        </main>
                    </div>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveEventInfoFile: (eventId, eventInfoElementId, eventInfoData, file, type, callback) => dispatch(eventActions.saveEventInfofile(eventId, eventInfoElementId, eventInfoData, file, type, callback)),
        onGetEventInfoFiles: (eventId, eventInfoElementId, callback) => dispatch(eventActions.getEventInfoFiles(eventId, eventInfoElementId, callback)),
    };
};

const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfoScreen);
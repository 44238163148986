import React, { Component } from 'react';

class QuestionTypesModal extends Component {

    render() {
        return (
            <div className="modal fade" id="QuestionTypesModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered questionModalsDialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="QuestionTypesModal">Create a Question</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body mx-3 mt-2 mb-2">
                            <div className="row">
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3  text-center pl-2 pr-1">
                                    <div className="card border-primary questionTypeCards">
                                        <div className="card-body text-center  h-80" data-toggle="modal" data-dismiss="modal" data-target="#MultipleChoice" onClick={() => {this.props.modalSelectedFunction("MultipleChoice") }}>
                                            {/* <img className="card-img-top" id="imagesize2" src="/files/Question-images/checklist-64.png" alt="Card cap" /> */}
                                            <i className="fas fa-tasks text-primary commentQuestionType"></i>
                                        </div>
                                    </div>
                                    <p className="text-center pt-2" id="textalign">Multiple Choice</p>
                                </div>
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3  text-center  pl-1 pr-1">
                                    <div className="card border-warning">
                                        <div className="card-body text-center   h-80 questionTypeCards" data-toggle="modal" data-dismiss="modal" data-target="#SimpleTextQuestion" onClick={() => {this.props.modalSelectedFunction("SimpleTextQuestion") }}>
                                            {/* <img className="card-img-top" id="imagesize2" src="/files/Question-images/school-material-256.png" alt="Card cap" /> */}
                                            <i className="far fa-edit text-warning commentQuestionType"></i>
                                        </div>
                                    </div>
                                    <p className="text-center pt-2" id="textalign">Simple Text<br/>(Open Text) </p>
                                </div>
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3 text-center pl-1 pr-1">
                                    <div className="card border-success">
                                        <div className="card-body text-success  h-80 questionTypeCards" data-toggle="modal" data-dismiss="modal" data-target="#SimpleTextAndMultipleChoice" onClick={() => {this.props.modalSelectedFunction("SimpleTextAndMultipleChoice") }}>
                                            {/* <img className="card-img-top" id="imagesize2" src="/files/Question-images/school-material-256.png" alt="Card cap" /> */}
                                            <i className="far fa-comments text-success commentQuestionType"></i>
                                        </div>
                                    </div>
                                    <p className="text-center pt-2" id="textalign">Simple Text And Multiple Choice</p>
                                </div>

                                <div className="col-3 col-sm-3 col-md-3 col-lg-3  text-center  pl-1 pr-1" >
                                    <div className="card border-danger">
                                        <div className="card-body text-center px-1 h-80 questionTypeCards" data-toggle="modal" data-dismiss="modal" data-target="#RatingQuestion" onClick={() => {this.props.modalSelectedFunction("Rating") }}>
                                            {/* <img className="card-img-top" id="imagesize2" src="/files/Question-images/school-material-256.png" alt="Card cap" /> */}
                                            <i className="fas fa-star text-danger ratingStartsType"></i>
                                            <i className="fas fa-star text-danger ratingStartsType"></i>
                                            <i className="far fa-star text-danger ratingStartsType"></i>
                                        </div>
                                    </div>
                                    <p className="text-center pt-2" id="textalign">Rating</p>
                                </div>
                                
                                {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="card border-warning">
                                        <div className="card-body text-center  h-100" data-toggle="modal" data-dismiss="modal" data-target="#Rating" onClick={() => {this.props.modalSelectedFunction("Rating") }}>
                                            <div className="card-img-top " id="imagesize3" >
                                                <img src="/files/Question-images/star.png" alt="Card  cap" />
                                                <img src="/files/Question-images/star.png" alt="Card  cap" />
                                                <img src="/files/Question-images/star-blank.png" alt="Card  cap" />
                                                <img src="/files/Question-images/star-blank.png" alt="Card  cap" />
                                                <img src="/files/Question-images/star-blank.png" alt="Card  cap" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center" id="textalign">Rating</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close">Cancel</button>
                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionTypesModal;


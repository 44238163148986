import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {BrowserView, MobileView} from "react-device-detect";
import HTML5Backend from 'react-dnd-html5-backend';
import {DragDropContext} from 'react-dnd';
import {
    arrayMove,
} from 'react-sortable-hoc';
import TreeNode from './TreeNode';
import Workbook from 'react-excel-workbook';
import {questionActions} from '../store/actions/QuestionActions';
import ExportQuestionsModal from '../Modals/ExportQuestionsModal';

/*
const data = {
	'/root': {
		path: '/root',
		sType: 'folder',
		isRoot: true,
		children: ['/root/david', '/root/jslancer'],
	},
	'/root/david': {
		path: '/root/david',
		sType: 'folder',
		children: ['/root/david/readme.md'],
	},
	'/root/david/readme.md': {
		path: '/root/david/readme.md',
		sType: 'file',
		content: 'Thanks for reading me me. But there is nothing here.'
	},
	'/root/jslancer': {
		path: '/root/jslancer',
		sType: 'folder',
		children: ['/root/jslancer/projects', '/root/jslancer/vblogs'],
	},
	'/root/jslancer/projects': {
		path: '/root/jslancer/projects',
		sType: 'folder',
		children: ['/root/jslancer/projects/treeview'],
	},
	'/root/jslancer/projects/treeview': {
		path: '/root/jslancer/projects/treeview',
		sType: 'folder',
		children: [],
	},
	'/root/jslancer/vblogs': {
		path: '/root/jslancer/vblogs',
		sType: 'folder',
		children: [],
	},
};
*/

class Tree extends Component {

    // state = {
    //   // nodes: data,
    //   nodes:[],
    //   eventId: this.props.match.params.idevent,
    // };

    constructor(props) {
        super(props)

        this.state = {
            nodes: [],
            currentItem: '',
            editingId: 0,
            openedItemsList: [],
            roomIconColor: 'default',
            roomVisibilityStatus: 'default',
            roomVisibilityValue: true,
            // nodes: data,
            tweetsList: [],
            currentNode: "default",
            questionList: [],
            itemInfo: "default"
        };

        this.deleteItem = this.deleteItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.saveEditedRoom = this.saveEditedRoom.bind(this);
        this.closeEditRoomModal = this.closeEditRoomModal.bind(this);
        this.getRoomUpdates = this.getRoomUpdates.bind(this);
        this.handleAddRoom = this.handleAddRoom.bind(this);

    }

    componentDidMount() {
        if (this.unsubscribeGetEventElementParents) {
            this.unsubscribeGetEventElementParents();
        }
        this.props.onGetEventElementParents(this.props.event.id, (parentsList, unsubscribe) => {
            this.unsubscribeGetEventElementParents = unsubscribe;
            if (this.unsubscribeGetEventElementChildren) {
                this.unsubscribeGetEventElementChildren();
            }
            this.props.onGetEventElementChildren(this.props.event.id, (childrenList, unsubscribe) => {
                this.unsubscribeGetEventElementChildren = unsubscribe;
                for (var m = 0; m < parentsList.length; m++) {
                    parentsList[m].children = [];
                    for (var n = 0; n < parentsList.length; n++) {
                        if (parentsList[n].sParentId === parentsList[m].id) {
                            parentsList[m].children.push(parentsList[n]);

                        }
                    }
                    parentsList[m].children.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                }

                for (var index = 0; index < parentsList.length; index++) {
                    //get default element children
                    for (var i = 0; i < childrenList.length; i++) {
                        if (childrenList.length > 0 && childrenList[i].sParentId === parentsList[index].id) {
                            parentsList[index].children.push(childrenList[i]);

                        }
                    }
                    parentsList[index].children.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                }

                parentsList.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                this.setState({...this.state, nodes: parentsList});

            });
        });
    }

    componentDidUpdate() {
        let childInputTitle = document.getElementById("childInputTitle");
        if (childInputTitle) childInputTitle.focus();
    }

    componentWillUnmount() {
        if (this.unsubscribeGetEventElementParents) {
            this.unsubscribeGetEventElementParents();
        }

        if (this.unsubscribeGetEventElementChildren) {
            this.unsubscribeGetEventElementChildren();
        }

        if (this.unsubscribeGetAllTweets) {
            this.unsubscribeGetAllTweets();
        }

        if (this.unsubscribeInitQuestionList) {
            this.unsubscribeInitQuestionList();
        }
    }

    getRootNodes = () => {
        const {nodes} = this.state;
        // return values(nodes).filter(node => node.isRoot === true);
        // return nodes.filter(node => node.isRoot === true);
        return nodes.filter(node => node.id === 'default');
    }

    getChildNodes = (node) => {
        //const { nodes } = this.state;

        if (!node.children) return [];
        return node.children;
    }

    onToggle = (node) => {
        //const { nodes } = this.state;

        if (!node.isOpen) {
            this.props.onUpdateElementData(this.props.event.id, 'setIsOpenField-true', node, '', callback => {

            });
        } else {
            this.props.onUpdateElementData(this.props.event.id, 'setIsOpenField-false', node, '', callback => {

            });
        }
    }

    onNodeSelect = node => {
        const {onSelect} = this.props;
        onSelect(node);
    }

    setCurrentItem(node) {
        this.setState({...this.state, currentItem: node, roomVisibilityStatus: 'default'});
        if (node.roomIconColor) {
            this.setState({
                ...this.state,
                currentItem: node,
                roomIconColor: node.roomIconColor,
            });
        }
    }

    getRoomUpdates(option) {
        switch (option) {
            case "roomCheckBox":
                this.setState({
                    ...this.state,
                    roomVisibilityValue: document.getElementById("roomCheckBox").checked,
                    roomVisibilityStatus: 'edited'
                });
                break;

            default:
                return true;
        }
    }

    editItem(node) {
        if (this.state.editingId === 0) {
            this.setState({...this.state, editingId: node.id});
        } else {
            this.saveEditedItem(node);
            this.setState({...this.state, editingId: 0});
        }
    }

    saveEditedItem(node) {
        if (node.sType === "folder") {
            var parentTitle = document.getElementById("parentInputTitle").value;
            let elementData = {
                title: parentTitle,
                id: node.id,
                sParentId: node.sParentId,
                sType: node.sType,
                elementIsDeleted: false,
                iOrder: node.iOrder,
                isOpen: node.isOpen,
                roomIconColor: node.roomIconColor,

                currentTweetElement: node.currentTweetElement,
                currentSurveyElement: node.currentSurveyElement,
                currentQuestionElement: node.currentQuestionElement,
                currentLiveSession: node.currentLiveSession,
                currentQuestion: node.currentQuestion,
                bigScreenStatus: node.bigScreenStatus,
                currentSelectedLiveSession: node.currentSelectedLiveSession,
                currentEventInfo: node.currentEventInfo,
                currentLibraryElement: node.currentLibraryElement,
                singleScreenElementId: node.singleScreenElementId

            };
            this.props.onSaveEventElementParent(this.props.event.id, elementData, callback => {
                this.setState({...this.state, editingId: 0});
            });
        } else {
            var childTitle = document.getElementById("childInputTitle").value;
            let elementData;
            if (node.sType === "survey") {
                elementData = {
                    title: childTitle,
                    id: node.id,
                    sParentId: node.sParentId,
                    sType: node.sType,
                    elementIsDeleted: false,
                    iOrder: node.iOrder,
                    isOpen: node.isOpen,
                    language: node.language,
                    surveyStatus: node.surveyStatus,
                    showSurveyInMenu: node.showSurveyInMenu,
                }
            } else if (node.sType === "library") {
                elementData = {
                    title: childTitle,
                    id: node.id,
                    sParentId: node.sParentId,
                    sType: node.sType,
                    elementIsDeleted: false,
                    iOrder: node.iOrder,
                    isOpen: node.isOpen,
                    language: node.language,
                    showLibraryInMenu: node.showLibraryInMenu,
                }
            } else if (node.sType === "qrcode") {
                elementData = {
                    title: childTitle,
                    id: node.id,
                    sParentId: node.sParentId,
                    sType: node.sType,
                    elementIsDeleted: false,
                    iOrder: node.iOrder,
                    isOpen: node.isOpen,
                    language: node.language,
                    showQRCodeInMenu: node.showQRCodeInMenu,
                    qrText: node.qrText
                }
            } else {
                elementData = {
                    title: childTitle,
                    id: node.id,
                    sParentId: node.sParentId,
                    sType: node.sType,
                    elementIsDeleted: false,
                    language: node.language,
                    iOrder: node.iOrder,
                    isOpen: node.isOpen
                };
            }
            
            this.props.onSaveEventElementChild(this.props.event.id, elementData, callback => {
                this.setState({...this.state, editingId: 0});
            });

            // if(node.sType ==="survey"){
            // 	var parentNode={id:node.sParentId};
            // 	//we update also the title of the survey on the parent that is going to be used on the client side
            // 	this.props.onUpdateElementData(this.props.event.id, "updateCurrentSurvey", parentNode, node, callback => {
            // 	});
            // }
        }
    }


    handleChangeColor() {
        let roomIconColor = document.getElementById("inputRoomIconColor").value;
        this.setState({
            ...this.state,
            roomIconColor: roomIconColor

        });
    }

    saveEditedRoom() {
        var node = this.state.currentItem;
        var roomTitle = document.getElementById("roomInputTitle").value;
        let roomIconColor = document.getElementById("inputRoomIconColor").value;

        //let roomCheckBox = document.getElementById("roomCheckBox").checked;
        //true or false
        var roomCheckBox;
        if (this.state.roomVisibilityStatus === "default") {
            roomCheckBox = node.visible;
        } else {
            roomCheckBox = this.state.roomVisibilityValue;
        }


        let elementData = {
            title: roomTitle,
            id: node.id,
            sParentId: node.sParentId,
            sType: node.sType,
            elementIsDeleted: false,
            iOrder: node.iOrder,
            isOpen: node.isOpen,
            roomIconColor: roomIconColor,
            currentTweetElement: node.currentTweetElement,
            currentSurveyElement: node.currentSurveyElement,
            currentQuestionElement: node.currentQuestionElement,
            currentLiveSession: node.currentLiveSession,
            currentQuestion: node.currentQuestion,
            bigScreenStatus: node.bigScreenStatus,
            currentSelectedLiveSession: node.currentSelectedLiveSession,
            currentEventInfo: node.currentEventInfo,
            currentLibraryElement: node.currentLibraryElement,
            singleScreenElementId: node.singleScreenElementId,
            visible: roomCheckBox
        };

        this.props.onSaveEventElementParent(this.props.event.id, elementData, callback => {

        });

        if (document.getElementById('editRoomForm')) {
            document.getElementById('editRoomForm').reset();
            this.setState({
                ...this.state,
                roomIconColor: 'default',
                currentItem: 'default',
                roomVisibilityStatus: 'default',
                roomVisibilityValue: true
            });
        }
    }

    closeEditRoomModal() {
        if (document.getElementById('editRoomForm')) {
            document.getElementById('editRoomForm').reset();
            this.setState({
                ...this.state, roomIconColor: 'default',
                currentItem: 'default',
                roomVisibilityStatus: 'default',
                roomVisibilityValue: true
            });
        }
    }

    deleteItem() {
        if (this.state.currentItem.sType === "room" || this.state.currentItem.sType === "folder") {
            this.props.onUpdateElementData(this.props.currentEvent.id, 'deleteParentItem', this.state.currentItem, "", callback => {

            });
        } else {
            this.props.onUpdateElementData(this.props.currentEvent.id, 'deleteChildItem', "", this.state.currentItem, callback => {
            });
        }
    }

    handleAddItem(elementType) {
        if (elementType === "folder" || elementType === "room") {
            var iOrder = 0;
            var parentChildren = this.state.currentItem.children;

            for (var i = 0; i < parentChildren.length; i++) {
                if (parentChildren[i].sType === "folder" || parentChildren[i].sType === "room") {
                    iOrder++;
                }
            }

            let elementData = {
                title: 'New ' + elementType,
                id: 0,
                sParentId: this.state.currentItem.id,
                sType: elementType,
                elementIsDeleted: false,
                iOrder: iOrder + 1,
                isOpen: false,
                roomIconColor: "#7fb6f1",
                visible: true,
                currentTweetElement: "default",
                currentSurveyElement: "default",
                currentQuestionElement: "default",
                currentLiveSession: {status: "default", id: "default"},
                currentQuestion: "default",
                bigScreenStatus: "default",
                currentSelectedLiveSession: "default",
                singleScreenElementId: "default",
                currentLibraryElement: "default",
                currentEventInfo: "default",
            };

            this.props.onSaveEventElementParent(this.props.currentEvent.id, elementData, callback => {
                //we need to set the parent isOpen field to true to show the added child to user
                if (this.state.currentItem.isOpen === false) {
                    this.props.onUpdateElementData(this.props.event.id, 'setIsOpenField-true', this.state.currentItem, '', callback => {
                    });
                }
            });
        } else {
            let elementData;
            if (elementType === "survey") {
                elementData = {
                    title: 'New external content',
                    id: 0,
                    sParentId: this.state.currentItem.id,
                    sType: elementType,
                    elementIsDeleted: false,
                    iOrder: this.state.currentItem.children.length + 1,
                    parentRoomIconColor: this.state.currentItem.roomIconColor,
                    language: "All",
                    surveyStatus: "stop",
                    showSurveyInMenu: true
                }
            } else if (elementType === "library") {
                elementData = {
                    title: 'New ' + elementType,
                    id: 0,
                    sParentId: this.state.currentItem.id,
                    sType: elementType,
                    elementIsDeleted: false,
                    iOrder: this.state.currentItem.children.length + 1,
                    parentRoomIconColor: this.state.currentItem.roomIconColor,
                    language: "All",
                    showLibraryInMenu: true
                }
            } else if (elementType === "qrcode") {
                elementData = {
                    title: 'New ' + elementType,
                    id: 0,
                    sParentId: this.state.currentItem.id,
                    sType: elementType,
                    elementIsDeleted: false,
                    iOrder: this.state.currentItem.children.length + 1,
                    parentRoomIconColor: this.state.currentItem.roomIconColor,
                    language: "All",
                    showQRCodeInMenu: true,
                    qrText: ''
                }

            } else {
                elementData = {
                    title: 'New ' + elementType,
                    id: 0,
                    sParentId: this.state.currentItem.id,
                    sType: elementType,
                    elementIsDeleted: false,
                    iOrder: this.state.currentItem.children.length + 1,
                    parentRoomIconColor: this.state.currentItem.roomIconColor,
                    language: "All"
                };
            }
			
			//we always save the items (children) inside the defalt collection because it is the root folder
            this.props.onSaveEventElementChild(this.props.currentEvent.id, elementData, callback => {
                //we need to set the parent isOpen field to true to show the added child to user
                if (this.state.currentItem.isOpen === false) {
                    this.props.onUpdateElementData(this.props.event.id, 'setIsOpenField-true', this.state.currentItem, '', callback => {
                    });
                }
            });
        }

        if (elementType === "agenda" || elementType === "qrcode") {
            document.getElementById("close-modal-room").click();
        } else if (elementType === "survey" || elementType === "library") {
            document.getElementById("close-modal-room").click();
            document.getElementById("close-modal").click();
        } else {
            document.getElementById("close-modal").click();
        }
    }

    handleAddRoom(elementType) {
        var iOrder = 0;
        var parentChildren = this.state.currentItem.children;
        for (var i = 0; i < parentChildren.length; i++) {
            if (parentChildren[i].sType === "folder" || parentChildren[i].sType === "room") {
                iOrder++;
            }
        }

        let elementData = {
            title: 'New ' + elementType,
            id: 0,
            sParentId: this.state.currentItem.id,
            sType: elementType,
            elementIsDeleted: false,
            iOrder: iOrder + 1,
            isOpen: false,
            roomIconColor: "#7fb6f1",
            visible: true,
            currentTweetElement: "default",
            currentSurveyElement: "default",
            currentQuestionElement: "default",
            currentLiveSession: {status: "default", id: "default"},
            currentQuestion: "default",
            bigScreenStatus: "default",
            currentSelectedLiveSession: "default",
            singleScreenElementId: "default",
            currentLibraryElement: "default",
            currentEventInfo: "default",
        };

        this.props.onSaveEventElementParent(this.props.currentEvent.id, elementData, callback => {
            //we need to set the parent isOpen field to true to show the added child to user
            if (this.state.currentItem.isOpen === false) {
                this.props.onUpdateElementData(this.props.event.id, 'setIsOpenField-true', this.state.currentItem, '', callback => {
                });
            }
        });
        document.getElementById("close-modal-room").click();
    }


    showItem(node) {
        var parentItem;
        for (var i = 0; i < this.state.nodes.length; i++) {
            if (node.sParentId === this.state.nodes[i].id) {
                parentItem = this.state.nodes[i];
            }
        }
        this.props.showItem(node, parentItem);

    }

    draggedChildElement(parentNode, childNode) {
        //we need to check if the current tweet element and current question fields are set to the id of this element we need to update them too
        for (var i = 0; i < this.state.nodes.length; i++) {
            if (this.state.nodes[i].id === childNode.sParentId) {
                if (childNode.sType === "question" && this.state.nodes[i].currentQuestionElement === childNode.id) {

                    var bigScreenStatus = "default";
                    if (bigScreenStatus !== "default" && bigScreenStatus.questionId === this.state.nodes[i].currentQuestion) {
                        bigScreenStatus = this.state.nodes[i].bigScreenStatus;
                    }

                    let updatedPreviousValues = {
                        currentQuestion: "default",
                        currentQuestionElement: "default",
                        currentLiveSession: "default",
                        currentSelectedLiveSession: "default",
                        bigScreenStatus: bigScreenStatus,
                    }

                    this.props.onUpdateElementData(this.props.event.id, 'updatePreviousParentNodeQuestionFields', this.state.nodes[i], updatedPreviousValues, callback => {
                    });

                    let updatedNewValues = {
                        currentQuestion: this.state.nodes[i].currentQuestion,
                        currentQuestionElement: this.state.nodes[i].currentQuestionElement,
                        currentLiveSession: this.state.nodes[i].currentLiveSession,
                        currentSelectedLiveSession: this.state.nodes[i].currentSelectedLiveSession,
                        bigScreenStatus: bigScreenStatus,
                    }

                    this.props.onUpdateElementData(this.props.event.id, 'updateNewParentNodeQuestionFields', parentNode, updatedNewValues, callback => {
                    });
                } else if (childNode.sType === "tweet" && this.state.nodes[i].currentTweetElement === childNode.id) {
                    this.props.onUpdateElementData(this.props.event.id, 'updatePreviousParentNodeTweetField', this.state.nodes[i], childNode, callback => {
                    });

                    this.props.onUpdateElementData(this.props.event.id, 'updateNewParentNodeTweetField', parentNode, childNode, callback => {
                    });
                }
            }
        }

        this.props.onUpdateElementData(this.props.event.id, 'updateChildElementParentNode', parentNode, childNode, callback => {

        });

    }

    onSortEndChild = ({oldIndex, newIndex, collection}) => {
        var elementChildren = [];
        if (collection === "default") {
            for (var j = 0; j < this.state.nodes.length; j++) {
                if (this.state.nodes[j].sType === "event") {
                    elementChildren = this.state.nodes[j].children;
                    break;
                }
            }
        } else {
            for (var i = 0; i < this.state.nodes.length; i++) {
                if (this.state.nodes[i].id === collection) {
                    elementChildren = this.state.nodes[i].children;
                    break;
                }
            }
        }
        var newSortedEventElementsList = arrayMove(elementChildren, oldIndex, newIndex);
        this.props.onUpdateEventElementIndexes(this.props.event.id, newSortedEventElementsList);

    };

    getTweetsToDownload(node) {
        if (this.unsubscribeGetAllTweets) {
            this.unsubscribeGetAllTweets();
        }
        this.props.onGetAllTweets(this.props.event.id, node.id, (tweetsList, unsubscribe) => {
            this.unsubscribeGetAllTweets = unsubscribe;
            if (tweetsList.length === 0) {
                alert("No tweets available to download");
            } else {
                this.setState({
                    ...this.state,
                    tweetsList,
                    currentNode: node
                }, () => {
                    document.getElementById("downloadTweets").click();
                });
            }
        });
    }

    getQuestionsToDownload(node) {
        if (this.unsubscribeInitQuestionList) {
            this.unsubscribeInitQuestionList();
        }
        this.props.onInitQuestionList(this.props.event.id, node.id, (callbackQuestionsList, unsubscribe) => {
            this.unsubscribeInitQuestionList = unsubscribe;
            this.setState({...this.state, questionList: callbackQuestionsList, currentNode: node});
        });
    }

    render() {
        const rootNodes = this.getRootNodes();
        var selectedLanguageStrings;
        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        var roomCheckBoxStatus;
        if (this.state.roomVisibilityStatus === 'default') {
            roomCheckBoxStatus = this.state.currentItem.visible;
        } else {
            roomCheckBoxStatus = this.state.roomVisibilityValue;
        }

        return (
            <div className="rootDiv p-2">
                {rootNodes.map(node => (
                    <TreeNode
                        key={node.id}
                        node={node}
                        currentEvent={this.props.currentEvent}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNodeSelect={this.onNodeSelect}
                        setCurrentItem={this.setCurrentItem.bind(this)}
                        saveEditedItem={this.saveEditedItem.bind(this)}
                        editingId={this.state.editingId}
                        editItem={this.editItem.bind(this)}
                        showItem={this.showItem.bind(this)}
                        getTweetsToDownload={this.getTweetsToDownload.bind(this)}
                        draggedChildElement={this.draggedChildElement.bind(this)}
                        onSortEndChild={this.onSortEndChild.bind(this)}
                        getQuestionsToDownload={this.getQuestionsToDownload.bind(this)}
                    />
                ))}

                <div className="modal fade" id="deleteEventItemModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Delete Event Item</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this event item : <br/>
                                <strong> {this.state.currentItem.title}</strong> ?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="deleteEventItemModal"
                                        data-dismiss="modal">Cancel
                                </button>
                                <button type="button " className="btn btn-danger" data-dismiss="modal"
                                        onClick={this.deleteItem}>Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="editRoomModal" tabIndex="-1" role="dialog"
                     aria-labelledby="editRoomModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modalEditRoom" role="document">
                        <div className="modal-content">
                            <form id="editRoomForm" className="">
                                <div className="modal-header bg-primary text-white">
                                    <h5 className="modal-title"
                                        id="editRoomModal">{selectedLanguageStrings.edit_room_item}:</h5>
                                </div>
                                <div className="modal-body pl-4">
                                    <div className="form-group form-inline mb-2">
                                        <label className="mr-3"
                                               htmlFor="roomInputTitle"> {selectedLanguageStrings.room_title}: </label>
                                        <input type="text" className="form-control" id="roomInputTitle"
                                               defaultValue={this.state.currentItem.title} aria-label="Title"
                                               aria-describedby="basic-addon2"/>
                                    </div>
                                    <div className="form-group form-inline mt-3 mb-2">
                                        <label htmlFor="inputRoomIconColor"
                                               className="d-block mr-3">{selectedLanguageStrings.select_room_icon_color}: </label>
                                        <div className="input-group">
                                            <input type="color" className="form-control-color" id="inputRoomIconColor"
                                                   value={this.state.roomIconColor} onChange={() => {
                                                this.handleChangeColor()
                                            }}/>
                                            <p className="color-code pl-2">{this.state.roomIconColor}</p>
                                        </div>
                                    </div>

                                    <div className="input-group form-inline mt-3 " onClick={() => {
                                        this.getRoomUpdates('roomCheckBox')
                                    }}>
                                        <label className="mr-2">
                                            Show the Room in the Main Page
                                        </label>
                                        {(roomCheckBoxStatus === true) ? (
                                            <input type="checkbox" className="form-check-input checkmark"
                                                   id="roomCheckBox" checked/>
                                        ) : (
                                            <input type="checkbox" className="form-check-input checkmark"
                                                   id="roomCheckBox"/>
                                        )}
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                            onClick={this.closeEditRoomModal}>{selectedLanguageStrings.cancel}</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal"
                                            onClick={this.saveEditedRoom}>{selectedLanguageStrings.update}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <div className="modal fade" tabIndex="-1" role="dialog" id="modalType">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center"> {selectedLanguageStrings.roomItems}</h5>
                            </div>
                            <BrowserView>
                                <div className="modal-body p-4 bg-grey">
                                    <div className="row">
                                        <div className="col-3 p-1">
                                            <div className="box-item folder text-center d-block" onClick={() => {
                                                this.handleAddItem("folder")
                                            }}>
                                                <i className="fas fa-folder mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.folder}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_folder}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-3 p-1">
                                            <div className="box-item tweet text-center d-block" onClick={() => {
                                                this.handleAddItem("tweet")
                                            }}>
                                                <i className="fas fa-comments mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">Tweet</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_tweet}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-3 p-1">
                                            <div className="box-item question text-center d-block" onClick={() => {
                                                this.handleAddItem("question")
                                            }}>
                                                <i className="fas fa-question mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.question_answer}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_question}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-3 p-1">
                                            <div className="box-item survey text-center d-block" onClick={() => {
                                                this.handleAddItem("survey")
                                            }}>
                                                <i className="far fa-list-alt text-info mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.external_content}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_external_content}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-3 p-1">
                                            <div className="box-item library libraryIcon text-center d-block"
                                                 onClick={() => {
                                                     this.handleAddItem("library")
                                                 }}>
                                                <i className="fas fa-book-open library-icon mb-2 mt-2 pt-2 libraryIcon"></i>
                                                <p className="title libraryIcon mb-2">{selectedLanguageStrings.library}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_library}
                                                </p>
                                            </div>
                                        </div>


                                        {/* <div className="col-3 p-1">
											<div className="box-item eventInfo text-center d-block" onClick={() => { this.handleAddItem("eventInfo") }}>
												<i className="far fa-newspaper mb-2 mt-2"></i>
												<p className="title mb-2">{selectedLanguageStrings.eventInfo}</p>
												<p className="description">
													{selectedLanguageStrings.description_eventInfo}
												</p>
											</div>
										</div> */}

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="close-modal"
                                            data-dismiss="modal">
                                        <i className="fas text-secondary fa-times iconClose"></i>
                                    </button>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="modal-body p-4 bg-grey">
                                    <div className="row justify-content-center">
                                        <div className="col-4 p-1 w-40 pt-2">
                                            <div className="box-item folder p-1 text-center d-block ">
                                                <i className="fas fa-folder mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("folder")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.folder_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "folder"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_folder1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseFolder" aria-expanded="false" aria-controls="multiCollapseFolder" onClick={() => { this.removeMoreWord('Folder') }}>{this.state.collapseFolder}</label>
													<div className="collapse multi-collapse" id="multiCollapseFolder" onClick={() => { this.handleAddItem("folder") }}>
														{selectedLanguageStrings.description_folder2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        <div className="col-4 p-1 pt-2">
                                            <div className="box-item room p-1 text-center d-block ">
                                                <i className="fas fa-door-open mb-2 pt-2" onClick={() => {
                                                    this.handleAddRoom("room")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.room_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "room"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_room1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseQuestion" aria-expanded="false" aria-controls="multiCollapseQuestion" onClick={() => { this.removeMoreWord('room') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseRoom" onClick={() => { this.handleAddRoom("room") }}>
														{selectedLanguageStrings.description_room2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        <div className="col-4 p-1 pt-2">
                                            <div className="box-item question p-1 text-center d-block ">
                                                <i className="fas fa-question mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("question")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.question_answer_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "question"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_question1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseQuestion" aria-expanded="false" aria-controls="multiCollapseQuestion" onClick={() => { this.removeMoreWord('Question') }}>{this.state.collapseQuestion}</label>
													<div className="collapse multi-collapse" id="multiCollapseQuestion" onClick={() => { this.handleAddItem("question") }}>
														{selectedLanguageStrings.description_question2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        <div className="col-4 p-1 pt-2">
                                            <div className="box-item tweet p-1 text-center d-block ">
                                                <i className="fas fa-comments mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("tweet")
                                                }}></i>
                                                <p className="title mb-2">Tweet</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "tweet"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_tweet1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseTweet" aria-expanded="false" aria-controls="multiCollapseTweet" onClick={() => { this.removeMoreWord('Tweet') }}>{this.state.collapseTweet}</label>
													<div className="collapse multi-collapse" id="multiCollapseTweet" onClick={() => { this.handleAddItem("tweet") }}>
														{selectedLanguageStrings.description_tweet2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        <div className="col-4 p-1 pt-2">
                                            <div className="box-item survey p-1 text-center d-block ">
                                                <i className="fas fa-list-alt mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("survey")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.external_content_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "survey"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_survey1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('Survey') }}>{this.state.collapseSurvey}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddItem("survey") }}>
														{selectedLanguageStrings.description_survey2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        <div className="col-4 p-1 pt-2">
                                            <div className="box-item library p-1 text-center d-block ">
                                                <i className="fas fa-book-open libraryIcon mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("library")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.library_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "library"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_library1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('Library') }}>{this.state.collapseLibrary}</label>
													<div className="collapse multi-collapse" id="multiCollapseLibrary" onClick={() => { this.handleAddItem("library") }}>
														{selectedLanguageStrings.description_library2}
													</div>
												</p> */}
                                            </div>
                                        </div>

                                        {/* <div className="col-4 p-1 pt-2">
											<div className="box-item eventInfo p-1 text-center d-block " >
												<i className="far fa-newspaper mb-2 pt-2" onClick={() => { this.handleAddItem("eventInfo") }}></i>
												<p className="title mb-2">{selectedLanguageStrings.eventInfo_mob}</p>
												<div className="text-right">
													<div data-toggle="modal" data-target="#modalInfoForItem">
														<i className="fas fa-question-circle xs" onClick={() => {this.setState({itemInfo: "eventInfo"})} }></i>
													</div>
												</div>
												<p className="description">
													{selectedLanguageStrings.description_eventInfo1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseEventInfo" aria-expanded="false" aria-controls="multiCollapseEventInfo" onClick={() => { this.removeMoreWord('EventInfo') }}>{this.state.collapseEventInfo}</label>
													<div className="collapse multi-collapse" id="multiCollapseEventInfo" onClick={() => { this.handleAddItem("eventInfo") }}>
														{selectedLanguageStrings.description_eventInfo2}
													</div>
												</p>
											</div>
										</div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="close-modal"
                                            data-dismiss="modal">
                                        <i className="fas text-secondary fa-times iconClose"></i>
                                    </button>
                                </div>
                            </MobileView>
                        </div>
                    </div>
                </div>


                <div className="modal fade" tabIndex="-1" role="dialog" id="modalTypeForRoot">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center"> {selectedLanguageStrings.eventItems}</h5>
                            </div>
                            <BrowserView>
                                <div className="modal-body p-4 bg-grey">
                                    <div className="row">
                                        <div className="col-3 p-1 pr-0 pb-3">
                                            <div className="box-item room text-center d-block" onClick={() => {
                                                this.handleAddRoom("room")
                                            }}>
                                                <i className="fas fa-door-open mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.room}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_room}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-3 p-1 pr-0 pb-3">
                                            <div className="box-item agenda text-center d-block text-warning"
                                                 onClick={() => {
                                                     this.handleAddItem("agenda")
                                                 }}>
                                                <i className="far fa-calendar-alt mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.agenda}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_agenda}
                                                </p>
                                                {/* <i className="fas fa-question-circle text-primary text-right"></i> */}
                                            </div>
                                        </div>
                                        <div className="col-3 p-1 pr-0 pb-3">
                                            <div className="box-item survey text-center d-block text-info"
                                                 onClick={() => {
                                                     this.handleAddItem("survey")
                                                 }}>
                                                <i className="far fa-list-alt mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.external_content}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_external_content}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-3 p-1 pb-3">
                                            <div className="box-item library text-center libraryIcon" onClick={() => {
                                                this.handleAddItem("library")
                                            }}>
                                                <i className="fas fa-book-open mb-2 mt-2 pt-2"></i>
                                                <p className="title libraryIcon mb-2">{selectedLanguageStrings.library}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_library}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-3 p-1 pr-0 pb-3">
                                            <div className="box-item qrcode text-center d-block" onClick={() => {
                                                this.handleAddItem("qrcode")
                                            }}>
                                                <i className="fas fa-qrcode mb-2 mt-2 pt-2"></i>
                                                <p className="title mb-2">{selectedLanguageStrings.qrcode}</p>
                                                <p className="description">
                                                    {selectedLanguageStrings.description_qrcode}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="close-modal-room"
                                            data-dismiss="modal">
                                        <i className="fas text-secondary fa-times iconClose"></i>
                                    </button>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="modal-body p-4 bg-grey">
                                    <div className="row justify-content-center">
                                        <div className="col-4 p-1">
                                            <div className="box-item room text-center d-block">
                                                <i className="fas fa-door-open mb-2 pt-2" onClick={() => {
                                                    this.handleAddRoom("room")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.room_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "room"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_room1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('room') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddRoom("room") }}>
														{selectedLanguageStrings.room2}
													</div>
												</p> */}
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className="box-item agenda text-warning text-center d-block">
                                                <i className="fas fa-calendar-alt mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("agenda")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.agenda}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "agenda"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_agenda1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('agenda') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddItem("agenda") }}>
														{selectedLanguageStrings.agenda2}
													</div>
												</p> */}
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className="box-item survey text-info text-center d-block">
                                                <i className="fas fa-list-alt mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("survey")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.external_content_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "survey"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_survey1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('survey') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddItem("survey") }}>
														{selectedLanguageStrings.survey2}
													</div>
												</p> */}
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className="box-item library libraryIcon text-center d-block">
                                                <i className="fas fa-book-open mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("library")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.library_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "library"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_library1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('library') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddItem("library") }}>
														{selectedLanguageStrings.room2}
													</div>
												</p> */}
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className="box-item qrcode text-center d-block">
                                                <i className="fas fa-qrcode mb-2 pt-2" onClick={() => {
                                                    this.handleAddItem("qrcode")
                                                }}></i>
                                                <p className="title mb-2">{selectedLanguageStrings.qrcode_mob}</p>
                                                <div className="text-right">
                                                    <div data-toggle="modal" data-target="#modalInfoForItem">
                                                        <i className="fas fa-question-circle xs" onClick={() => {
                                                            this.setState({itemInfo: "qrcode"})
                                                        }}></i>
                                                    </div>
                                                </div>
                                                {/* <p className="description">
													{selectedLanguageStrings.description_qrcode1}
													<label className=" text-primary" data-toggle="collapse" data-target="#multiCollapseSurvey" aria-expanded="false" aria-controls="multiCollapseSurvey" onClick={() => { this.removeMoreWord('qrcode') }}>{this.state.collapseRoom}</label>
													<div className="collapse multi-collapse" id="multiCollapseSurvey" onClick={() => { this.handleAddItem("qrcode") }}>
														{selectedLanguageStrings.qrcode2}
													</div>
												</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="close-modal-room"
                                            data-dismiss="modal">
                                        <i className="fas text-secondary fa-times iconClose"></i>
                                    </button>
                                </div>
                            </MobileView>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id="modalInfoForItem">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center">Info</h5>
                            </div>
                            <div className="modal-body p-4 bg-grey">
                                <div className="row">
                                    {(this.state.itemInfo === "room") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_room}
                                        </p>

                                    ) : (this.state.itemInfo === "agenda") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_agenda}
                                        </p>

                                    ) : (this.state.itemInfo === "survey") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_external_content}
                                        </p>

                                    ) : (this.state.itemInfo === "library") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_library}
                                        </p>

                                    ) : (this.state.itemInfo === "qrcode") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_qrcode}
                                        </p>

                                    ) : (this.state.itemInfo === "folder") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_folder}
                                        </p>

                                    ) : (this.state.itemInfo === "question") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_question}
                                        </p>

                                    ) : (this.state.itemInfo === "tweet") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_tweet}
                                        </p>

                                    ) : (this.state.itemInfo === "eventInfo") ? (

                                        <p className="description">
                                            {selectedLanguageStrings.description_eventInfo}
                                        </p>

                                    ) : (
                                        <p></p>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="close-modal-room"
                                        data-dismiss="modal">
                                    <i className="fas text-secondary fa-times iconClose"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {(this.state.tweetsList && this.state.tweetsList.length > 0) ? (

                    <div className="col-6 text-right">
                        <Workbook filename={"4meet-Tweets" + this.props.event.title + ".xlsx"}
                                  element={<div type="button" id="downloadTweets"></div>}>
                            <Workbook.Sheet data={this.state.tweetsList}
                                            name={"All Tweets - " + this.props.event.title}>
                                <Workbook.Column label="Tweet Text" value="sText"/>
                                <Workbook.Column label="Time" value="dtmCreatedMoment"/>
                                <Workbook.Column label="Approved status" value="bApproved"/>
                                <Workbook.Column label="Live status" value="live"/>
                                <Workbook.Column label="Archived status" value="bArchived"/>
                                <Workbook.Column label="Participant's Name" value="participantDefinedName"/>
                            </Workbook.Sheet>
                        </Workbook>
                    </div>

                ) : (
                    <div></div>
                )}

                <ExportQuestionsModal
                    questionsList={this.state.questionList}
                    event={this.props.event}
                    parentInfo={this.state.parentInfo}
                    idQuestionEl={this.state.currentNode.id}
                    /*  getVotingSessions={this.getVotingSessions.bind(this)}
                    validVotingSessions={this.state.validVotingSessions} */
                />
            </div>
        )
    }
}

Tree.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const {eventCodeAvailability} = state.eventReducer;
    return {
        eventElementsList: state.eventReducer.eventElementsList,
        currentEvent: state.eventReducer.currentEvent,
        eventCodeAvailability,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onGetEventElementParents: (eventId, callback) => dispatch(eventActions.getEventElementParents(eventId, callback)),
        onGetEventElementChildren: (eventId, callback) => dispatch(eventActions.getEventElementChildren(eventId, callback)),
        onSaveEventElementParent: (eventId, elementData, callback) => dispatch(eventActions.saveEventElementParent(eventId, elementData, callback)),
        onSaveEventElementChild: (eventId, elementData, callback) => dispatch(eventActions.saveEventElementChild(eventId, elementData, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
        onUpdateEventElementIndexes: (eventId, newSortedEventElementsList) => dispatch(eventActions.updateEventElementIndexes(eventId, newSortedEventElementsList)),
        onGetAllTweets: (eventId, idTweetEl, callback) => dispatch(eventActions.getAllTweets(eventId, idTweetEl, callback)),
        onInitQuestionList: (eventId, idQuestionEl, callback) => dispatch(questionActions.getAllQuestions(eventId, idQuestionEl, callback)),
    };
};

Tree.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

Tree = DragDropContext(HTML5Backend)(Tree);
export default connect(mapStateToProps, mapDispatchToProps)(Tree)
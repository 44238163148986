import { actionTypes } from '../actions/actionTypes';

let initialState = {
    eventList: null,
    currentEvent: 'default',
    tweetsList: {},
    currentShareEvent: "default"
};

export function eventReducer(state = initialState, action) {

    switch (action.type) {

        case actionTypes.ADD_EVENT:
            //When the user pushes the create button the element will be added to the list
            return state;
        // let newArray = state.eventList.slice();
        // newArray.push(action.event);
        // return {
        //     ...state,
        //     eventList: newArray
        // };
        case actionTypes.Fetch_SINGLE_EVENT_TREE_SUCCESS:
            return {
                ...state,
                jsonTree: action.jsonTree
            }
        case actionTypes.Fetch_ALL_EVENTS_SUCCESS:
            return {
                ...state,
                eventList: action.eventList,
            };

        case actionTypes.Fetch_ALL_EVENT_ELEMENTS_PARENT_SUCCESS:
            return {
                ...state,
                eventElementsParentList: action.eventElementsList,
            };


        case actionTypes.SAVE_EVENT_ELEMENT_PARENT_SUCCESS:
            var index = state.eventElementsParentList.findIndex((elem) => {
                if (elem && elem.id === action.elementData.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventElementsParentList: [
                        //before the one we are updating
                        ...state.eventElementsParentList.slice(0, index),
                        {
                            ...state.eventElementsParentList[index], id: action.elementData.id,
                            sName: action.elementData.title,
                            title: action.elementData.sName,
                            subtitle: action.elementData.subtitle,
                            sParentId: action.elementData.sParentId,
                            sType: action.elementData.sType,
                            treeIndex: action.elementData.treeIndex,
                        }, ...state.eventElementsParentList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

        case actionTypes.GET_PARENT_ELEMENT_ON_CHANGE_SUCCESS:
            return {
                ...state,
                parentData: action.parentData,
            };

        case actionTypes.FETCH_ALL_EVENT_ELEMENT_CHILDREN_SUCCESS:
            return state;


        case actionTypes.SAVE_EVENT_ELEMENT_CHILD_SUCCESS:
            return state;

        case actionTypes.UPDATE_CHILD_ELEMENT_SUCCESS:
            return state;

        case actionTypes.CANCEL_EVENT:
            return {
                ...state,
                eventList: state.eventList.filter(event => event.id !== 0)
            };

        case actionTypes.SAVE_EVENT_SUCCESS:
            //Updating the event code of the selected event inside the redux.
            var index = state.eventList.findIndex((elem) => {
                if (elem && elem.id === action.eventData.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventList: [
                        //before the one we are updating
                        ...state.eventList.slice(0, index),
                        {
                            ...state.eventList[index], id: action.eventData.id,
                            title: action.eventData.title,
                            eventCode: action.eventData.eventCode,
                            dtmStart: action.eventData.dtmStart,
                            dtmEnd: action.eventData.dtmEnd,
                        }, ...state.eventList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

        case actionTypes.SAVE_EVENT_ELEMENT_SUCCESS:
            return state;

        case actionTypes.DELETE_EVENT_SUCCESS:

            return {
                ...state,
                eventList: state.eventList.filter(event => event.id !== action.id),
                currentEvent: 'default'
            };

        case actionTypes.FETCH_EVENT_INFO_CHANGE_SUCCESS:
            return {
                ...state,
                currentEvent: action.currentEvent,
                currentLiveQustionId: action.currentEvent.currentQuestion
            }

        case actionTypes.UPDATE_CURRENT_FIELD_SUCCESS:
            //return state;
            return {
                ...state,
                updatedCurrentFiledstatus: 'success'
            };

        case actionTypes.SET_CURRENT_EVENT_SUCCESS:
            return {
                ...state,
                currentEventId: action.eventId
            };

        case actionTypes.CODE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                currentEvent: action.event,
                currentEventId: action.event.id
            };

        case actionTypes.CODE_AVAILABILITY_FAILED:
            return state;

        case actionTypes.SET_CURRENT_TWEET_ELEMENT_SUCCESS:
            return state;

        case actionTypes.SET_LIVE_TWEET_ID_SUCCESS:
            return state;

        case actionTypes.GET_ALL_TWEETS_SUCCESS:
            return state;

        case actionTypes.UPDATE_TWEET_FIELD_SUCCESS:
            return state;


        case actionTypes.DELETE_TWEET_SUCCESS:
            return state;

        case actionTypes.STOP_LIVE_TWEET_SUCCESS:
            return state;

        case actionTypes.SAVE_NEW_TWEET_SUCCESS:
            return state;

        case actionTypes.UPDATE_EVENT_LOGO_FIELD_SUCCESS:
            var index = state.eventList.findIndex((elem) => {
                if (elem && elem.id === action.logoData.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventList: [
                        //before the one we are updating
                        ...state.eventList.slice(0, index),
                        {
                            ...state.eventList[index], id: action.logoData.id,
                            eventLogoUrl: action.logoData.url
                        }, ...state.eventList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

        case actionTypes.UPDATE_EVENT_SPLASH_IMAGE_FIELD_SUCCESS:
            var index = state.eventList.findIndex((elem) => {
                if (elem && elem.id === action.splashImage.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventList: [
                        //before the one we are updating
                        ...state.eventList.slice(0, index),
                        {
                            ...state.eventList[index], id: action.splashImage.id,
                            splashImageUrl: action.splashImage.url
                        }, ...state.eventList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

            case actionTypes.UPDATE_EVENT_HOME_IMAGE_FIELD_SUCCESS:
                var index = state.eventList.findIndex((elem) => {
                    if (elem && elem.id === action.homeImage.id) {
                        return true;
                    }
                    return false;
                });
    
                if (index !== (-1)) {
                    return {
                        ...state, eventList: [
                            //before the one we are updating
                            ...state.eventList.slice(0, index),
                            {
                                ...state.eventList[index], id: action.homeImage.id,
                                homeImageUrl: action.homeImage.url
                            }, ...state.eventList.slice(index + 1)
                        ],
                    }
                } else {
                    return state;
                }            

        case actionTypes.UPDATE_EVENT_THEME_BIG_SCREEN_FIELD_SUCCESS:
            var index = state.eventList.findIndex((elem) => {
                if (elem && elem.id === action.logoData.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventList: [
                        //before the one we are updating
                        ...state.eventList.slice(0, index),
                        {
                            ...state.eventList[index], id: action.logoData.id,
                            themeBigScreenImageUrl: action.logoData.url
                        }, ...state.eventList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

        case actionTypes.UPDATE_EVENT_THEME_PARTICIPANT_FIELD_SUCCESS:
            var index = state.eventList.findIndex((elem) => {
                if (elem && elem.id === action.logoData.id) {
                    return true;
                }
                return false;
            });

            if (index !== (-1)) {
                return {
                    ...state, eventList: [
                        //before the one we are updating
                        ...state.eventList.slice(0, index),
                        {
                            ...state.eventList[index], id: action.logoData.id,
                            themeParticipantImageUrl: action.logoData.url
                        }, ...state.eventList.slice(index + 1)
                    ],
                }
            } else {
                return state;
            }

        case actionTypes.DELETE_SELECTED_VOTING_SESSION_FIELD_SUCCESS:
            return state;


        case actionTypes.DELETE_LIVE_SESSION_FIELD_SUCCESS:
            return {
                ...state,
                votingSessionsList: []
            }

        case actionTypes.FETCH_PARTICIPANT_LIST_SUCCESS:
            return {
                ...state,
                participantList: action.participantList
            }

        case actionTypes.LIBRARY_FILE_UPLOAD_SUCCESS:
            return state;

        case actionTypes.AGENDA_FILE_UPLOAD_SUCCESS:
            return state;

        case actionTypes.SURVEY_FILE_UPLOAD_SUCCESS:
            return state;

        case actionTypes.SAVE_LIBRARY_FILE_SUCCESS:
            return state;

        case actionTypes.SAVE_AGENDA_FILE_SUCCESS:
            return state;

        case actionTypes.SAVE_SURVEY_FILE_SUCCESS:
            return state;

        case actionTypes.GET_ALL_LIBRARY_FILES_SUCCESS:
            return {
                ...state,
                libraryFilesList: action.libraryFilesList
            }

        case actionTypes.GET_ALL_AGENDA_FILES_SUCCESS:
            return {
                ...state,
                agendaFilesList: action.agendaFilesList
            }

        case actionTypes.GET_ALL_SURVEY_FILES_SUCCESS:
            return {
                ...state,
                surveyFilesList: action.surveyFilesList
            }

        case actionTypes.DELETE_LIBRARY_FILE_SUCCESS:
            return state;

        case actionTypes.LIBRARY_FILE_INDEXES_UPDATED_SUCCESS:
            return state;

        case actionTypes.SAVE_EVENT_INFO_FILE_SUCCESS:
            return state;

        case actionTypes.GET_ALL_EVENT_INFO_FILES_SUCCESS:
            return {
                ...state,
                eventInfoFilesList: action.eventInfoFilesList
            }

        case actionTypes.GET_SURVEY_INFO_ON_CHANGE_SUCCESS:
            return {
                ...state,
                surveyInfo: action.surveyInfo
            }

        case actionTypes.Fetch_ALL_PARTICIPANTS_LIST_SUCCESS:
            return {
                ...state,
                participantsList: action.participantsList,
            };

        case actionTypes.EMAIL_AVAILABILITY_CHECK_SUCCESS:
            return {
                ...state,
                shareUserData: action.shareUserData
            }

        case actionTypes.UPDATE_SHARED_EVENT_DATA_SUCCESS:
            return state;

        case actionTypes.FETCH_SHARED_EVENTS_SUCCESS:
            return {
                ...state,
                sharedEventsList: action.sharedEventsList
            }

        case actionTypes.FETCH_SHARED_EMAILS_SUCCESS:
            return {
                ...state,
                sharedEmailsList: action.data.sharedEmailsList,
                currentShareEvent: action.data.event
            }

        case actionTypes.EVENTS_ELEMENTS_INDEXES_UPDATED_SUCCESS:
            return state;

        case actionTypes.GET_ELEMENT_ITEM_ON_CHANGE_SUCCESS:
            return state;

        default:
            return state;
    }
};

import React, { Component } from 'react';
import '@syncfusion/ej2-react-buttons/styles/material.css';
import moment from 'moment';

class Tweet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tweetsLiveStatus: "default",
            editTweetValue: "default",
        }
        this.editTweetFunction = this.editTweetFunction.bind(this);
        this.saveEditedTweetBtn = this.saveEditedTweetBtn.bind(this);
    }

    editTweetFunction() {
        this.setState({ ...this.state, editTweetStatus: "Edited", editTweetValue: this.props.tweet });
    }

    saveEditedTweet(e) {
        e.preventDefault();

        if (e.keyCode === 13) {
            this.props.saveEditedTweetFunction(this.props.tweet);
            this.setState({ ...this.state, editTweetValue: "default" });
        }
    }

    saveEditedTweetBtn() {
        this.props.saveEditedTweetFunction(this.props.tweet);
        this.setState({ ...this.state, editTweetValue: "default" });
    }


    render() {
        let menuList;
        let approvedClass;
        let textColorDefined;
        if(this.props.event.autoApproveTweets && this.props.event.autoApproveTweets === "Without Moderator"){
        if (this.props.tweet.bArchived === false) {
            approvedClass = this.props.event.primaryColor;
            textColorDefined = this.props.event.textColor;
            menuList = (
                <div className="dropdown-menu dropleft" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-primary" href="#" onClick={this.editTweetFunction}> <i className="fas fa-pen"></i> Edit</a>
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.archive(this.props.tweet) }}><i className="fas fa-archive"></i> Archive</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash" ></i> Delete</a>
                </div>
            );
             }else{
                  // approvedClass = "primary"
            approvedClass = "rgb(216, 171, 37)";
            textColorDefined = "#fff";
            menuList = (
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.unArchive(this.props.tweet) }}><i className="fas fa-archive"></i> Unarchive</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash" ></i> Delete</a>
                </div>
            );
        } 
        }else{
        if (this.props.tweet.bApproved === true && this.props.tweet.bArchived === false) {
            // approvedClass = "primary";
            approvedClass = this.props.event.primaryColor;
            textColorDefined = this.props.event.textColor;
            menuList = (
                <div className="dropdown-menu dropleft" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-info" href="#" onClick={() => { this.props.unApproveFunction(this.props.tweet) }}><i className="fas fa-minus-circle"></i> Unapprove</a>
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.archive(this.props.tweet) }}><i className="fas fa-archive"></i> Archive</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash"></i> Delete</a>
                    {/* <a className="dropdown-item text-danger text-right" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash"></i> Delete</a> */}
                </div>
            );
        }
        else {
            // approvedClass = "secondary";
            approvedClass = "#6c757d"
            textColorDefined = "#fff"
            menuList = (
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-info" href="#" onClick={() => { this.props.approveFunction(this.props.tweet) }}><i className="fas fa-check"></i> Approve</a>
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.archive(this.props.tweet) }}><i className="fas fa-archive"></i> Archive</a>
                    <a className="dropdown-item text-primary" href="#" onClick={this.editTweetFunction}> <i className="fas fa-pen"></i> Edit</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash" ></i> Delete</a>
                </div>
            );
        }

        if (this.props.tweet.bArchived === true && this.props.tweet.bApproved === true) {
            // approvedClass = "primary"
            approvedClass = "rgb(216, 171, 37)";
            textColorDefined = "#fff";
            menuList = (
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-info" href="#" onClick={() => { this.props.unApproveFunction(this.props.tweet) }}><i className="fas fa-minus-circle"></i> Unapprove</a>
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.unArchive(this.props.tweet) }}><i className="fas fa-archive"></i> Unarchive</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash" ></i> Delete</a>
                </div>
            );
        } else if (this.props.tweet.bArchived === true && this.props.tweet.bApproved === false) {
            // approvedClass = "info"
            approvedClass = "rgb(216, 171, 37)";
            textColorDefined = "#fff";
            menuList = (
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item text-info" href="#" onClick={() => { this.props.approveFunction(this.props.tweet) }}><i className="fas fa-check"></i> Approve</a>
                    <a className="dropdown-item text-warning" href="#" onClick={() => { this.props.unArchive(this.props.tweet) }}><i className="fas fa-archive"></i> Unarchive</a>
                    <a className="dropdown-item text-danger" href="#" onClick={() => { this.props.deleteFunction(this.props.tweet) }}><i className="fas fa-trash" ></i> Delete</a>
                </div>
            );
        }
    }


        return (
            <div>
                <div className="single-tweet bg-white box-shadow mb-2" style={{ borderColor: approvedClass }} id={this.props.tweet.id}>
                        <div className="row head-single-tweet"  style={{ backgroundColor: approvedClass }}>
                                <div className="col-7 col-sm-7 col-md-7 col-lg-7 text-left">
                                    {(this.props.tweet.participantDefinedName && this.props.tweet.participantDefinedName !== "") ? (
                                        //if the participant does not define any name we will use the anonymous as the name
                                        <p className="username-single-tweet pl-3" style={{ color: textColorDefined }}><b>{this.props.tweet.participantDefinedName}</b></p>
                                    ) : (
                                        // <p className="username-single-tweet pl-3" style={{ color: textColorDefined }}><b>Anonymous</b></p>
                                        <p></p>
                                    )}
                                </div>
                                {(this.props.event.autoApproveTweets === "Without Moderator")?(
                                     <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-right pr-2">
                                    <div className="hours-single-tweet" style={{ color: textColorDefined }}>
                                        
                                        {(this.props.tweet.dtmCreated)?(
                                            <label className="pr-2 mb-0" >
                                                {moment(this.props.tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")}
                                            </label>
                                        ):(
                                            <label className="pr-2 mb-0" > 00:00 </label>
                                        )}
                                    </div>
                                    </div>
                                ):(
                            
                            (this.props.tweet.bApproved && this.props.tweet.bApproved === true && this.props.tweet.bArchived === false) ? (
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-right pr-2">
                                    {(this.props.tweet.live && this.props.tweet.live === true) ? (
                                        <div className="hours-single-tweet">
                                            <label className="pr-2 mb-0" style={{ color: textColorDefined }}>{moment(this.props.tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</label>
                                            <button className="stop-icon-tweet rounded btn-danger" onClick={() => { this.props.stopTweetToScreen(this.props.tweet) }}>
                                                <i className="fas fa-stop-circle"></i>
                                            </button>
                                        </div>

                                    ) : (
                                        <div className="hours-single-tweet" style={{ color: textColorDefined }}>
                                            <label className="pr-2 mb-0" >{moment(this.props.tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</label>
                                            <button className="screen-icon-tweet rounded btn-primary " onClick={() => { this.props.sendTweetToScreen(this.props.tweet) }}>
                                                <i className="fas fa-desktop"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-right pr-0" >
                                    {(this.props.tweet.dtmCreated) ? (
                                        <p className="hours-single-tweet pr-2" style={{ color: textColorDefined }}>{moment(this.props.tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</p>
                                    ) : (
                                        <p className="hours-single-tweet pr-2" style={{ color: textColorDefined }}>00:00</p>
                                    )}
                                </div>
                            )
                            )}
                      
                            
                        </div>
                   
                    <div className="row p-3">
                        <div className="col-12">
                            {/* {this.props.tweet.sText} */}
                            {(this.state.editTweetValue.id === this.props.tweet.id) ? (
                                <div className=" col-9">
                                    <div className="input-group mb-3 ml-2">
                                        <input type="text" className="form-control" onKeyUp={this.saveEditedTweet.bind(this)} id="tweetTextInput" defaultValue={this.props.tweet.sText} aria-label="Title" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn bg-success text-white" type="button" onClick={this.saveEditedTweetBtn}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                    <div className=" col-9 pl-0">
                                        {this.props.tweet.sText}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>    
                <div className="btn-group menu-single-tweet dropleft" role="group">
                    <button id="btnGroupDrop1" className="menu-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v" style={{ color: textColorDefined }}></i>
                    </button>
                    {menuList}
                </div>
            </div>           
        );
    }
}
export default Tweet;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticationActions } from '../store/actions/AuthenticationActions';
import firebase  from '../config/firebase';
import {alertActions} from '../store/actions/alert.actions';


class ResgiterationPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                email:'',
                password: '',
                errorRegisteration:'',
                errorMessage:''
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.props.onAlertClear();
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
             // navigate('/login')
             // this.props.history.push('/login');
            }
          });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
        // The alert will only be disappeard that the user changes the email address
        if(name === 'email'){
            // this.props.onAlertClear();
            this.setState({...this.state,   user: {
                ...user,
                [name]: value
            }, errorRegisteration: '', errorMessage:''});
        }
    }


    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        var { user } = this.state;
        // const encodedPassword = md5(this.state.user.password);
        // user.password = encodedPassword;
        user.password = this.state.user.password;
        const { dispatch } = this.props;
        if (user.firstName && user.lastName && user.email && user.password) {
            this.props.onRegisteration(user, callback => {
                if(callback === 'RegistrationSuccessful'){
                     
                        this.props.history.push('/mainScreen');
                }else if(callback.code === "auth/email-already-in-use"){
                    this.setState({...this.state, errorRegisteration: 'emailError', errorMessage:callback.message });
                }
            });
            //dispatch(authenticationActions.register(user));
        }
    }

    render() {
        const { registering  } = this.props;
        const { user, submitted } = this.state;
        const { alert } = this.props;
        return (
            <main role="main">
                  {/* {(alert.message !== {})?(
                            <div id ='alertError' className={`alert ${alert.type}`}>{alert.message}</div> )
                       :(
                            <div></div>
                        )
                    } */}
                    <div className="container">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3">
                            </div>
                            <div className="col-lg-5">
                            <div className='form-group'>
                            <h1>Register</h1><br/>
                            </div>
                                <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChange} />
                                    {submitted && !user.firstName &&
                                        <div className="help-block text-danger">First Name is required</div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChange} />
                                    {submitted && !user.lastName &&
                                        <div className="help-block text-danger">Last Name is required</div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !user.email ? ' has-error' : '')}>
                                    <label htmlFor="email">E-Mail</label>
                                    <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange} />
                                    {submitted && !user.email &&
                                        <div className="help-block text-danger">E-Mail Address is required</div>
                                    }
                                     {submitted && user.email && this.state.errorRegisteration === "emailError" &&
                                        <div className="help-block text-danger">{this.state.errorMessage}</div>
                                    }
                                    
                                </div>
                                <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                                    {submitted && !user.password &&
                                        <div className="help-block text-danger">Password is required</div>
                                    }

                                    {submitted && user.password.length !==0 && user.password.length<6 &&
                                        <div className="help-block text-danger">Password must contain at least six characters.</div>
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                            </div>
                            <div className="col-lg-5">
                                {/* <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                </div> */}
                                <button type="submit" className="btn btn-success mr-3"  id="register" >Register</button>
                                {registering }
                                <Link to="/login" className="btn btn-primary">Cancel</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                            </div>
                        </div>
                    </form>
                    </div>
            </main>
        )
    }
}


// export default ResgiterationPage;

const mapStateToProps = state => {
   const { registering } = state.authenticationReducer;
   const { loggingIn } = state.authenticationReducer;
   const  alert  = state.alertReducer;
    return {
       registering,
       loggingIn,
       alert
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onRegisteration: (user, callback) => dispatch(authenticationActions.register(user, callback)),
        onAlertClear: () => dispatch(alertActions.clear('Registration successful')),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(ResgiterationPage);
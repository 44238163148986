import React, {Component} from 'react';
import {questionActions} from '../store/actions/QuestionActions';
import {connect} from 'react-redux';
import moment from 'moment';


class CompareQuestionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionsModalToCompare: "sessionsModalToCompare",
            selectedQuestionsList: [],
            validVotingSessions: [],
            selectedsessionsToCompare: [],
            compareResultsStatus: false

        };
        this.getUserPreferences = this.getUserPreferences.bind(this);
        this.nextBtn = this.nextBtn.bind(this);
        this.compareResults = this.compareResults.bind(this);
        this.saveSessionsModal = this.saveSessionsModal.bind(this);

    }

    getUserPreferences(item, type) {

        switch (type) {
            case "questionCompareCheckBox":
                var question = item;
                var checkboxStatus = document.getElementById("questionCompareCheckBox-" + question.id).checked;
                var selectedQuestionsList = this.state.selectedQuestionsList;
                var questionNotExists = true;

                for (var index = 0; index < this.props.questionsList.length; index++) {
                    if (this.props.questionsList[index] && this.props.questionsList[index].id === item.id) {
                        this.props.questionsList[index].selected = checkboxStatus;
                    }
                }
                break;

            case "compareSessionCheckBox":
                var sessionCheckboxStatus = document.getElementById("compareSessionCheckBox-" + item.session.id).checked;
                var selectedsessionsToCompare = this.state.selectedsessionsToCompare;
                var sessionNotExists = true;

                if (sessionCheckboxStatus === true) {
                    for (var i = 0; i < this.state.selectedsessionsToCompare.length; i++) {
                        if (this.state.selectedsessionsToCompare[i].id === item.session.id) {
                            sessionNotExists = false;
                        }
                    }

                    if (sessionNotExists) {
                        selectedsessionsToCompare.push(item.session);
                        this.setState({
                            ...this.state,
                            selectedsessionsToCompare,
                        });
                    }


                } else {
                    for (var i = 0; i < this.state.selectedsessionsToCompare.length; i++) {
                        if (this.state.selectedsessionsToCompare[i].id === item.session.id) {
                            selectedsessionsToCompare.pop(item.session);
                        }
                    }
                    this.setState({
                        ...this.state,
                        selectedsessionsToCompare,
                    });
                }

            default:
                return true;
        }
    }

    nextBtn() {
        for (var i = -0; i < this.props.questionsList.length; i++) {
            if (this.props.questionsList[i] && this.props.questionsList[i].selected === true) {
                this.props.getVotingSessions(this.props.questionsList[i]);
                this.setState({
                    ...this.state,
                    sessionsModalToCompare: "",
                    mainQuestionConsidered: this.props.questionsList[i]
                });
                break;
            }
        }

    }

    saveSessionsModal() {
        this.setState({
            ...this.state,
            compareResultsStatus: true
        });

    }

    compareResults() {
        //we update the selected sessions in the main question that has been considered for defining the sessions
        this.props.resultsToCompare(this.props.questionsList, this.state.selectedsessionsToCompare, this.state.mainQuestionConsidered);
    }

    render() {
        var questionsListComponent = this.props.questionsList.map((question, i) => {
            if (question.firstVotingSessionInitialized === true && question.type !== "SimpleTextQuestion") {
                return (
                    <div key={i} className="custom-control custom-checkbox" id="questionsListComponent" onClick={() => {
                        this.getUserPreferences(question, 'questionCompareCheckBox')
                    }}>
                        <input type="checkbox" className="custom-control-input"
                               id={"questionCompareCheckBox-" + question.id}/>
                        <label className="custom-control-label"
                               htmlFor={"questionCompareCheckBox-" + question.id}>{question.text}</label>
                    </div>
                )
            }
        })

        var sessionItemsModal;
        if (this.props.validVotingSessions && this.props.validVotingSessions.length !== 0) {
            sessionItemsModal = this.props.validVotingSessions.map((item, i) => {
                return (
                    <div key={i} className="custom-control custom-checkbox  pb-1" onClick={() => {
                        this.getUserPreferences(item, 'compareSessionCheckBox')
                    }}>
                        <input type="checkbox" className="custom-control-input"
                               id={"compareSessionCheckBox-" + item.session.id}/>
                        <label className="custom-control-label"
                               htmlFor={"compareSessionCheckBox-" + item.session.id}>{"Session " + (i + 1) + " ( Started at: " + moment(item.session.timeStamp.toDate()).format("HH:mm") + " )"}</label>
                    </div>
                )
            });
        } else {
            sessionItemsModal = <p> No Session initialized for this question. </p>

        }
        return (
            <div>
                <div className="modal fade" id="compareQuestionsModal" tabIndex="-1" role="dialog"
                     aria-labelledby="compareQuestionsModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Compare Questions' Results</h5>
                            </div>
                            <div className="modal-body">
                                <div><h5>Select Questions To Compare</h5></div>
                                <div>
                                    {questionsListComponent}
                                </div>
                            </div>
                            <div className="modal-footer">

                                {(this.state.compareResultsStatus === false) ? (
                                    <div>
                                        <button type="button" className="btn btn-secondary mr-2"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="button" className="btn btn-primary mr-2">Select All</button>
                                        <button type="button" className="btn btn-primary" data-toggle="modal"
                                                data-target="#sessionsModalToCompare" onClick={this.nextBtn}>Next
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <button type="button" className="btn btn-secondary mr-2"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="button" className="btn btn-success" onClick={this.compareResults}
                                                data-dismiss="modal">Compare
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id={this.state.sessionsModalToCompare} tabIndex="-1" role="dialog"
                     aria-labelledby="sessionsModalToCompare" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sessions</h5>
                            </div>
                            <div className="modal-body modalSessions">
                                <p>Select Sessions To Compare Results </p>
                                <div className="pt-2 pl-2">
                                    {sessionItemsModal}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        onClick={this.saveSessionsModal}>Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveVotingSession: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.saveVotingSession(eventId, idQuestionEl, questionId, callback)),
        onGetResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetLastVotingSession: (eventId, idQuestionEl, question, callback) => dispatch(questionActions.getLastVotingSession(eventId, idQuestionEl, question, callback)),
    };
};

export default connect(null, mapDispatchToProps)(CompareQuestionsModal);
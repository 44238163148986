import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';
import HeaderTab from '../Components/HeaderTab';
import EditModal from '../Modals/EditModal';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import Flag from 'react-world-flags';


class SurveyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            surveyId: this.props.match.params.idItem,
            user: 'default',
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
            sortedFilesList: [],
            editingItem: 'default',
            currentEvent: 'default',
            parentInfo: 'default',
            selectedLanguageStatus: "default",
            saveLinkStatus: "default",
            surveyInfo: '',
            editingSurveyItemTitle: 'default',
            editingSurveyItemPopupMessage: 'default',
            surveyFile: "default",
        };

        this.removeFile = this.removeFile.bind(this);
        this.editFileName = this.editFileName.bind(this);
        this.saveEditedFile = this.saveEditedFile.bind(this);
        this.cancelFile = this.cancelFile.bind(this);
        this.saveLink = this.saveLink.bind(this);
        this.handleText = this.handleText.bind(this);
        this.getUserPreference = this.getUserPreference.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({...this.state, user: user});

        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }
        this.props.onGetSurveyInfoOnChange(this.state.eventId, this.state.surveyId, (surveyInfo, unsubscribe) => {
            this.unsubscribeGetSurveyInfoOnChange = unsubscribe;
            if (!surveyInfo.startFullScreenMode) {
                surveyInfo.startFullScreenMode = 'default';
            }
            if (surveyInfo.startInIframe === undefined) {
                surveyInfo.startInIframe = true;
            }
            this.setState({...this.state, surveyInfo: surveyInfo});
        });

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }
        this.props.onGetSurveyFiles(this.state.eventId, this.state.surveyId, (filesList, unsubscribe) => {
            this.unsubscribeGetSurveyFiles = unsubscribe;
            if (filesList.length > 0) {
                this.setState({...this.state, surveyFile: filesList[0]});
            }
        });

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
        });

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({...this.state, parentInfo: parentInfo});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let surveyNameInput = document.getElementById("surveyNameInput");
        if (surveyNameInput) surveyNameInput.focus();
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }
    }

    handleText = (e) => {
        switch (e.target.name) {
            case "LinkAddressSurvey":
                if (e.value !== "") {
                    this.setState({...this.state, saveLinkStatus: "available", [e.LinkAddressSurvey]: e.value});
                }
                break;

            default:
                return true;
        }
    }

    saveLink() {
        let LinkAddressSurvey = document.getElementById('LinkAddressSurvey').value;
        const file = this.state.selectedFileToUpload;
        var addOrderIndex = this.state.sortedFilesList.length + 1;
        let fileData = {
            name: 'default',
            type: "link",
            sParentId: this.state.idElement,
            fileOrderIndex: addOrderIndex,
            fileUrl: LinkAddressSurvey
        }
        this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, fileData, 'saveSurveyLink', savedItem => {
        });
    }

    cancelFile() {
        this.setState({
            ...this.state,
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
        })
    }

    removeFile(file) {
        //we also change the currentSurvey filed to default 
        this.props.onRemoveLibraryFile(this.state.eventId, this.state.surveyId, file.id);
        this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, file, 'stopSurvey', savedItem => {
        });
        if (this.state.parentInfo.id === "default") {
            this.props.onUpdateCurrentField("updateCurrentSurvey", this.state.eventId, "default", callback => {
            });
        } else {
            this.props.onUpdateElementData(this.state.eventId, "updateCurrentSurveyStop", this.state.parentInfo, "", callback => {
            });
        }
        this.setState({...this.state, surveyFile: "default"})
    }


    editFileName(file) {
        this.setState({
            ...this.state, editingItem: file
        });
    }

    saveEditedFile(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            var fileInputName = document.getElementById("fileInputName").value;

            let fileData = this.state.editingItem;
            fileData.name = fileInputName;
            this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, fileData, 'nameEdited', savedItem => {
                this.setState({
                    ...this.state, editingItem: 'default'
                });
            });
        }
    }

    goToTweetScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    goToQuestionScreen() {
        if (this.state.parentInfo.currentQuestionElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }
    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentSurveyElement.id !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);

        }
    }

    handleEditEvent() {
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    getUserPreference(file, type) {
        switch (type) {
            case "showSurveyInMenu":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, file, 'showSurveyInMenu', savedItem => {
                });
                break;


            case "hideSurveyInMenu":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, file, 'hideSurveyInMenu', savedItem => {
                });
                break;


            case "makeLiveSurvey":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, file, 'makeLiveSurvey', savedItem => {
                });

                if (this.state.parentInfo.id === "default") {
                    this.props.onUpdateCurrentField("updateCurrentSurvey", this.state.eventId, this.state.surveyId, callback => {
                    });
                } else {
                    //update the current survey element in the room element
                    this.props.onUpdateElementData(this.state.eventId, "updateCurrentSurveyLive", this.state.parentInfo, this.state.surveyId, callback => {
                    });
                }
                break;

            case "stopSurvey":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, file, 'stopSurvey', savedItem => {
                });

                if (this.state.parentInfo.id === "default") {
                    this.props.onUpdateCurrentField("updateCurrentSurvey", this.state.eventId, "default", callback => {
                    });
                } else {
                    this.props.onUpdateElementData(this.state.eventId, "updateCurrentSurveyStop", this.state.parentInfo, "", callback => {
                    });
                }

                break;

            case "editSurveyName":
                this.setState({
                    ...this.state, editingSurveyItemTitle: ''
                });
                break;

            case "saveEditedSurveyName":
                var surveyNameInput = document.getElementById("surveyNameInput").value;
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, surveyNameInput, 'saveEditedSurveyName', savedItem => {
                    this.setState({
                        ...this.state, editingSurveyItemTitle: 'default'
                    });
                });
                break;

            case "cancelEditedSurveyName":
                this.setState({
                    ...this.state, editingSurveyItemTitle: 'default'
                });
                break;

            case "editSurveyPopupMessage":
                this.setState({
                    ...this.state, editingSurveyItemPopupMessage: ''
                });
                break;

            case "saveEditedSurveyPopupMessage":
                var surveyPopupMessageInput = document.getElementById("surveyPopupMessageInput").value;
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, surveyPopupMessageInput, 'saveEditedSurveyPopupMessage', savedItem => {
                    this.setState({
                        ...this.state, editingSurveyItemPopupMessage: 'default'
                    });
                });
                break;

            case "cancelEditedSurveyPopupMessage":
                this.setState({
                    ...this.state, editingSurveyItemPopupMessage: 'default'
                });
                break;


            case "cancelEditedFileName":
                this.setState({
                    ...this.state, editingItem: 'default'
                });
                break;

            case "startFullScreenMode":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, 'Yes', 'startFullScreenMode', savedItem => {
                    this.setState({
                        surveyInfo: {
                            ...this.state.surveyInfo,
                            startFullScreenMode: 'Yes'
                        }
                    });
                });
                break;

            case "startNoFullScreenMode":
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, 'default', 'startNoFullScreenMode', savedItem => {
                    this.setState({
                        surveyInfo: {
                            ...this.state.surveyInfo,
                            startFullScreenMode: 'default'
                        }
                    });
                });
                break;

            case 'startInIframe':
                let newStartInIframe = !this.state.surveyInfo.startInIframe;
                this.props.onSaveSurveyFile(this.state.eventId, this.state.surveyId, newStartInIframe, 'startInIframe', savedItem => {
                    this.setState({
                        surveyInfo: {
                            ...this.state.surveyInfo,
                            startInIframe: newStartInIframe
                        }
                    });
                });
                break;

            default:
                return true;
        }
    }

    setLanguage(e) {
        let lang = e.target.value;
        this.props.setElementLanguage(this.state.eventId, this.state.surveyId, lang);
    }

    render() {
        var selectedLanguageStrings;
        if (this.state.currentEvent && this.state.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        var addedClassstartFullScreenMode;

        if (this.state.surveyInfo.startFullScreenMode === "default") {
            addedClassstartFullScreenMode = " ";
        } else if (this.state.surveyInfo.startFullScreenMode === "Yes") {
            addedClassstartFullScreenMode = " recent bg-primary ";
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="SurveyScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"survey"}
                        />

                        <HeaderTab
                            page="SurveyScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            goToTweetScreen={this.goToTweetScreen.bind(this)}
                            activeScreen={'library'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        />

                        <main role="main">
                            {(this.state.currentEvent.eventStatus === "Archived") ? (
                                <div className="container">
                                    <div className="row align-items-center mt-2 mb-2">
                                        <div className="col-2 pr-0">
                                            <h3>Survey Title:</h3>
                                        </div>
                                        <div className="col-4 text-left text-primary">
                                            <h3>{this.state.surveyInfo.title}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 pt-3 pb-3">
                                            <h3>External content's File Info: </h3>
                                        </div>
                                    </div>
                                    {(this.state.surveyFile === "default") ? (
                                        <div className="card">
                                            <div className="card-body text-center">
                                                No Uploaded file.
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row align-items-center pb-3">
                                            <div className="col-1 text-center pr-0 card-body-files-icons pl-2">
                                                <i className="fas fa-link text-primary linkIcon"></i>
                                            </div>
                                            <div className="col-11">
                                                <div className="card card-body-files">
                                                    <div className="row">
                                                        <div className="col-11 col-lg-8 col-md-9 col-sm-10">
                                                            {(this.state.surveyFile.name === "default") ? (
                                                                <a href={this.state.surveyFile.fileUrl} target="_blank">
                                                                    {this.state.surveyFile.fileUrl}
                                                                </a>
                                                            ) : (
                                                                <a href={this.state.surveyFile.fileUrl} target="_blank">
                                                                    {this.state.surveyFile.name}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="container">

                                    {/* Row Survey Title */}
                                    <div className="row align-items-center mt-2 mb-2 space-between">

                                        <div className="col-auto pr-0">
                                            <h3>Title:</h3>
                                        </div>

                                        {/* Survey Title Field */}
                                        {(this.state.editingSurveyItemTitle === "default") ? (
                                            /* Label */
                                            <div className="col text-left text-primary">
                                                <h3>{this.state.surveyInfo.title}</h3>
                                            </div>
                                        ) : (
                                            /* Input */
                                            <div className="col text-left">
                                                <input type="text" className="form-control" id="surveyNameInput"
                                                       defaultValue={this.state.surveyInfo.title} aria-label="Title"
                                                       aria-describedby="basic-addon2"/>
                                            </div>
                                        )}

                                        {/* Commands */}
                                        <div className="col-auto text-left">
                                            <div className="row">
                                                <div className="col-auto">
                                                    {(this.state.editingSurveyItemTitle === "default") ? (
                                                        /* Not editing */
                                                        /* Show Edit Button */
                                                        <button
                                                            className="btn bg-primary mb-1 text-white fileBtnTexts px-2"
                                                            onClick={() => {
                                                                this.getUserPreference("", "editSurveyName")
                                                            }}>
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </button>
                                                    ) : (
                                                        /* Editing */
                                                        /* Show Cancel Edit / Save Buttons */
                                                        <span>
                                                            <button
                                                                className="btn bg-secondary text-white mb-1 fileBtnTexts px-3"
                                                                onClick={() => {
                                                                    this.getUserPreference("", "cancelEditedSurveyName")
                                                                }}>
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                            <button
                                                                className="btn bg-success text-white mb-1 fileBtnTexts px-3"
                                                                onClick={() => {
                                                                    this.getUserPreference("", "saveEditedSurveyName")
                                                                }}>
                                                                <i className="fas fa-check"></i>
                                                            </button>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-auto">
                                                    {(this.state.surveyInfo.surveyStatus === "live") ? (
                                                        <button style={{backgroundColor: "#5a5c5f"}}
                                                                className=" mb-1 btn text-white px-2" type="button"
                                                                id="survey-live-button"
                                                                survey-id={this.state.surveyFile.id} onClick={() => {
                                                            this.getUserPreference(this.state.surveyFile, "stopSurvey")
                                                        }}>
                                                            <i className="fas fa-stop"></i>
                                                        </button>
                                                    ) : (
                                                        <button className="px-2 mb-1 btn btn-success" type="button"
                                                                id="survey-stop-button"
                                                                survey-id={this.state.surveyFile.id} onClick={() => {
                                                            this.getUserPreference(this.state.surveyFile, "makeLiveSurvey")
                                                        }}>
                                                            {/* <i className="fas fa-play-circle"></i> */}
                                                            <i className="fas fa-play"></i>
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="col-auto">
                                                    {(this.state.surveyInfo.showSurveyInMenu === true) ? (
                                                        <button style={{backgroundColor: "#6e7177"}}
                                                                className="btn text-white  mb-1" onClick={() => {
                                                            this.getUserPreference(this.state.surveyFile, "hideSurveyInMenu")
                                                        }}>
                                                            <i className="fas fa-eye-slash mr-1"></i> Hide
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-warning  mb-1"
                                                                style={{color: "#6e7177"}} onClick={() => {
                                                            this.getUserPreference(this.state.surveyFile, "showSurveyInMenu")
                                                        }}>
                                                            <i className="fas fa-eye mr-1"></i> Show
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="col-auto">
                                                    <select className="form-control d-table" id="lang-select"
                                                            value={this.state.surveyInfo.language}
                                                            onChange={this.setLanguage.bind(this)}>
                                                        <option value="All">All language</option>
                                                        <option value="English">English</option>
                                                        <option value="Italian">Italian</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mt-2 mb-2 space-between">
                                        <div className="col-auto pr-0">
                                            <h3>Popup Message:</h3>
                                        </div>

                                        {(this.state.editingSurveyItemPopupMessage === "default") ? (
                                            <div className="col text-left text-primary">
                                                <p>{this.state.surveyInfo.popupmessage}</p>
                                            </div>
                                        ) : (
                                            <div className="col text-left">
                                                <input type="text" className="form-control" id="surveyPopupMessageInput"
                                                       defaultValue={this.state.surveyInfo.popupmessage}
                                                       aria-label="PopupMessage" aria-describedby="basic-addon2"/>
                                            </div>
                                        )}

                                        <div className="col-auto text-left">
                                            <div className="row">
                                                <div className="col-auto">
                                                    {(this.state.editingSurveyItemPopupMessage === "default") ? (
                                                        <button
                                                            className="btn bg-primary mb-1 text-white fileBtnTexts px-2"
                                                            onClick={() => {
                                                                this.getUserPreference("", "editSurveyPopupMessage")
                                                            }}>
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </button>
                                                    ) : (
                                                        <span>
                                                            <button
                                                                className="btn bg-secondary text-white mb-1 fileBtnTexts px-3"
                                                                onClick={() => {
                                                                    this.getUserPreference("", "cancelEditedSurveyPopupMessage")
                                                                }}>
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                            <button
                                                                className="btn bg-success text-white mb-1 fileBtnTexts px-3"
                                                                onClick={() => {
                                                                    this.getUserPreference("", "saveEditedSurveyPopupMessage")
                                                                }}>
                                                                <i className="fas fa-check"></i>
                                                            </button>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mt-2 mb-2 space-between">
                                        <div className="col-auto pr-0">
                                            <h3>Start in Fullscreen</h3>
                                        </div>

                                        {(this.state.surveyInfo.startFullScreenMode === 'default') ? (
                                            <div className="col-auto pr-0">
                                                <button type='button' className="text-center mt-1 tweetsToShow"
                                                        id="removeBtnShadow" checked onClick={() => {
                                                    this.getUserPreference("", "startFullScreenMode")
                                                }}>
                                                    <div className={"switch " + addedClassstartFullScreenMode}>
                                                        <div
                                                            className="textToggleOrder text-white ">{selectedLanguageStrings.no}</div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="col-auto pr-0">
                                                <button type='button' className="text-center mt-1 tweetsToShow"
                                                        id="removeBtnShadow" checked onClick={() => {
                                                    this.getUserPreference("", "startNoFullScreenMode")
                                                }}>
                                                    <div className={"switch " + addedClassstartFullScreenMode}>
                                                        <div
                                                            className="textToggleOrder text-white ">{selectedLanguageStrings.yes}</div>
                                                        <div className="inputOrder bg-white"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row align-items-center mt-2 mb-2 space-between">
                                        <div className="col-auto pr-0">
                                            <h3>Start in IFrame</h3>
                                        </div>
                                        <div className="col-auto pr-0">
                                            <button type='button' className="text-center mt-1 tweetsToShow"
                                                    id="removeBtnShadow" onClick={() => this.getUserPreference("", "startInIframe")}>
                                                <div
                                                    className={`switch ${this.state.surveyInfo.startInIframe ? 'recent bg-primary' : ''}`}>
                                                    <div
                                                        className="textToggleOrder text-white ">{this.state.surveyInfo.startInIframe ? selectedLanguageStrings.yes : selectedLanguageStrings.no}</div>
                                                    <div className="inputOrder bg-white"></div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                    {(this.state.surveyFile === "default") ? (
                                        <div className="row pt-2">
                                            <div className="col-12">
                                                <div className="form-group row mt-3">
                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 pr-0">
                                                        <label htmlFor="LinkAddressSurvey"
                                                               className="col-form-label">{selectedLanguageStrings.survey_link_address}</label>
                                                    </div>
                                                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                                                        <input type="text" className="form-control"
                                                               id="LinkAddressSurvey" name="LinkAddressSurvey"
                                                               placeholder="URL" onChange={this.handleText}/>
                                                    </div>

                                                    {(this.state.saveLinkStatus === "default") ? (
                                                        <button type="button"
                                                                className="btn bg-secondary text-white px-4" disabled>
                                                            Save
                                                        </button>
                                                    ) : (
                                                        <button type="button" className="btn bg-success text-white px-4"
                                                                onClick={this.saveLink}>
                                                            Save
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="row">
                                                <div className="col-12 pt-3 pb-3">
                                                    <h3>External content's File Info: </h3>
                                                </div>
                                            </div>
                                            <div className="row align-items-center pb-3">
                                                <div className="col-auto text-center pr-0 card-body-files-icons pl-2">
                                                    <i className="fas fa-link text-primary linkIcon"></i>
                                                </div>
                                                {(this.state.editingItem === "default") ? (
                                                    <div className="col-6 col-lg-6 col-md-5 col-sm-6 pl-0">
                                                        <div className="card card-body-files">
                                                            <div className="row">
                                                                <div className="col-11 col-lg-8 col-md-9 col-sm-10">
                                                                    {(this.state.surveyFile.name === "default") ? (
                                                                        <a href={this.state.surveyFile.fileUrl}
                                                                           target="_blank">
                                                                            {this.state.surveyFile.fileUrl}
                                                                        </a>
                                                                    ) : (
                                                                        <a href={this.state.surveyFile.fileUrl}
                                                                           target="_blank">
                                                                            {this.state.surveyFile.name}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col labelFolder pl-0 ">
                                                        <div className="input-group mb-3 ml-2">
                                                            {(this.state.surveyFile.name === "default") ? (
                                                                <input type="text" className="form-control"
                                                                       onKeyUp={this.saveEditedFile} id="fileInputName"
                                                                       defaultValue={this.state.surveyFile.fileUrl}
                                                                       aria-label="Title"
                                                                       aria-describedby="basic-addon2"/>
                                                            ) : (
                                                                <input type="text" className="form-control"
                                                                       onKeyUp={this.saveEditedFile} id="fileInputName"
                                                                       defaultValue={this.state.surveyFile.name}
                                                                       aria-label="Title"
                                                                       aria-describedby="basic-addon2"/>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col text-left pl-0">
                                                    {(this.state.editingItem === "default") ? (
                                                        <button
                                                            className="col-4 btn bg-primary text-white mr-2 mb-1 fileBtnTexts"
                                                            onClick={() => {
                                                                this.editFileName(this.state.surveyFile)
                                                            }}>
                                                            <i className="fas fa-pencil-alt mr-1"></i>Edit File Name
                                                        </button>
                                                    ) : (
                                                        <span>
                                                            <button
                                                                className="col-1 btn bg-secondary text-white mr-2 mb-1 fileBtnTexts px-3"
                                                                onClick={() => {
                                                                    this.getUserPreference("", "cancelEditedFileName")
                                                                }}>
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                            <button
                                                                className="col-1 btn bg-success text-white mr-2 mb-1 fileBtnTexts px-3"
                                                                onClick={this.saveEditedFile}>
                                                                <i className="fas fa-check"></i>
                                                            </button>
                                                        </span>
                                                    )}
                                                    <button className="col-1 btn bg-danger text-white fileBtnTexts mb-1"
                                                            onClick={() => {
                                                                this.removeFile(this.state.surveyFile)
                                                            }}>
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </main>

                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"SurveyScreen"}
                        />

                    </div>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUploadSurveyFile: (userId, eventId, elementId, itemId, type, file, callback) => dispatch(eventActions.uploadSurveyFile(userId, eventId, elementId, itemId, type, file, callback)),
        onSaveSurveyFile: (eventId, surveyId, fileData, type, callback) => dispatch(eventActions.saveSurveyFile(eventId, surveyId, fileData, type, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(eventActions.getSurveyFiles(eventId, surveyId, callback)),
        onRemoveLibraryFile: (eventId, surveyId, fileId) => dispatch(eventActions.removeLibraryFile(eventId, surveyId, fileId)),
        onUpdateFilesIndexes: (eventId, surveyId, newSortedList) => dispatch(eventActions.updateLibraryFileIndexes(eventId, surveyId, newSortedList)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onGetSurveyInfoOnChange: (eventId, surveyId, callback) => dispatch(eventActions.getSurveyInfoOnChange(eventId, surveyId, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        setElementLanguage: (eventId, surveyId, lang) => dispatch(eventActions.setElementLanguage(eventId, surveyId, lang))
    };
};

const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
        agendaFilesList: state.eventReducer.agendaFilesList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyScreen);
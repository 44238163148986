import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';
import HeaderTab from '../Components/HeaderTab';
import EditModal from '../Modals/EditModal';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import Flag from 'react-world-flags';


class QRCodeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            qrcodeId: this.props.match.params.idItem,
            user: 'default',
            editingItem: 'default',
            currentEvent: 'default',
            parentInfo: 'default',
            selectedLanguageStatus: "default",
            saveLinkStatus: "default",
            qrcodeInfo: '',
            editingQRCodeItem: 'default',
            saveTextStatus: 'default'
        };

        this.getUserPreference = this.getUserPreference.bind(this);
        this.handleText = this.handleText.bind(this);
        this.saveText = this.saveText.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({...this.state, user: user});

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
        });

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({...this.state, parentInfo: parentInfo});
        });

        if (this.unsubscribeGetQRCodeInfoOnChange) {
            this.unsubscribeGetQRCodeInfoOnChange();
        }
        this.props.onGetQRCodeInfoOnChange(this.state.eventId, this.state.qrcodeId, (qrcodeInfo, unsubscribe) => {
            this.unsubscribeGetQRCodeInfoOnChange = unsubscribe;
            this.setState({...this.state, qrcodeInfo: qrcodeInfo});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let qrcodeNameInput = document.getElementById("qrcodeNameInput");
        if (qrcodeNameInput) qrcodeNameInput.focus();
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetQRCodeInfoOnChange) {
            this.unsubscribeGetQRCodeInfoOnChange();
        }
    }

    getUserPreference(file, type) {
        switch (type) {
            case "showQRCodeInMenu":
                this.props.onSaveQRCodeFile(this.state.eventId, this.state.qrcodeId, file, 'showQRCodeInMenu', savedItem => {
                });
                break;
            case "hideQRCodeInMenu":
                this.props.onSaveQRCodeFile(this.state.eventId, this.state.qrcodeId, file, 'hideQRCodeInMenu', savedItem => {
                });
                break;

            case "editQRCodeName":
                this.setState({
                    ...this.state, editingQRCodeItem: ''
                });
                break;

            case "saveEditedQRCodeName":
                var qrcodeNameInput = document.getElementById("qrcodeNameInput").value;
                this.props.onSaveQRCodeFile(this.state.eventId, this.state.qrcodeId, qrcodeNameInput, 'saveEditedQRCodeName', savedItem => {
                    this.setState({
                        ...this.state, editingQRCodeItem: 'default'
                    });
                });
                break;

            case "cancelEditedQRCodeName":
                this.setState({
                    ...this.state, editingQRCodeItem: 'default'
                });
                break;

            default:
                return true;
        }
    }

    goToTweetScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    goToQuestionScreen() {

        if (this.state.parentInfo.currentQuestionElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }
    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentSurveyElement.id !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);

        }
    }

    handleEditEvent() {
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    handleText = (e) => {
        switch (e.target.name) {

            case "QRText":
                if (e.value !== "") {
                    this.setState({...this.state, saveTextStatus: "available"});
                }
                break;

            default:
                return true;
        }
    }

    saveText() {
        let fileData = document.getElementById('QRText').value;
        this.props.onSaveQRCodeFile(this.state.eventId, this.state.qrcodeId, fileData, 'saveQRText', savedItem => {
            if (savedItem.message === "success") {
                this.setState({...this.state, saveTextStatus: "default"});
            }
        });
    }

    render() {

        var selectedLanguageStrings;
        if (this.state.currentEvent && this.state.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="QRCodeScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"qrcode"}
                        />

                        <HeaderTab
                            page="QRCodeScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            goToTweetScreen={this.goToTweetScreen.bind(this)}
                            activeScreen={'library'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        />

                        <main role="main">
                            <div className="container">
                                <div className="row align-items-center mt-2 mb-2">
                                    <div className="col-12 col-sm-2 pr-0 text-center text-sm-left mb-3 mb-sm-3">
                                        <h3>QR Scanner Title:</h3>
                                    </div>

                                    {(this.state.editingQRCodeItem === "default") ? (
                                        <div className="col-12 col-sm-4 text-center text-sm-left text-primary">
                                            <h3>{this.state.qrcodeInfo.title}</h3>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-sm-4 text-center text-sm-left">
                                            <input type="text" className="form-control" id="qrcodeNameInput"
                                                   defaultValue={this.state.qrcodeInfo.title} aria-label="Title"
                                                   aria-describedby="basic-addon2"/>
                                        </div>
                                    )}

                                    <div className="col-12 col-sm-6 mt-1 mt-sm-0 text-center text-sm-left">

                                        {(this.state.editingQRCodeItem === "default") ? (
                                            <button
                                                className="col-auto btn bg-primary px-3 mr-2 mb-1 text-white fileBtnTexts"
                                                onClick={() => {
                                                    this.getUserPreference("", "editQRCodeName")
                                                }}>
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                        ) : (
                                            <span>
                                                <button
                                                    className="col-auto btn bg-secondary text-white mr-2 mb-1 fileBtnTexts px-3"
                                                    onClick={() => {
                                                        this.getUserPreference("", "cancelEditedQRCodeName")
                                                    }}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                                <button
                                                    className="col-auto btn bg-success text-white mr-2 mb-1 fileBtnTexts px-3"
                                                    onClick={() => {
                                                        this.getUserPreference("", "saveEditedQRCodeName")
                                                    }}>
                                                    <i className="fas fa-check"></i>
                                                </button>
                                            </span>
                                        )}

                                        {(this.state.qrcodeInfo.showQRCodeInMenu === true) ? (
                                            <button style={{backgroundColor: "#6e7177"}}
                                                    className="col-auto btn text-white mr-2 mb-1" onClick={() => {
                                                this.getUserPreference(this.state.qrcodeFile, "hideQRCodeInMenu")
                                            }}>
                                                <i className="fas fa-eye-slash mr-1"></i> Hide
                                            </button>
                                        ) : (
                                            <button className="col-auto btn btn-warning mr-2 mb-1"
                                                    style={{color: "#6e7177"}} onClick={() => {
                                                this.getUserPreference(this.state.qrcodeFile, "showQRCodeInMenu")
                                            }}>
                                                <i className="fas fa-eye mr-1"></i> Show
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-12">
                                        <div className="form-group row mt-3">
                                            <div
                                                className="col-12 col-sm-2 col-md-2 col-lg-2 pr-0 text-center text-sm-left">
                                                <label htmlFor="QRText"
                                                       className="col-form-label">{selectedLanguageStrings.qr_text}</label>
                                            </div>
                                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 text-center">
                                                <textarea type="text" className="form-control mb-2" id="QRText"
                                                          name="QRText" rows="5" placeholder="QR Scanner Client Text"
                                                          onChange={this.handleText}/>
                                                {(this.state.saveTextStatus === "default") ? (
                                                    <button type="button"
                                                            className="btn bg-secondary text-white px-5 py-2" disabled>
                                                        Save
                                                    </button>

                                                ) : (
                                                    <button type="button"
                                                            className="btn bg-success text-white px-5 py-2"
                                                            onClick={this.saveText}>
                                                        Save
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"SurveyScreen"}
                        />
                    </div>
                )}
            </div>
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveQRCodeFile: (eventId, qrcodeId, fileData, type, callback) => dispatch(eventActions.saveQRCodeFile(eventId, qrcodeId, fileData, type, callback)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onGetQRCodeInfoOnChange: (eventId, qrcodeId, callback) => dispatch(eventActions.getQRCodeInfoOnChange(eventId, qrcodeId, callback))
    };
}
const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeScreen);
import {actionTypes} from './actionTypes';
import {authenticationFunctions} from '../../services/AuthenticationFunctions';
import {alertActions} from './alert.actions';
import moment from 'moment';

export const authenticationActions = {
    register,
    login,
    logout,
    getUserDataOnChange
};

function register(user, callback) {

    return dispatch => {
        dispatch(request(user));
        authenticationFunctions.register(user, userResult => {
                if (userResult.message === "RegistrationSuccessful") {
                    dispatch(success(user));
                    localStorage.setItem('user', JSON.stringify({
                        'userId': userResult.data.userId,
                        'sFirstName': userResult.data.firstName,
                        'sLastName': userResult.data.lastName,
                        'sEmail': userResult.data.email,
                        'sType': "Basic"
                    }));
                    let userData = {
                        'userId': userResult.data.userId,
                        'sFirstName': userResult.data.firstName,
                        'sLastName': userResult.data.lastName,
                        'sEmail': userResult.data.email,
                        'sType': "Basic"
                    };
                    dispatch(successLogin(userData));
                    callback('RegistrationSuccessful');
                } else {
                    callback(userResult.data);
                }

                // if(userCallback.code ==='auth/email-already-in-use'){
                //     dispatch(alertActions.error(userCallback));
                // }else{
                //     dispatch(success(userCallback));
                //     localStorage.setItem('user', JSON.stringify({'userId':callback.userId,
                //     'sFirstName': userCallback.sFirstName, 'sLastName':userCallback.sLastName, 'sEmail':userCallback.sEmail,'sType':"Basic"}));
                //     callback('RegistrationSuccessful');
                //    dispatch(alertActions.success('Registration successful'));
                // }
            },
            error => {
                // dispatch(failure(error));
                // callback(error);
                // dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return {type: actionTypes.REGISTER_REQUEST, user}
    }

    function successLogin(user) {
        return {type: actionTypes.LOGIN_SUCCESS, user}
    }

    // function success(user) { return { type: actionTypes.REGISTER_SUCCESS, user } }
    function success(user) {
        return {type: actionTypes.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: actionTypes.REGISTER_FAILURE, error}
    }

}


function login(email, password, callback) {

    return dispatch => {
        dispatch(request({email}));
        authenticationFunctions.login(email, password).then(function (doc) {
            let userData = doc.data();
            Object.assign(userData, {userId: doc.id});
            dispatch(success(userData));
            localStorage.setItem('user', JSON.stringify({
                'userId': doc.id,
                'sFirstName': doc.data().sFirstName,
                'sLastName': doc.data().sLastName,
                'sEmail': doc.data().sEmail,
                'sType': doc.data().sType,
            }));
            callback('loginSuccessful')
            // this.props.history.push('/login');
            return doc.data();
        }).catch(function (error) {
            dispatch(failure(error));
            // dispatch(alertActions.error(error));
        })
    };

    function request(user) {
        return {type: actionTypes.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: actionTypes.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: actionTypes.LOGIN_FAILURE, error}
    }

}

function logout() {

    authenticationFunctions.logout();
    // return { type: userConstants.LOGOUT };
    //The action will be handled by the root reducer to delete the content for all the reducers.
    return {type: actionTypes.LOGOUT};

}

function getUserDataOnChange(userId, callback) {

    return dispatch => {
        authenticationFunctions.getUserDataOnChange(userId, (userData, unsubscribe) => {
                dispatch(success(userData));
                callback(userData, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(userData) {
        return {type: actionTypes.GET_USER_DATA_ON_CHANGE_SUCCESS, userData}
    }
}

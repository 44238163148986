import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import HeaderTab from '../Components/HeaderTab';
import Header from '../Components/Header';
import EditModal from '../Modals/EditModal';
import moment from 'moment';
import firebase from '../config/firebase';

class MessageScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idevent,
            currentEvent: 'default',
            messageList: []
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (this.unsubscribeGetEventInfoOnChange) {
                this.unsubscribeGetEventInfoOnChange();
            }
            //in the first initialization of the page we set the current event for the header and the other components
            this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeGetEventInfoOnChange = unsubscribe;
                this.setState({...this.state, user: user, currentEvent: this.props.currentEvent});
            });

            if (this.unsubscribeGetMessageList) {
                this.unsubscribeGetMessageList();
            }
            this.props.onGetMessageList(this.state.eventId, (messageList, unsubscribe) => {
                this.unsubscribeGetMessageList = unsubscribe;
                this.setState({...this.state, messageList: messageList});
            });
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetMessageList) {
            this.unsubscribeGetMessageList();
        }
    }

    handleEditEvent() {
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    deleteMessage(message) {
        this.props.onDeleteMessage(this.state.eventId, message.id);
    }

    pushMessage(message) {
        this.props.onPushMessage(this.state.eventId, message.id, !(message.pushed));
    }

    render() {
        let messages = (this.state.messageList || []).map((message, i) => {
            return (
                <div className="p-3 bg-white mb-3" key={i}>
                    <div className="row mb-2">
                        <div className="col">
                            <b>{message.title}</b>
                        </div>
                        <div className="col-auto">
                            {(message.pushed) ? (
                                    <button className="rounded border-0 mr-2 btn-dark" onClick={() => {
                                        this.pushMessage(message)
                                    }}>
                                        <i className="fas fa-bell text-white mr-2"></i>
                                        <b className="text-white">PUSHED</b>
                                    </button>
                                ) :
                                (
                                    <button className="rounded border-0 mr-2 btn-warning" onClick={() => {
                                        this.pushMessage(message)
                                    }}>
                                        <i className="fas fa-bell text-white mr-2"></i>
                                        <b className="text-white">PUSH</b>
                                    </button>
                                )}
                            <button className="rounded border-0 btn-danger" onClick={() => {
                                this.deleteMessage(message)
                            }}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>{message.body}</p>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="MessageScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item="messages"
                        />
                        <HeaderTab
                            page="MessageScreen"
                            // goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            // goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            // navItemTweet={"border-bottom"}
                            // activeScreen={'tweet'}
                            // tabColor={"text-primary"}
                            item="messages"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                        />
                        <main role="main">
                            <div className="container">
                                <div className="row my-4">
                                    <div className="col">
                                        <h2>Message list</h2>
                                    </div>
                                    <div className="col-auto">
                                        <button data-toggle="modal" data-target="#messageModal"
                                                className="btn btn-outline-primary float-left">
                                            <i className="fas fa-plus mr-2"></i>
                                            <strong>New Message</strong>
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {messages}
                                    </div>
                                </div>
                            </div>
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"TweetScreen"}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        onGetMessageList: (eventId, callback) => dispatch(eventActions.getMessageList(eventId, callback)),
        onDeleteMessage: (eventId, messageId) => dispatch(eventActions.deleteMessage(eventId, messageId)),
        onPushMessage: (eventId, messageId, bPush) => dispatch(eventActions.pushMessage(eventId, messageId, bPush)),
    };
};
const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
        updatedCurrentFiledstatus: state.eventReducer.updatedCurrentFiledstatus,
        eventCodeAvailability: state.eventReducer.eventCodeAvailability
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageScreen);
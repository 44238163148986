import React, { Component } from 'react';

class DeleteModal extends Component {

    render() {
        return (
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.deleteModalTitle}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                           {this.props.deleteModalQuote}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  data-dismiss="modal" onClick={()=>{this.props.modalDeleteFunction(this.props.event)}}>Yes</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DeleteModal;
import firebase from '../config/firebase';
var firestore = firebase.firestore();

export const bigScreenFunctions = {
    getLiveSessionOptions,
    getQuestion,
    getAllLiveTweetsList,
    getAllApprovedTweetsList,
    getAllQuestionOptionsScreen,
    getLastVotingSessionId
}


function getLiveSessionOptions(eventId, elementId, questionId, liveSessionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/Answers").get();
}

function getQuestion(eventId, elementId, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/").doc(questionId).get();
}

function getAllLiveTweetsList(eventId, idElement, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/tweets").orderBy("dtmLive", "asc").onSnapshot(querySnapShot => {
        let liveTweetsList = [];
        querySnapShot.forEach(document => {
            if (!document.data().elementIsDeleted || document.data().elementIsDeleted && document.data().elementIsDeleted === false) {
                let tweet = document.data();
                tweet.id = document.id;
                if (tweet.live) {
                    liveTweetsList.push(tweet);
                }
            }
        });
        callback(liveTweetsList, unsubscribe);
    });
}

function getAllApprovedTweetsList(eventId, idElement, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/tweets").orderBy("dtmApproved", "asc").onSnapshot(querySnapShot => {
        let approvedTweetsList = [];
        querySnapShot.forEach(document => {
            if (!document.data().elementIsDeleted || document.data().elementIsDeleted && document.data().elementIsDeleted === false) {
                let tweet = document.data();
                tweet.id = document.id;
                if (tweet.bApproved) { approvedTweetsList.push(tweet); }
            }
        });
        callback(approvedTweetsList, unsubscribe);
    });
}

function getAllQuestionOptionsScreen(eventId, idElement, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/" + questionId + "/Answers").get();
}

function getLastVotingSessionId(eventId, idElement, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/" + questionId + "/VotingSession")
        // .where("sessionIsDeleted", "==", false)
        .orderBy('time', 'desc').limit(1).get();
}
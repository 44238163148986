import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Tweet from '../Components/Tweet';
import HeaderTab from '../Components/HeaderTab';
import EditModal from '../Modals/EditModal';
import Workbook from 'react-excel-workbook';

class TweetScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            idTweetEl: this.props.match.params.idItem,
            parentInfo: 'default',
            approvedTweetsList: [],
            archivedTweetsList: [],
            allTweetsList: [],
            liveTweetsIds: [],
            activeScreen: '',
            participantName: '',
            currentFieldUpdated: false,
            saveBtnTitle: '',
            user: '',
            currentEvent: 'default',
            sendTweetStatus: 'disabled',
            tabScreenOpened: false,
            receivedTweets: [],
            tweetSessionInfo: 'default'

        }
        this.handleChangeText = this.handleChangeText.bind(this);
        this.setCurrentTweetSession = this.setCurrentTweetSession.bind(this);
        this.setTweetWriteEnable = this.setTweetWriteEnable.bind(this);
        this.resetTweetWriteEnable = this.resetTweetWriteEnable.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            if (this.unsubscribeGetEventInfoOnChange) {
                this.unsubscribeGetEventInfoOnChange();
            }
            //in the first initialization of the page we set the current event for the header and the other components
            this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeGetEventInfoOnChange = unsubscribe;
                this.setState({...this.state, user: user, currentEvent: this.props.currentEvent});
            });

            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({...this.state, parentInfo: parentInfo});
            });

            if (this.unsubscribeGetElementItemInfoOnChange) {
                this.unsubscribeGetElementItemInfoOnChange();
            }
            this.props.onGetElementItemInfoOnChange(this.state.eventId, this.state.idTweetEl, (tweetSessionInfo, unsubscribe) => {
                this.unsubscribeGetElementItemInfoOnChange = unsubscribe;
                this.setState({...this.state, tweetSessionInfo: tweetSessionInfo});
            });

            if (this.unsubscribeGetAllTweets) {
                this.unsubscribeGetAllTweets();
            }
            this.props.onGetAllTweets(this.state.eventId, this.state.idTweetEl, (tweetsList, unsubscribe) => {
                this.unsubscribeGetAllTweets = unsubscribe;

                this.setState({
                    ...this.state,
                    getAllTweets: tweetsList
                });
                var approvedTweets = [];
                var archivedTweets = [];
                var allTweets = [];
                var participantDefinedName = ""
                if (user && user.participantDefinedName) {
                    participantDefinedName = user.participantDefinedName;
                }

                for (var index = 0; index < tweetsList.length; index++) {
                    if (tweetsList[index].bArchived === true) {
                        archivedTweets.push(tweetsList[index]);
                    } else if (tweetsList[index].bApproved === true) {
                        approvedTweets.push(tweetsList[index]);
                    } else {
                        allTweets.push(tweetsList[index]);
                    }
                }

                approvedTweets.sort((a, b) => (a.dtmApproved > b.dtmApproved) ? 1 : ((b.dtmApproved > a.dtmApproved) ? -1 : 0));
                if (approvedTweets[0] && approvedTweets[0].dtmApproved === null) {
                    approvedTweets.push(approvedTweets.shift());
                }

                if (this.props.currentEvent.LiveTweetsIds) {
                    this.setState({
                        ...this.state,
                        approvedTweetsList: approvedTweets,
                        allTweetsList: allTweets,
                        liveTweetsIds: this.props.currentEvent.LiveTweetsIds,
                        archivedTweetsList: archivedTweets,
                        participantName: participantDefinedName,
                        user: user
                    });
                } else {
                    this.setState({
                        ...this.state,
                        approvedTweetsList: approvedTweets,
                        allTweetsList: allTweets,
                        activeScreen: 'tweets',
                        archivedTweetsList: archivedTweets,
                        participantName: participantDefinedName,
                        user: user

                    });
                }
            });
        } else {
            this.props.history.push('/login');

        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetAllTweets) {
            this.unsubscribeGetAllTweets();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetElementItemInfoOnChange) {
            this.unsubscribeGetElementItemInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
    }

    approveFunction(tweet) {
        tweet.bApproved = true;
        if (tweet.bArchived === true) {
            //it means that we have Approved the tweet from the list of the archived tweets so we remove it from the list of archived tweets
            //so we only update the approved field but the tweet will be moved to the approved list if the user makes it unarchive
            this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "Approved");
        } else {
            for (var index = 0; index < this.state.allTweetsList.length; index++) {
                if (this.state.allTweetsList[index].id === tweet.id) {
                    this.state.allTweetsList.splice(index, 1);
                }
            }
            this.state.approvedTweetsList.push(tweet);
            //we update the approved field of the tweet in the firebase
            this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "Approved");
        }
    }

    unApproveFunction(tweet) {
        tweet.bApproved = false;
        //we will not remove the tweet from the archive list if the user has been unapproved it
        if (tweet.bArchived === true) {
            this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "unApproved");
        } else {
            for (var index = 0; index < this.state.approvedTweetsList.length; index++) {
                if (this.state.approvedTweetsList[index].id === tweet.id) {
                    this.state.approvedTweetsList.splice(index, 1);
                }
            }
            this.state.allTweetsList.push(tweet);
            this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "unApproved");

        }
    }

    deleteFunction(tweet) {
        if (tweet.bApproved && tweet.bApproved === true) {
            for (var index = 0; index < this.state.approvedTweetsList.length; index++) {
                if (this.state.approvedTweetsList[index].id === tweet.id) {
                    this.state.approvedTweetsList.splice(index, 1);
                }
            }
        } else {
            for (var index1 = 0; index1 < this.state.allTweetsList.length; index1++) {
                if (this.state.allTweetsList[index1].id === tweet.id) {
                    this.state.allTweetsList.splice(index1, 1);
                }
            }
        }
        this.props.onDeleteTweet(this.state.eventId, this.state.idTweetEl, tweet.id);
    }

    sendTweetToScreen(tweet) {
        var updatedLiveTweetsList = this.state.liveTweetsIds;
        updatedLiveTweetsList.push(tweet.id);
        this.setState({...this.state, liveTweetsIds: updatedLiveTweetsList});
        this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, 'liveTrue');
    }

    stopTweetToScreen(tweet) {
        var updatedTweetsIds = this.state.liveTweetsIds;
        var index = updatedTweetsIds.indexOf(tweet.id);
        if (index > -1) {
            updatedTweetsIds.splice(index, 1);
        }
        this.setState({...this.state, liveTweetsIds: updatedTweetsIds});
        this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, 'liveFalse');
    }

    goToQuestionScreen() {
        if (this.state.parentInfo.currentQuestionElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }

    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentSurveyElement.id !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);

        }
    }

    archiveFunction(tweet) {
        tweet.bArchived = true;
        for (var index = 0; index < this.state.allTweetsList.length; index++) {
            if (this.state.allTweetsList[index].id === tweet.id) {
                this.state.allTweetsList.splice(index, 1);
            }
        }
        this.state.archivedTweetsList.push(tweet);
        //we update the approved field of the tweet in the firebase
        this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "Archived");
    }

    unArchiveFunction(tweet) {
        tweet.bArchived = false;
        if (tweet.bApproved === true) {
            for (var index = 0; index < this.state.archivedTweetsList.length; index++) {
                if (this.state.archivedTweetsList[index].id === tweet.id) {
                    this.state.archivedTweetsList.splice(index, 1);
                }
                this.state.approvedTweetsList.push(tweet);
            }
        } else {
            for (var i = 0; i < this.state.archivedTweetsList.length; i++) {
                if (this.state.archivedTweetsList[i].id === tweet.id) {
                    this.state.archivedTweetsList.splice(i, 1);
                }
                this.state.allTweetsList.push(tweet);
            }
        }
        this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet.id, "unArchived");

    }

    tweetClicked() {
        alert('tweet clicked');
    }


    handleChangeText = (e) => {
        if (e.target.name === "tweetInputText") {
            this.setState({...this.state, sendTweetStatus: " "});
        }
    }

    sendTweet() {
        let user = JSON.parse(localStorage.getItem('user'));
        var tweetInputText = document.getElementById("tweetInputText").value;
        var tweetInputName = document.getElementById("tweetInputName").value;
        var anonymoudstatusValue = document.getElementById("optionAnonymous").checked;

        if (this.state.sendTweetStatus !== "disabled") {
            if (anonymoudstatusValue === true || tweetInputName === "") {
                //if the user checked the annonymoud status while sending the tweet
                this.props.onSaveNewTweet(user, this.state.eventId, this.state.idTweetEl, tweetInputText, "");
            } else if (tweetInputName !== "" || tweetInputName !== this.state.participantName) {
                //we also update the participant's data if the name is provided
                this.props.onSaveNewTweet(user, this.state.eventId, this.state.idTweetEl, tweetInputText, tweetInputName);
                this.setState({...this.state, participantName: tweetInputName});
            }
            document.getElementById('formTweetModal').reset();
            this.setState({...this.state, sendTweetStatus: "disabled"});
        }
    }

    closeModal() {
        this.setState({...this.state, sendTweetStatus: "disabled"});
        document.getElementById('formTweetModal').reset();
    }


    goToBigScreen() {
        if (this.props.currentEvent.screenNumber === "Two") {
            // this.props.history.push("/event/" + this.state.eventId + "/liveTweet");
            //Open the big screen page on the new Tab
            if (this.state.tabScreenOpened === false) {
                var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/liveTweet", '_blank');
                win.focus();
                this.setState({...this.state, tabScreenOpened: true});
            }
            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }
        } else {
            this.props.onUpdateElementData(this.state.eventId, "singleScreen-tweet", this.state.idElement, this.state.idTweetEl, callback => {
                //Open the big screen page on the new Tab
                if (this.state.tabScreenOpened === false) {
                    var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/live", '_blank');
                    win.focus();
                    this.setState({...this.state, tabScreenOpened: true})
                }
            });

            if (this.props.currentEvent.showIntroductionPage !== "No") {
                this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
                });
            }


        }
    }

    goToBigScreenModerator() {
        var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/liveTweet-M", '_blank');
        win.focus();

        if (this.props.currentEvent.showIntroductionPage !== "No") {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.props.currentEvent.id, "No", callback => {
            });
        }
    }

    handleEditEvent() {
        //	this.props.setCurrentEventId(this.props.currentEvent.id, callback => {
        // var img = document.getElementById('imagePreview');
        // if (this.props.currentEvent.eventLogoUrl !== 'default') {
        //     img.src = this.props.currentEvent.eventLogoUrl;
        // } else {
        //     img.src = "/files/LogoTemp.png";
        // }

        //});

        this.setState({...this.state, saveBtnTitle: 'Update'});

    }

    goIntroductionPage() {
        if (this.state.tabScreenOpened === false) {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/live", '_blank');
                win.focus();
                this.setState({...this.state, tabScreenOpened: true})
            });
        } else {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                this.setState({...this.state, tabScreenOpened: true})
            });
        }
    }

    saveEditedTweetFunction(tweet) {
        var tweetTextInput = document.getElementById("tweetTextInput").value;
        tweet.sText = tweetTextInput;
        this.props.onUpdateTweetFields(this.state.eventId, this.state.idTweetEl, tweet, "editedTweetText");

    }

    setCurrentTweetSession() {
        this.props.onUpdateElementData(this.state.eventId, "updateCurrentTweet", this.state.parentInfo, this.state.tweetSessionInfo, callback => {

        });
    }

    resetCurrentTweetSession() {
        this.props.onUpdateElementData(this.state.eventId, "updateCurrentTweet", this.state.parentInfo, "default", callback => {

        });
    }

    setTweetWriteEnable() {
        this.props.onUpdateElementData(this.state.eventId, "updateTweetsParticipantsWriteEnable-set", this.state.parentInfo, "", callback => {

        });
    }

    resetTweetWriteEnable() {
        this.props.onUpdateElementData(this.state.eventId, "updateTweetsParticipantsWriteEnable-reset", this.state.parentInfo, "", callback => {

        });
    }


    render() {
        let tweetListComponents;
        if (this.state.allTweetsList) {
            /************** Question List on Start Up **********/
            tweetListComponents = this.state.allTweetsList.map((tweet, i) => {
                return (
                    <Tweet
                        key={tweet.id}
                        tweet={tweet}
                        approveFunction={this.approveFunction.bind(this)}
                        deleteFunction={this.deleteFunction.bind(this)}
                        archive={this.archiveFunction.bind(this)}
                        saveEditedTweetFunction={this.saveEditedTweetFunction.bind(this)}
                        primaryColor={this.props.currentEvent.primaryColor}
                        textColor={this.props.currentEvent.textColor}
                        event={this.props.currentEvent}

                    />
                );
            });

            if (this.state.allTweetsList.length === 0) {
                tweetListComponents = (
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="card question-card">
                                <form>
                                    <p className="lead questionLabel"></p>
                                    <div className="card-body option-card-body">
                                        <h6 className="font-no-result text-center">No Tweet.</h6>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        }


        let approvedTweetListComponents;
        if (this.state.approvedTweetsList) {
            /************** Question List on Start Up **********/
            approvedTweetListComponents = this.state.approvedTweetsList.map((tweet, i) => {
                return (
                    <Tweet
                        key={tweet.id}
                        tweet={tweet}
                        unApproveFunction={this.unApproveFunction.bind(this)}
                        deleteFunction={this.deleteFunction.bind(this)}
                        sendTweetToScreen={this.sendTweetToScreen.bind(this)}
                        liveTweetsIds={this.state.liveTweetsIds}
                        stopTweetToScreen={this.stopTweetToScreen.bind(this)}
                        archive={this.archiveFunction.bind(this)}
                        primaryColor={this.props.currentEvent.primaryColor}
                        textColor={this.props.currentEvent.textColor}
                        event={this.props.currentEvent}

                    />
                );
            });

            if (this.state.approvedTweetsList.length === 0) {
                approvedTweetListComponents = (
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="card question-card">
                                <form>
                                    <p className="lead questionLabel"></p>
                                    <div className="card-body option-card-body">
                                        <div className="font-no-result text-center"><h6>No Approved Tweet.</h6></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        let archivedTweetListComponents;
        if (this.state.archivedTweetsList) {
            /************** Question List on Start Up **********/
            archivedTweetListComponents = this.state.archivedTweetsList.map((tweet, i) => {
                return (
                    <Tweet
                        key={tweet.id}
                        tweet={tweet}
                        approveFunction={this.approveFunction.bind(this)}
                        unApproveFunction={this.unApproveFunction.bind(this)}
                        deleteFunction={this.deleteFunction.bind(this)}
                        unArchive={this.unArchiveFunction.bind(this)}
                        primaryColor={this.props.currentEvent.primaryColor}
                        textColor={this.props.currentEvent.textColor}
                        event={this.props.currentEvent}

                    />
                );
            });

            if (this.state.archivedTweetsList.length === 0) {
                archivedTweetListComponents = (
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="card question-card">
                                <form>
                                    <p className="lead questionLabel"></p>
                                    <div className="card-body option-card-body">
                                        <div className="font-no-result text-center"><h6>No Archived Tweet.</h6></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        var allTweetListWithoutModeratorComponents = (this.state.getAllTweets || []).map((tweet, i) => {
            if (tweet.bArchived === false) {
                return (
                    <Tweet
                        key={tweet.id}
                        tweet={tweet}
                        saveEditedTweetFunction={this.saveEditedTweetFunction.bind(this)}
                        deleteFunction={this.deleteFunction.bind(this)}
                        archive={this.archiveFunction.bind(this)}
                        event={this.props.currentEvent}
                    />
                );
            }
        });

        if (this.state.getAllTweets && this.state.getAllTweets.length === 0) {
            archivedTweetListComponents = (
                <div className="row mt-1">
                    <div className="col-12">
                        <div className="card question-card">
                            <form>
                                <p className="lead questionLabel"></p>
                                <div className="card-body option-card-body">
                                    <div className="font-no-result text-center"><h6>No Tweet Yet.</h6></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="TweetScreen"
                            goToBigScreen={this.goToBigScreen.bind(this)}
                            goToBigScreenModerator={this.goToBigScreenModerator.bind(this)}
                            goIntroductionPage={this.goIntroductionPage.bind(this)}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"tweet"}
                        />

                        <HeaderTab
                            page="TweetScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            navItemTweet={"border-bottom"}
                            activeScreen={'tweet'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        />

                        <main role="main">
                            <div className="container">
                                <div className="row mb-4 align-items-center">
                                    <div className="col-auto">
                                        <h3><strong>Start/Stop Current Tweet Session:</strong></h3>
                                    </div>
                                    <div className="col-auto">
                                        {(this.state.parentInfo.currentTweetElement === "default") ? (
                                            <div className="col-3 text-left">
                                                <button type="button" className="btn btn-success float-left"
                                                        onClick={() => {
                                                            this.setCurrentTweetSession()
                                                        }}>
                                                    <i className="fas fa-play"></i></button>
                                            </div>
                                        ) : (
                                            <div className="col-3 text-left">
                                                <button type="button" className="btn btn-danger float-left"
                                                        onClick={() => {
                                                            this.resetCurrentTweetSession()
                                                        }}>
                                                    <i className="fas fa-stop"></i></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-4 align-items-center">
                                    <div className="col-auto">
                                        <h3><strong>Enable/Disable Send Tweet:</strong></h3>
                                    </div>
                                    <div className="col-auto">
                                        {(this.state.parentInfo.tweetsParticipantsWriteEnable === false) ? (
                                            <div className="col-3 text-left">
                                                <button type="button" className="btn btn-success float-left"
                                                        onClick={() => {
                                                            this.setTweetWriteEnable()
                                                        }}>
                                                    <i className="fas fa-play"></i></button>
                                            </div>
                                        ) : (
                                            <div className="col-3 text-left">
                                                <button type="button" className="btn btn-danger float-left"
                                                        onClick={() => {
                                                            this.resetTweetWriteEnable()
                                                        }}>
                                                    <i className="fas fa-stop"></i></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button type="button" className="btn btn-outline-primary float-left mb-3"
                                                data-toggle="modal" data-target="#newTweetModal"
                                                defaultValue={this.state.participantName}>
                                            <i className="fas fa-plus"></i> <strong>New Tweet</strong></button>
                                    </div>
                                    {(this.state.getAllTweets && this.state.getAllTweets.length > 0) ? (
                                        <div className="col-6 text-right">
                                            <Workbook
                                                filename={"4meet-Tweets" + this.props.currentEvent.title + "-" + this.state.parentInfo.title + ".xlsx"}
                                                element={<button className="btn btn-success"
                                                                 style={{fontSize: "15px"}}>
                                                    Export Tweets in Excel &nbsp;&nbsp;
                                                    <i className="fas fa-download"></i>
                                                </button>}>
                                                <Workbook.Sheet data={this.state.getAllTweets}
                                                                name={"All Tweets - " + this.state.parentInfo.title}>
                                                    <Workbook.Column label="Tweet Text" value="sText"/>
                                                    <Workbook.Column label="Time" value="dtmCreatedMoment"/>
                                                    <Workbook.Column label="Approved status" value="bApproved"/>
                                                    <Workbook.Column label="Live status" value="live"/>
                                                    <Workbook.Column label="Archived status" value="bArchived"/>
                                                    <Workbook.Column label="Participant's Name"
                                                                     value="participantDefinedName"/>
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {(this.props.currentEvent.autoApproveTweets === "Without Moderator") ? (
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-lg-6">
                                            <div className="container-tweet">
                                                <h2 className="mb-3"><b>All Live Tweets (No Moderation)</b>
                                                </h2>
                                                {allTweetListWithoutModeratorComponents}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-6">
                                            <div className="container-tweet" id="container-tweet-half">
                                                <h2 className="mb-3"><b>Archived Tweets</b></h2>
                                                {archivedTweetListComponents}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-lg-6">
                                            <div className="container-tweet">
                                                <h2 className="mb-3"><b>Incoming Tweets</b></h2>
                                                {tweetListComponents}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-6">
                                            <div className="container-tweet" id="container-tweet-half">
                                                <h2 className="mb-3"><b>Approved Tweets</b></h2>
                                                {approvedTweetListComponents}
                                            </div>
                                            <div className="container-tweet" id="container-tweet-half">
                                                <h2 className="mb-3 mt-3"><b>Archived Tweets</b></h2>
                                                {archivedTweetListComponents}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"TweetScreen"}
                        />

                    </div>
                )}
                <div className="modal fade" id="newTweetModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Write a new Tweet</h5>
                            </div>
                            <div className="modal-body">
                                <form id="formTweetModal">
                                    <div className="form-group">
                                        <label htmlFor="tweetInputText">Your Message</label>
                                        <textarea className="form-control" id="tweetInputText" rows="3"
                                                  name="tweetInputText" onChange={this.handleChangeText}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tweetInputName">Your Name (optional)</label>
                                        <input type="text" className="form-control" id="tweetInputName"
                                               name="tweetInputName" defaultValue={this.state.participantName}/>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-10 ml-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="optionAnonymous"
                                                       name="optionAnonymous"/>
                                                <label className="form-check-label mt-1" htmlFor="optionAnonymous">
                                                    Send as Anonymous
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={this.closeModal.bind(this)}>Close
                                </button>
                                <button type="button" className={"btn btn-primary " + this.state.sendTweetStatus}
                                        data-dismiss="modal" onClick={this.sendTweet.bind(this)}>Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitTree: (idEvent) => dispatch(eventActions.getSingleEventTree(idEvent)),
        onGetAllTweets: (eventId, idTweetEl, callback) => dispatch(eventActions.getAllTweets(eventId, idTweetEl, callback)),
        onUpdateTweetFields: (eventId, idTweetEl, tweetId, field) => dispatch(eventActions.updateTweetFields(eventId, idTweetEl, tweetId, field)),
        onDeleteTweet: (eventId, idTweetEl, tweetId) => dispatch(eventActions.deleteTweet(eventId, idTweetEl, tweetId)),
        onSaveNewTweet: (user, eventId, idTweetEl, tweetInputText, tweetInputName) => dispatch(eventActions.saveNewTweet(user, eventId, idTweetEl, tweetInputText, tweetInputName)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onGetElementItemInfoOnChange: (eventId, elementItemId, callback) => dispatch(eventActions.GetElementItemInfoOnChange(eventId, elementItemId, callback)),
    };
};
const mapStateToProps = state => {
    return {
        jsonTree: state.eventReducer.jsonTree,
        currentEvent: state.eventReducer.currentEvent,
        updatedCurrentFiledstatus: state.eventReducer.updatedCurrentFiledstatus,
        eventCodeAvailability: state.eventReducer.eventCodeAvailability
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TweetScreen);
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
/********** REDUX IMPORT ***************/
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

/********** FIREBASE IMPORT ********************/


/********** CUSTOM CLASSES IMPORT *************/

import MainScreen from './Screens/MainScreen';
import {eventReducer} from './store/reducers/EventReducer';
import {questionReducer} from'./store/reducers/QuestionReducer';
import {surveyReducer} from './store/reducers/SurveyReducer';
import {bigScreenReducer} from './store/reducers/BigScreenReducer';
import {authenticationReducer} from './store/reducers/AuthenticationReducer';
import QuestionsScreen from './Screens/QuestionsScreen';
import TweetScreen from './Screens/TweetScreen';
import SurveyScreen from './Screens/SurveyScreen';
import QRCodeScreen from './Screens/QRCodeScreen';
import MessageScreen from './Screens/MessageScreen';


import registerServiceWorker from './registerServiceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import LiveQuestionScreen from './Screens/LiveQuestionScreen';
import LoginPage from './Screens/LoginPage';
import ResgiterationPage from './Screens/ResgiterationPage';
import {alertReducer} from './store/reducers/AlertReducer';
import PrivateRoute from './Components/PrivateRoute';
import LiveTweetScreen from './Screens/LiveTweetScreen';
import LiveScreen from './Screens/LiveScreen';
import LiveTweetScreenModerator from './Screens/LiveTweetScreenModerator';
import SortableComponent from './Screens/SortableComponent';
import FileExplorer from './TreeComponent/FileExplorer';
import LibraryScreen from './Screens/LibraryScreen';
import EventInfoScreen from './Screens/EventInfoScreen';
import PreviewHtmlFile from './Screens/PreviewHtmlFile';
import MultipleQuestionsComparison from './Screens/MultipleQuestionsComparison';
import SingleQuestionComparison from './Screens/SingleQuestionComparison';
import AgendaScreen from './Screens/AgendaScreen';
import ActiveParticipantsScreen from './Screens/ActiveParticipantsScreen';
import SharedEventScreen from './Screens/SharedEventScreen';
import {version} from '../package.json';

//4meet-First-Stable-Version-8-Nov-2018


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage: storage,
    //Defining the reducers that we want to store their data as persistent insetaed of considering all the reducers
   // whitelist: ['eventReducer','questionReducer','bigScreenReducer']
}
const rootReducer = combineReducers({
    eventReducer: eventReducer,
    questionReducer: questionReducer,
    bigScreenReducer: bigScreenReducer,
    authenticationReducer: authenticationReducer,
    surveyReducer: surveyReducer,
    // registration: registration,
    alertReducer:alertReducer
});
 const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, composeEnhancers(
    applyMiddleware(thunk)
));


var appVersion = localStorage.getItem('4meet-admin-version');
if (!(appVersion && appVersion === version) && ((window.location.origin + "/") !== window.location.href)) {
    localStorage.clear();
    window.location.href ="/";
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
        {/* <App/> */}
            <BrowserRouter>
                <Switch> 
                    <PrivateRoute exact path="/" component={MainScreen} />
                    <Route exact path="/shared" component={SharedEventScreen}/>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/registeration" component={ResgiterationPage}/>
                    <Route exact path="/mainScreen" component={MainScreen} />
                    {/* <Route exact path="/event/:idevent/" component={SingleEventScreen} /> */}
                    <Route exact path="/event/:idevent/tweet/:idTweetEl" component={TweetScreen} />
                    <Route exact path="/event/:idevent/question/:idQuestionEl" component={QuestionsScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/question/:idItem" component={QuestionsScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/tweet/:idItem" component={TweetScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/survey/:idItem" component={SurveyScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/liveQuestion" component={LiveQuestionScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/liveTweet" component={LiveTweetScreen}/>
                    <Route exact path="/event/:idevent/element/:idElement/live" component={LiveScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/multipleQuestionsComparison" component={MultipleQuestionsComparison} />
                    <Route exact path="/event/:idevent/element/:idElement/singleQuestionComparison" component={SingleQuestionComparison} />
                    <Route exact path="/event/:idevent/element/:idElement/liveTweet-M" component={LiveTweetScreenModerator} />
                    <Route exact path="/event/:idevent/element/:idElement/library/:idItem" component={LibraryScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/eventInfo/:idItem" component={EventInfoScreen} />
                    <Route exact path="/event/:idevent/element/:idElement/agenda/:idItem" component={AgendaScreen} />   
                    <Route exact path="/event/:idevent/element/:idElement/qrcode/:idItem" component={QRCodeScreen} />
                    <Route exact path="/sort" component={SortableComponent} />
                    <Route exact path="/event/:idevent" component={FileExplorer}/>
                    <Route exact path="/event/:idevent/PreviewHtml" component={PreviewHtmlFile}/>
                    <Route exact path="/event/:idevent/participants" component={ActiveParticipantsScreen}/>
                    <Route exact path="/event/:idevent/messages" component={MessageScreen}/>

                    {/* <Route exact path="/event/:idevent/ElementNodeWrapper" component={ElementNodeWrapper}/> */}
                </Switch>
            </BrowserRouter> 
        </PersistGate>
    </Provider>, 
    document.getElementById('root')
);
registerServiceWorker();
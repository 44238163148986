import { actionTypes } from '../actions/actionTypes';

let initialState = {
    questionList: null,
    questionOptions: [],
    votingSessionsList: [],
    responsesList: []
};

export function questionReducer(state = initialState, action) {

    switch (action.type) {

        case actionTypes.Fetch_ALL_QUESTIONS_SUCCESS:
            return {
                ...state,
                questionList: action.questionList,
            };

        case actionTypes.SAVE_QUESTION_SUCCESS:
            return state;
        case actionTypes.ADD_QUESTION:
            return state;
        case actionTypes.Fetch_ALL_QUESTION_OPTIONS_SUCCESS:
            var questionIndex = state.questionList.findIndex((elem) => {
                if (elem && elem.id === action.optionsData.id) {
                    return true;
                }
                return false;
            });

            if (questionIndex !== (-1)) {
                return {
                    ...state, questionList: [
                        //before the one we are updating
                        ...state.questionList.slice(0, questionIndex),
                        {
                            ...state.questionList[questionIndex],
                            optionsValues: action.optionsData.optionsValues
                        }, ...state.questionList.slice(questionIndex + 1)
                    ], questionOptions: action.optionsData.optionsValues,
                }
            }
            return state;

        case actionTypes.ADD_AND_DELETE_QUESTION:
            //We need to delete the previous question of the multiple options and add the new one 
            var newQuestionList = state.questionList.filter(question => question.id !== action.oldQuestion.id);
            let indexNew = (newQuestionList.length);
            if (indexNew !== (-1)) {


                return {
                    ...state, questionList: [
                        ...newQuestionList.slice(0, indexNew),//before the one we are updating
                        {
                            ...newQuestionList[indexNew],
                            id: action.questionData.newQuestion.id,
                            text: action.questionData.newQuestion.text,
                            type: action.questionData.newQuestion.type,
                            questionOrderIndex: action.questionData.questionOrderIndex,
                            letMultipleSelect: action.questionData.newQuestion.letMultipleSelect,
                            firstVotingSessionInitialized: action.questionData.newQuestion.firstVotingSessionInitialized,
                            optionsValues: action.questionData.newQuestion.optionsValues,
                            showCorrectAnswerColor: action.newQuestion.showCorrectAnswerColor
                        }
                    ]
                }
            };
            break;

        case actionTypes.ADD_OPTION_SUCCESS:

            let indexOption = (state.questionOptions.length);
            if (indexOption !== (-1)) {

                return {
                    ...state, questionOptions: [
                        ...state.questionOptions.slice(0, indexOption),//before the one we are updating
                        {
                            ...state.questionList[indexOption],
                            id: 'newValue',
                            text: '',
                        }
                    ]
                }

            };

            return state;


        case actionTypes.DELETE_QUESTION_SUCCESS:

            return {
                questionList: state.questionList.filter(question => question.id !== action.id)
            };

        case actionTypes.DELETE_OPTION_SUCCESS:

            return state;

        case actionTypes.SAVE_VOTING_SESSION_SUCCESS:
            return state;

        case actionTypes.Fetch_ALL_VOTING_SESSION_SUCCESS:
            return {
                ...state,
                votingSessionsList: action.votingSessionsList
            }

        case actionTypes.GET_LAST_VOTING_SESSION_SUCCESS:
        return{
            ...state,
            lastVotingSession:action.lastVotingSession
        }

        case actionTypes.Fetch_ALL_VOTING_RESPONSES_SUCCESS:
            return {
                ...state,
                responsesList: action.responsesList
            }

        case actionTypes.Fetch_ALL_RESPONSES_TEXT_SUCCESS:
        return {
            ...state,
            responsesTextList: action.responsesTextList
        }

        case actionTypes.FETCH_LIVE_QUESTIONS_INFO_SUCCESS:
            return {
                ...state,
                currentLiveQuestion: action.liveQuestionData
            }
        case actionTypes.STORE_SELECTED_LIVE_SESSION_SUCCESS:
            return {
                ...state,
                liveSession: action.liveSession
            }
        case actionTypes.UPDATE_QUESTION_FIELD_SUCCESS:
        // return state;
        var questionChangedIndex;
        questionChangedIndex = state.questionList.findIndex((elem) => {

            if (elem && elem.id === action.questionData.id) {

                return true;
            }
            return false;
        });

        if (questionChangedIndex !== (-1)) {
            return {
                ...state, questionList: [
                    //before the one we are updating
                    ...state.questionList.slice(0, questionChangedIndex),
                    {
                        ...state.questionList[questionChangedIndex],
                        id: action.questionData.id,
                        text: action.questionData.text,
                        type: action.questionData.type,
                        questionOrderIndex: action.questionData.questionOrderIndex,
                        letMultipleSelect: action.questionData.letMultipleSelect,
                        firstVotingSessionInitialized: action.questionData.firstVotingSessionInitialized,
                        showCorrectAnswerColor:action.questionData.showCorrectAnswerColor,
                        optionsValues: action.questionData.optionsValues
                    }, ...state.questionList.slice(questionChangedIndex + 1)
                ],
            }
        }
        
        case actionTypes.GET_CURRENT_STORED_SESSION_SUCCESS:
        return state;

        case actionTypes.DELETE_QUESTION_SESSION_SUCCESS:
        return {
            ...state,
            votingSessionsList: state.votingSessionsList.filter(votingSession => votingSession.id !== action.id)
        };

        case actionTypes.Fetch_VOTING_SESSION_TO_COMPARE_SUCCESS:
        return {
            ...state,
            votingSessionsListToCompare: action.votingSessionsListToCompare
        }

        case actionTypes.QUESTION_INDEXES_UPDATED_SUCCESS:
        return state;

        case actionTypes.OPTION_STATUS_UPDATED_SUCCESS:
        return state;

        default:
            return state;
    }
};

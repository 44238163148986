import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';
import HeaderTab from '../Components/HeaderTab';
import Header from '../Components/Header';
import EditModal from '../Modals/EditModal';
import moment from 'moment';
import firebase from '../config/firebase';

class ActiveParticipantsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            currentEvent: 'default',
            user: '',
            participantsList: [],
            onlineParticipantsIds: [],
            startTime: 'default',
            endTime: 'default',
        }

        this.filterTime = this.filterTime.bind(this);
        this.updateServerHeartBeatCounter = this.updateServerHeartBeatCounter.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (this.unsubscribeGetEventInfoOnChange) {
                this.unsubscribeGetEventInfoOnChange();
            }
            //in the first initialization of the page we set the current event for the header and the other components
            this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeGetEventInfoOnChange = unsubscribe;
                this.setState({ ...this.state, user: user, currentEvent: this.props.currentEvent });
            });
        }

        if (this.unsubscribeGetParticipantsList) {
            this.unsubscribeGetParticipantsList();
        }
        this.props.OnGetParticipantsList(this.state.eventId, (participantsList, unsubscribe) => {
            this.unsubscribeGetParticipantsList = unsubscribe;
            this.setState({ ...this.state, participantsList });
            var onlineParticipantsIds = this.state.onlineParticipantsIds;
            var conState = this;
            for (var i = 0; i < participantsList.length; i++) {
                var userRef = firebase.database().ref('/status/' + participantsList[i].id);
                userRef.on('value', function (snapshot) {
                    if (snapshot.val() && snapshot.val().state === "online") {
                        if (onlineParticipantsIds && !onlineParticipantsIds.includes(snapshot.key)) {
                            // User is online.
                            // userRef.set(true);
                            onlineParticipantsIds.push(snapshot.key);
                            conState.setState({ ...conState.state, onlineParticipantsIds });
                        }
                    } else {
                        // User is offline.
                        // WARNING: This won't work! See an explanation below.
                        //userRef.set(false);
                        var updatedOnlineParticipantsList = onlineParticipantsIds.filter(id => id !== snapshot.key);
                        conState.setState({ ...conState.state, onlineParticipantsIds: updatedOnlineParticipantsList });
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetParticipantsList) {
            this.unsubscribeGetParticipantsList();
        }
    }

    goIntroductionPage() {
        if (this.state.tabScreenOpened === false) {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/live", '_blank');
                win.focus();
                this.setState({ ...this.state, tabScreenOpened: true })
            });
        } else {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                this.setState({ ...this.state, tabScreenOpened: true })
            });
        }

    }

    handleEditEvent() {
        this.setState({ ...this.state, saveBtnTitle: 'Update' });
    }

    filterTime() {
        var dateStart = document.getElementById("dateStart").value;
        var dateEnd = document.getElementById("dateEnd").value;

        dateStart = moment(dateStart).format("MM/DD/YYYY HH:mm")
        dateEnd = moment(dateEnd).format("MM/DD/YYYY HH:mm")

        this.setState({ ...this.state, endTime: dateEnd, startTime: dateStart })
    }

    updateServerHeartBeatCounter() {
        this.props.onUpdateServerHeartBeatCounter(this.state.eventId);
    }    

    render() {
        if (this.state.onlineParticipantsIds) {
            var participantsListComponent;
            if (this.state.startTime === "default" && this.state.endTime === "default") {
                participantsListComponent = (this.state.participantsList || []).map((participant, i) => {
                    return (
                        <div className="card mb-2" >
                            <div className="card-body p-1">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="pl-3">{i + 1}</div>
                                    </div>
                                    <div className="col-3">
                                        {(participant.sName) ? (
                                            <div>{participant.sName}</div>
                                        ) : (
                                                <div> --- </div>
                                        )}
                                    </div>
                                    <div className="col-3 ">
                                        {(participant.dtmLastLogin) ? (
                                            <div>{moment(participant.dtmLastLogin.toDate()).format("MM/DD/YYYY - HH:mm:ss")}</div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <div className="col-3 ">
                                        {moment(participant.dtmFirstLogin.toDate()).format("MM/DD/YYYY - HH:mm:ss")}
                                    </div>
                                    <div className="col-2 ">
                                        {(this.state.onlineParticipantsIds.includes(participant.id)) ? (
                                            <div><i className="fas fa-circle text-success">  Online</i></div>
                                        ) : (
                                                <div><i className="fas fa-circle text-danger">  Offline</i></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            } else {
                var index=0;
                participantsListComponent = (this.state.participantsList || []).map((participant, i) => {                
                    if (this.state.startTime < moment(participant.dtmLastLogin.toDate()).format("MM/DD/YYYY HH:mm") &&
                        moment(participant.dtmLastLogin.toDate()).format("MM/DD/YYYY HH:mm") < this.state.endTime) {
                        index ++;
                        return (
                            <div className="card mb-2">
                                <div className="card-body p-1">
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="pl-3">{index}</div>
                                        </div>
    
                                        <div className="col-3">
                                            {(participant.sName) ? (
                                                <div>{participant.sName}</div>
                                            ) : (
                                                <div> --- </div>
                                            )}
                                        </div>
    
                                        <div className="col-3 ">
                                            {(participant.dtmLastLogin) ? (
                                                <div>{moment(participant.dtmLastLogin.toDate()).format("MM/DD/YYYY - HH:mm:ss")}</div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
    
                                        <div className="col-3 ">
                                            {moment(participant.dtmFirstLogin.toDate()).format("MM/DD/YYYY - HH:mm:ss")}
                                        </div>

                                        <div className="col-2 ">
                                            {(this.state.onlineParticipantsIds.includes(participant.id)) ? (
                                                <div><i className="fas fa-circle text-success">  Online</i></div>
                                            ) : (
                                                <div><i className="fas fa-circle text-danger">  Offline</i></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin" ></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="ActiveUsersScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item="participants"
                        />
                        <HeaderTab
                            page="ActiveUsersScreen"
                            // goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            // goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            // navItemTweet={"border-bottom"}
                            // activeScreen={'tweet'}
                            // tabColor={"text-primary"}
                            item="participants"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                        />
                        <main role="main">
                            <div className="container">
                                <div className="row align-items-center mb-4">
                                    <div className="col-4">
                                        <form className="form-inline">
                                            <div className="form-group mr-2">
                                                <label htmlFor="dateStart">Start Time:</label>
                                                <input type="datetime-local" className="form-control ml-2" id="dateStart" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-4">
                                        <form className="form-inline">
                                            <div className="form-group">
                                                <label htmlFor="dateEnd">End Time:</label>
                                                <input type="datetime-local" className="ml-2 form-control" id="dateEnd" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-1">
                                        {/* {(this.state.startTime !== "default" && this.state.endTime !== "default")?( */}
                                        <button className="btn btn-primary" onClick={this.filterTime}>Filter</button>
                                        {/* // ):(
                                        //     <button className="btn btn-primary" disabled>Filter</button>
                                        // )} */}
                                    </div>
                                    <div className="col-1">
                                        <button className="btn btn-primary" onClick={this.updateServerHeartBeatCounter}>CHECK CONNECTION</button>
                                    </div>                                    
                                </div>
                                <div className="card mb-2 bg-success text-white">
                                    <div className="card-body p-2">
                                        <div className="row ">
                                            <div className="col-1 pl-3"><b>No.</b></div>
                                            <div className="col-3 "><b>Name</b></div>
                                            <div className="col-3 "><b>Last Login</b></div>
                                            <div className="col-3"><b>First Login</b></div>
                                            <div className="col-2 "><b>Current Status</b></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="participanstScreen">
                                    {participantsListComponent}
                                </div>
                            </div>
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"TweetScreen"}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        OnGetParticipantsList: (eventId, callback) => dispatch(eventActions.getParticipantsList(eventId, callback)),
        onUpdateServerHeartBeatCounter: (eventId) => dispatch(eventActions.updateServerHeartBeatCounter(eventId))
    };
};

const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
        updatedCurrentFiledstatus: state.eventReducer.updatedCurrentFiledstatus,
        eventCodeAvailability: state.eventReducer.eventCodeAvailability
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveParticipantsScreen);
import React, { Component } from 'react';
import 'react-tippy/dist/tippy.css';
import { DropTarget } from 'react-dnd';

const Types = {

 ITEM: 'ChildElement'

}

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const elemntParentTarget = {
    // canDrop(props, monitor) {
    //   // You can disallow drop based on props or item
    //   //we prevent to drop the item on his own parent
    //   const item = monitor.getItem();
    //  // return canMakeChessMove(item.fromPosition, props.position);
    // },
  
    hover(props, monitor, component) {
      // This is fired very often and lets you perform side effects
      // in response to the hover. You can't handle enter and leave
      // here—if you need them, put monitor.isOver() into collect() so you
      // can just use componentDidUpdate() to handle enter/leave.
  
    //   // You can access the coordinates if you need them
    //   const clientOffset = monitor.getClientOffset();
    //   //const componentRect = findDOMNode(component).getBoundingClientRect();
  
    //   // You can check whether we're over a nested drop target
    //   const isJustOverThisOne = monitor.isOver({ shallow: true });
  
    //   // You will receive hover() even for items for which canDrop() is false
    //   const canDrop = monitor.canDrop();
    },
  
    drop(props, monitor, component) {

        const item = monitor.getItem();
        props.draggedChildElement(props.parentNode, item.childNode);
        if (monitor.didDrop()) {
            // If you want, you can check whether some nested
            // target already handled drop
            return;
        }
  
        // Obtain the dragged item
        //   const item = monitor.getItem();
  
        // You can do something with it
        // ChessActions.movePiece(item.fromPosition, props.position);
  
        // You can also do nothing and return a drop result,
        // which will be available as monitor.getDropResult()
        // in the drag source's endDrag() method
        //   return { moved: true };
    }

};
  

function collect(connect, monitor) {
    
    return {
        connectDropTarget: connect.dropTarget()
    }

}

class ParentElement extends Component {

    render() {

        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div>
                {(this.props.parentNode.id === "default") ? (
                    
                    <div className="row eventElementRoot">
                        <div className="col-11">
                            <div className="row">
                                <div className="col-4 pr-0 iconColumn">
                                    <i className="fas fa-calendar-alt"></i>
                                </div>
                                <div className="col-8"> 
                                    {this.props.event.title}
                                </div>
                            </div>
                        </div>
                        <div className="col-1 text-right pr-0">
                            <button className="btn btn-success itemsFunctionsBtn" data-toggle="modal" data-target="#modalType">
                                <i className="fas fa-plus bg-success "></i>
                            </button>
                        </div>
                    </div>
                
                ) : (

                    <div className="row eventElementParent">
                        <div className="col-10">
                            <div className="row">
                                <div className="col-4 pr-0 iconColumn">
                                    {(this.props.parentNode.sType === 'folder') ? (
                                        
                                        <i className="fas fa-folder folderIcon"></i>
                                    
                                    ) : (
                                
                                        <i className="fas fa-map-marker-alt text-danger folderIcon"></i>
                                
                                    )}
                                </div>
                                {(this.props.editingId === this.props.parentNode.id)?(
                                    <div className=" col-8 labelFolder"> 
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" id="parentInputTitle" defaultValue={this.props.parentNode.title} aria-label="Title" aria-describedby="basic-addon2"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.parentNode)}}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                
                                ) : (

                                    <div className=" col-8 labelFolder"> {this.props.parentNode.title}</div>
                                
                                )}
                            </div>
                        </div>
                        <div className="col-2 text-right pr-0">
                            {(this.props.parentNode.sType === 'folder' || this.props.parentNode.sType === 'room') ? (
                            
                                <button className="btn btn-success itemsFunctionsBtn" data-toggle="modal" data-target="#modalType" onClick={()=>{this.props.setCurrentItem(this.props.parentNode)}}>
                                    <i className="fas fa-plus bg-success "></i>
                                </button>
                            
                            ) : (
                            
                                <button className="btn btn-success itemsFunctionsBtn" onClick={()=>{this.props.setCurrentItem(this.props.parentNode)}} >
                                    <i className="fas fa-eye bg-success px-1"></i>
                                </button>
                            
                            )}
                            <button className="btn btn-primary itemsFunctionsBtn" >
                                <i className="fas fa-pen bg-primary " onClick={()=>{this.props.editItem(this.props.parentNode)}}></i>
                            </button>

                            <button className="btn btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={()=>{this.props.setCurrentItem(this.props.parentNode)}} >
                                <i className="fas fa-trash bg-danger "></i>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default DropTarget(Types.ITEM, elemntParentTarget, collect)(ParentElement)
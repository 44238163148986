import {actionTypes} from './actionTypes';
import {questionFunctions} from '../../services/QuestionFunctions';
import moment from 'moment';


export const questionActions = {
    getAllQuestions,
    saveQuestion,
    deleteQuestion,
    getAllQuestionOptions,
    addNewOption,
    saveVotingSession,
    getAllVotingSessions,
    getVotingSessionsToCompare,
    getResponses,
    getArchivedResponses,
    getQuestionInfoOnChange,
    getQuestionInfo,
    storeSelectedLiveSession,
    updateQuestionField,
    getCurrentStoredSession,
    deleteQuestionSession,
    updateQuestionsIndexes,
    setAnswerTextOptionStatus,
    getLastVotingSession
};


//Get all the questions of a certain event
function getAllQuestions(eventId, idElement, callback) {
    return dispatch => {
        questionFunctions.getQuestions(eventId, idElement, (questionList, unsubscribe) => {
                dispatch(success(questionList));
                callback(questionList, unsubscribe);
            },
            error => dispatch(failure(error))
        );
    };

    function success(questionList) {
        return {type: actionTypes.Fetch_ALL_QUESTIONS_SUCCESS, questionList}
    }

    function failure(error) {
        return {type: actionTypes.Fetch_ALL_QUESTIONS_ERROR, error}
    }
}


function saveQuestion(eventId, idElement, question, deletedOptionsArray, callbackData) {
    var questionData;
    return dispatch => {
        questionFunctions.saveQuestion(eventId, idElement, question)
            .then(result => {
                    if (question.id === 0) {
                        var questionId = result.id;
                        let status = 'create';
                        //In the case of multiple choice questions we will proceed to create the answer collection in the firebase and store the possible answers there
                        if (question.type === "MultipleChoice") {
                            questionFunctions.saveOptions(eventId, idElement, questionId, question, status, callback => {
                                questionData = question;
                                questionData.id = questionId;
                                questionData.optionsValues = callback;
                            });
                            //dispatch(successAdding(questionData));
                            dispatch(success(questionData));
                        } else if (question.type === "SimpleTextAndMultipleChoice") {
                            questionFunctions.saveOptions(eventId, idElement, questionId, question, status, callback => {
                                questionData = question;
                                questionData.id = questionId;
                                questionData.optionsValues = callback;
                            });
                            //dispatch(successAdding(questionData));
                            dispatch(success(questionData));

                        } else {
                            //Data of the other type of questions
                            questionData = question;
                            questionData.id = questionId;
                            //  dispatch(successAdding(questionData));
                            dispatch(success(questionData));
                        }
                        callbackData(questionData);

                    } else {
                        //Save an edited question
                        if (question.type === "MultipleChoice") {
                            let status = 'edit';
                            questionFunctions.saveOptions(eventId, idElement, question.id, question, status, optionsArraycallback => {
                                questionData = question;
                                questionData.id = question.id;
                                questionData.optionsValues = optionsArraycallback;
                            })
                            questionFunctions.deleteOptions(eventId, idElement, question.id, deletedOptionsArray);

                        } else if (question.type === "SimpleTextAndMultipleChoice") {
                            let status = 'edit';
                            questionFunctions.saveOptions(eventId, idElement, question.id, question, status, optionsArraycallback => {
                                questionData = question;
                                questionData.id = question.id;
                                questionData.optionsValues = optionsArraycallback;
                            })
                            questionFunctions.deleteOptions(eventId, idElement, question.id, deletedOptionsArray);

                        } else {
                            questionData = question;
                            questionData.id = question.id;
                        }
                        dispatch(success(questionData));
                        callbackData(questionData);
                    }
                },
                error => {
                    callbackData(error);
                    //dispatch(failure(error));
                });
    }

    function success(questionData) {
        return {type: actionTypes.SAVE_QUESTION_SUCCESS, questionData}
    }

    //function successAdding(questionData) { return { type: actionTypes.ADD_QUESTION, questionData } }
};

function getAllQuestionOptions(eventId, idElement, questionId, callback) {
    return dispatch => {
        questionFunctions.getAllQuestionOptions(eventId, idElement, questionId,
            (optionsResult, unsubscribe) => {
                dispatch(success({id: questionId, optionsValues: optionsResult}));
                callback(optionsResult, unsubscribe);
            },
            error => {
                // callback(error);
            }
        )
    };

    function success(optionsData) {
        return {type: actionTypes.Fetch_ALL_QUESTION_OPTIONS_SUCCESS, optionsData}
    }
}


function deleteQuestion(eventId, idElement, question) {
    return dispatch => {
        questionFunctions.deleteQuestion(eventId, idElement, question)
            .then(
                result => {
                    dispatch(success(question.id));
                },
                error => {
                    // dispatch(failure(question.id, error));
                }
            );
    };

    function success(id) {
        return {type: actionTypes.DELETE_QUESTION_SUCCESS, id}
    }
};

function addNewOption() {
    return dispatch => {
        dispatch(success());
    };

    function success(optionsData) {
        return {type: actionTypes.ADD_OPTION_SUCCESS, optionsData}
    }
}

function saveVotingSession(eventId, idElement, questionId, callback) {
    return dispatch => {
        questionFunctions.saveVotingSession(eventId, idElement, questionId)
            .then(result => {
                    dispatch(success(result.id));
                    callback(result.id)
                },
                error => {
                    // dispatch(failure(question.id, error));
                }
            );
    };

    function success(votingSessionData) {
        return {type: actionTypes.SAVE_VOTING_SESSION_SUCCESS, votingSessionData}
    }
}

function getAllVotingSessions(eventId, idElement, questionId, callback) {
    return dispatch => {
        questionFunctions.getAllVotingSessions(eventId, idElement, questionId)
            .then(
                function (querySnapshot) {

                    let votingSessionsList = [];
                    querySnapshot.forEach(function (document) {

                        let votingSession = {
                            id: document.id,
                            time: moment(document.data().time.toDate()).format("DD MMM YYYY HH:mm:ss"),
                            timeStamp: document.data().time,
                            voted: document.data().voted
                        };
                        votingSessionsList.push(votingSession);
                    });
                    dispatch(success(votingSessionsList));
                    callback(votingSessionsList);
                },
                error => {

                }
            )
    };

    function success(votingSessionsList) {
        return {type: actionTypes.Fetch_ALL_VOTING_SESSION_SUCCESS, votingSessionsList}
    }
}

function getVotingSessionsToCompare(eventId, idElement, question, selectedVotingSessions, callback) {

    return dispatch => {
        questionFunctions.getVotingSessionsToCompare(eventId, idElement, question.id)
            .then(
                function (querySnapshot) {

                    let votingSessionsList = [];
                    querySnapshot.forEach(function (document) {
                        for (var i = 0; i < selectedVotingSessions.length; i++) {
                            //we only chooce the documents that the user selected
                            if (selectedVotingSessions[i].id === document.id) {
                                let votingSession = {
                                    id: document.id,
                                    time: moment(document.data().time.toDate()).format("DD MMM YYYY HH:mm:ss"),
                                    timeStamp: document.data().time,
                                    question: question,
                                    voted: document.data().voted
                                };
                                votingSessionsList.push(votingSession);
                            }
                        }
                    });
                    dispatch(success(votingSessionsList));
                    callback(votingSessionsList);
                },
                error => {

                }
            )
    };

    function success(votingSessionsListToCompare) {
        return {type: actionTypes.Fetch_VOTING_SESSION_TO_COMPARE_SUCCESS, votingSessionsListToCompare}
    }
}


function getLastVotingSession(eventId, idQuestionEl, question, callback) {
    return dispatch => {
        questionFunctions.getLastVotingSession(eventId, idQuestionEl, question.id)
            .then(function (querySnapshot) {
                    let votingSessionsList = [];
                    querySnapshot.forEach(function (document) {

                        let votingSession = {
                            id: document.id,
                            time: moment(document.data().time.toDate()).format("DD MMM YYYY HH:mm:ss"),
                            timeStamp: document.data().time,
                            question: question,
                        };
                        votingSessionsList.push(votingSession);
                    });
                    votingSessionsList.sort(function (a, b) {
                        return a.timeStamp > b.timeStamp ? -1 : 1
                    });
                    dispatch(success(votingSessionsList[0]));
                    callback(votingSessionsList[0]);
                },
                error => {

                }
            )
    };

    function success(lastVotingSession) {
        return {type: actionTypes.GET_LAST_VOTING_SESSION_SUCCESS, lastVotingSession}
    }
}


function getResponses(eventId, idElement, question, votingId, callbackResponses) {
    return dispatch => {
        questionFunctions.getResponses(eventId, idElement, question, votingId,
            (responsesList, unsubscribe) => {
                if (question.type !== "SimpleTextQuestion") {
                    dispatch(success({id: question.id, votingId: votingId, responses: responsesList}));
                    callbackResponses(responsesList, unsubscribe);
                } else {
                    dispatch(successText({id: question.id, votingId: votingId, responses: responsesList}));
                    callbackResponses(responsesList, unsubscribe);
                }
            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(responsesList) {
        return {type: actionTypes.Fetch_ALL_VOTING_RESPONSES_SUCCESS, responsesList}
    }

    function successText(responsesTextList) {
        return {type: actionTypes.Fetch_ALL_RESPONSES_TEXT_SUCCESS, responsesTextList}
    }
}


function getArchivedResponses(eventId, idElement, question, votingId, callbackResponses) {
    return dispatch => {
        questionFunctions.getArchivedResponses(eventId, idElement, question, votingId).then(
            querySnapShot => {
                if (question.type === "SimpleTextQuestion") {
                    let archivedResponsesTextList = [];
                    querySnapShot.forEach(document => {
                        let response = {
                            id: document.id,
                            sAnswer: document.data().sAnswer,
                            sName: document.data().sName,
                            participantsId: document.data().participantsId,
                            dtmCreated: document.data().dtmCreated,
                            isHidden: document.data().isHidden,
                            votingSessionId: votingId
                        };
                        archivedResponsesTextList.push(response);
                    });
                    dispatch(successText({id: question.id, votingId: votingId, responses: archivedResponsesTextList}));
                    callbackResponses(archivedResponsesTextList);
                } else if (question.type === "RatingQuestion") {
                    let archivedResponsesList = [];
                    querySnapShot.forEach(document => {
                        let response = {
                            id: document.id,
                            ratingNumber: document.data().ratingNumber,
                            sName: document.data().sName,
                            participantsId: document.data().participantsId,
                            dtmCreated: document.data().dtmCreated,
                            votingSessionId: votingId
                        };
                        archivedResponsesList.push(response);
                    });
                    dispatch(success({id: question.id, votingId: votingId, responses: archivedResponsesList}));
                    callbackResponses(archivedResponsesList);
                } else {
                    let archivedResponsesList = [];
                    querySnapShot.forEach(document => {
                        let response = {
                            id: document.id,
                            option: document.data().option,
                            participantsId: document.data().participantsId,
                            isHidden: document.data().isHidden,
                            votingSessionId: votingId
                        };
                        archivedResponsesList.push(response);
                    });
                    dispatch(success({id: question.id, votingId: votingId, responses: archivedResponsesList}));
                    callbackResponses(archivedResponsesList);
                }
            });
    };

    function success(archivedResponsesList) {
        return {type: actionTypes.Fetch_ALL_ARCHIVED_VOTING_RESPONSES_SUCCESS, archivedResponsesList}
    }

    function successText(archivedResponsesTextList) {
        return {type: actionTypes.Fetch_ALL_ARCHIVED_RESPONSES_TEXT_SUCCESS, archivedResponsesTextList}
    }
}

function getQuestionInfoOnChange(eventId, idElement, questionId, callback) {
    return dispatch => {
        questionFunctions.getQuestionInfoOnChange(eventId, idElement, questionId,
            (questionData, unsubscribe) => {
                dispatch(success(questionData));
                callback(questionData, unsubscribe);
            },
            error => {

                // callback(error);
            }
        );
    };

    function success(liveQuestionData) {
        return {type: actionTypes.FETCH_LIVE_QUESTIONS_INFO_SUCCESS, liveQuestionData}
    }
}

function getQuestionInfo(eventId, idElement, questionId, callback) {

    return dispatch => {
        questionFunctions.getQuestionInfo(eventId, idElement, questionId).then(
            document => {
                if (document.data()) {
                    let questionData = document.data();
                    questionData.id = document.id;

                    dispatch(success(questionData));
                    callback(questionData);
                } else {
                    dispatch(success([]));
                    callback([]);
                }
            },
            error => {

                // callback(error);
            }
        );
    };

    function success(liveQuestionData) {
        return {type: actionTypes.FETCH_LIVE_QUESTIONS_INFO_SUCCESS, liveQuestionData}
    }
}

function storeSelectedLiveSession(eventId, liveSession) {
    return dispatch => {
        questionFunctions.storeSelectedLiveSession(eventId, liveSession)
            .then(result => {
                    dispatch(success(liveSession));
                },
                error => {

                    // callback(error);
                }
            );
    };

    function success(liveSession) {
        return {type: actionTypes.STORE_SELECTED_LIVE_SESSION_SUCCESS, liveSession}
    }
}

function updateQuestionField(option, eventId, idQuestionEl, question, value, callback) {
    return dispatch => {
        questionFunctions.updateQuestionField(option, eventId, idQuestionEl, question.id, value)
            .then(result => {
                    if (option === "firstVotingSession") {
                        question.firstVotingSessionInitialized = true;
                    } else if (option === "resetFirstVotingsession") {
                        question.firstVotingSessionInitialized = false;
                    }
                    dispatch(success(question));
                    callback(question);
                },
                error => {

                    // callback(error);
                }
            );
    };

    function success(questionData) {
        return {type: actionTypes.UPDATE_QUESTION_FIELD_SUCCESS, questionData}
    }
}

/*
function getCurrentStoredSession(eventId, idQuestionEl, questionId, callback) {
    return dispatch => {
        questionFunctions.getCurrentStoredSession(eventId, idQuestionEl, questionId)
            .then(result => {
                    dispatch(success(result));
                    callback(result);
                },
                error => {

                    // callback(error);
                }
            );
    };

    function success(sessionData) {
        return {type: actionTypes.GET_CURRENT_STORED_SESSION_SUCCESS, sessionData}
    }
}
*/

function getCurrentStoredSession(eventId, idQuestionEl, questionId, votingId, callback) {
    return dispatch => {
        questionFunctions.getCurrentStoredSession(eventId, idQuestionEl, questionId, votingId)
            .then(document => {
                    let votingSession = {
                        id: document.id,
                        time: moment(document.data().time.toDate()).format("DD MMM YYYY HH:mm:ss"),
                        timeStamp: document.data().time
                    };
                    dispatch(success(votingSession));
                    callback(votingSession);
                },
                error => {

                    // callback(error);
                }
            );
    };

    function success(sessionData) {
        return {type: actionTypes.GET_CURRENT_STORED_SESSION_SUCCESS, sessionData}
    }
}

function deleteQuestionSession(eventId, idQuestionEl, questionId, currentVotingSessionSelected, callback) {
    return dispatch => {
        questionFunctions.deleteQuestionSession(eventId, idQuestionEl, questionId, currentVotingSessionSelected)
            .then(
                result => {
                    dispatch(success(currentVotingSessionSelected));
                    callback(currentVotingSessionSelected);
                },
                error => {
                    // dispatch(failure(question.id, error));
                }
            );
    };

    function success(id) {
        return {type: actionTypes.DELETE_QUESTION_SESSION_SUCCESS, id}
    }
}

function updateQuestionsIndexes(eventId, idQuestionEl, newSortedQuestionsList) {
    return dispatch => {
        questionFunctions.updateQuestionsIndexes(eventId, idQuestionEl, newSortedQuestionsList,
            callback => {
                dispatch(success());
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.QUESTION_INDEXES_UPDATED_SUCCESS}
    }
}

function setAnswerTextOptionStatus(eventId, idQuestionEl, questionId, votingId, option, status, callback) {
    return dispatch => {
        questionFunctions.setAnswerTextOptionStatus(eventId, idQuestionEl, questionId, votingId, option, status).then(
            result => {
                dispatch(success());
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.OPTION_STATUS_UPDATED_SUCCESS}
    }
}

import {actionTypes} from './actionTypes';
import {bigScreenFunctions} from '../../services/BigScreenFunctions';
import {questionFunctions} from '../../services/QuestionFunctions';
import moment from 'moment';


export const bigScreenActions = {
    getLiveSessionOptions,
    getQuestion,
    getAllQuestionOptionsScreen,
    getAllLiveTweetsList,
    getAllApprovedTweetsList,
    getLastVotingSessionId
}

// in the big screen we implement only the get methods because after that the user put the question live he will not be able any more to change the question 


function getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback) {

    return dispatch => {
        bigScreenFunctions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId).then(
            querySnapShot => {
                let optionsList = [];
                querySnapShot.forEach(document => {
                    let option = {
                        id: document.id,
                        text: document.data().text,
                        isAnswer: document.data().isAnswer
                    };
                    if (document.data().type) {
                        option.type = document.data().type;
                    }
                    optionsList.push(option);
                });
                dispatch(success(optionsList));
                callback(optionsList);
            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(questionOptions) {
        return {type: actionTypes.FETCH_ALL_QUESTION_OPTIONS_SUCCESS, questionOptions}
    }

}

function getQuestion(eventId, elementId, questionId, callback) {
    return dispatch => {
        bigScreenFunctions.getQuestion(eventId, elementId, questionId).then(
            querySnapShot => {
                let questionData = {
                    id: querySnapShot.id,
                    text: querySnapShot.data().text,
                    type: querySnapShot.data().type,
                    showCorrectAnswerColor: querySnapShot.data().showCorrectAnswerColor,
                    optionDecorationValue: document.data().optionDecorationValue
                };
                dispatch(success(questionData));
                callback(questionData);
            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(questionData) {
        return {type: actionTypes.FETCH_QUESTION_DATA_SUCCESS, questionData}
    }

}

function getAllQuestionOptionsScreen(eventId, idElement, questionId, callback) {

    return dispatch => {
        bigScreenFunctions.getAllQuestionOptionsScreen(eventId, idElement, questionId).then(function (querySnapshot) {
                let optionsList = [];
                querySnapshot.forEach(function (document) {
                    let option = {
                        id: document.id,
                        text: document.data().text,
                        isAnswer: document.data().isAnswer,
                        optionsOrderIndex: document.data().optionsOrderIndex,
                    };
                    if (document.data().type) {
                        option.type = document.data().type;
                    }
                    // optionsList.push(option);
                    // we put the option in the same order(index) as the user has been defined and has been stored in the firebase
                    optionsList[document.data().optionsOrderIndex] = option;
                });
                dispatch(success({id: questionId, optionsValues: optionsList}));
                callback(optionsList)
            },
            error => {
                // callback(error);
            })
    };

    function success(optionsData) {
        return {type: actionTypes.FETCH_ALL_QUESTION_OPTIONS_BIG_SCREEN_SUCCESS, optionsData}
    }
}

function getAllLiveTweetsList(eventId, idElement, callback) {
    return dispatch => {
        bigScreenFunctions.getAllLiveTweetsList(eventId, idElement, (callbackLiveTweetsList, unsubscribe) => {
                callback(unsubscribe);
                dispatch(success(callbackLiveTweetsList));
            },
            error => {
                // callback(error);
            })
    };

    function success(liveTweetsList) {
        return {type: actionTypes.FETCH_ALL_LIVE_TWEETS_BIG_SCREEN_SUCCESS, liveTweetsList}
    }
}

function getAllApprovedTweetsList(eventId, idElement, callback) {
    return dispatch => {
        bigScreenFunctions.getAllApprovedTweetsList(eventId, idElement,
            (callbackApprovedTweetsList, unsubscribe) => {
                callback(unsubscribe);
                dispatch(success(callbackApprovedTweetsList));
            },
            error => {
                // callback(error);
            }
        )
    };

    function success(approvedTweetsList) {
        return {type: actionTypes.FETCH_ALL_APPROVED_TWEETS_BIG_SCREEN_SUCCESS, approvedTweetsList}
    }
}


function getLastVotingSessionId(eventId, idQuestionEl, questionId, callback) {
    return dispatch => {
        bigScreenFunctions.getLastVotingSessionId(eventId, idQuestionEl, questionId).then(function (querySnapshot) {
                let votingSession;
                querySnapshot.forEach(function (document) {
                    votingSession = {
                        id: document.id,
                        // time: moment(document.data().time.toDate()).format("DD MMM YYYY HH:mm:ss"),
                        // timeStamp: document.data().time
                    }
                });
                dispatch(success(votingSession));
                callback(votingSession);
            },
            error => {

            }
        )
    };

    function success(votingSession) {
        return {type: actionTypes.Fetch_LAST_VOTING_SESSION_SUCCESS, votingSession}
    }
}
import firebase1 from 'firebase';
import firebase from '../config/firebase';
import moment from 'moment';


var firestore = firebase.firestore();


export const questionFunctions = {
    getQuestions,
    saveQuestion,
    deleteQuestion,
    saveOptions,
    getAllQuestionOptions,
    deleteOptions,
    // addNewOptionFunction,
    saveVotingSession,
    getAllVotingSessions,
    getResponses,
    getArchivedResponses,
    getQuestionInfoOnChange,
    storeSelectedLiveSession,
    updateQuestionField,
    getCurrentStoredSession,
    deleteQuestionSession,
    updateQuestionsIndexes,
    getQuestionInfo,
    setAnswerTextOptionStatus,
    getLastVotingSession,
    getVotingSessionsToCompare

};

function getQuestions(eventId, idElement, callback) {
    //using onSnapshot will cause to call getAllevents whenever the user adds a new question so we
    //used the get function instead.
    // return firestore.collection("events/" + eventId + "/elements/" + idElement + "/questions").where("questionIsDeleted", "==", false).get();
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions").where("questionIsDeleted", "==", false)
        .onSnapshot(querySnapShot => {
            let questionList = [];
            querySnapShot.forEach(document => {
                let question = document.data();
                question.id = document.id;
                // let question = {
                //     id: document.id,
                //     text: document.data().text,
                //     type: document.data().type,
                //     letMultipleSelect: document.data().letMultipleSelect,
                //     firstVotingSessionInitialized: document.data().firstVotingSessionInitialized,
                //     questionOrderIndex: document.data().questionOrderIndex,
                //     simpleTextAnswerMandatory: document.data().simpleTextAnswerMandatory,
                //     layout: document.data().layout,
                //     optionDecorationValue: document.data().optionDecorationValue,
                //     multipleChoiceLimitAnswer: document.data().multipleChoiceLimitAnswer,
                //     selectedToCompare: document.data().selectedToCompare,
                //};
                // //questionList.push(question);
                questionList[document.data().questionOrderIndex] = question;
            });
            callback(questionList, unsubscribe);
        });
}


function saveQuestion(eventId, idElement, question) {
    var questionData;
    if (question.type === 'MultipleChoice') {
        questionData = {
            text: question.text,
            type: question.type,
            letMultipleSelect: question.letMultipleSelect,
            questionIsDeleted: false,
            firstVotingSessionInitialized: question.firstVotingSessionInitialized,
            questionOrderIndex: question.questionOrderIndex,
            layout: question.layout,
            optionDecorationValue: question.optionDecorationValue,
            multipleChoiceLimitAnswer: question.multipleChoiceLimitAnswer,
        };
    } else if (question.type === "SimpleTextAndMultipleChoice") {
        questionData = {
            text: question.text,
            type: question.type,
            letMultipleSelect: question.letMultipleSelect,
            questionIsDeleted: false,
            firstVotingSessionInitialized: question.firstVotingSessionInitialized,
            questionOrderIndex: question.questionOrderIndex,
            simpleTextAnswerMandatory: question.simpleTextAnswerMandatory,
            layout: question.layout,
            optionDecorationValue: question.optionDecorationValue,
            multipleChoiceLimitAnswer: question.multipleChoiceLimitAnswer,
        };
    } else if (question.type === 'SimpleTextQuestion') {
        questionData = {
            text: question.text,
            type: question.type,
            questionIsDeleted: false,
            firstVotingSessionInitialized: question.firstVotingSessionInitialized,
            questionOrderIndex: question.questionOrderIndex,
            layout: question.layout,
        };
    }else if(question.type === "RatingQuestion"){
        questionData = {
            text: question.text,
            type: question.type,
            questionIsDeleted: false,
            firstVotingSessionInitialized: question.firstVotingSessionInitialized,
            questionOrderIndex: question.questionOrderIndex,
            layout: question.layout,
            numberOfStars: question.numberOfStars,
        };

    }

    if (question.id === 0) {
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions").add(questionData);
    } else {
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions").doc(question.id).update(questionData);
    }

};

function saveOptions(eventId, idElement, questionId, question, status, callback) {
    var optionsArray = question.optionsValues;
    var resultArray = [];
    //remove empty items
    for (var indexx = 0; indexx < optionsArray.length; indexx++) {
        //check to remove the options that are empty 
        if (optionsArray[indexx].text === "") {
            optionsArray.splice(indexx, 1);
        }
    }
    if (status === "create") {
        for (var index = 0; index < optionsArray.length; index++) {

            let optionData = {
                text: optionsArray[index].text,
                isAnswer: optionsArray[index].isAnswer,
                optionsOrderIndex: index
            }
            if(question.layout ==="like-dislike" && optionsArray[index] && optionsArray[index].type){
                optionData.type =  optionsArray[index].type;
            }
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/" + questionId + "/Answers").add(optionData).then(
                resultSecond => {
                    resultArray.push({
                        id: resultSecond.id,
                    })
                });
        }
        return callback(resultArray);
    } else {
        //for the existing ids we will update the current values and for the values with the id === "newValue"
        //we will add a new document
        let resultArray = [];
        for (var optionIndex in optionsArray) {

            let optionData = {
                text: optionsArray[optionIndex].text,
                isAnswer: optionsArray[optionIndex].isAnswer,
                optionsOrderIndex: parseInt(optionIndex,10)
            }

            if(question.layout ==="like-dislike" && optionsArray[index] &&  optionsArray[index].type){
                optionData.type =  optionsArray[index].type;
            }

            if (optionsArray[optionIndex].id === "newValue") {
                firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/" + question.id + "/Answers").add(optionData)
                .then(
                    result => {
                        resultArray.push({
                            id: result.id,
                            // text: result.text,
                            // isAnswer: result.isAnswer,
                            // optionsOrderIndex: result.optionsOrderIndex,
                        });
                    }
                )
            } else {
                let optionData ={
                    text: optionsArray[optionIndex].text,
                    isAnswer: optionsArray[optionIndex].isAnswer,
                    optionsOrderIndex: parseInt(optionIndex,10)
                }
                if(question.layout === "like-dislike" && optionsArray[index] && optionsArray[index].type){
                    optionData.type =  optionsArray[index].type;
                }
                firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/" + question.id + "/Answers").doc(optionsArray[optionIndex].id)
                .update(optionData).then(
                    result => {

                        resultArray.push({
                            id: optionsArray[optionIndex].id,
                            text: optionsArray[optionIndex].text,
                            isAnswer: optionsArray[optionIndex].isAnswer,
                            optionsOrderIndex: optionsArray[optionIndex].optionsOrderIndex,
                        });
                    }
                )
            }
        }
        return callback(optionsArray);
    }

}

function deleteQuestion(eventId, idQuestionEl, question) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions").doc(question.id).update({ questionIsDeleted: true });
}


function getAllQuestionOptions(eventId, idQuestionEl, questionId, callback) {
    // return firestore.collection("events/" + eventId + "/elements/" + idElement + "/questions/" + questionId + "/Answers").get();
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/Answers")
        .onSnapshot(querySnapShot => {
            let optionsList = [];
            querySnapShot.forEach(function (document) {
                let option = {
                    id: document.id,
                    text: document.data().text,
                    isAnswer: document.data().isAnswer,
                    optionsOrderIndex: document.data().optionsOrderIndex,
                    questionId:questionId

                };
                if(document.data().type){
                    option.type = document.data().type;
                }
                // optionsList.push(option);
                // we put the option in the same order(index) as the user has been defined and has been stored in the firebase
                optionsList[document.data().optionsOrderIndex] = option;
            });
            callback(optionsList, unsubscribe);
        });
}

function deleteOptions(eventId, idQuestionEl, questionId, deletedOptionsArray) {
    for (let index in deletedOptionsArray) {
        firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/Answers").doc(deletedOptionsArray[index].id).delete();
    }
    return true;
}

// function addNewOptionFunction(eventId, idQuestionEl, questionId) {

//     var questioDefinedId;
//     //on new question creation we first create the question collection and return its id to add the answer collection and the options

//     firestore.collection("events/8vo0WvvGrXgfaeshsLFW/elements/" + idQuestionEl + "/questions").add({ text: 'default' }).then(
//         result => {
//             questioDefinedId = result.id
//             //return firestore.collection("events/8vo0WvvGrXgfaeshsLFW/elements/2ybjziPJAS3LsWcRHsvb/questions/" +questioDefinedId + "/Answers").add({ text:  'default' });

//             return firestore.collection("events/8vo0WvvGrXgfaeshsLFW/elements/" + idQuestionEl + "/questions/" + questioDefinedId + "/Answers").add({ text: 'default' });
//         }


//     )

// }

function saveVotingSession(eventId, idQuestionEl, questionId) {
    const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession/")
    .add({ time: timestamp, sessionIsDeleted: false, voted: false })

}

function getAllVotingSessions(eventId, idQuestionEl, questionId, callback) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession").where("sessionIsDeleted", "==", false).get();
}

function getVotingSessionsToCompare(eventId, idQuestionEl, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession").where("sessionIsDeleted", "==", false).get();
}

function getLastVotingSession(eventId, idQuestionEl, questionId){
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession")
    .where("sessionIsDeleted", "==", false)
    // .orderBy("time")
    .get();
}

function getResponses(eventId, idQuestionEl, question, votingId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + question.id + "/VotingSession/" + votingId + "/Responses")
        .onSnapshot(querySnapShot => {
            if (question.type === "SimpleTextQuestion") {
                let responsesTextList = [];
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        sAnswer: document.data().sAnswer,
                        sName: document.data().sName,
                        participantsId: document.data().participantsId,
                        dtmCreated: document.data().dtmCreated,
                        isHidden: document.data().isHidden
                    };
                    responsesTextList.push(response);
                });
                callback(responsesTextList, unsubscribe);
            } else if (question.type === "RatingQuestion"){
                let responsesTextList = [];
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        ratingNumber: document.data().ratingNumber,
                        sName: document.data().sName,
                        participantsId: document.data().participantsId,
                        dtmCreated: document.data().dtmCreated,
                    };
                    responsesTextList.push(response);
                });
                callback(responsesTextList, unsubscribe);
            }else {
                let responsesList = [];
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        option: document.data().option,
                        participantsId: document.data().participantsId,
                    };

                    responsesList.push(response);
                });
                callback(responsesList, unsubscribe);
            }
        });
}

function getArchivedResponses(eventId, idQuestionEl, question, votingId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + question.id + "/VotingSession/" + votingId + "/Responses").get();
}



function getQuestionInfoOnChange(eventId, idQuestionEl, questionId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId)
        .onSnapshot(function (document) {
            if (document.data().questionIsDeleted === false) {
                let questionData = document.data();
                questionData.id = document.id;

                callback(questionData, unsubscribe);
            }
        });
}


function getQuestionInfo(eventId, idQuestionEl, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId).get();

}

function storeSelectedLiveSession(eventId, liveSession) {
    //update user selected session
    return firestore.collection("events/").doc(eventId).update({
        currentSelectedLiveSession: liveSession.id,
        bigScreenStatus: liveSession.id
    });
}

function updateQuestionField(option, eventId, idQuestionEl, questionId, value) {
    switch (option){

    case "firstVotingSession":
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId).update({ 'firstVotingSessionInitialized': value });
    
    case "resetFirstVotingsession":
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId).update({ 'firstVotingSessionInitialized': false });
    
    case "selectedToCompare":
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId).update({ 'selectedToCompare': value });

    case "mainSelectedQuestionToCompare":
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/").doc(questionId).update({ 'selectedSessionsToCompare': value });

    }
}

function getCurrentStoredSession(eventId, idQuestionEl, questionId, votingId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession/").doc(votingId).get();
}


function deleteQuestionSession(eventId, idQuestionEl, questionId, currentVotingSessionSelected) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession/").doc(currentVotingSessionSelected).update({ sessionIsDeleted: true });

}

function updateQuestionsIndexes(eventId, idElement, newSortedQuestionsList, callback) {

    for (var index = 0; index < newSortedQuestionsList.length; index++) {
        if (newSortedQuestionsList[index]) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idElement + "/questions/").doc(newSortedQuestionsList[index].id).update({ questionOrderIndex: index });
        }
    }
    callback('success');
}

function setAnswerTextOptionStatus(eventId, idQuestionEl, questionId, votingId, option, status) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + questionId + "/VotingSession/" + votingId + "/Responses")
        .doc(option.id).update({ isHidden: status });
}
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';


class HeaderTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventTitle: '',
            eventCode: '',
            messageBodyInput: '',
            messageTextStatus: 'default',
            importanceStatus: 'default',
            modalMessageOpened: true,

        };

        this.sendNewMessage = this.sendNewMessage.bind(this);
        this.cancelNewMessage = this.cancelNewMessage.bind(this);
        this.checkMessageInputStatusOk = this.checkMessageInputStatusOk.bind(this);
    }

    cancelNewMessage() {

        if (document.getElementById('sendMessageModalForm')) {
            document.getElementById('sendMessageModalForm').reset();
        }

    }

    handleMessageChange = (e) => {

        switch (e.target.name) {
            case 'messageBodyInput':
                if (e.target.value !== "") {
                    document.getElementById("messageBodyInput").classList.remove('is-invalid');
                }

            case 'importanceCheckBox':
                if (e.target.checked === true) {
                    document.getElementById("highCheckBox").classList.remove('is-invalid');
                    document.getElementById("normalCheckBox").classList.remove('is-invalid');
                    this.setState({...this.state, importanceStatus: e.target.value})
                }
        }

    }

    checkMessageInputStatusOk(e) {

        e.preventDefault();
        var messageBody = document.getElementById('messageBodyInput').value
        var normalCheckBox = document.getElementById('normalCheckBox').checked;
        var highCheckBox = document.getElementById('highCheckBox').checked;

        if (messageBody === "") {
            document.getElementById("messageBodyInput").classList.add('is-invalid');
        } else if (normalCheckBox === false && highCheckBox === false) {
            document.getElementById("normalCheckBox").classList.add('is-invalid');
            document.getElementById("highCheckBox").classList.add('is-invalid');
            this.setState({...this.state, importanceStatus: 'notDefined'});
        } else {
            this.sendNewMessage();
            //We call the close button to close the modal
            document.getElementById("closeNewMessageModal").click();
        }

    }

    sendNewMessage() {
        
        var messageTitle = document.getElementById('messageTitleInput').value;
        var messageBody = document.getElementById('messageBodyInput').value
        var messageTitleToSend;
        if (messageTitle === "") {
            //we use the event title as the default title for the message if the user does not define it
            messageTitleToSend = this.props.currentEvent.title;
        } else {
            messageTitleToSend = messageTitle;
        }

        var message = {
            title: messageTitleToSend,
            body: messageBody,
            messageImportance: this.state.importanceStatus,
            userId: this.props.currentEvent.userId
        };
        
        this.props.onSendNewMessage(this.props.currentEvent.id, message, callback => {

        });

        
        if (this.unsubscribeGetParticipantsList) {
            this.unsubscribeGetParticipantsList();
        }

        this.props.onGetParticipantsList(this.props.currentEvent.id, (participantList, unsubscribe) => {
            this.unsubscribeGetParticipantsList = unsubscribe;
            // send the same notification to all of the participants from a single request and at once
            // we use this url:https://iid.googleapis.com/iid/v1/TOKEN/rel/topics/TOPICO_NAME
            for (var i = 0; i < participantList.length; i++) {
                if (participantList[i].notificationToken) {
                    fetch('https://fcm.googleapis.com/fcm/send', {
                        // fetch('https://iid.googleapis.com/iid/v1/'+participantList[i].notificationToken+'/rel/topics/'+this.props.currentEvent.eventCode,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'key=AAAAY6m59-w:APA91bFxas3t-xx4XFurJQgOH3853HhaSKK7QJn69Gj1g8zSvnvz4R5NsjAJccwOYUsNmuG-2oz4VBCmSxzxI2zVF3A7-38091NkZIx8ihogCGVaREHo__dxlT9y6F_dipqBlPP7BhtLBxWE1WX30ZZpocKF5OwbPQ'
                        },
                        body: JSON.stringify({
                            "notification": {
                                "title": messageTitleToSend,
                                "body": messageBody,
                                "click_action": "https://4meet.in/" + this.props.currentEvent.eventCode,
                                "icon": this.props.currentEvent.eventLogoUrl
                            },
                            "data": {
                                "messageImportance": this.state.importanceStatus
                            },
                            // "to": "dCPWIBDp_Qs:APA91bHxO51Q7Ev4bsPwr5CDPSY9EXja71e2pcMElHbjAHfl_hO0EdApnzfGu_Vr7-ZOgshVRYPFSHBT68GrmO9OzlvVPr4irjSro7K3ZEB1XSVFSivjVXK7cNqJwtvcCDUKZas06Cn5"
                            "to": participantList[i].notificationToken
                            //  "to":"/topics/"+this.props.currentEvent.eventCode
                        }),
                    })
                }
            }
        });
        

        if (document.getElementById('sendMessageModalForm')) {
            document.getElementById('sendMessageModalForm').reset();
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParticipantsList) {
            this.unsubscribeGetParticipantsList();
        }
    }

    render() {
        var selectedLanguageStrings;
        if (this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (
            <div>
                <nav className="nav-bar-tab" id="nav-bar-tab">
                    {(this.props.page === "SingleEventScreen" || this.props.page === "ActiveUsersScreen" || this.props.page === "MessageScreen") ? (

                        <div className="container" id="navContainer">
                            <div className="row mt-1 itemsNav">
                                <div className="tabCol3 text-center">
                                    <div className="tweetTab">
                                        <Link to={"/"}><label
                                            className="eventsTabs text-primary pointer">{selectedLanguageStrings.all_events}</label></Link>
                                    </div>
                                </div>
                                <div className="tabCol3 text-center">
                                    <div className="tweetTab">
                                        <label className="eventsTabs text-primary pointer" data-toggle="modal"
                                               data-target="#editModal" onClick={() => {
                                            this.props.handleEditEvent()
                                        }}>{selectedLanguageStrings.event_settings}</label>
                                    </div>
                                </div>
                                {(this.props.page === "SingleEventScreen") ? (

                                    <div className="tabCol">
                                        <div className="tweetTab">
                                            <label
                                                className="eventsTabs text-secondary">{selectedLanguageStrings.current_event}</label>
                                        </div>
                                        <div className="border-bottom" id="borderLine"></div>
                                    </div>

                                ) : (

                                    <div className="tabCol">
                                        <div className="tweetTab">
                                            <Link to={"/event/" + this.props.currentEvent.id + "/"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.current_event}</label></Link>
                                        </div>
                                    </div>

                                )}

                                {(this.props.page === "ActiveUsersScreen") ? (

                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <label className="eventsTabs text-secondary"
                                                   disabled>{selectedLanguageStrings.active_participants}</label>
                                        </div>
                                        <div className="border-bottom" id="borderLine"></div>
                                    </div>

                                ) : (

                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <Link to={"/event/" + this.props.currentEvent.id + "/participants"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.active_participants}</label></Link>
                                        </div>
                                    </div>

                                )}
                                {(this.props.page === "MessageScreen") ? (
                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <label className="eventsTabs text-secondary"
                                                   disabled>{selectedLanguageStrings.send_message}</label>
                                        </div>
                                        <div className="border-bottom" id="borderLine"></div>
                                    </div>

                                ) : (
                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <Link to={"/event/" + this.props.currentEvent.id + "/messages"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.send_message}</label></Link>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                    ) : (

                        <div className="container" id="navContainer">
                            <div className="row mt-1 itemsNav">
                                <div className="tabCol">
                                    <div className="tweetTab">
                                        <Link to={"/"}><label
                                            className="eventsTabs text-primary pointer">{selectedLanguageStrings.all_events}</label></Link>
                                    </div>
                                </div>
                                <div className="tabCol">
                                    <div className="tweetTab" id="tweet-tab">
                                        <label className="eventsTabs text-primary pointer" data-toggle="modal"
                                               data-target="#editModal" onClick={() => {
                                            this.props.handleEditEvent()
                                        }}>{selectedLanguageStrings.event_settings}</label>
                                    </div>
                                </div>
                                <div className="tabCol">
                                    <div className="tweetTab">
                                        <Link to={"/event/" + this.props.currentEvent.id + "/"}><label
                                            className="eventsTabs text-primary pointer">{selectedLanguageStrings.current_event}</label></Link>
                                    </div>
                                </div>
                                <div className="tabCol3 text-center ">
                                    <div className="tweetTab" id="tweet-tab">
                                        <Link to={"/event/" + this.props.currentEvent.id + "/participants"}><label
                                            className="eventsTabs text-primary pointer">{selectedLanguageStrings.active_participants}</label></Link>
                                    </div>
                                </div>
                                {(this.props.parentInfo != "default" && this.props.parentInfo.currentTweetElement === "default") ? (

                                    //No Tweet element defined
                                    <div className="tabCol text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <label
                                                className="navBarText  text-secondary">{selectedLanguageStrings.hTweets}</label>
                                            <div className={this.props.navItemTweet} id="borderLine"></div>
                                        </div>
                                    </div>

                                ) : (

                                    <div className="tabCol text-center">
                                        <div
                                            className={"tweetTab " + this.props.activeScreen === "tweet" ? "active" : ""}
                                            id="tweet-tab" onClick={() => {
                                            this.props.goToTweetScreen()
                                        }}>
                                            {(this.props.activeScreen === 'tweet') ? (

                                                <label
                                                    className="navBarText text-secondary  ">{selectedLanguageStrings.hTweets}</label>

                                            ) : (

                                                <label
                                                    className="navBarText text-primary ">{selectedLanguageStrings.hTweets}</label>

                                            )}
                                            <div className={this.props.navItemTweet} id="borderLine"></div>
                                        </div>
                                    </div>

                                )}

                                {(this.props.parentInfo != "default" && this.props.parentInfo.currentQuestionElement === "default") ? (

                                    <div className="tabColQuestion text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <label
                                                className="navBarText text-secondary">{selectedLanguageStrings.hQuestions}</label>
                                        </div>
                                        <div className={this.props.navItemQuestion} id="borderLine"></div>
                                    </div>

                                ) : (

                                    <div className="tabColQuestion text-center">
                                        <div id="question-tab"
                                             className={"questionTab" + this.props.activeScreen === "question" ? "active" : ""}
                                             onClick={() => {
                                                 this.props.goToQuestionScreen()
                                             }}>
                                            {(this.props.activeScreen === 'question') ? (

                                                <label
                                                    className="navBarText text-secondary ">{selectedLanguageStrings.hQuestions}</label>

                                            ) : (

                                                <label
                                                    className="navBarText text-primary">{selectedLanguageStrings.hQuestions}</label>

                                            )}

                                            <div className={this.props.navItemQuestion} id="borderLine"></div>
                                        </div>
                                    </div>

                                )}

                                {(this.props.page === "MessageScreen") ? (
                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <label className="eventsTabs text-secondary"
                                                   disabled>{selectedLanguageStrings.send_message}</label>
                                        </div>
                                        <div className="border-bottom" id="borderLine"></div>
                                    </div>

                                ) : (
                                    <div className="tabCol3 text-center ">
                                        <div className="tweetTab" id="tweet-tab">
                                            <Link to={"/event/" + this.props.currentEvent.id + "/messages"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.send_message}</label></Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </nav>

                <div className="modal fade" id="messageModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form id="sendMessageModalForm">
                                <div className="modal-header bg-primary">
                                    <h5 className="modal-title text-white"
                                        id="exampleModalLabel">{selectedLanguageStrings.send_message_participants}</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <h6 htmlFor="messageTitle">{selectedLanguageStrings.title} :</h6>
                                        <input type="text" className="form-control col-6 mb-3 mr-sm-2"
                                               id="messageTitleInput"/>
                                    </div>
                                    <div className="form-group">
                                        <h6 htmlFor="messageBodyInput">{selectedLanguageStrings.message} :</h6>
                                        <input type="text" className="form-control mr-sm-2 " id="messageBodyInput"
                                               name="messageBodyInput" onChange={this.handleMessageChange} required/>
                                        <div className="invalid-feedback ml-1">
                                            {selectedLanguageStrings.note_message}
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <h6 htmlFor="messageImportance "> {selectedLanguageStrings.importance} :</h6>
                                        <div className="row">
                                            <div className="form-check col-9  ml-4">
                                                <input className="form-check-input" type="radio"
                                                       name="importanceCheckBox" id="highCheckBox" value="high"
                                                       onChange={this.handleMessageChange}/>
                                                <label className="form-check-label" htmlFor="highCheckBox">
                                                    {selectedLanguageStrings.high_priority}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-check col-9  ml-4">
                                                <input className="form-check-input" type="radio"
                                                       name="importanceCheckBox" id="normalCheckBox" value="normal"
                                                       onChange={this.handleMessageChange}/>
                                                <label className="form-check-label" htmlFor="normalCheckBox">
                                                    {selectedLanguageStrings.normal}
                                                </label>
                                            </div>
                                        </div>
                                        {(this.state.importanceStatus === "notDefined") ? (

                                            <div className="importanceFeedback ml-2">
                                                {selectedLanguageStrings.note_importance}
                                            </div>

                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary " id="closeNewMessageModal"
                                            data-dismiss="modal"
                                            onClick={this.cancelNewMessage}>{selectedLanguageStrings.cancel}</button>
                                    <button type="submit" className="btn btn-primary"
                                            onClick={this.checkMessageInputStatusOk}>{selectedLanguageStrings.sendBtn}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default HeaderTab;
const mapStateToProps = state => {

    return {
        currentEvent: state.eventReducer.currentEvent,
        modalType: state.eventReducer.modalType
    };

};

const mapDispatchToProps = dispatch => {

    return {
        onSendNewMessage: (eventId, message, callback) => dispatch(eventActions.sendNewMessage(eventId, message, callback)),
        onGetParticipantsList: (eventId, callback) => dispatch(eventActions.getParticipantsList(eventId, callback)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTab);
import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

class Event extends Component {

    render() {

        return (
            <div className="align-items-center row mb-3">
                <div className="col-10 col-sm-10 col-md-11 col-lg-11">
                    <div className="card event-card" onClick={() => { this.props.viewEventFunction(this.props.event) }}>
                        <div className="card-body p-2">
                            <Tooltip
                                // options
                                title="Enter to add Question and Tweet sessions or edit this event."
                                position="top"
                                trigger={"mouseenter"}
                                distance="-50"
                                animation={"fade"}
                                animateFill={false}
                                duration={400}
                                delay={1000}
                            // followCursor={true}
                            >
                                <div className="row pl-3">
                                    <div className="col-3 col-sm-3 col-md-2 col-lg-1 pl-1 pr-1 my-auto d-flex eventColumnLogo">
                                        {(this.props.event.eventLogoUrl !== "default") ? (

                                            <img src={this.props.event.eventLogoUrl} className="event-logo-small" alt="Logo" />
                                        
                                        ) : (
                                        
                                            <img src={this.props.logoPath} className="event-logo-small" alt="Logo" />
                                        
                                        )}
                                    </div>
                                    <div className="col-8 col-sm-8 col-md-10 col-lg-11 my-auto pl-1 pr-1 " id="eventColumnTitle">
                                        <b>{this.props.title}</b>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-left pl-2">
                    <button type="button" className="rounded border-0 btn-danger" id="eventDeleteBtn" data-toggle="modal" data-target="#deleteModal" onClick={() => { this.props.deleteFunction(this.props.event) }}><i className="fas fa-trash"></i></button>
                </div>
            </div>
        );
    }
}

export default Event;

import React, { Component } from 'react';
import { spawn } from 'child_process';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';


class Question extends Component {


    constructor(props) {
        super(props);

        this.state = {
            // initializePlayIcon: "fas fa-play-circle",
            linkShow:true,
        };

        this.firstVotingSession = this.firstVotingSession.bind(this);
    }

    firstVotingSession() {

        // document.querySelector('initializedPlayBtn').addEventListener('click', function() {
        //     const icon = this.querySelector('i');
        //     const text = this.querySelector('span');

        //     if (icon.classList.contains('fas fa-play-circle')) {
        //       icon.classList.remove('fas fa-play-circle');
        //       icon.classList.add('fas fa-box-open');
        //     }
        // });
        //  this.setState({initializePlayIcon: "fas fa-box-open" });
        // this.props.addFirstVotingSession(this.props.question)

    }

    setIsOpen(value){

        if(value === true) this.setState({linkShow:false});

    }

    render() {

        return (
            <div className="row questionIcons">
                {(this.props.question.firstVotingSessionInitialized === false) ? (
                    
                    <Tooltip
                        // options
                        title="Start the first live session for this question"
                        position="top"
                        trigger={"mouseenter"}
                        distance="10"
                        animation={"fade"}
                        arrow={true}
                        duration={1200}
                    >
                        <button className="rounded btn-success border-0 liveButton mr-1 questionsingleIcon" question-id={this.props.question.id} onClick={() => { this.props.setArchivedSession(this.props.question) }}>
                            <i className="fas fa-archive text-white"></i>
                        </button>
                    </Tooltip>
                
                ) : (

                    <div>
                        {(this.props.question.id === this.props.event.currentQuestion) ? (
                            
                            <Tooltip
                                // options
                                title="Current active question"
                                position="top"
                                trigger={"mouseenter"}
                                distance="10"
                                animation={"fade"}
                                arrow={true}
                                duration={1200}
                            >
                                <button type="button" className="rounded border-0 btn-success mr-1 questionsingleIcon">
                                    <i className="fas fa-box-open openIcon"></i>
                                </button>
                            </Tooltip>
                            
                        ) : (

                            <Tooltip
                                // options
                                title="Review the archived sessions or start a new live question session"
                                position="top"
                                trigger={"mouseenter"}
                                distance="10"
                                animation={"fade"}
                                arrow={true}
                                duration={1200}
                            >
                                <button type="button" className="rounded border-0 btn-warning mr-1 questionsingleIcon" onClick={() => { this.props.setArchivedSession(this.props.question) }} >
                                    <i className="fas fa-archive text-white"></i>
                                </button>
                            </Tooltip>

                        )}    
                    </div>

                )}
                {(this.props.question.firstVotingSessionInitialized === false) ? (
                    
                    <Tooltip
                        // options
                        title="Edit this question"
                        position="top"
                        trigger={"mouseenter"}
                        distance="10"
                        animation={"fade"}
                        arrow={true}
                        duration={1200}
                    >
                        <button type="button" data-toggle="modal" data-target={"#" + this.props.question.type} className="rounded border-0 btn-primary mr-1 questionsingleIcon" onClick={() => { this.props.editFunction(this.props.question) }}>
                            <i className="fas fa-pen"></i>
                        </button>
                    </Tooltip>
                
                ) : (
                
                    <Tooltip
                        // options
                        title="Duplicate this question"
                        position="top"
                        trigger={"mouseenter"}
                        distance="10"
                        animation={"fade"}
                        arrow={true}
                        duration={1200}
                        interactive
                        html={(
                            <div className="text-left pl-1 linkTooltip" onClick={() => {this.setIsOpen(true)}}>Duplicate this question &nbsp;
                                {(this.state.linkShow === true) ? (
                                
                                    <a data-toggle="collapse" id="collapseLinkReadMore" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        Read More ...
                                    </a>
                                          
                                ) : (

                                    <a data-toggle="collapse" id="collapseLinkReadMore"  role="button" aria-expanded="false" aria-controls="collapseExample">
                                    :
                                    </a>
        
                                )}       
                                <div className="collapse pt-1 pb-1" id="collapseExample">
                                    <div className="card text-left p-2 cardTooltip">
                                        After you have created the first live question session you will not be able anymore to edit the question. But by duplicating the question you will be able to apply your desired changes and save it as a new question.
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        <button type="button" data-toggle="modal" data-target={"#" + this.props.question.type} className="rounded border-0 btn-info mr-1 questionsingleIcon" onClick={() => { this.props.duplicateQuestion(this.props.question) }}>
                            <i className="far fa-copy"></i>
                        </button>
                    </Tooltip>

                )}
                <Tooltip
                    // options
                    title="Delete this question"
                    position="top"
                    trigger={"mouseenter"}
                    distance="10"
                    animation={"fade"}
                    arrow={true}
                    duration={1200}
                >
                    <button type="button" className="rounded border-0 btn-danger questionsingleIcon" data-toggle="modal" data-target="#deleteQuestionModal" onClick={() => { this.props.deleteQuestionFunction(this.props.question) }}>
                        <i className="fas fa-trash"></i>
                    </button>
                </Tooltip>
            </div>
        )
    }
}
//             <div className="row mt-2" id={'question' + this.props.question.id}>
//                 <div className="col-12">
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="row">
//                                 <div className="col-12 my-auto text-left">
//                                     <h5>{this.props.questionText}</h5>
//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-7 col-sm-7 col-md-9 col-lg-8 col-xl-9 d-flex justify-content-left">
//                                     {(this.props.questionType === 'MultipleChoice') ? (
//                                         <form className="form-inline">
//                                             <i className="rounded text-danger fas fa-check-square questionIconMulti mr-2"></i>
//                                             <div className=" questionTypeText">Multiple Choice</div>
//                                         </form>
//                                     ) : (
//                                             <form className="form-inline">
//                                                 <i className="rounded text-primary fas fa-align-left questionIcon mr-2"></i>
//                                                 <div className=" questionTypeText">Simple Text</div>
//                                             </form>
//                                         )}
//                                 </div>
//                                 <div className="col-5 col-sm-5 col-md-3 col-lg-4 col-xl-3 d-flex justify-content-center">
//                                 <div className="row questionIcons">
//                                     {(this.props.question.firstVotingSessionInitialized === false ) ? (
//                                         <button className="rounded btn-success border-0 liveButton mr-1 questionsingleIcon"  question-id={this.props.question.id}  onClick={() => { this.props.addFirstVotingSession(this.props.question) }}><i className="fas fa-play-circle"></i></button>
//                                         ) : (
//                                             <div>
//                                                           {(this.props.question.id === this.props.event.currentQuestion) ? (
//                                                             <button type="button" className="rounded border-0 btn-success mr-1 questionsingleIcon">
//                                                             <i className="fas fa-box-open openIcon"></i>
//                                                         </button>

//                                                     ) : (
//                                                         <button type="button" className="rounded border-0 btn-warning mr-1 questionsingleIcon" onClick={() => { this.props.setArchivedSession(true, this.props.question) }} >
//                                                         <i className="fas fa-archive text-white"></i>
//                                                     </button>
//                                                         )}
//                                             </div>
//                                         )}
//                                      {(this.props.question.firstVotingSessionInitialized === false)  ? ( 
//                                         <button type="button" data-toggle="modal" data-target={"#"+this.props.question.type} className="rounded border-0 btn-primary mr-1 questionsingleIcon" onClick={() => { this.props.editFunction(this.props.question) }}><i className="fas fa-pen"></i></button>
//                                      ) : (
//                                             <button type="button" data-toggle="modal" data-target={"#"+this.props.question.type} className="rounded border-0 btn-info mr-1 questionsingleIcon" onClick={() => { this.props.duplicateQuestion(this.props.question) }}><i className="far fa-copy"></i></button>
//                                          )} 
//                                     <button type="button" className="rounded border-0 btn-danger questionsingleIcon" data-toggle="modal" data-target="#deleteQuestionModal"  onClick={() => { this.props.deleteQuestionFunction(this.props.question) }}><i className="fas fa-trash"></i></button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         );
//     }
// }
export default Question;
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { DropTarget } from 'react-dnd';
import '../assets/touchy.js';



const Types = {

    ITEM: 'ChildComponent'

}

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const elemntParentTarget = {
    // canDrop(props, monitor) {
    //   // You can disallow drop based on props or item
    //   //we prevent to drop the item on his own parent
    //   const item = monitor.getItem();
    //  // return canMakeChessMove(item.fromPosition, props.position);
    // },

    hover(props, monitor, component) {
        // This is fired very often and lets you perform side effects
        // in response to the hover. You can't handle enter and leave
        // here—if you need them, put monitor.isOver() into collect() so you
        // can just use componentDidUpdate() to handle enter/leave.

        //   // You can access the coordinates if you need them
        //   const clientOffset = monitor.getClientOffset();
        //   //const componentRect = findDOMNode(component).getBoundingClientRect();

        //   // You can check whether we're over a nested drop target
        //   const isJustOverThisOne = monitor.isOver({ shallow: true });

        //   // You will receive hover() even for items for which canDrop() is false
        //   const canDrop = monitor.canDrop();
    },

    drop(props, monitor, component) {

        const item = monitor.getItem();

        props.draggedChildElement(props.parentNode, item.childNode);
        if (monitor.didDrop()) {

            // If you want, you can check whether some nested
            // target already handled drop
            return;
        }

        // Obtain the dragged item
        //   const item = monitor.getItem();

        // You can do something with it
        // ChessActions.movePiece(item.fromPosition, props.position);

        // You can also do nothing and return a drop result,
        // which will be available as monitor.getDropResult()
        // in the drag source's endDrag() method
        //   return { moved: true };
    }
};


function collect(connect, monitor) {

    return {
        connectDropTarget: connect.dropTarget()
    }

}

class ParentComponent extends Component {   

    componentDidMount() {

        ReactDOM.findDOMNode(this).addEventListener("doubleTap", () => {this.props.onToggle(this.props.parentNode);}, false); 

    }

    componentWillUnmount(){        

        ReactDOM.findDOMNode(this).removeEventListener("doubleTap", () => {this.props.onToggle(this.props.parentNode);});

    }

    saveItem(e) {

        e.preventDefault();
        if (e.keyCode === 13) {
            this.props.saveEditedItem(this.props.parentNode)
        }

    }

    render() {

        const { connectDropTarget } = this.props;
        return connectDropTarget(
            // return (
            <div  style={{ paddingLeft: this.props.getPaddingLeft }}>
                {(this.props.parentNode.id === "default") ? (

                    <div className="row eventElementRoot">
                        <div className="col-10 col-lg-11 col-md-10 col-sm-10 pr-0">
                            <span>
                                {/* <i className="fas fa-calendar-alt pr-4"></i> */}
                                <i className="fas fa-chalkboard-teacher pr-3"></i>
                            </span>
                            {this.props.event.title}
                        </div>
                        {(this.props.event.eventStatus === "Archived") ? (

                            <div></div>

                        ) : (

                            <div className="col-2 col-lg-1 col-md-2 col-sm-2 text-right pr-0">
                                <button className="btn btn-success itemsFunctionsBtn" data-toggle="modal" data-target="#modalTypeForRoot" onClick={() => { this.props.setCurrentItem(this.props.parentNode) }}>
                                    <i className="fas fa-plus bg-success "></i>
                                </button>
                            </div>

                        )}
                    </div>

                ) : (

                    <div className="row eventElementParent">
                        <div className="col-4 col-lg-1 col-md-2 col-sm-2 pr-0">
                            {(this.props.parentNode.sType === 'folder') ? (

                                <i className="fas fa-folder folderIcon"></i>

                            ) : (
                                // <i className="fas fa-map-marker-alt text-danger folderIcon"></i>
                                <i className="fas fa-door-open folderIcon" style={{color: this.props.parentNode.roomIconColor}}></i>

                            )}
                        </div>
                        {(this.props.editingId === this.props.parentNode.id) ? (

                            <div className="col-8 col-lg-8 col-md-6 col-sm-7">
                                <div className="input-group mb-3 ml-2">
                                    <input type="text" className="form-control" onKeyUp={this.saveItem.bind(this)} id="parentInputTitle" defaultValue={this.props.parentNode.title} aria-label="Title" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.parentNode)}}>Save</button> */}
                                    </div>
                                </div>
                            </div>

                        ) : (

                            <div className=" col-8 col-lg-8 col-md-6 col-sm-7 pl-0"> {this.props.parentNode.title}</div>

                        )}

                            {(this.props.event.eventStatus === "Archived") ? (

                                <div className="col-12 col-lg-3 col-md-4 col-sm-3 text-right pr-0"></div>

                            ) : (

                                <div className="col-12 col-lg-3 col-md-4 col-sm-3 text-right pr-0">
                                {(this.props.parentNode.sType === 'folder' || this.props.parentNode.sType === 'room') ? (

                                    <button className="btn btn-success itemsFunctionsBtn" data-toggle="modal" data-target="#modalType" onClick={() => { this.props.setCurrentItem(this.props.parentNode) }}>
                                        <i className="fas fa-plus bg-success "></i>
                                    </button>

                                ) : (

                                    <button className="btn btn-success itemsFunctionsBtn" onClick={() => { this.props.setCurrentItem(this.props.parentNode) }} >
                                        <i className="fas fa-eye bg-success px-1"></i>
                                    </button>
                                
                                )}
                                {(this.props.parentNode.sType === 'room') ? (

                                    <button className="btn btn-primary itemsFunctionsBtn" data-toggle="modal" data-target="#editRoomModal" onClick={() => { this.props.setCurrentItem(this.props.parentNode) }}>
                                        <i className="fas fa-pen bg-primary  "></i>
                                    </button>

                                ) : (
                                    
                                    <button className="btn btn-primary itemsFunctionsBtn" onClick={() => { this.props.editItem(this.props.parentNode) }}>
                                        <i className="fas fa-pen bg-primary " ></i>
                                    </button>
                                
                                )}

                                {(this.props.parentNode.id === "defaultRoom") ? (
                                    <div></div>

                                ) : (

                                    <button className="btn btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={() => { this.props.setCurrentItem(this.props.parentNode) }} >
                                        <i className="fas fa-trash bg-danger "></i>
                                    </button>

                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default DropTarget(Types.ITEM, elemntParentTarget, collect)(ParentComponent)

import React, { Component } from 'react';
import 'react-tippy/dist/tippy.css';

const Types = {

    ITEM: 'ChildElement'

}
   
const itemSource = {

    beginDrag(props) {
        const item = { id: props.id, childNode:props.childNode };
        // we call the function to remove the item here
        return item;
    },
    endDrag(props,monitor) {
        if (!monitor.didDrop()) {
            // You can check whether the drop was successful
            // or if the drag ended but nobody handled the drop
            //the item has been moved properly
            return;
        }
        // When dropped on a compatible target, do something.
        // Read the original dragged item from getItem():
        const item = monitor.getItem();
        // You may also read the drop result from the drop target
        // that handled the drop, if it returned an object from
        // its drop() method.
        const dropResult = monitor.getDropResult();
   }

}
   
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

class ChildElement extends Component{

    render() {
        // Your component receives its own props as usual
        const { id } = this.props;
        // These props are injected by React DnD,
        // as defined by your `collect` function above:
        const { isDragging, connectDragSource } = this.props;
        var childIcon;  
        if(this.props.childNode.sType === "question"){
            childIcon=( 
                <i className="fas fa-question text-primary childIcons"></i>
            )
        }else if(this.props.childNode.sType === "tweet"){
            childIcon=( 
                <i className="fas fa-comments text-success childIcons"></i>
            )
        }else if (this.props.childNode.sType === "survey"){
            childIcon=( 
                <i className="far fa-list-alt text-info childIcons"></i>
            )
        }else if (this.props.childNode.sType === "eventInfo"){
            childIcon=( 
                <i className="far fa-newspaper eventInfoIcon"></i>
            )
        }else if (this.props.childNode.sType === "library"){
            childIcon=( 
                <i className="fas fa-align-right text-warning libraryIconItem"></i>	
            )
        }
        return (
            <div>
                {(this.props.childNode.sParentId === "default")?(

                    <div className="row rootElementChild" >
                        <div className="col-10">
                            <div className="row">
                                <div className="col-4 pr-0 iconColumn">
                                    {childIcon}
                                </div>
                                {(this.props.editingId === this.props.childNode.id)?(
                        
                                    <div className=" col-8 labelFolder"> 
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" id="childInputTitle" defaultValue={this.props.childNode.title} aria-label="Title" aria-describedby="basic-addon2"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                
                                ):(

                                    <div className=" col-8 labelChild"> {this.props.childNode.title}</div>
                                
                                )}
                            </div>
                        </div>
                        <div className="col-2 text-right pr-0">
                            <button className="btn-primary itemsFunctionsBtn" onClick={()=>{this.props.editItem(this.props.childNode)}}>
                                <i className="fas fa-pen bg-primary "></i>
                            </button>
                            <button className="btn-success itemsFunctionsBtn" onClick={()=>{this.props.setCurrentItem(this.props.childNode)}}>
                                <i className="fas fa-eye bg-success"></i>
                            </button>
                            <button className="btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={()=>{this.props.setCurrentItem(this.props.childNode)}} >
                                <i className="fas fa-trash bg-danger"></i>
                            </button>
                        </div>
                    </div> 
            
                ) : (

                    <div className="row eventElementChild" >
                        <div className="col-10">
                            <div className="row">
                                <div className="col-4 pr-0 iconColumn">
                                    {childIcon}
                                </div>
                                {(this.props.editingId === this.props.childNode.id)?(
                                
                                    <div className=" col-8 labelFolder"> 
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" id="childInputTitle" defaultValue={this.props.childNode.title} aria-label="Title" aria-describedby="basic-addon2"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button>
                                            </div>
                                        </div>
                                    </div>

                                ):(

                                    <div className=" col-8 labelChild"> {this.props.childNode.title}</div>

                                )}
                            </div>
                        </div>
                        <div className="col-2 text-right pr-0">
                            <button className="btn-primary itemsFunctionsBtn" onClick={()=>{this.props.editItem(this.props.childNode)}}>
                                <i className="fas fa-pen bg-primary "></i>
                            </button>    
                            <button className="btn-success itemsFunctionsBtn" onClick={()=>{this.props.setCurrentItem(this.props.childNode)}}>
                                <i className="fas fa-eye bg-success"></i>
                            </button>
                            <button className="btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={()=>{this.props.setCurrentItem(this.props.childNode)}} >
                                <i className="fas fa-trash bg-danger"></i>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ChildElement;
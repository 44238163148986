import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Event from '../Components/Event';
import '@syncfusion/ej2-react-navigations/styles/bootstrap.css';
import "@syncfusion/ej2-react-calendars/styles/bootstrap.css";
import EditModal from '../Modals/EditModal';
import DeleteModal from '../Modals/DeleteModal';
import '../style.css';
import Sidebar from "react-sidebar";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";

import {
    arrayMove,
} from 'react-sortable-hoc';

import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {authenticationActions} from '../store/actions/AuthenticationActions';
import {Link} from 'react-router-dom';


class SharedEventScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentEvent: '',
            saveBtnTitle: '',
            optionsOrderStatus: 'default',
            voteCounterStatus: 'default',
            tweetsToShow: 'default',
            imageCode: '',
            userId: '',
            user: '',
            eventLogoList: '',
            sidebarOpen: false,
            toggleActive: false,
            textToggle: 'OFF',
            eventsListFetched: 'default',
            sortedEventList: [],
            sharedEventsList: []
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleNewEventBtn = this.handleNewEventBtn.bind(this)
        this.viewEvent = this.viewEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.closePlanModal = this.closePlanModal.bind(this);
        this.shareEvent = this.shareEvent.bind(this);
        this.shareEventCheck = this.shareEventCheck.bind(this);

    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (this.unsubscribeGetUserDataOnChange) {
                this.unsubscribeGetUserDataOnChange();
            }
            this.props.onGetUserDataOnChange(user.userId, (userData, unsubscribe) => {
                this.unsubscribeGetUserDataOnChange = unsubscribe;
                userData.userId = userData.id;
                localStorage.setItem('user', JSON.stringify({
                    'userId': userData.id,
                    'sFirstName': userData.sFirstName,
                    'sLastName': userData.sLastName,
                    'sEmail': userData.sEmail,
                    'sType': userData.sType,
                }));
                this.setState({...this.state, userId: userData.id, user: userData});
            });

            if (this.unsubscribeSharedEventsList) {
                this.unsubscribeSharedEventsList();
            }
            this.props.onSharedEventsList(user.userId, (sharedEventsList, unsubscribe) => {
                this.unsubscribeSharedEventsList = unsubscribe
                this.setState({...this.state, eventsListFetched: "", sharedEventsList: sharedEventsList});
            });
        } else {
            this.props.history.push('/login');

        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetUserDataOnChange) {
            this.unsubscribeGetUserDataOnChange();
        }

        if (this.unsubscribeSharedEventsList) {
            this.unsubscribeSharedEventsList();
        }
    }

    deleteEvent(event) {
        this.setState({...this.state, currentEvent: event});
        if (event.id === 0) {
            this.props.onCancelEvent();
        } else {
        }
    }

    shareEvent(event) {
        this.setState({...this.state, currentEvent: event});
    }

    shareEventCheck() {
        var email = document.getElementById("shareEmailAddress").value;
        var comment = document.getElementById("shareComment").value;
        this.props.onShareEventEmailCheck(email, callback => {
            if (callback.message === "Document Exists") {

            } else {

            }
        });
    }

    modalDeleteEvent(event) {
        this.props.onDeleteEvent(event);
    }

    closeEditModal() {
        if (document.getElementById('eventModalsForm')) {
            this.setState({...this.state, openModal: false});
            document.getElementById('eventModalsForm').reset();
        }
    }

    handleEditBtn(event) {
        this.props.setCurrentEventId(event.id, callback => {
            var img = document.getElementById('imagePreview');
            if (event.eventLogoUrl !== 'default') {
                img.src = event.eventLogoUrl;
            } else {
                img.src = "/files/LogoTemp.png";
            }
        });


        var selectedLanguageStrings = englishStrings;


        this.setState({...this.state, currentEvent: event, saveBtnTitle: selectedLanguageStrings.update});
    }


    handleNewEventBtn() {
        this.props.onAddEvent(callback => {
            var indexToAdd = this.state.sortedEventList.length + 1;
            callback.eventOrderIndex = indexToAdd;
            var selectedLanguageStrings = englishStrings;

            // this.state.currentEvent.titleStatus = "is-invalid";
            this.setState({...this.state, currentEvent: callback, saveBtnTitle: selectedLanguageStrings.create});
            // var img = document.getElementById('imagePreview');
            // img.src = "/files/file-upload.png";
        });
    }


    viewEvent(event) {
        this.props.history.push("/event/" + event.id + "/");
        this.props.setCurrentEventId(event.id, callback => {
            this.props.history.push("/event/" + event.id + "/");
        });
    }

    onSetSidebarOpen(open) {
        this.setState({sidebarOpen: open});
    }

    onToggle() {
        if (this.state.toggleActive === true) {
            this.setState({toggleActive: false, textToggle: 'OFF'});

        } else {
            this.setState({toggleActive: true, textToggle: 'ON'});
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        const {sortedEventList} = this.state;
        var newSortedEventList = arrayMove(sortedEventList, oldIndex, newIndex);
        this.props.onUpdateEventsIndexes(newSortedEventList);
        this.setState({
            sortedEventList: arrayMove(sortedEventList, oldIndex, newIndex)
        });
    };

    closePlanModal() {
        document.getElementById("closePlanModal").click();
    }

    render() {
        const {sortedEventList} = this.state;

        var selectedLanguageStrings = englishStrings;
        var sharedEventsComponent;

        if (this.state.sharedEventsList.length > 0) {
            sharedEventsComponent = this.state.sharedEventsList.map((event, index) => {
                return (
                    <div className="card event-card mb-3" onClick={() => {
                        this.viewEvent(event)
                    }}>
                        <div className="card-body p-2">
                            <Tooltip
                                // options
                                title="Enter to add Question and Tweet sessions or edit this event."
                                position="top"
                                trigger={"mouseenter"}
                                distance="-50"
                                animation={"fade"}
                                animateFill={false}
                                duration={400}
                                delay={1000}
                                // followCursor={true}
                            >
                                <div className="align-items-center row ml-3">
                                    <div
                                        className="col-3 col-sm-3 col-md-2 col-lg-1 pl-1 pr-1 my-auto d-flex eventColumnLogo">
                                        {(event.eventLogoUrl !== "default") ? (
                                            <img src={event.eventLogoUrl} className="event-logo-small" alt="Logo"/>
                                        ) : (
                                            <img src={"/files/LogoTemp.png"} className="event-logo-small" alt="Logo"/>
                                        )}
                                    </div>
                                    <div className="col-8 col-sm-8 col-md-10 col-lg-11 my-auto pl-1 pr-1 "
                                         id="eventColumnTitle">
                                        <b>{event.title}</b>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )
            });
        } else {
            sharedEventsComponent = (
                <div className="card p-2 mt-2">
                    <div className="card-body text-center">
                        No shared events yet.
                    </div>
                </div>
            )
        }


        var selectedLanguageStrings;
        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (

            <div>
                {(this.state.eventsListFetched === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="MainScreen"
                            eventId={this.state.currentEvent.id}
                            onSetSidebarOpen={this.onSetSidebarOpen}
                        />
                        <div>
                            <nav className="nav-bar-tab" id="nav-bar-tab">
                                <div className="container" id="navContainer">
                                    <div className="row mt-1 itemsNav">
                                        <div className="col-6 text-center  pl-0 pr-0">
                                            <Link to={"/"}>
                                                <div className="eventsTab">
                                                    <label
                                                        className="text-primary pointer">{selectedLanguageStrings.your_events}</label>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-6 text-center">
                                            <div className="eventsTab">
                                                <label
                                                    className="text-secondary pointer">{selectedLanguageStrings.shared_events}</label>
                                                <div className="border-bottom" id="borderLine"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <main role="main">
                            <div className="container">
                                {sharedEventsComponent}
                            </div>
                        </main>

                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {user} = state.authenticationReducer;
    const {eventCodeAvailability} = state.eventReducer;
    return {
        eventList: state.eventReducer.eventList,
        user,
        eventCodeAvailability,
        currentEvent: state.eventReducer.currentEvent,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddEvent: (callback) => dispatch(eventActions.addEvent(callback)),
        onCancelEvent: () => dispatch(eventActions.cancelEvent()),
        onSaveEvent: (userId, event, callback) => dispatch(eventActions.saveEvent(userId, event, callback)),
        onDeleteEvent: (event) => dispatch(eventActions.deleteEvent('iE8HlfnSDcc2njpztun0tvydO1s2', event)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onUploadEventLogo: (userId, eventId, logoFile, callback) => dispatch(eventActions.uploadEventLogo(userId, eventId, logoFile, callback)),
        onUpdateEventsIndexes: (newSortedEventList) => dispatch(eventActions.updateEventsIndexes(newSortedEventList)),
        onGetUserDataOnChange: (userId, callback) => dispatch(authenticationActions.getUserDataOnChange(userId, callback)),
        onShareEventEmailCheck: (email, callback) => dispatch(eventActions.shareEventEmailCheck(email, callback)),
        onSharedEventsList: (userId, callback) => dispatch(eventActions.sharedEventsList(userId, callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SharedEventScreen);
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {authenticationActions} from '../store/actions/AuthenticationActions';
import {version} from '../../package.json';

class LoginPage extends Component {

    constructor(props) {
        super(props);
        //reset login status
        this.props.onLogout();

        this.state = {
            email: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        var appVersion = localStorage.getItem('4meet-admin-version');

        if (!(appVersion && appVersion === version)) {
            //Reset Local Storage due to new client version.
            localStorage.clear();
            localStorage.setItem("4meet-admin-version", version);
        }        
    }

    handleChange(e) {
        const {name, value} = e.target;
        if ([name]) this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {email, password} = this.state;
        if (email && password) {
            // dispatch(userActions.login(email, password));
            this.props.onLogin(email, password, callback => {
                if (callback === 'loginSuccessful') {
                    this.props.history.push('/mainScreen');
                }
            });
        }
    }

    render() {
        const {loggingIn} = this.props;
        const {email, password, submitted} = this.state;
        return (
            <main role="main">
                <div className="container">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-5">
                                <div className='form-group'>
                                    <h1>Login</h1><br/>
                                </div>
                                <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" name="email" value={email}
                                           onChange={this.handleChange}/>
                                    {submitted && !email &&
                                        <div className="help-block">Email is required</div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" name="password" value={password}
                                           onChange={this.handleChange}/>
                                    {submitted && !password &&
                                        <div className="help-block">Password is required</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row mb-20">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-5 text-center mt-2">
                                <button type="submit" className="btn btn-success customSize" id="login">Log in</button>
                                {loggingIn}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-3"></div>
                            <div className="form-group col-lg-5 text-center">
                                <Link to="/registeration" className="btn btn-primary customSize">Register</Link>
                                <small className="form-text text-muted">If you don't have an account yet please
                                    register.</small>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="app-version">
                    4meet.in Admin v. {version}
                </div>
            </main>
        )
    }
}

// export default LoginPage;

function mapStateToProps(state) {
    const {loggingIn} = state.authenticationReducer;
    return {
        loggingIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (email, password, callback) => dispatch(authenticationActions.login(email, password, callback)),
        onLogout: () => dispatch(authenticationActions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
import React, {Component} from 'react';
import StarRatingComponent from 'react-star-rating-component';

class RatingResponse extends Component {

    render() {
      
        let totalRating = 0;
        if (this.props.componentType !== "liveComponent") {
            let inv = 1.0 / 0.5;
            totalRating = Math.round(this.props.totalRating * inv) / inv;
        }

        return (
            <div className="container-tweet-live">
                <div className="card cardAnswetText mt-3">
                    <div className="card-header cardHeaderAnswer"
                         style={{backgroundColor: this.props.event.primaryColor}}>
                        <div className="row pt-2">
                            <div className="col-6 text-left">
                                <h4><i className="fas fa-user mr-2" style={{color: this.props.event.textColor}}></i>
                                </h4>
                            </div>
                        </div>
                    </div>
                    {(this.props.componentType === "liveComponent") ? (

                        <div className="card-text cardBodyAnswer">
                            <div className="row">
                                <div className="col-4" style={{fontSize: "15px", color: this.props.event.primaryColor}}>
                                    <b> Average Votes: {this.props.totalLiveRating} </b>
                                </div>
                                <div className="col-8">
                                    <StarRatingComponent
                                        name="rate1"
                                        starCount={parseInt(this.props.question.numberOfStars)}
                                        // value={rating}
                                        renderStarIcon={() => <span><i
                                            className="fas fa-star starIconRating"></i></span>}
                                        emptyStarColor={"grey"}
                                        value={this.props.totalLiveRating}
                                        renderStarIcon={(index, value) => {
                                            return (
                                                <span>
                                                    <i className={index <= value ? 'fas fa-star' : 'far fa-star'}/>
                                                </span>
                                            );
                                        }}
                                        renderStarIconHalf={() => {
                                            return (
                                                <span>
                                                    <span style={{position: 'absolute'}}><i
                                                        className="far fa-star"/></span>
                                                    <span><i className="fas fa-star-half text-warning"/></span>
                                                </span>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    ) : (

                        <div className="card-text cardBodyAnswer">
                            <div className="row">
                                <div className="col-4" style={{fontSize: "15px", color: this.props.event.primaryColor}}>
                                    <b> Average Votes: {totalRating} </b>
                                </div>
                                <div className="col-8">
                                    <StarRatingComponent
                                        name="rate1"
                                        starCount={parseInt(this.props.question.numberOfStars)}
                                        // value={rating}
                                        renderStarIcon={() => <span><i
                                            className="fas fa-star starIconRating"></i></span>}
                                        emptyStarColor={"grey"}
                                        value={this.props.totalRating}
                                        renderStarIcon={(index, value) => {
                                            return (
                                                <span>
                                                    <i className={index <= value ? 'fas fa-star' : 'far fa-star'}/>
                                                </span>
                                            );
                                        }}
                                        renderStarIconHalf={() => {
                                            return (
                                                <span>
                                                    <span style={{position: 'absolute'}}><i
                                                        className="far fa-star"/></span>
                                                    <span><i className="fas fa-star-half text-warning"/></span>
                                                </span>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
        );
    }
}

export default RatingResponse;
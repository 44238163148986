import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Sidebar from "react-sidebar";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { BrowserView } from 'react-device-detect';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventTitle: '',
            eventCode: '',
            user:'',
            sidebarOpen: false,
            sidebardisplay :"hidden"
        };

        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    componentDidMount() {

        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({user:user});
    
    }

    onSetSidebarOpen() {
        
        if (this.state.sidebarOpen === false) {
            this.setState({ ...this.state, sidebarOpen: true,sidebardisplay:"visible" });

        } else {
            this.setState({ ...this.state, sidebarOpen: false,sidebardisplay:"hidden" });
        }

    }

    render() {

        var itemIcon;
        switch(this.props.item){
            case "question":
                itemIcon = <i className="text-white fas fa-question my-auto text-right"></i>;
                break;

            case "tweet":
                itemIcon = <i className="text-white fas fa-comments my-auto text-right"></i>;
                break;

            case "survey":
                itemIcon = <i className="text-white far fa-list-alt my-auto text-right"></i>;
                break;

            case "library":
                itemIcon = <i className="text-white fas fa-book-open my-auto text-right"></i>;
                break;

            case "agenda":
                itemIcon = <i className="text-white fas fa-calendar-alt my-auto text-right"></i>;
                break;

            case "qrcode":
                itemIcon = <i className="text-white fas fa-qrcode my-auto text-right"></i>;
                break;

            case "participants":
                itemIcon = <i className="text-white far fa-address-book my-auto text-right"></i>;
                break;

            case "messages":
                itemIcon = <i className="text-white far fa-address-book my-auto text-right"></i>;
                break;

            default:
                itemIcon = <div></div>;
        }
        var selectedLanguageStrings;
        if(this.props.currentEvent){
            if(this.props.currentEvent.selectedLanguage === 'English'){
                selectedLanguageStrings = englishStrings;
            }else{
                selectedLanguageStrings = italianStrings;
            }
        }
        return (
            <nav className="nav-bar py-1 bg-primary">
                {(this.props.page !== "MainScreen") ? (

                    <Sidebar
                        children={<div></div>}
                        sidebar={
                            <div className="settingContainer dropDownContainer">
                                <div className="col-12 headerSetting "
                                     style={{backgroundColor: '#007bff', color: '#ffffff'}}></div>
                                <div className="col-12 p-3"
                                     style={{backgroundColor: '#007bff', color: '#ffffff', textAlign: 'center'}}>
                                    <h5>Main Menu</h5>
                                </div>
                                {(this.props.page === "SingleEventScreen" || this.props.page === "ActiveUsersScreen") ? (
                                
                                    <div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <Link to={"/"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.all_events}</label></Link>
                                        </div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <label className="eventsTabs text-primary pointer" data-toggle="modal"
                                                   data-target="#editModal" onClick={() => {
                                                this.props.handleEditEvent()
                                            }}>{selectedLanguageStrings.event_settings}</label>
                                        </div>
                                        
                                        {(this.props.page === "SingleEventScreen") ? (
                                            
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <label
                                                    className="eventsTabs text-secondary">{selectedLanguageStrings.current_event}</label>
                                            </div>
                                    
                                        ) : (
                                    
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <Link to={"/event/" + this.props.currentEvent.id + "/"}><label
                                                    className="eventsTabs text-primary pointer">{selectedLanguageStrings.current_event}</label></Link>
                                            </div>
            
                                        )}

                                        {(this.props.page === "ActiveUsersScreen") ? (
                                    
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <label className="eventsTabs text-secondary"
                                                       disabled>{selectedLanguageStrings.active_participants}</label>
                                            </div>
                                
                                        ) : (
                                
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <Link
                                                    to={"/event/" + this.props.currentEvent.id + "/participants"}><label
                                                    className="eventsTabs text-primary pointer">{selectedLanguageStrings.active_participants}</label></Link>
                                            </div>
                                        
                                        )}

                                        {(this.props.page === "MessageScreen") ? (
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <label className="eventsTabs text-secondary"
                                                       disabled>{selectedLanguageStrings.send_message}</label>
                                            </div>
                                        ) : (
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <Link to={"/event/" + this.props.currentEvent.id + "/messages"}><label
                                                    className="eventsTabs text-primary pointer">{selectedLanguageStrings.send_message}</label></Link>
                                            </div>
                                        )}
                                

                                        {/* <div className="col-12 p-3 menuItem" style={{ borderBottomColor: '#007bff', color: '#007bff' }} >
                                            <label className="eventsTabs text-primary pointer" data-toggle="modal" data-target="#messageModal" >{selectedLanguageStrings.send_message}</label>
                                        </div> */}
                                    </div>
                    
                                ) : (
                                
                                    <div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <Link to={"/"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.all_events}</label></Link>
                                        </div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <label className="eventsTabs text-primary pointer" data-toggle="modal"
                                                   data-target="#editModal" onClick={() => {
                                                this.props.handleEditEvent()
                                            }}>{selectedLanguageStrings.event_settings}</label>
                                        </div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <Link to={"/event/" + this.props.currentEvent.id + "/"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.current_event}</label></Link>
                                        </div>
                                        <div className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                            <Link to={"/event/" + this.props.currentEvent.id + "/participants"}><label
                                                className="eventsTabs text-primary pointer">{selectedLanguageStrings.active_participants}</label></Link>
                                        </div>
                                        <div id="tweet-tab" className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}} onClick={() => {
                                            this.props.goToTweetScreen()
                                        }}>
                                            {(this.props.activeScreen === 'tweet') ? (
                                                
                                                <label
                                                    className="eventsTabs text-secondary  ">{selectedLanguageStrings.hTweets}</label>
                                            
                                            ) : (
                                        
                                                <label
                                                    className="eventsTabs text-primary ">{selectedLanguageStrings.hTweets}</label>
                                        
                                            )}
                                            <div className={this.props.navItemTweet} id="borderLine"></div>
                                        </div>
                                        <div id="question-tab" className="col-12 p-3 menuItem"
                                             style={{borderBottomColor: '#007bff', color: '#007bff'}} onClick={() => {
                                            this.props.goToQuestionScreen()
                                        }}>
                                            {(this.props.activeScreen === 'question') ? (
                                                
                                                <label
                                                    className="eventsTabs text-secondary  ">{selectedLanguageStrings.hQuestions}</label>
                                            
                                            ) : (
                                        
                                                <label
                                                    className="eventsTabs text-primary ">{selectedLanguageStrings.hQuestions}</label>
                                        
                                            )}
                                            <div className={this.props.navItemQuestion} id="borderLine"></div>
                                        </div>

                                        {(this.props.page === "MessageScreen") ? (
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <label className="eventsTabs text-secondary"
                                                       disabled>{selectedLanguageStrings.send_message}</label>
                                            </div>
                                        ) : (
                                            <div className="col-12 p-3 menuItem"
                                                 style={{borderBottomColor: '#007bff', color: '#007bff'}}>
                                                <Link to={"/event/" + this.props.currentEvent.id + "/messages"}><label
                                                    className="eventsTabs text-primary pointer">{selectedLanguageStrings.send_message}</label></Link>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        }
                        open={this.state.sidebarOpen}
                        onSetOpen={this.onSetSidebarOpen}
                        transitions={true}
                        styles={{
                            sidebar: {
                                background: "white",
                                width: "280px",
                                // width: "50%",
                                // height: '195px',
                                // overflowY: 'none',
                                transition: "transform 0.5s",
                                WebkitTransition: "-webkit-transform 0.5s ",
                                willChange: "transform",
                                // top: "35px",
                            },
                            root: {
                                // marginLeft: "15px",
                                visibility:this.state.sidebardisplay,
                                position: "fixed",
                                zIndex: 123
                            },
                        }}
                    ></Sidebar>
                ) : (
                        null
                )}

                <div className="container">
                    {(this.props.page !== "MainScreen") ? (
                        
                        <div className="row align-items-center">
                            <div className="col-2 col-sm-4 my-auto">
                                <i className="fas fa-bars text-white headerDropdown"
                                   onClick={() => this.onSetSidebarOpen()}></i>
                            </div>             
                            <div className="col-8 col-sm-4 text-center" >
                                <div className="row mt-1 no-gutters justify-content-center">
                                    {itemIcon}
                                    <Link to="/">
                                        <div className="col-8 logo m-auto d-table" id="meetTitle"><img
                                            src={require("../assets/img/logo.png")} alt="4-meet"/></div>
                                    </Link>
                                    <div className="col-12">
                                        <p className="event-code-title text-white"><i
                                            className="fab fa-slack-hash"></i> {this.props.currentEvent.eventCode} / {this.props.currentEvent.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                            {(this.props.page === "SingleEventScreen") ? (
                                <div></div>
                            ) : (

                                <div className="col-2 col-sm-4 px-0" >
                                    {(this.props.page === "TweetScreen") ? (
                            
                                        <div className="row row-moderator">
                                            <div className="col-3 col-sm-3 col-lg-6"></div>
                                            <div
                                                className="col-3 col-sm-3 col-lg-2 moderatorIcon text-white text-right mt-2">
                                                    <Tooltip
                                                        // options
                                                        position="left"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        animateFill={false}
                                                        duration={1200}
                                                        html = {(
                                                        <p className="text-left" style={{width: 100}}>The moderator big
                                                            screen view</p>
                                                        )}
                                                    > 
                                                        <div  onClick={() => this.props.goToBigScreenModerator()}>
                                                            <i className="fas fa-user-edit"></i>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            <div
                                                className="col-3 col-sm-3 col-lg-2   moderatorIcon text-white text-right mt-2">
                                                    <Tooltip
                                                        // options
                                                        title="The big screen view"
                                                        position="left"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        animateFill={false}
                                                        duration={1200}
                                                    > 
                                                    <div onClick={() => this.props.goToBigScreen()}><i
                                                        className="fas fa-desktop"></i></div>
                                                    </Tooltip>
                                                </div>
                                            <div
                                                className="col-3 col-sm-3 col-lg-2   moderatorIcon text-white text-right mt-2">
                                                    <Tooltip
                                                        // options
                                                        title="Show introduction page"
                                                        position="left"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        animateFill={false}
                                                        duration={1200}
                                                    > 
                                                        <div  onClick={() => this.props.goIntroductionPage()}>
                                                            <i className="fas fa-info-circle text-white "></i>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            
                                        ) : (
                                    
                                        <div
                                            className="col-12 col-sm-12 col-lg-12   moderatorIcon text-white text-sm-right mt-2">
                                            <div className="goToIntroPageIcon"
                                                 onClick={() => this.props.goIntroductionPage()}>
                                                    <i className="fas fa-info-circle text-white "></i>
                                                </div>
                                            </div>
                                        
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    
                    ) : (
                    
                        <div className="row">
                            <div className="col-4">
                                <div className="text-white userProfileText pt-2">Plan
                                    Status: {this.state.user.sType}</div>
                            </div>
                            <div className="col-4 my-auto float-center">
                                <Link to="/">
                                    <div className="logo mx-auto d-table"><img src={require("../assets/img/logo.png")}
                                                                               alt="4-meet"/></div>
                                </Link>
                            </div>
                            <div className="col-4 text-white text-right">
                                <div className="user-profile ">
                                    <label className="userProfileText pr-2">{this.state.user.sEmail}</label>
                                    <i className="fas fa-envelope "></i>
                                </div>
                                <div className="log-out ">                                        
                                    <Link to="/login" className="text-white">
                                        <label className="userProfileText pr-2 pointer">Log out</label>
                                        <i className="fas fa-sign-out-alt pt-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    
                    )}
                </div>
            </nav>
        );
    }
}
// export default Header;
const mapStateToProps = state => {
    return {
        currentEventId: state.eventReducer.currentEventId,
        currentEvent: state.eventReducer.currentEvent,
        modalType: state.eventReducer.modalType
    };
};

export default connect(mapStateToProps, null)(Header);
import React, { Component } from 'react';

class QuestionToDelete extends Component {

    render() {
        return(
        <div className="modal fade" id="deleteQuestionModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalQuestion" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete Question: {this.props.questionToDelete.text}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                Are you sure you want to delete this Question?
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger"  data-dismiss="modal" id="deleteQuestion" onClick={()=>this.props.deleteQuestion(this.props.questionToDelete)}>Yes</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                </div>
            </div>
        </div>
    </div>
    )
    }
}

export default QuestionToDelete;
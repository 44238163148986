import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import 'react-tippy/dist/tippy.css';


class SimpleTextAndMultipleChoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createdInputBox: false,
            optionsArray: [],
            saveBtnTitle: '',
            letMultipleChoice2Value: false,
            letMultipleChoice2Status: 'default',
            correctAnswerStatus: 'default',
            alertType: '',
            questionModal: 'show',
            alertModal: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            correctAnswerBtn: "",
            correctAnswerClass: "",
            simpleTextAnswerMandatoryStatus: "default",
            simpleTextAnswerMandatoryValue: false,
            layoutStatus: "default",
            optionDecorationStatus:"default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default"
        };
        this.closeQuestion = this.closeQuestion.bind(this);
        this.chooseCorrectAnswer = this.chooseCorrectAnswer.bind(this);
        this.addLikeDislikeOption = this.addLikeDislikeOption.bind(this);
        this.addNewOptionFunction = this.addNewOptionFunction.bind(this);
        this.goBackFunction = this.goBackFunction.bind(this);
        this.handleText = this.handleText.bind(this);
    }

    componentDidMount() {
        this.setState({ sortedOptionsList: this.props.optionsListNew });
    }

    handleText = (e) => {

        switch (e.target.name) {

            case "questionTextMultiple":
                this.setState({                    
                    ...this.state,
                    [e.questionTextMultiple]: e.value });
                break;

            case "multipleChoiceLimitAnswer":
                this.setState({ ...this.state, [e.multipleChoiceLimitAnswer]: e.value });
            break;

            case "optionDecoration":
            this.setState({...this.state, 
                            optionDecorationStatus:"edited"});
            break;

            case "optionInput":

                this.props.optionsListNew[e.target.id].text = e.target.value;
                break;

          case "checkBoxMultipleChoice2":
                for (var index = 0; index < this.props.optionsListNew.length; index++) {
                    this.props.optionsListNew[index].isAnswer = false;
                }
                if(e.target.checked === true){
                    this.setState({
                        ...this.state,
                        letMultipleChoice2Value: e.target.checked,
                        letMultipleChoice2Status: 'Edited',
                        multipleChoiceLimitationStatus:"edited"
    
                    });
                    
                }else{
                    this.setState({
                        ...this.state,
                        letMultipleChoice2Value: e.target.checked,
                        letMultipleChoice2Status: 'Edited',
                        multipleChoiceLimitationStatus:"default"

                    });
                }

                break;

            case "simpleTextAnswerMandatory":

                this.setState({
                    ...this.state,
                    simpleTextAnswerMandatoryValue: e.target.checked,
                    simpleTextAnswerMandatoryStatus: "Edited",

                });

                break;
            case "correctAnswer":
                this.setState({
                    ...this.state,
                    correctAnswerStatus: 'Edited',
                    correctAnswerAlert: 'done',
                })

                var indexCorrectAnswer = parseInt(e.target.id.split('-')[1],10);
                //we check if it is radio bax we need to make the others items' corrrect answer to false
                if (e.target.type === "radio") {
                    for (var index1 = 0; index1 < this.props.optionsListNew.length; index1++) {
                        // we used == to compare just values not the type of the indexes!!!!!!
                        if (this.props.optionsListNew[index1].optionsOrderIndex === indexCorrectAnswer) {
                            this.props.optionsListNew[index1].isAnswer = e.target.checked;
                        } else {
                            // in the single choice correct answer we make the correct answer of the other options to false
                            this.props.optionsListNew[index1].isAnswer = false;
                        }
                    }
                } else {
                    for (var index2 = 0; index2 < this.props.optionsListNew.length; index2++) {
                        if (this.props.optionsListNew[index2].optionsOrderIndex === indexCorrectAnswer) {
                            this.props.optionsListNew[index2].isAnswer = e.target.checked;
                        }
                    }
                }

                break;

            default:
                return true;
        }
    }

    chooseCorrectAnswer(option) {
        for (var index = 0; index < this.props.optionsListNew.length; index++) {
            this.props.optionsListNew[index].isAnswer = document.getElementById("correctAnswerRadio-" + this.props.optionsListNew[index].optionsOrderIndex).checked;
        }
    }

    saveQuestionFunction() {
        var multipleChoiceSelection;
        var simpleTextAnswerMandatory;
        var layoutStatus;
        var optionDecorationValue;
        var multipleChoiceLimitAnswer;

        if (this.state.letMultipleChoice2Status === 'default') {
            multipleChoiceSelection = this.props.question.letMultipleSelect;
        } else {
            multipleChoiceSelection = this.state.letMultipleChoice2Value;
        }

        if( multipleChoiceSelection === true){
            multipleChoiceLimitAnswer =  parseInt(document.getElementById("multipleChoiceLimitAnswer").value,10);
        }else{
            multipleChoiceLimitAnswer=0;
        }

        if (this.state.simpleTextAnswerMandatoryStatus === "default") {
            simpleTextAnswerMandatory = this.props.question.simpleTextAnswerMandatory;
        } else {
            simpleTextAnswerMandatory = this.state.simpleTextAnswerMandatoryValue;
        }

        if (this.state.layoutStatus === 'default') {
            layoutStatus = this.props.question.layout;
        } else {
            layoutStatus = this.state.layoutStatus
        }

        if (this.state.optionDecorationStatus === 'default') {
            optionDecorationValue = this.props.question.optionDecorationValue;
        } else {
            optionDecorationValue = this.state.optionDecorationValue;
        }

        this.props.saveQuestionFunction('SimpleTextAndMultipleChoice', this.props.question, multipleChoiceSelection,multipleChoiceLimitAnswer, simpleTextAnswerMandatory,optionDecorationValue, layoutStatus);
        this.setState({
            ...this.state,
            letMultipleChoice2Status: 'default',
            letMultipleChoice2Value: false,
            simpleTextAnswerMandatoryStatus: "default",
            simpleTextAnswerMandatoryValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            layoutStatus: "default",
            optionDecorationStatus: "default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default"
        });
    }

    closeQuestion() {
        this.setState({
            letMultipleChoice2Status: 'default',
            letMultipleChoice2Value: false,
            simpleTextAnswerMandatoryStatus: "default",
            simpleTextAnswerMandatoryValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            layoutStatus: "default",
            optionDecorationStatus: "default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default"
        });
        this.props.closeQuestionFunction(this.state.letMultipleChoice2Selection)
    }

    goBackFunction() {
        this.setState({
            // letMultipleChoice2Status: 'default',
            // letMultipleChoice2Value: false,
            // simpleTextAnswerMandatoryStatus: 'default',
            // simpleTextAnswerMandatoryValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            layoutStatus: "default"
        });
        this.props.resetOptionsListFunction();
    
    }

    optionDecorationSelected(type){
        this.setState({
            ...this.state, optionDecorationValue:type, optionDecorationStatus:"edited"
        });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        var newSortedoptionsList = arrayMove(this.props.optionsListNew, oldIndex, newIndex);
        this.props.onSortEndOptions(newSortedoptionsList);
    };

    addNewOptionFunction() {
        if (this.props.question.layout === "default") {
            this.setState({
                ...this.state,
                layoutStatus: "option-text"
            });
        }
        this.props.addNewOptionFunction(this.props.optionsListNew);
    }

    addLikeDislikeOption() {
        if (this.props.question.layout === "default") {
            this.setState({
                ...this.state,
                layoutStatus: "like-dislike"
            });
        }
        this.props.addLikeDislikeOptionFunction(this.props.optionsListNew);
    }


    render() {
        var sortedOptionsList = this.props.optionsListNew;

        const DragHandle = SortableHandle(({ option }) => {
            return (
                <button type="button" className="rounded border-0 mr-sm-2 mt-1 dragOptionsbtn">
                    <i className="fa fa-fw fa-sort"></i>
                </button>
            )
        });

        const SortableItem = SortableElement(({ option, index }) => {
            var i = option.optionsOrderIndex;
            return (
                <li>
                    {((this.props.question.layout === "default" && this.state.layoutStatus === "option-text") || (this.props.question.layout === "option-text" && this.state.layoutStatus === "default"))?(
                    <div className="row mb-2 ml-1">
                        <div className="col-sm-11 col-11 col-md-11 col-lg-11">
                            <div className="row border optionsRow" >
                                <div className="col-sm-10 col-10 col-md-10 col-lg-10 p-1">
                                    <input type="text" className="form-control mb-1" id={i} placeholder="add an option" defaultValue={option.text} name="optionInput" onChange={this.handleText} />
                                </div>
                                <div className="col-sm-1 col-1 col-md-1 col-lg-1 pl-1 p-0">
                                    {(this.state.letMultipleChoice2Status === "default") ? (
                                        <div>
                                            {(this.props.question.letMultipleSelect === true) ? (
                                                <div className="custom-control custom-checkbox mr-sm-2">
                                                    <input type="checkbox" className="custom-control-input correctAnswerRadioSimpleLabel" defaultChecked={option.isAnswer} id={'correctAnswerRadioSimple-' + i} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label " id="correctAnswerRadioSimpleLabel" htmlFor={'correctAnswerRadioSimple-' + i} ></label>
                                                </div>
                                            ) : (
                                                <div className="custom-control custom-radio mr-sm-2">
                                                    <input type="radio" className="custom-control-input correctAnswerRadioSimpleLabel" id={'correctAnswerRadioSimple-' + i} defaultChecked={option.isAnswer} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label " id="correctAnswerRadioSimpleLabel" htmlFor={'correctAnswerRadioSimple-' + i}></label>
                                                </div>
                                                )}
                                        </div>
                                    ) : (
                                        <div>
                                            {(this.state.letMultipleChoice2Value === true) ? (
                                                <div className="custom-control custom-checkbox mr-sm-2">
                                                    <input type="checkbox" className="custom-control-input correctAnswerRadioSimpleLabel" id={'correctAnswerRadioSimple-' + i} checked={option.isAnswer} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label  " id="correctAnswerRadioSimpleLabel" htmlFor={'correctAnswerRadioSimple-' + i} ></label>
                                                </div>
                                            ) : (
                                                <div className="custom-control custom-radio mr-sm-2">
                                                    <input type="radio" className="custom-control-input correctAnswerRadioSimpleLabel" id={'correctAnswerRadioSimple-' + i} checked={option.isAnswer} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label  " id="correctAnswerRadioSimpleLabel" htmlFor={'correctAnswerRadioSimple-' + i}></label>
                                                </div>
                                                )}
                                        </div>
                                        )}
                                </div>
                                <div className="col-sm-1 col-1 col-md-1 col-lg-1 pr-1 pl-1">
                                    <button type="button" className="rounded border-0 btn-danger mb-2 mt-2 " onClick={() => { this.props.deleteOptionFunction(i) }}>
                                        <i className="fas fa-trash-alt" id='trash'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1 col-1 col-md-1 col-lg-1 p-0 mt-2 optionsDragableRow">
                            <DragHandle
                                option={option}
                            />
                        </div>
                    </div>
                    ):(
                        <div className="row ml-2">
                            {(option.type === "like")?(
                                <div className="col-10 text-lef input-group mb-2">
                                    <span><i className="fas fa-thumbs-up text-success optionLikeIcon pt-1"></i></span>
                                    <input type="text" className="form-control mb-1" id={i} placeholder="add a title" defaultValue={option.text} name="optionInput" onChange={this.handleText} />
                                </div>
                            ):(
                                <div className="col-10 text-left input-group mb-1">
                                    <span><i className="fas fa-thumbs-down text-danger optionLikeIcon pt-2"></i></span>
                                    <input type="text" className="form-control mb-1" id={i} placeholder="add a title" defaultValue={option.text} name="optionInput" onChange={this.handleText} />
                                </div>
                            )}
                        </div>
                    )}
                </li>
            );
        });

        const SortableList = SortableContainer(({ sortedOptionsList }) => {
            return (
                <div>
                    <ul id="questionsList">
                        {sortedOptionsList.map((option, index) => (
                            <SortableItem key={`item-${index}`} index={index} option={option} />
                        ))}
                    </ul>
                </div>
            );
        });

        const multipleChoiceComponent = (
            <div>
                {(this.state.letMultipleChoice2Status === 'default') ? (
                    <div>
                        <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                            <input type="checkbox" className="custom-control-input" id="multipleChoice2Simple" defaultChecked={this.props.question.letMultipleSelect} name='checkBoxMultipleChoice2' onChange={this.handleText} />
                            <label className="custom-control-label" htmlFor="multipleChoice2Simple">Let participants select multiple choices</label>
                        </div>
                        {(this.props.question.letMultipleSelect === true)?(
                            <div className="col-7 pl-4 pt-1 mb-2">
                                <input type="number" className="form-control" id="multipleChoiceLimitAnswer" name="multipleChoiceLimitAnswer" defaultValue={this.props.question.multipleChoiceLimitAnswer} onChange={this.handleText}/>
                            </div> 
                        ):(
                            <div></div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                            <input type="checkbox" className="custom-control-input" id="multipleChoice2Simple" name='checkBoxMultipleChoice2' checked={this.state.letMultipleChoice2Value} onChange={this.handleText} />
                            <label className="custom-control-label" htmlFor="multipleChoice2Simple">Let participants select multiple choices</label>
                        </div>
                        <div className="col-7 pl-4 pt-1 mb-2">
                            {(this.state.multipleChoiceLimitationStatus !== "default")?(
                                <input type="number" className="form-control" id="multipleChoiceLimitAnswer" name="multipleChoiceLimitAnswer" placeholder="Set number of selections" onChange={this.handleText}/>
                            ):(
                                <div></div>
                            )}
                        </div> 
                    </div> 
                )}
            </div>
        )

//         const multipleChoiceComponent = (
//         <div>
//         {(this.state.letMultipleChoice2Status === 'default') ? (
//             <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
//                 <input type="checkbox" className="custom-control-input" id="multipleChoice2Simple" defaultChecked={this.props.question.letMultipleSelect} name='checkBoxMultipleChoice' onChange={this.handleText} />
//                 <label className="custom-control-label" htmlFor="multipleChoice2Simple">Let participants select multiple choices</label>
//             </div>

//         ) : (
//             <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
//                 <input type="checkbox" className="custom-control-input" id="multipleChoice2Simple" name='checkBoxMultipleChoice' checked={this.state.letMultipleChoice2Value} onChange={this.handleText} />
//                 <label className="custom-control-label" htmlFor="multipleChoice2Simple">Let participants select multiple choices</label>
//             </div>
//             )}
//     </div>
// )
        

        const simpleTextMandatoryComponent =(
            <div>
            {(this.state.simpleTextAnswerMandatoryStatus === "default") ? (
                <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                    <input type="checkbox" className="custom-control-input" id="simpleTextAnswerMandatoryId" defaultChecked={this.props.question.simpleTextAnswerMandatory} name='simpleTextAnswerMandatory' onChange={this.handleText} />
                    <label className="custom-control-label" htmlFor="simpleTextAnswerMandatoryId">Simple text is mandatory</label>
                </div>

            ) : (
                <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                    <input type="checkbox" className="custom-control-input" id="simpleTextAnswerMandatoryId" name='simpleTextAnswerMandatory' checked={this.state.simpleTextAnswerMandatoryValue} onChange={this.handleText} />
                    <label className="custom-control-label" htmlFor="simpleTextAnswerMandatoryId">Simple text is mandatory</label>
                </div>
                )}
            </div>

        )


        const optionDecorationComponent = (
            <div>
                {(this.state.optionDecorationStatus === 'default') ? (
                    <div>
                        {(this.props.question.optionDecorationValue && this.props.question.optionDecorationValue !== "default" )?(
                            <label className="mr-2">
                                {"Options Decoration " + "( " +this.props.question.optionDecorationValue+" )"}
                            </label>
                        ):(
                            <label className="mr-2"> Options Decoration ( None )</label>
                        )}
                        <button type="button" className="btn btn-primary dropdown-toggle optionDecorationBtn" id="optionDecoration" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="optionDecorationBtnName">Choose</span>
                        </button>
                        <div className="dropdown-menu ml-2 pl-2 pr-2 optionDecoration" aria-labelledby="optionDecoration">
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Number")}}>Number <span className="optionDecorationItemEx">(1, 2,..)</span></button>
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Alphabet")}}>Alphabet <span className="optionDecorationItemEx">(a, b,...)</span></button>
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Bullet")}}>Bullet <span className="optionDecorationItemEx">( • )</span></button>
                            <button className="dropdown-item" type="button" onClick={()=>{this.optionDecorationSelected("None")}}>None</button>
                        </div>
                    </div>
                ) : (
                    <div>
                        {(this.state.optionDecorationValue !== "default" )?(
                            <label className="mr-2">
                                    {"Options Decoration " + "( " +this.state.optionDecorationValue+" )"}
                            </label>
                        ):(
                            <label className="mr-2"> Options Decoration ( None )</label>
                        )}
                        <button type="button" className="btn btn-primary dropdown-toggle optionDecorationBtn" id="optionDecoration" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="optionDecorationBtnName">Choose</span>
                        </button>
                        <div className="dropdown-menu ml-2 pl-2 pr-2 optionDecoration" aria-labelledby="optionDecoration">
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Number")}}>Number <span className="optionDecorationItemEx">(1, 2,..)</span></button>
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Alphabet")}}>Alphabet <span className="optionDecorationItemEx">(a, b,...)</span></button>
                            <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Bullet")}}>Bullet <span className="optionDecorationItemEx">( • )</span></button>
                            <button className="dropdown-item" type="button" onClick={()=>{this.optionDecorationSelected("None")}}>None</button>
                        </div>
                    </div>
                    )} 
            </div>
        )

        return (
            <div className="modal fade" id="SimpleTextAndMultipleChoice" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered questionModalsDialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Simple Text And Multiple Choice Question</h5>
                        </div>
                        <div className="modal-body">
                            <div className="card-body text-center p-1">
                                <form id='multipleChoiceCommentModalsForm'>
                                    <div className="row align-items-center">
                                        <div className="col-auto " id="questionTextMultiple">
                                            <input type="text" className="form-control mb-3 mr-sm-2" id="questionTextMultipleComment" placeholder="What would you like to ask?" name="questionTextMultiple" defaultValue={this.props.question.text} onChange={this.handleText} />
                                        </div>
                                    </div>
                                    {(this.props.question.layout === 'default') ? (
                                        (this.state.layoutStatus === "default") ? (
                                            <div>
                                                <div className="row align-items-center ml-1">
                                                    <div className="col-sm-1 col-1 col-md-1 col-lg-1 mr-1 ml-2">
                                                        <button type="button" htmlFor="inputOption" className="rounded border-0 btn-primary mr-sm-2 " onClick={this.addNewOptionFunction}>
                                                            <i className="fas fa-plus" ></i>
                                                        </button>
                                                    </div>
                                                    <div className="ml-2 text-secondary">Add an option</div>
                                                </div>
                                                <div className="row align-items-center mt-2 ml-1">
                                                    <div className="col-sm-1 col-1 col-md-1 col-lg-1 mr-1 ml-2">
                                                        <button type="button" htmlFor="inputOption" className="rounded border-0 btn-primary mr-sm-2 " onClick={this.addLikeDislikeOption}>
                                                            <i className="fas fa-plus " ></i>
                                                        </button>
                                                    </div>
                                                    <div className="ml-2 text-secondary">Add Like / Dislike option
                                                </div>
                                                </div>
                                                <div className="row mt-3 ml-2">
                                                    <div className="col-12">
                                                        {multipleChoiceComponent}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                                (this.state.layoutStatus === "option-text") ? (
                                                    <div>
                                                        <div className="row align-items-center ml-1">
                                                            <div className="col-sm-1 col-1 col-md-1 col-lg-1 mr-1 ml-2">
                                                                <button type="button" htmlFor="inputOption" className="rounded border-0 btn-primary mr-sm-2 " onClick={this.addNewOptionFunction}>
                                                                    <i className="fas fa-plus " ></i>
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-10 col-10 col-md-10 col-lg-10">
                                                                <SortableList sortedOptionsList={sortedOptionsList} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 ml-2">
                                                            <div className="col-12">
                                                                {multipleChoiceComponent}
                                                            </div>
                                                        </div>
                                                    </div>
                                                 ) : (
                                                        <SortableList sortedOptionsList={sortedOptionsList} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                                     )
                                            )

                                    ) : (
                                            (this.props.question.layout === "option-text") ? (
                                                <div>
                                                    <div className="row align-items-center ml-1">
                                                        <div className="col-sm-1 col-1 col-md-1 col-lg-1 mr-1 ml-2">
                                                            <button type="button" htmlFor="inputOption" className="rounded border-0 btn-primary mr-sm-2 " onClick={this.addNewOptionFunction}>
                                                                <i className="fas fa-plus " ></i>
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-10 col-10 col-md-10 col-lg-10">
                                                            <SortableList sortedOptionsList={sortedOptionsList} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3 ml-2">
                                                        <div className="col-12">
                                                            {multipleChoiceComponent}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <SortableList sortedOptionsList={sortedOptionsList} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                            )

                                        )}


                                    <div className="row mt-2 ml-2">
                                        <div className="col-12">
                                            {simpleTextMandatoryComponent}
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        {/* <div className="col-12 text-left"> */}
                                        <div className="col-1">
                                        </div>
                                        <div className="col-11 text-left pl-1">
                                            {optionDecorationComponent}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {(this.state.layoutStatus === "default") ? (
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary px-3" data-dismiss="modal" onClick={this.closeQuestion}>Cancel</button>
                                <button type="button" className="btn btn-success px-3" data-dismiss="modal" onClick={this.saveQuestionFunction.bind(this)}>{this.props.saveBtnTitle}</button>
                            </div>
                        ) : (
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={this.goBackFunction}>Go Back</button>
                                    <button type="button" className="btn btn-secondary px-3" data-dismiss="modal" onClick={this.closeQuestion}>Cancel</button>
                                    <button type="button" className="btn btn-success px-3" data-dismiss="modal" onClick={this.saveQuestionFunction.bind(this)}>{this.props.saveBtnTitle}</button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        questionList: state.questionReducer.questionList,
        questionOptions: state.questionReducer.questionOptions,
    };
};

export default connect(mapStateToProps, null)(SimpleTextAndMultipleChoice);

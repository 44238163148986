import firebase from '../config/firebase';
import firebase1 from 'firebase';
import moment from 'moment';

var firestore = firebase.firestore();


export const eventFunctions = {
    getEvents,
    getEventElementParents,
    saveEventElementParent,
    getEventElementChildren,
    getParentInfoOnChange,
    saveEventElementChild,
    updateElementData,
    setElementLanguage,
    checkCodeAvailability,
    saveEvent,
    deleteEvent,
    createElementRoot,
    getEventInfoOnChange,
    saveEventCode,
    // setCurrentTweetElement,
    setLiveTweetList,
    getAllTweets,
    updateTweetFields,
    deleteTweet,
    saveNewTweet,
    updateCurrentField,
    uploadEventLogo,
    updateEventsIndexes,
    sendNewMessage,
    getParticipantsList,
    uploadLibraryFile,
    uploadAgendaFile,
    uploadSurveyFile,
    saveLibraryFile,
    saveAgendaFile,
    saveSurveyFile,
    saveQRCodeFile,
    getLibraryFiles,
    getAgendaFiles,
    getAgendaInfoOnChange,
    getSurveyFiles,
    removeLibraryFile,
    updateLibraryFileIndexes,
    saveEventInfofile,
    getEventInfoFiles,
    getSurveyInfoOnChange,
    getLibraryInfoOnChange,
    getQRCodeInfoOnChange,
    shareEventEmailCheck,
    updateSharedEventsData,
    getListOfSharedEmails,
    sharedEventsList,
    updateEventElementIndexes,
    getMessageList,
    deleteMessage,
    pushMessage,
    getElementItemInfoOnChange,
    updateServerHeartBeatCounter
};

function createElementRoot(eventId, eventTitle, status) {
    let elementData = {
        iOrder: 0,
        title: eventTitle,
        sType: "event",
        elementIsDeleted: false,
        isOpen: true
    };
    if (status === 'create') {
        return firestore.collection("events/" + eventId + "/elements").doc('default').set(elementData)
            .then(result => {
                //we add the defaultRoom too
                return firestore.collection("events/" + eventId + "/elements").doc('defaultRoom').set({
                    title: "Main Room",
                    sParentId: 'default',
                    sType: "room",
                    elementIsDeleted: false,
                    iOrder: 1,
                    isOpen: false,
                    roomIconColor: "#7fb6f1",
                    visible: true,
                    currentTweetElement: "default",
                    tweetsParticipantsWriteEnable: false,
                    currentSurveyElement: "default",
                    currentQuestionElement: "default",
                    currentLiveSession: {status: "default", id: "default"},
                    currentQuestion: "default",
                    bigScreenStatus: "default",
                    currentSelectedLiveSession: "default",
                    singleScreenElementId: "default",
                    currentLibraryElement: "default",
                    currentEventInfo: "default"
                })
            })
    }
}

function getEvents(userId, callback) {
    let unsubscribe = firestore.collection("events").where("userId", "==", userId).where("eventIsDeleted", "==", false).orderBy('eventOrderIndex', 'desc').onSnapshot(querySnapShot => {
        let eventList = [];
        querySnapShot.forEach(eventDoc => {
            if (eventDoc.data().dtmStart && eventDoc.data().dtmEnd) {
                let event = eventDoc.data();
                event.id = eventDoc.id;
                event.dtmStart = moment(eventDoc.data().dtmStart.toDate()).format("MM/DD/YYYY");
                event.dtmEnd = moment(eventDoc.data().dtmEnd.toDate()).format("MM/DD/YYYY");
                //event.startDateObject= moment(eventDoc.data().dtmStart.toDate());
                //event.endDateObject= moment(eventDoc.data().dtmEnd.toDate());
                eventList.push(event);
                //eventList[eventDoc.data().eventOrderIndex] = event;

            } else {
                let event = {
                    id: eventDoc.id,
                    title: eventDoc.data().title,
                    eventCode: eventDoc.data().eventCode,
                    eventOrderIndex: eventDoc.data().eventOrderIndex

                };
                eventList.push(event);
                //eventList[eventDoc.data().eventOrderIndex] = event;

            }
        });
        //eventList.sort((a, b) =>  b.eventOrderIndex - a.eventOrderIndex);
        callback(eventList, unsubscribe);
    });
}


function getEventElementParents(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/").where("elementIsDeleted", "==", false)
        .onSnapshot(querySnapShot => {
            let elementsList = [];
            querySnapShot.forEach(elementDoc => {

                let elementData = elementDoc.data();
                elementData.id = elementDoc.id;
                elementsList.push(elementData);
            });
            callback(elementsList, unsubscribe);
        });
}

function saveEventElementParent(eventId, elementData) {
    let elementDataToSave = elementData;
    elementDataToSave.elementIsDeleted = false;

    if (elementDataToSave.sType === "room") {
        elementDataToSave.visible = elementData.visible;
    }

    if (elementData.id === 0) {
        return firestore.collection("events/" + eventId + "/elements/").add(elementDataToSave);
    } else {
        return firestore.collection("events/" + eventId + "/elements/").doc(elementData.id).update(elementDataToSave);
    }

}

function getParentInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/").doc(elementId).onSnapshot(elementDoc => {
        let elementData = {
            id: elementDoc.id,
            title: elementDoc.data().title,
            sParentId: elementDoc.data().sParentId,
            sType: elementDoc.data().sType,
            roomIconColor: elementDoc.data().roomIconColor,

            currentLiveSession: elementDoc.data().currentLiveSession,
            currentQuestion: elementDoc.data().currentQuestion,
            currentQuestionElement: elementDoc.data().currentQuestionElement,
            currentSelectedLiveSession: elementDoc.data().currentSelectedLiveSession,
            bigScreenStatus: elementDoc.data().bigScreenStatus,
            currentTweetElement: elementDoc.data().currentTweetElement,
            tweetsParticipantsWriteEnable: elementDoc.data().tweetsParticipantsWriteEnable,
            currentSurveyElement: elementDoc.data().currentSurveyElement,
            singleScreenElementId: elementDoc.data().singleScreenElementId,
            currentLibraryElement: elementDoc.data().currentLibraryElement,
            currentEventInfo: elementDoc.data().currentEventInfo,
            selectedSessionsToCompare: elementDoc.data().selectedSessionsToCompare,
        }
        callback(elementData, unsubscribe);
    });
}

function getEventElementChildren(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").where("elementIsDeleted", "==", false)
        .onSnapshot(querySnapShot => {
            let childrenList = [];
            querySnapShot.forEach(elementDoc => {
                let elementData = elementDoc.data();
                elementData.id = elementDoc.id;
                childrenList.push(elementData);
            });
            callback(childrenList, unsubscribe);
        });
}

function getElementItemInfoOnChange(eventId, elementItemId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementItemId).onSnapshot(elementItemDoc => {
        let elementItemData = {
            id: elementItemDoc.id,
            title: elementItemDoc.data().title,
            elementIsDeleted: elementItemDoc.data().elementIsDeleted,
            iOrder: elementItemDoc.data().iOrder,
            sParentId: elementItemDoc.data().sParentId,
            sType: elementItemDoc.data().sType
        }
        callback(elementItemData, unsubscribe);
    });
}

function saveEventElementChild(eventId, elementData) {
    let elementDataToSave;
    if (elementData.sType === "survey") {
        elementDataToSave = {
            title: elementData.title,
            sParentId: elementData.sParentId,
            sType: elementData.sType,
            elementIsDeleted: false,
            iOrder: elementData.iOrder,
            surveyStatus: elementData.surveyStatus,
            showSurveyInMenu: elementData.showSurveyInMenu,
            startFullScreenMode: 'default',
            startInIframe: true,
            language: elementData.language
        }
    } else if (elementData.sType === "library") {
        elementDataToSave = {
            title: elementData.title,
            sParentId: elementData.sParentId,
            sType: elementData.sType,
            elementIsDeleted: false,
            iOrder: elementData.iOrder,
            showLibraryInMenu: elementData.showLibraryInMenu,
            language: elementData.language
        }
    } else if (elementData.sType === "qrcode") {
        elementDataToSave = {
            title: elementData.title,
            sParentId: elementData.sParentId,
            sType: elementData.sType,
            elementIsDeleted: false,
            iOrder: elementData.iOrder,
            showQRCodeInMenu: elementData.showQRCodeInMenu,
            qrText: elementData.qrText,
            language: elementData.language
        }
    } else {
        elementDataToSave = {
            title: elementData.title,
            sParentId: elementData.sParentId,
            sType: elementData.sType,
            elementIsDeleted: false,
            iOrder: elementData.iOrder,
            language: elementData.language
        };
    }

    if (elementData.id === 0) {
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems").add(elementDataToSave);
    } else {
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementData.id).update(elementDataToSave);
    }

}

function updateElementData(eventId, type, parentNode, childNode) {
    let value = childNode;
    switch (type) {
        case "updateChildElementParentNode":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(childNode.id).update({sParentId: parentNode.id});

        case "updatePreviousParentNodeQuestionFields":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id)
                .update({
                    currentQuestion: childNode.currentQuestion,
                    currentQuestionElement: childNode.currentQuestionElement,
                    currentLiveSession: childNode.currentLiveSession,
                    currentSelectedLiveSession: childNode.currentSelectedLiveSession,
                    bigScreenStatus: childNode.bigScreenStatus,
                });

        case "updateNewParentNodeQuestionFields":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id)
                .update({
                    currentQuestion: childNode.currentQuestion,
                    currentQuestionElement: childNode.currentQuestionElement,
                    currentLiveSession: childNode.currentLiveSession,
                    currentSelectedLiveSession: childNode.currentSelectedLiveSession,
                    bigScreenStatus: childNode.bigScreenStatus,
                });

        case "updatePreviousParentNodeTweetField":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id)
                .update({
                    currentTweetElement: "default"
                });

        case "updateNewParentNodeTweetField":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id)
                .update({
                    currentTweetElement: childNode.id
                });

        case "deleteParentItem":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({elementIsDeleted: true});

        case "deleteChildItem":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(childNode.id).update({elementIsDeleted: true});

        case "setIsOpenField-true":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({isOpen: true});

        case "setIsOpenField-false":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({isOpen: false});

        case "updateCurrentQuestionElement":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({
                // currentQuestion: value.questionId,
                currentQuestionElement: value.questionId,
                currentSelectedLiveSession: value.selectedVotingSessionId
            });

        case 'updateCurrentQuestionElementDelete':
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentSelectedLiveSession: value.selectedVotingSessionId,
                // bigScreenStatus: value.bigScreenStatus
            });

        case "updateCurrentTweet": {
            if (childNode !== "default") {
                return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({currentTweetElement: childNode.id});
            } else {
                return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({currentTweetElement: 'default'});
            }
        }

        case "updateTweetsParticipantsWriteEnable-set":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({tweetsParticipantsWriteEnable: true});

        case "updateTweetsParticipantsWriteEnable-reset":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({tweetsParticipantsWriteEnable: false});

        case "updateCurrentLibrary":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({
                currentLibraryElement: {
                    id: value.id,
                    title: value.title
                }
            });

        case "updateCurrentEventInfo":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({currentEventInfo: childNode.id});

        case "updateCurrentSurveyStop":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({currentSurveyElement: "default"});

        case "updateCurrentSurveyLive":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.id).update({currentSurveyElement: childNode});

        case "updateSurveyTypeExternal":
            // var value = {roomId: this.state.parentInfo.sParentId, title: this.state.parentInfo.title, id:this.state.parentInfo.id }

            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode.roomId)
                .update({currentSurveyElement: {title: parentNode.title, id: parentNode.id, type: 'external'}});

        case "singleScreen-tweet":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({singleScreenElementId: childNode});

        case "setArchivedQuestion":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentQuestion: childNode.currentQuestion,
                currentQuestionElement: childNode.currentQuestionElement,
                currentSelectedLiveSession: 'default',
                // currentLiveSession: {status:'default', id:'default'},     
                // showCorrectAnswerColor: 'default'   
            });

        case "updateCurrentQuestionLiveOnDelete":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentQuestion: 'default',
                currentSelectedLiveSession: 'default',
                currentLiveSession: 'default',
                currentQuestionElement: 'default'
            });

        case "updateCurrentQuestionOnDelete":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentQuestion: 'default',
            });

        case "updateLiveQuestionSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentLiveSession: {
                    status: value.currentLiveSession.status,
                    id: value.currentLiveSession.id,
                    liveQuestionId: value.currentLiveSession.liveQuestionId,
                    liveQuestionElement: value.currentLiveSession.liveQuestionElement
                },
            });


        case "OneUpdateBigScreenStatus&CurrentSelectedLiveSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                singleScreenElementId: value.currentQuestionElement,
                currentSelectedLiveSession: value.votingsessionSelected,
                bigScreenStatus: value.bigScreenStatus,
            });

        case "OneUpdateBigScreenStatus-compareResults":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                singleScreenElementId: value.currentQuestionElement + "-compareResults",
                bigScreenStatus: value.bigScreenStatus,
                selectedSessionsToCompare: value.selectedSessionsToCompare,
            });

        case "TwoUpdateBigScreenStatus&CurrentSelectedLiveSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentSelectedLiveSession: value.votingsessionSelected,
                bigScreenStatus: value.bigScreenStatus,
            });

        case "updateCurrentSelectedLiveSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                currentSelectedLiveSession: value.votingsessionSelected,

            });

        case "OneUpdateBigScreenStatus&CurrentLiveSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                // currentLiveSession: value.liveSession,
                // currentLiveSession: {status:value.statusLiveSession, id:value.liveSession},  
                singleScreenElementId: value.currentQuestionElement,
                bigScreenStatus: value.bigScreenStatus,
            });

        case "TwoUpdateBigScreenStatus&CurrentLiveSession":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                // currentLiveSession: value.liveSession,
                //currentLiveSession: {status:value.statusLiveSession, id:value.liveSession},   
                bigScreenStatus: value.bigScreenStatus,
            });


        case "updateShowIntroductionPage":
            return firestore.collection("events/" + eventId + "/elements/").doc(parentNode).update({
                'showIntroductionPage': value.status
            });

        default:
            ;
    }
}

function checkCodeAvailability(eventCode) {
    return firestore.collection("events").where("eventCode", "==", eventCode).where('eventIsDeleted', "==", false).get();
}

function saveEventCode(eventId, eventCode) {
    if (eventId === 0) {
        //The new event has been created by the user so we create a new event document to save the event code
        return firestore.collection("events").add({eventCode: eventCode});
    } else {
        return firestore.collection("events").doc(eventId).update({eventCode: eventCode});
    }
}

function saveEvent(userId, event) {
    var eventData;

    if (event.id === 0) {
        eventData = event;
        eventData.eventIsDeleted = false;
        eventData.userId = userId;
        eventData.dtmStart = moment(event.dtmStart).toDate();
        eventData.dtmEnd = moment(event.dtmEnd).toDate();
        eventData.eventLogoUrl = "default";
        eventData.splashImageUrl = "default";
        eventData.homeImageUrl = "default";
        eventData.themeBigScreenImageUrl = "default";
        eventData.themeParticipantImageUrl = "default";
        eventData.showCorrectAnswerColor = 'default';
        eventData.showIntroductionPage = 'Yes';
        eventData.currentTweetElement = "default";
        eventData.currentSurveyElement = "default";
        eventData.currentQuestionElement = "default";
        eventData.currentLiveSession = {status: "default", id: "default"};
        eventData.currentQuestion = "default";
        eventData.bigScreenStatus = "default";
        eventData.currentSelectedLiveSession = "default";
        eventData.showProfileToParticipants = "default";
        eventData.showEmailToParticipants = "default";
        eventData.availableLanguages = event.availableLanguages;

        //We need to add this document as a new document in the firebase so we use add method
        return firestore.collection("events").add(eventData);
    } else {
        eventData = event;
        eventData.eventIsDeleted = false;
        eventData.userId = userId;
        eventData.dtmStart = moment(event.dtmStart).toDate();
        eventData.dtmEnd = moment(event.dtmEnd).toDate();
        //We need to update the existing document so we use the update method
        return firestore.collection("events").doc(event.id).update(eventData);
    }
};

function deleteEvent(userId, event) {

    //we add a field to the email which shows the user has been deleted it instead of deleting it
    //As currently is not possiblie to move a collection with alll its sub collections to another collection
    return firestore.collection("events").doc(event.id).update({eventIsDeleted: true});
}

function getEventInfoOnChange(eventId, callback) {
    let unsubscribe = firestore.collection("events").doc(eventId).onSnapshot(function (eventDoc) {
        let event = eventDoc.data();
        event.id = eventDoc.id;
        event.dtmStart = moment(eventDoc.data().dtmStart.toDate()).format("MM/DD/YYYY");
        event.dtmEnd = moment(eventDoc.data().dtmEnd.toDate()).format("MM/DD/YYYY");
        //event.startDateObject= moment(eventDoc.data().dtmStart.toDate());
        //event.endDateObject= moment(eventDoc.data().dtmEnd.toDate());
        callback(event, unsubscribe);
    });
}

function getSurveyInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementId).onSnapshot(function (surveyDoc) {
        let surveyElement = surveyDoc.data();
        surveyElement.id = surveyDoc.id;
        callback(surveyElement, unsubscribe);
    });
}

function getLibraryInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementId).onSnapshot(function (libraryDoc) {
        let libraryElement = libraryDoc.data();
        libraryElement.id = libraryDoc.id;
        callback(libraryElement, unsubscribe);
    });
}

function getQRCodeInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementId).onSnapshot(function (qrcodeDoc) {
        let qrcodeElement = qrcodeDoc.data();
        qrcodeElement.id = qrcodeDoc.id;
        callback(qrcodeElement, unsubscribe);
    });
}

function setLiveTweetList(eventId, liveTweetsIds) {
    //we remove the live question fields and update the tweet fields
    firestore.collection("events/").doc(eventId).update({
        currentLiveSession: 'default',
        currentQuestion: 'default',
        currentQuestionElement: 'default',
        currentSelectedLiveSession: 'default'
    });
    return firestore.collection("events/").doc(eventId).update({LiveTweetsIds: liveTweetsIds});
}


function getAllTweets(eventId, tweetIdEl, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets").orderBy("dtmCreated", "asc").onSnapshot(querySnapShot => {
        let tweetsList = [];
        var tweetDate;
        if (querySnapShot.empty === true) {
            callback(tweetsList, unsubscribe);
        } else {
            querySnapShot.forEach(tweetDoc => {
                // because we have already some tweeets in the events that they don't have the elementIsDeleted field we need to receive the tweets in this way
                if (!tweetDoc.data().elementIsDeleted || tweetDoc.data().elementIsDeleted && tweetDoc.data().elementIsDeleted === false) {
                    if (tweetDoc.data().dtmCreated && tweetDoc.data().dtmCreated.toDate()) {
                        tweetDate = moment(tweetDoc.data().dtmCreated.toDate()).format("YYYY.MMM.DD - HH:mm");
                    } else {
                        tweetDate = "not available";
                    }
                    let tweet = tweetDoc.data();
                    tweet.id = tweetDoc.id;
                    tweetsList.push(tweet);
                }
            });
            callback(tweetsList, unsubscribe);
        }
    });
}

function updateTweetFields(eventId, tweetIdEl, tweetId, field) {
    switch (field) {
        case 'Approved':
            var dtmApproved = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({
                bApproved: true,
                dtmApproved: dtmApproved
            });

        case 'unApproved':
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({
                bApproved: false,
                live: false
            });

        case 'liveTrue':
            var dtmLive = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({
                live: true,
                dtmLive: dtmLive
            });

        case 'liveFalse':
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({live: false});

        case 'Archived':
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({
                bArchived: true,
                live: false
            });

        case 'unArchived':
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({bArchived: false});

        case "editedTweetText":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId.id).update({sText: tweetId.sText});


    }
}

function deleteTweet(eventId, tweetIdEl, tweetId) {
    // return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).delete();
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").doc(tweetId).update({elementIsDeleted: true});
}

function deleteMessage(eventId, messageId) {
    return firestore.collection("events/" + eventId + "/messages/").doc(messageId).update({elementIsDeleted: true});
}

function pushMessage(eventId, messageId, bPush) {
    return firestore.collection("events/" + eventId + "/messages/").doc(messageId).update({pushed: bPush});
}

function saveNewTweet(user, eventId, tweetIdEl, tweetInputText, tweetInputName) {
    let tweet = {
        bApproved: false,
        bArchived: false,
        bShown: false,
        dtmCreated: firebase1.firestore.FieldValue.serverTimestamp(),
        userId: user.userId,
        participantDefinedName: tweetInputName,
        sText: tweetInputText,
        elementIsDeleted: false,
    };
    Object.assign(user, {'participantDefinedName': tweetInputName});
    localStorage.setItem("user", JSON.stringify(user));
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").add(tweet);
}

function updateCurrentField(option, eventId, value) {
    switch (option) {

        case "updateCurrentSurvey":
            return firestore.collection("events/").doc(eventId).update({currentSurveyElement: value});

        case "updateShowIntroductionPage":
            return firestore.collection("events/").doc(eventId).update({
                showIntroductionPage: value
            });


        case "updateCurrentQuestion":
            //we receive the previous document that has the current value of 1, make it zero and then update the new one
            return firestore.collection("events/").doc(eventId).update({
                //currentLiveSession: value.votingId,
                currentQuestion: value.questionId,
                currentQuestionElement: value.idQuestionEl,
                currentSelectedLiveSession: value.selectedVotingSessionId

            });

        case "updateLiveQuestionSession":
            return firestore.collection("events/").doc(eventId).update({
                currentLiveSession: {
                    status: value.currentLiveSession.status,
                    id: value.currentLiveSession.id,
                    liveQuestionId: value.currentLiveSession.liveQuestionId,
                    liveQuestionElementL: value.currentLiveSession.liveQuestionElement
                },
            });

        case "setArchivedQuestion":

            return firestore.collection("events/").doc(eventId).update({
                currentQuestion: value.questionId,
                currentSelectedLiveSession: 'default',
                // currentLiveSession: {status:'default', id:'default'},     
                // showCorrectAnswerColor: 'default'


            });

        case "updateCurrentQuestionOnDelete":
            return firestore.collection("events/").doc(eventId).update({
                currentQuestion: 'default',
                //currentLiveSession: 'default',
                currentLiveSession: {status: 'default', id: 'default'},
                currentSelectedLiveSession: 'default',
                bigScreenStatus: 'default',
                showCorrectAnswerColor: 'default'

            });

        case "updateCurrentSurveyQuestionOnDelete":
            return firestore.collection("events/").doc(eventId).update({
                currentSurveyElement: 'default',
            });


        case "updateCurrentTweet":
            return firestore.collection("events/").doc(eventId).update({currentTweetElement: value.tweetId});


        case "tweet":
            return firestore.collection("events/").doc(eventId).update({currentTweetElement: "default"});

        case "survey":
            return firestore.collection("events/").doc(eventId).update({currentSurveyElement: "default"});

        case "question":
            return firestore.collection("events/").doc(eventId).update({
                currentQuestion: "default",
                //currentLiveSession: "default", 
                currentLiveSession: {status: 'default', id: 'default'},
                currentQuestionElement: "default", currentSelectedLiveSession: 'default'
            });

        case "liveScreen-question":
            return firestore.collection("events/").doc(eventId).update({
                screenNumber: "One-" + value,
                showIntroductionPage: "No"
            });

        case "liveScreen-tweet":
            return firestore.collection("events/").doc(eventId).update({
                screenNumber: "One-" + value,
                showIntroductionPage: "No"
            });

        case "voteResultsDisplay":
            return firestore.collection("events/").doc(eventId).update({voteResultsDisplay: value});

        case "tweetsToShow":
            return firestore.collection("events/").doc(eventId).update({tweetsToShow: value});

        // case "OneUpdateBigScreenStatus&CurrentSelectedLiveSession":
        //     return firestore.collection("events/").doc(eventId).update({ 
        //         screenNumber: "One-" + value.currentQuestionElement,
        //         bigScreenStatus: value.bigScreenStatus,
        //         showIntroductionPage:"No"
        //     });

        case "TwoUpdateBigScreenStatus&CurrentSelectedLiveSession":
            return firestore.collection("events/").doc(eventId).update({
                currentSelectedLiveSession: value.votingsessionSelected,
                bigScreenStatus: value.bigScreenStatus,
                showIntroductionPage: "No"
            });

        case "updateCurrentSelectedLiveSession":
            return firestore.collection("events/").doc(eventId).update({
                currentSelectedLiveSession: value.votingsessionSelected,
                showIntroductionPage: "No"

            });

        // case "OneUpdateBigScreenStatus&CurrentLiveSession":
        // return firestore.collection("events/").doc(eventId).update({ 
        //    // currentLiveSession: value.liveSession,
        //    // currentLiveSession: {status:value.statusLiveSession, id:value.liveSession},  
        //    screenNumber: "One-" + value.currentQuestionElement,
        //    bigScreenStatus: value.bigScreenStatus,
        //    showIntroductionPage:"No"
        //  });

        case "TwoUpdateBigScreenStatus&CurrentLiveSession":
            return firestore.collection("events/").doc(eventId).update({
                // currentLiveSession: value.liveSession,
                //currentLiveSession: {status:value.statusLiveSession, id:value.liveSession},   
                bigScreenStatus: value.bigScreenStatus,
                showIntroductionPage: "No"
            });

        case "showCorrectAnswerColor":
            return firestore.collection("events/").doc(eventId).update({
                'showCorrectAnswerColor': value
            });

        case "updateShowIntroductionPage":
            return firestore.collection("events/").doc(eventId).update({
                'showIntroductionPage': value.status
            });


    }
}

function uploadEventLogo(userId, eventId, type, imageFile, callback) {
    //we update the url link of the event's logo
    //UPLOAD LOGO TO FIREBASE STORAGE
    //Now that we have the event id in the firestore so we also upload the logo to the firebase storage
    // var imagesRef = storageRef.child('logos/User-' + userId + '/Event-' + eventId);
    var storageRef = firebase.storage().ref();
    var metadata = {
        contentType: 'image/jpeg',
    };
    var imagesRef;
    switch (type) {
        case "LOGO":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/LogoImage');
            imagesRef.put(imageFile, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({eventLogoUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;

        case "SPLASH":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/SplashImage');
            imagesRef.put(imageFile, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({splashImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;

        case "SPLASH&LOGO":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/LogoImage');
            imagesRef.put(imageFile.LOGO, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({eventLogoUrl: downloadURL});
                    callback({type: "LOGO", url: downloadURL});
                });
            });

            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/SplashImage');
            imagesRef.put(imageFile.SPLASH, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({splashImageUrl: downloadURL});
                    callback({type: "SPLASH", url: downloadURL});
                });
            });
            break;

        case "HOME":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/HomeImage');
            imagesRef.put(imageFile, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({homeImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;
    
        case "ThemeBigscreen":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/ThemeBigscreen');
            imagesRef.put(imageFile, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({themeBigScreenImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;

        case "ThemeParticipant":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/ThemeParticipant');
            imagesRef.put(imageFile, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({themeParticipantImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;

        case "ThemeParticipant&BigScreen":
            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/ThemeBigscreen');
            imagesRef.put(imageFile.ThemeBigscreen, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({themeBigScreenImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });

            imagesRef = storageRef.child('Images/User-' + userId + '/Event-' + eventId + '/ThemeParticipant');
            imagesRef.put(imageFile.ThemeParticipant, metadata).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firestore.collection("events").doc(eventId).update({themeParticipantImageUrl: downloadURL});
                    callback(downloadURL);
                });
            });
            break;

        case "ThemeParticipant-REMOVE":
            firestore.collection("events").doc(eventId).update({themeParticipantImageUrl: "default"});
            callback({type: "LOGO", url: "default"});
            break;

        case "ThemeBigscreen-REMOVE":
            firestore.collection("events").doc(eventId).update({themeBigScreenImageUrl: "default"});
            callback({type: "LOGO", url: "default"});
            break;

        case "ThemeParticipant&BigScreen-REMOVE":
            firestore.collection("events").doc(eventId).update({
                themeParticipantImageUrl: "default",
                themeBigScreenImageUrl: "default"
            });
            callback({type: "LOGO", url: "default"});
            break;

        case "LOGO-REMOVE":
            firestore.collection("events").doc(eventId).update({eventLogoUrl: "default"});
            callback({type: "LOGO", url: "default"});
            break;

        case "SPLASH-REMOVE":
            firestore.collection("events").doc(eventId).update({splashImageUrl: "default"});
            callback({type: "SPLASH", url: "default"});
            break;

        case "SPLASH&LOGO-REMOVE":
            firestore.collection("events").doc(eventId).update({eventLogoUrl: "default", splashImageUrl: "default"});
            callback({type: "SPLASH&LOGO-REMOVE", url: "default"});
            break;

        case "HOME-REMOVE":
            firestore.collection("events").doc(eventId).update({homeImageUrl: "default"});
            callback({type: "HOME", url: "default"});
            break;            
        
        default:
            return true;
    }
}

function saveLibraryFile(eventId, libraryId, fileData, type) {
    /* if (type === "newFile") {
        fileData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files/").add(fileData);
    } else {
        return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files/").doc(fileData.id).update({ name: fileData.name });
    } */
    switch (type) {
        case "newFile":
            fileData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files/").add(fileData);

        case "nameEdited":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files/").doc(fileData.id).update({name: fileData.name});

        case "showLibraryInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(libraryId).update({showLibraryInMenu: true});

        case "hideLibraryInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(libraryId).update({showLibraryInMenu: false});

        case "saveEditedLibraryName":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(libraryId).update({title: fileData});

        default:
            return true;
    }
}

function saveAgendaFile(eventId, agendaId, fileData, type) {

    switch (type) {
        case "newFile":
            fileData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files/").add(fileData);

        case "nameEdited":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files/").doc(fileData.id).update({name: fileData.name});

        case "languageEdited":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files/").doc(fileData.id).update({language: fileData.language});

        case "saveAgendaLink":
            fileData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files/").add(fileData);

        default:
            return true;
    }
}

function setElementLanguage(eventId, elementId, lang) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(elementId).update({language: lang});
}

function saveSurveyFile(eventId, surveyId, fileData, type) {
    switch (type) {
        case "nameEdited":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + surveyId + "/files/").doc(fileData.id).update({name: fileData.name});

        case "saveSurveyLink":
            fileData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + surveyId + "/files/").add(fileData);

        case "saveEditedSurveyName":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({title: fileData});

        case "saveEditedSurveyPopupMessage":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({popupmessage: fileData});

        case "showSurveyInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({showSurveyInMenu: true});

        case "hideSurveyInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({showSurveyInMenu: false});

        case "makeLiveSurvey":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({surveyStatus: "live"});

        case "stopSurvey":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({surveyStatus: "stop"});

        case "startFullScreenMode":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({startFullScreenMode: fileData});

        case "startNoFullScreenMode":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({startFullScreenMode: fileData});

        case "startInIframe":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(surveyId).update({startInIframe: fileData});

        default:
            return true;
    }
}

function saveQRCodeFile(eventId, qrcodeId, fileData, type) {
    switch (type) {
        case "saveEditedQRCodeName":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(qrcodeId).update({title: fileData});

        case "saveQRText":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(qrcodeId).update({qrText: fileData});

        case "showQRCodeInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(qrcodeId).update({showQRCodeInMenu: true});

        case "hideQRCodeInMenu":
            return firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(qrcodeId).update({showQRCodeInMenu: false});

        default:
            return true;
    }
}

function uploadLibraryFile(userId, eventId, elementId, itemId, type, file, callback) {
    //we update the url link of the event's logo
    //UPLOAD LOGO TO FIREBASE STORAGE
    //Now that we have the event id in the firestore so we also upload the logo to the firebase storage
    // var imagesRef = storageRef.child('logos/User-' + userId + '/Event-' + eventId);
    var storageRef = firebase.storage().ref();
    // var metadata = {
    //     contentType: 'image/jpeg',
    // };
    // var metadata = {
    //     contentType: file.type,
    // };
    var filesRef;
    if (type === "ImageFile") {
        filesRef = storageRef.child('Library-Files/Event-' + eventId + '/Library-' + elementId + '/LibraryFiles-' + itemId);
        // filesRef.put(file, metadata).then(function (snapshot) {
        filesRef.put(file).then(function (snapshot) {

            snapshot.ref.getDownloadURL().then(function (downloadURL) {
                firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/files/").doc(itemId).update({fileUrl: downloadURL});
                callback(downloadURL);
            });
        });
    }
}

function uploadAgendaFile(userId, eventId, elementId, itemId, type, file, callback) {
    var storageRef = firebase.storage().ref();
    var filesRef;
    filesRef = storageRef.child('Agenda-Files/Event-' + eventId + '/Agenda-' + elementId + '/AgendaFiles-' + itemId);
    filesRef.put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/files/").doc(itemId).update({fileUrl: downloadURL});
            callback(downloadURL);
        });
    });
}


function uploadSurveyFile(userId, eventId, elementId, itemId, type, file, callback) {
    var storageRef = firebase.storage().ref();
    var filesRef;
    filesRef = storageRef.child('Survey-Files/Event-' + eventId + '/Survey-' + elementId + '/SurveyFiles-' + itemId);
    filesRef.put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/files/").doc(itemId).update({fileUrl: downloadURL});
            callback(downloadURL);
        });
    });
}

function getLibraryFiles(eventId, libraryId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files").onSnapshot(querySnapShot => {
        let libraryFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            // libraryFilesList.push(file);
            libraryFilesList[fileDoc.data().fileOrderIndex] = file;
        });
        callback(libraryFilesList, unsubscribe);
    });
}

function getAgendaFiles(eventId, agendaId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files").onSnapshot(querySnapShot => {
        let agendaFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            agendaFilesList[fileDoc.data().fileOrderIndex] = file;
        });
        callback(agendaFilesList, unsubscribe);
    });
}

function getAgendaInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementId).onSnapshot(function (agendaDoc) {
        let agendaElement = agendaDoc.data();
        agendaElement.id = agendaDoc.id;
        callback(agendaElement, unsubscribe);
    });
}

function getSurveyFiles(eventId, surveyId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + surveyId + "/files").onSnapshot(querySnapShot => {
        let surveyFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            // surveyFilesList[fileDoc.data().fileOrderIndex] = file;
            surveyFilesList.push(file);
        });
        callback(surveyFilesList, unsubscribe);
    });
}

function removeLibraryFile(eventId, libraryId, fileId) {
    var storageRef = firebase.storage().ref();
    var filesRef = storageRef.child('Library-Files/Event-' + eventId + '/Library-' + libraryId + '/LibraryFiles' + fileId);
    filesRef.delete();
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files").doc(fileId)
        .delete();

}

function updateLibraryFileIndexes(eventId, libraryId, newSortedFilesList, callback) {

    for (var index = 0; index < newSortedFilesList.length; index++) {
        if (newSortedFilesList[index]) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files/").doc(newSortedFilesList[index].id).update({fileOrderIndex: index});
        }
    }
    callback('success');
}

function updateEventsIndexes(newSortedEventListt, callback) {

    for (var index = 0; index < newSortedEventListt.length; index++) {
        if (newSortedEventListt[index]) {
            firestore.collection("events/").doc(newSortedEventListt[index].id).update({eventOrderIndex: index});
        }
    }
    callback('success');
}

function sendNewMessage(eventId, message) {
    return firestore.collection("events/" + eventId + "/messages").add({
        title: message.title,
        body: message.body,
        messageImportance: message.messageImportance,
        userId: message.userId,
        dtmMessage: firebase1.firestore.FieldValue.serverTimestamp(),
        elementIsDeleted: false,
        pushed: false
    });
}

function getMessageList(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/messages").where("elementIsDeleted", "==", false).orderBy("dtmMessage", "desc").onSnapshot(querySnapShot => {
        let messageList = [];
        querySnapShot.forEach(messageDoc => {
            let message = messageDoc.data();
            message.id = messageDoc.id;
            message.dtmMessage = messageDoc.data().dtmMessage;
            messageList.push(message);
        });
        callback(messageList, unsubscribe);
    });
}

function getEventInfoFiles(eventId, eventInfoElementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + eventInfoElementId + "/eventInfoFiles").onSnapshot(querySnapShot => {
        let eventInfoFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            eventInfoFilesList.push(file);
            // eventInfoFilesList[fileDoc.data().fileOrderIndex] = file;
        });
        callback(eventInfoFilesList, unsubscribe);
    });
}

function saveEventInfofile(eventId, eventInfoElementId, eventInfoData, file, type, callback) {
    if (type === "newFile") {

        var storageRef = firebase.storage().ref();
        var metadata = {
            contentType: 'image/jpeg',
        };
        var imagesRef;
        imagesRef = storageRef.child('EventInfoFiles/Event-' + eventId + '/EventInfo-' + eventInfoElementId);
        imagesRef.put(file, metadata).then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
                eventInfoData.eventFileUrl = downloadURL;
                eventInfoData.dtmCreated = firebase1.firestore.FieldValue.serverTimestamp();
                firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + eventInfoElementId + "/eventInfoFiles/").add(eventInfoData);
                callback(eventInfoData);
            });
        });

    } else {
        // return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + eventInfoElementId + "/files/").doc(fileData.id).update({name:fileData.name});
    }

}

function getParticipantsList(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/participants").orderBy("dtmLastLogin", "desc").onSnapshot(querySnapShot => {
        let participantsList = [];
        querySnapShot.forEach(participantDoc => {
            let participant = participantDoc.data();
            participant.id = participantDoc.id;
            participantsList.push(participant);
        });
        callback(participantsList, unsubscribe);
    });
}

function shareEventEmailCheck(email, callback) {
    return firestore.collection("/users").where('sEmail', '==', email).get();
}

function updateSharedEventsData(type, eventId, userData, sharedUserData, callback) {
    //return firestore.collection("events/"+eventId+"sharedUsers").where('userId', '==', sharedUserData.id).update
    const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
    switch (type) {
        case "add":
            firestore.collection("events/" + eventId + "/sharedUsers/").add({
                userId: sharedUserData.id,
                email: sharedUserData.sEmail,
                itemIsDeleted: false,
                shareTime: timestamp
            }).then(result => {
                firestore.collection("users/" + sharedUserData.id + "/sharedEvents").add({
                    userId: userData.id,
                    email: userData.sEmail,
                    eventId: eventId,
                    itemIsDeleted: false,
                    shareTime: timestamp
                })
            }).then(result2 => {
                callback("success");
            }).catch(function (error) {
                console.error("Error writing document: ", error);
                callback("Error writing document");
            });
            return true;

        case "cancelShare":
            firestore.collection("events/" + eventId + "/sharedUsers/").doc(sharedUserData.id).update({
                itemIsDeleted: true,
                cancelShareTime: timestamp
            }).then(result => {
                firestore.collection("users/" + sharedUserData.userId + "/sharedEvents/").where("eventId", "==", eventId).get().then(querySnapShot => {
                    querySnapShot.forEach(sharedeventDoc => {
                        firestore.collection("users/" + sharedUserData.userId + "/sharedEvents").doc(sharedeventDoc.id).update({
                            itemIsDeleted: true,
                            cancelShareTime: timestamp
                        }).then(result2 => {
                            callback("success");
                        }).catch(function (error) {
                            callback("Error writing document");
                        });
                    })
                })
            })


        default:
            return true;
    }
}

function getListOfSharedEmails(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/sharedUsers").where("itemIsDeleted", "==", false).onSnapshot(sharedEmails => {
        var sharedEmailsList = [];
        sharedEmails.forEach(function (doc) {
            let shareUserData = doc.data();
            shareUserData.id = doc.id;
            sharedEmailsList.push(shareUserData);
        });
        callback(sharedEmailsList, unsubscribe);
    });
}


function sharedEventsList(userId, callback) {
    let unsubscribe = firestore.collection("users/" + userId + "/sharedEvents").where("itemIsDeleted", "==", false).onSnapshot(querySnapShot => {
        var sharedEventsList = [];
        if (querySnapShot.docs.length > 0) {
            querySnapShot.forEach(eventItem => {
                firestore.collection("events").doc(eventItem.data().eventId).get().then(eventDoc => {
                    if (eventDoc.data().eventIsDeleted === false) {
                        let event = eventDoc.data();
                        event.id = eventDoc.id;
                        event.dtmStart = moment(eventDoc.data().dtmStart.toDate()).format("MM/DD/YYYY");
                        event.dtmEnd = moment(eventDoc.data().dtmEnd.toDate()).format("MM/DD/YYYY");
                        //event.startDateObject= moment(eventDoc.data().dtmStart.toDate());
                        //event.endDateObject= moment(eventDoc.data().dtmEnd.toDate());
                        sharedEventsList.push(event);
                    }
                    callback(sharedEventsList, unsubscribe);
                })

            })
        } else {
            callback(sharedEventsList, unsubscribe);
        }

    })

}


function updateEventElementIndexes(eventId, newSortedEventElementsList, callback) {

    for (var index = 0; index < newSortedEventElementsList.length; index++) {
        if (newSortedEventElementsList[index] && (newSortedEventElementsList[index].sType === 'folder' || newSortedEventElementsList[index].sType === 'room')) {
            firestore.collection("events/" + eventId + "/elements/").doc(newSortedEventElementsList[index].id).update({iOrder: index});
        } else {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(newSortedEventElementsList[index].id).update({iOrder: index});
        }
    }
    callback('success');

}

async function updateServerHeartBeatCounter(eventId) {
    let result = await firestore.collection("events/" + eventId + "/elements/default/heartBeat/").doc("server").get();
    let newheartbeat = result.data();
    newheartbeat.counter = newheartbeat.counter + 1;
    await firestore.collection("events/" + eventId + "/elements/default/heartBeat/").doc("server").update(newheartbeat);
}


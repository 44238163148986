import React, {Component} from 'react';
import styled from 'styled-components';
import Tree from './Tree';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import EditModal from '../Modals/EditModal';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Header from '../Components/Header';
import HeaderTab from '../Components/HeaderTab';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';

const StyledFileExplorer = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
`;

const TreeWrapper = styled.div`
  width: 250px;
`;

class FileExplorer extends Component {

    state = {

        selectedFile: null,
        eventId: this.props.match.params.idevent,
        currentEvent: 'default',
        user: ''

    };

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));

		if (this.unsubscribeGetEventInfoOnChange) {
			this.unsubscribeGetEventInfoOnChange();
		}
        //in the first initialization of the page we set the current event for the header and the other components
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
			this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, user: user, currentEvent: callback});

            
            if (this.unsubscribeGetEventElementParents) {
                this.unsubscribeGetEventElementParents();
            }
            this.props.onGetEventElementParents(callback.id, (parentsList, unsubscribe) => {
                this.unsubscribeGetEventElementParents = unsubscribe;
                if (this.unsubscribeGetEventElementChildren) {
                    this.unsubscribeGetEventElementChildren();
                }
                this.props.onGetEventElementChildren(callback.id, (childrenList, unsubscribe) => {
                    this.unsubscribeGetEventElementChildren = unsubscribe;
                    for (var m = 0; m < parentsList.length; m++) {
                        parentsList[m].children = [];
                        for (var n = 0; n < parentsList.length; n++) {
                            if (parentsList[n].sParentId === parentsList[m].id) {
                                parentsList[m].children.push(parentsList[n]);
                            }
                        }
                        parentsList[m].children.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                    }
    
                    for (var index = 0; index < parentsList.length; index++) {
                        //get default element children
                        for (var i = 0; i < childrenList.length; i++) {
                            if (childrenList.length > 0 && childrenList[i].sParentId === parentsList[index].id) {
                                parentsList[index].children.push(childrenList[i]);
                            }
                        }
                        parentsList[index].children.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                    }
    
                    parentsList.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                    this.setState({ ...this.state, nodes: parentsList });
        });
            });        
            
    
        });

    }

	componentWillUnmount() {
		if (this.unsubscribeGetEventInfoOnChange) {
			this.unsubscribeGetEventInfoOnChange();
		}

        if (this.unsubscribeGetEventElementParents) {
            this.unsubscribeGetEventElementParents();
        }

        if (this.unsubscribeGetEventElementChildren) {
            this.unsubscribeGetEventElementChildren();
        }        
	}

	onSelect = (file) => this.setState({selectedFile: file});

    //we need to show the item from here because of the push function works only on the parent page with the url not components
    showItem(node, parentItem) {
        if (parentItem.sType === "default") {
            parentItem.sType = 'root'
        }

        if (node.sType === "question") {

            if (node.id !== parentItem.currentQuestionElement) {
                let value = {
                    questionId: node.id,
                    votingId: "default",
                    statusLiveSession: 'default',
                    selectedVotingSessionId: "default"
                }
                this.props.onUpdateElementData(this.state.currentEvent.id, "updateCurrentQuestionElement", parentItem, value, callback => {
                    this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/question/" + node.id + "/");
                });
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/question/" + node.id + "/");
            } else {
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/question/" + node.id + "/");
            }

        } else if (node.sType === "tweet") {

            if (node.id !== parentItem.currentTweetElement) {
                /* FC: Removed code. Start/Stop of Tweet Sessions are handled inside Tweet Admin screen.
                this.props.onUpdateElementData(this.state.currentEvent.id, "updateCurrentTweet", parentItem, node, callback => {
                    this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/tweet/" + node.id + "/");
                });
                */
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/tweet/" + node.id + "/");
            } else {
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/tweet/" + node.id + "/");
            }

        } else if (node.sType === "library") {

            var value = {id: node.id, title: node.title}
            this.props.onUpdateElementData(this.state.currentEvent.id, "updateCurrentLibrary", parentItem, value, callback => {
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/library/" + node.id + "/");
            });

        } else if (node.sType === "eventInfo") {
            if (node.id !== parentItem.currentEventInfo) {
                this.props.onUpdateElementData(this.state.currentEvent.id, "updateCurrentEventInfo", parentItem, node, callback => {
                    this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/eventInfo/" + node.id + "/");
                });
            } else {
                this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/eventInfo/" + node.id + "/");
            }
        } else if (node.sType === "survey") {
            this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/survey/" + node.id + "/");
        } else if (node.sType === "agenda") {
            this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/agenda/" + node.id + "/");
        } else if (node.sType === "qrcode") {
            this.props.history.push("/event/" + this.state.currentEvent.id + "/element/" + parentItem.id + "/qrcode/" + node.id + "/");
        }
    }

    handleEditEvent() {
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    render() {
        const {selectedFile} = this.state;

        var selectedLanguageStrings;

        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        let backgroundColor;
        if (this.props.secondaryColor) {
            backgroundColor = this.props.primaryColor;
        } else {
            backgroundColor = 'white';
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    <div>
                        <Header
                            page="SingleEventScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                        />
                        <HeaderTab
                            page="SingleEventScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                        />
                        <main role="main" className={"bg-" + backgroundColor}>
                            <div className="container">
                                {/* <StyledFileExplorer> */}
                                {/* <TreeWrapper> */}
                                <Tree onSelect={this.onSelect}
                                      event={this.state.currentEvent}
                                      showItem={this.showItem.bind(this)}
                                />
                                {/* </TreeWrapper> */}
                                <div>
                                    {selectedFile && selectedFile.type === 'file' && selectedFile.content}
                                </div>
                                {/* </StyledFileExplorer> */}
                            </div>
                        </main>

                        <EditModal
                            event={this.props.currentEvent}
                            saveBtnTitle={this.props.saveBtnTitle}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"SingleEventScreen"}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {eventCodeAvailability} = state.eventReducer;

    return {
        currentEvent: state.eventReducer.currentEvent,
        eventCodeAvailability,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetEventElementParents: (eventId, callback) => dispatch(eventActions.getEventElementParents(eventId, callback)),
        onGetEventElementChildren: (eventId, callback) => dispatch(eventActions.getEventElementChildren(eventId, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FileExplorer)
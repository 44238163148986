import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import {questionActions} from '../store/actions/QuestionActions';
import {Progress} from 'reactstrap';
import {Textfit} from 'react-textfit';
import AnswerText from '../Components/AnswerText';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import IntroductionPage from '../Components/IntroductionPage';
import Chart from "react-google-charts";
import firebase from '../config/firebase';
import moment from 'moment';


var firestore = firebase.firestore();

const data = [
    ["Year", "Visitations", {role: "style"}],
    ["2010", 10, "color: gray"],
    ["2020", 14, "color: #76A7FA"],
    ["2030", 16, "color: blue"],
    ["2040", 22, "stroke-color: #703593; stroke-width: 4; fill-color: #C5A5CF"],
    [
        "2050",
        28,
        "stroke-color: #871B47; stroke-opacity: 0.6; stroke-width: 8; fill-color: #BC5679; fill-opacity: 0.2"
    ]
];


class SingleQuestionComparison extends Component {

    constructor(props) {
        super(props);


        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            sessionId: '',
            questionId: '',
            elementId: '',
            cardTitle: '',
            responsesArray: [],
            status: '',
            questionData: [],
            optionsArrayLive: [],
            currentEvent: 'default',
            parentInfo: 'default',
            barChartData: [],
            maxValue: 0,
        }

        this.escFunction = this.escFunction.bind(this);
        this.getResponses = this.getResponses.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetQuestionInfoOnChange) {
            this.unsubscribeGetQuestionInfoOnChange();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubuscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubuscribe;
            this.setState({...this.state, currentEvent: this.props.currentEvent});

            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({...this.state, parentInfo: parentInfo});
                if (this.unsubscribeGetQuestionInfoOnChange) {
                    this.unsubscribeGetQuestionInfoOnChange();
                }
                this.props.onGetQuestionInfoOnChange(this.state.eventId, parentInfo.currentQuestionElement, parentInfo.currentQuestion, questionData => {
                    this.setState({...this.state, questionData});
                    var description = ['Options'];
                    var dataList = [description];
                    var resultsList = [];
                    if (questionData.selectedSessionsToCompare && questionData.selectedSessionsToCompare.length > 0) {
                        this.props.onGetVotingSessionsToCompare(this.state.eventId, parentInfo.currentQuestionElement, questionData, questionData.selectedSessionsToCompare, votingListCallback => {
                            for (var i = 0; i < votingListCallback.length; i++) {
                                var totalVote = 0;
                                votingListCallback[i].responsesList.forEach(item => {
                                    var sessionTime = moment(votingListCallback[i].timeStamp.toDate()).format("HH:mm");
                                    if (!description.includes(sessionTime)) {
                                        description.push(sessionTime);
                                        description.push({type: 'string', role: 'annotation'})
                                    }
                                    resultsList.push(item);
                                });
                            }
                            var maxValue;
                            for (var t = 0; t < resultsList.length; t++) {
                                if (maxValue < Math.round((resultsList[t].votes / resultsList[t].totalVotes) * 100)) {
                                    maxValue = Math.round((resultsList[t].votes / resultsList[t].totalVotes) * 100);
                                }
                                for (var n = 0; n < resultsList.length; n++) {
                                    var item = [];
                                    if (resultsList[t].optionText === resultsList[n].optionText && resultsList[t].timeStamp > resultsList[n].timeStamp) {
                                        //we only show in the percentage because if we use numbers of votes the different total votes can make a problem on showing results properly
                                        item.push(resultsList[t].optionText, Math.round((resultsList[t].votes / resultsList[t].totalVotes) * 100),
                                            Math.round((resultsList[t].votes / resultsList[t].totalVotes) * 100).toString(),
                                            Math.round((resultsList[n].votes / resultsList[n].totalVotes) * 100),
                                            Math.round((resultsList[n].votes / resultsList[n].totalVotes) * 100).toString()
                                        );
                                        // item.push(resultsList[t].optionText, resultsList[t].votes,resultsList[n].votes)
                                        dataList = dataList.concat([item]);
                                        this.setState({...this.state, barChartData: dataList, maxValue})
                                    }
                                }
                            }
                        });

                    }
                });
            });
        });

        /*************** BACK HISTORY BUTTON ****************/
        window.onpopstate = () => {
        }
    }

    getResponses(votingSession, eventId, idQuestionEl, question, option, callback) {

        if (votingSession && question) {
            firestore.collection("events/" + this.state.eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + question.id + "/VotingSession/" + votingSession.id + "/Responses")
                .get().then(querySnapShot => {
                var itemVote = 0;
                let archivedResponsesList = [];
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        option: document.data().option,
                        participantsId: document.data().participantsId,
                        isHidden: document.data().isHidden,
                    };
                    archivedResponsesList.push(response);
                    if (option.id === document.data().option) {
                        itemVote += 1;
                    }
                });


                callback({
                    session: votingSession,
                    itemVote: itemVote,
                    option: option,
                });
            });
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }
    }

    render() {
        var maxValue = this.state.maxValue;
        var middleContainerComponent = (
            <div>
                <div className="row pt-4 pb-4">
                    <div className="col-12" style={{
                        color: this.state.currentEvent.textColor,
                        fontSize: "45px",
                        paddingLeft: '5%'
                    }}>
                        <b>{this.state.questionData.text}</b>
                    </div>
                </div>
                <div style={{paddingLeft: "5%", display: 'flex',}}>
                    <Chart
                        backgroundImage={"url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}
                        width="90%"
                        height="70vh"
                        chartType="BarChart"
                        color={this.state.currentEvent.textColor}
                        loader={<div>Loading Chart</div>}
                        data={this.state.barChartData}


                        options={{
                            'backgroundColor': 'transparent',
                            'is3D': true,


                            // title: 'Population of Largest U.S. Cities',
                            chartArea: {
                                top: 20,
                                color: this.state.currentEvent.textColor,
                                fontName: 'Roboto',
                                fontSize: 25,
                                bold: true,

                            },
                            legend: {
                                textStyle: {
                                    color: this.state.currentEvent.textColor,
                                    fontName: 'Roboto',
                                    fontSize: 25,
                                    bold: true,
                                }
                            },
                            colors: ['#b0120a', '#ffab91'],
                            hAxis: {
                                // title: 'Votes',
                                titleTextStyle: {
                                    fontName: 'Roboto',
                                    fontSize: 25,
                                    bold: true,
                                    italic: false,
                                    color: this.state.currentEvent.textColor,
                                },
                                textStyle: {
                                    // color: this.state.currentEvent.textColor,
                                    color: 'transparent',

                                    fontName: 'Roboto',
                                    fontSize: 20,
                                    bold: true,
                                },
                                baselineColor: 'transparent',
                                gridlineColor: 'transparent',
                                minValue: 0,
                                maxValue: maxValue,
                            },
                            vAxis: {
                                titleTextStyle: {
                                    fontName: 'Roboto',
                                    fontSize: 25,
                                    bold: true,
                                    italic: false,
                                    color: this.state.currentEvent.textColor,
                                },
                                textStyle: {
                                    color: this.state.currentEvent.textColor,
                                    fontName: 'Roboto',
                                    fontSize: 20,
                                    bold: true,

                                },
                                // title: 'Options',
                            },
                        }}
                    />
                </div>
            </div>
        )

        return (
            <div style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    (this.state.currentEvent.showIntroductionPage === "Yes") ? (
                        <div className="row containerIntroduction text-center"
                             style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                            <IntroductionPage
                                currentEventToIntroduction={this.state.currentEvent}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="LiveScreenContainer">
                                <div className="headerBigScreen"
                                     style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}>
                                    {(this.state.currentEvent.voteCounterStatus === 'Active' && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice")) ? (
                                        <div className="row">
                                            <div className="col-9 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (
                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (
                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})</div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>
                                                ) : (
                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (
                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>
                                                )}
                                            </div>

                                            <div className="col-3 text-center pt-4">

                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (
                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (
                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}

                                                    </form>
                                                ) : (
                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (
                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* middle container */}
                                {(this.state.currentEvent.themeBigScreenImageUrl === "default") ? (
                                    <div>
                                        {middleContainerComponent}
                                    </div>
                                ) : (
                                    <div className="imageTheme"
                                         style={{backgroundImage: "url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}}>
                                        {middleContainerComponent}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        eventData: state.bigScreenReducer.eventData,
        questionOptions: state.bigScreenReducer.questionOptions,
        questionData: state.bigScreenReducer.questionData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetAllVotingSessions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllVotingSessions(eventId, idQuestionEl, questionId, callback)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetQuestionInfoOnChange: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfoOnChange(eventId, idQuestionEl, questionId, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(bigScreenActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onGetVotingSessionsToCompare: (eventId, idQuestionEl, question, sessionsList, callback) => dispatch(questionActions.getVotingSessionsToCompare(eventId, idQuestionEl, question, sessionsList, callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SingleQuestionComparison);
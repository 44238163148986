import firebase from '../config/firebase';
import firebase1 from 'firebase';

var firestore = firebase.firestore();

export const authenticationFunctions = {
    register,
    login,
    logout,
    getUserDataOnChange,
};

function register(user, callback) {
    //We check if this email address already exists in the firebase or not?
    return firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
        .then(function (firebaseUser) {
            // Success 
            var registerationTime = firebase1.firestore.FieldValue.serverTimestamp();
            let userData = { 
                'sFirstName': user.firstName, 
                'sLastName': user.lastName, 
                'sEmail': user.email, 
                'sPassword': user.password, 
                'sType': "Basic", 
                'registerationTime': registerationTime,
            }
        firestore.collection("users").doc(firebaseUser.user.uid).set(userData);
        Object.assign(user, { userId: firebaseUser.user.uid });
        // return user;
        callback({message:"RegistrationSuccessful", data:user});
        //.then(user => {
        //         Object.assign(userData, { userId: firebaseUser.user.uid });
        //         userCallback(userData);
        //     });
         })
        .catch(function (err) {
            
            callback({message:"err authentication.....", data:err});
            // Handle errors
              //return Promise.reject("This Email Address is already used.");
            // return Promise.reject("This Email Address is already used.");
          //  userCallback(err);
         });
}


function login(email, userPassword) {
    return firebase.auth().signInWithEmailAndPassword(email, userPassword)
        .then(function (firebaseUser) {
            return firestore.collection("users").doc(firebaseUser.user.uid).get();
        })
        .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                alert('Wrong password.');
                return Promise.reject(error.message);
            } else {
                alert(errorMessage);
                return Promise.reject(error.code);
            }
        });
}

function logout() {
    //remove the redux and the user data stored in the local storage 
    // localStorage.removeItem('user');
    localStorage.clear();
}

function getUserDataOnChange(userId, callback){
    let unsubscribe = firestore.collection("users/").doc(userId).onSnapshot(userDoc => {
        let userData = userDoc.data();
        userData.id = userDoc.id;
        callback(userData, unsubscribe);
    });
}
import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { isMobile, isBrowser } from 'react-device-detect';
import { connect } from 'react-redux';


class Options extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: '',
            optionsArray: [],
        };
    }

    componentDidMount(){
        //we fetch the question from the list of the questions in this way the browsers on different devices will fetch the same data when we change in one of the browsers
        if(this.props.question){
            if(this.props.currentEvent.currentQuestion !== this.props.question.id){

            }
        }

    }

    render() {

        let optionsListComponents = [];
        let totalVote = 0;
        var optionCol = '';
        var progressCol = '';
        //  let itemVote = 0;
        if (this.props.optionsList) {
            var contentWidthStatus = '';
            for (var i = 0; i < this.props.optionsList.length; i++) {
                if(this.props.optionsList[i]){
                    var optionCharLength = this.props.optionsList[i].text.length;
                    if (optionCharLength > 20) {
                        contentWidthStatus = 'big'
                    } else if(optionCharLength >= 11 && optionCharLength < 20 && contentWidthStatus !== 'big') {
                        contentWidthStatus = 'medium';
                    } else if(optionCharLength >= 5 && optionCharLength < 11 && contentWidthStatus !== 'big' && contentWidthStatus !== 'medium') {
                        contentWidthStatus = 'small'
                    }else if(optionCharLength < 5 && contentWidthStatus !== 'big' && contentWidthStatus !== 'medium' && contentWidthStatus !== 'small') {
                        contentWidthStatus = 'verySmall';
                    }
                }
            }                
            if (contentWidthStatus === 'verySmall') {
                optionCol= 'col-sm-2 col-2 col-lg-2 col-md-2';
                progressCol= 'col-sm-9 col-9 col-lg-9 col-md-9';
            } else if (contentWidthStatus === 'small') {
                optionCol= 'col-sm-3 col-3 col-lg-3 col-md-3';
                progressCol= 'col-sm-8 col-8 col-lg-8 col-md-8';
            } else if (contentWidthStatus === 'medium') {
                optionCol= 'col-sm-4 col-4 col-lg-4 col-md-4';
                progressCol= 'col-sm-7 col-7 col-lg-7 col-md-7';
            } else if (contentWidthStatus === 'big') {
                optionCol= 'col-sm-7 col-7 col-lg-7 col-md-7';
                progressCol= 'col-sm-4 col-4 col-lg-4 col-md-4';
            }
            if (this.props.responsesArrayArchive) {
                if (this.props.responsesArrayArchive.length === 0) {
                    // var contentWidthStatus = 'verySmall';            
                    optionsListComponents = (this.props.optionsList || []).map((option, i) => {
                        var itemVote = 0;
                        var optionOrderType;
                        if(this.props.question.optionDecorationValue === "Alphabet" ){
                            optionOrderType="a"
                        }else if(this.props.question.optionDecorationValue === "Number"){
                            optionOrderType="1"
                        }else if(this.props.question.optionDecorationValue === "Bullet"){
                            optionOrderType="disc"
                        }
                        return (
                            <div className="row mb-2" key={option.id}>
                                {(this.props.voteResultsDisplay === "Percentage") ? (

                                    <form className="col-12">
                                        <div className="row">
                                            {(option.text === " ") ? (

                                                (option.type === "like") ? (

                                                    <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                    </div>

                                                ) : (

                                                    <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                    </div>

                                                )

                                            ) : (

                                                <div className={"optionDivOption mb-1 " + optionCol}>
                                                    {(this.props.question.optionDecorationValue === "None") ? (
                                                    
                                                        <div>{option.text}</div>
                                                    
                                                    ) : (                                             
                                                        
                                                        <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                    
                                                    )}
                                                </div>
                                            )}
                                            <div className={"progressBarDiv p-0 " + progressCol}>
                                                <Progress id="screenProgressOption" animated style={{ width: '90%'}} aria-valuenow="30" >
                                                    <div className="progress-bar-option " role="progressbar" name="progress"  role="progressbar" aria-valuenow="90"></div>
                                                </Progress>
                                            </div>
                                            <div className="col-sm-1 col-1 col-lg-1 col-md-1 voteDivOption p-0">
                                                0%
                                            </div>
                                        </div>
                                    </form>

                                ) : (

                                    <form className="col-12">
                                        <div className="row">
                                            {(option.text === " ")?(

                                                (option.type === "like")?(

                                                    <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                    </div>

                                                ) : (

                                                    <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                    </div>
                                                
                                                )

                                            ) : (

                                                <div className={"optionDivOption mb-1 " + optionCol}>
                                                    {(this.props.question.optionDecorationValue === "None") ? (
                                                    
                                                        <div>{option.text}</div>
                                                    
                                                    ) : (

                                                        <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                     
                                                    )}
                                                </div>
                                            
                                            )}
                                            <div className={"progressBarDiv p-0 " + progressCol}>
                                                <Progress id="screenProgressOption" animated style={{ width: '90%'}} aria-valuenow="30" >
                                                    <div className="progress-bar-option " role="progressbar" name="progress" style={{backgroundColor: this.props.textColor }} role="progressbar" aria-valuenow="90"></div>
                                                </Progress>
                                            </div>
                                            <div className="col-sm-1 col-1 col-lg-1 col-md-1 p-0 voteDivOption">
                                                0
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )
                    });

                } else {
                    // we remove the repetitive participants id that may happen because of the multiple choices selection
                    var uniqueResponses = [];
                    for (var index = 0; index < this.props.responsesArrayArchive.length; index++) {
                        if (!uniqueResponses.includes(this.props.responsesArrayArchive[index].participantsId)) {
                            //if the list of the participants does not have this participants id we add it to the list 
                            uniqueResponses.push(this.props.responsesArrayArchive[index].participantsId);
                        }
                    }
                    totalVote += uniqueResponses.length;
                    var sortedOptions = [];
                    //Check the user settings for ordering the question options based on the number of votes
                    if (this.props.optionsOrderStatus === 'Active') {
                        //To sort the responses list in decreasing order, the item[0] will have the highest vote */}
                        for (var i = 0; i < this.props.optionsList.length; i++) {
                            var itemVote = 0;
                            for (let index in this.props.responsesArrayArchive) {
                                if (this.props.responsesArrayArchive[index].option === this.props.optionsList[i].id) {
                                    itemVote += 1;
                                }
                            }
                            sortedOptions.push({ text: this.props.optionsList[i].text, votes: itemVote, isAnswer:this.props.optionsList[i].isAnswer, type:this.props.optionsList[i].type })
                        };
                        sortedOptions.sort(function (a, b) { return a.votes > b.votes ? -1 : 1 });
                        optionsListComponents = (sortedOptions || []).map((option, i) => {
                            var optionOrderType;
                            if(this.props.question.optionDecorationValue === "Alphabet" ){
                                optionOrderType="a"
                            } else if(this.props.question.optionDecorationValue === "Number"){
                                optionOrderType="1"
                            } else if(this.props.question.optionDecorationValue === "Bullet"){
                                optionOrderType="disc"
                            }

                            var voteBarColorActive;
                          
                            if(this.props.optionsFor === "ArchivedSessions"){
                                if(option.isAnswer === true && this.props.currentEvent.showCorrectAnswerColor === "show"){                                    
                                    // voteBarColor = '#dc3545';
                                    voteBarColorActive = this.props.currentEvent.correctQuestionColor;
                                } else {
                                    voteBarColorActive = this.props.currentEvent.primaryColor;
                                }

                            } else {
                                //for the live session we only show the color when the user has stopped the live session
                                if(option.isAnswer === true && this.props.currentEvent.showCorrectAnswerColor === "show" && this.props.parentInfo.currentLiveSession.status === "stop"){                                    
                                    // voteBarColor = '#dc3545';
                                    voteBarColorActive = this.props.currentEvent.correctQuestionColor;
                                } else {
                                    voteBarColorActive = this.props.currentEvent.primaryColor;
                                }
                            }

                            return (
                                <div className="row mb-2">
                                    {(this.props.voteResultsDisplay === "Percentage") ? (
                                    
                                        <form className="col-12 col-sm-12 col-lg-12 pr-4">
                                            <div className="row">
                                                {(option.text === " ") ? (
                                                    
                                                    (option.type === "like") ? (
                                                        
                                                        <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                        </div>
                                                    
                                                    ) : (
                                                        
                                                        <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                        </div>
                                                    
                                                    )
                                                
                                                ) : (

                                                    <div className={"optionDivOption mb-1 " + optionCol}>
                                                        {(this.props.question.optionDecorationValue === "None") ? (
                                                            
                                                            <div>{option.text}</div>
                                                    
                                                        ):(                                             
                                                
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                
                                                        )}
                                                    </div>
                                                
                                                )}
                                                <div className={"progressBarDiv pl-2 pr-2" + progressCol}>
                                                    <Progress id="screenProgressOption" animated style={{ width: '100%'}} aria-valuenow="30" value={Math.round((option.votes / totalVote) * 100)}>
                                                        <div className="progress-bar-option " role="progressbar" name="progress" style={{backgroundColor: voteBarColorActive }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-sm-1 col-1 col-lg-1 col-md-1 voteDivOption p-0">
                                                    {Math.round(((option.votes) / totalVote) * 100)}%
                                                </div>
                                            </div>
                                        </form>
                                    
                                    ) : (
                                            
                                        <form className="col-12 col-sm-12 col-lg-12 pr-4">
                                            <div className="row">
                                                {(option.text === " ") ? (

                                                    (option.type === "like")?(

                                                        <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                        </div>
                                                    
                                                    ) : (

                                                        <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                        </div>
                                                    
                                                    )
                                                
                                                ) : (

                                                    <div className={"optionDivOption mb-1 " + optionCol}>
                                                        {(this.props.question.optionDecorationValue === "None") ? (
                                                            
                                                            <div>{option.text}</div>
                                                        
                                                        ):(
                                                                                                     
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                        
                                                        )}
                                                    </div>
                                                
                                                )}
                                                <div className={"progressBarDiv pr-2 pl-2 " + progressCol}>
                                                    <Progress id="screenProgressOption" animated style={{ width: '100%'}} aria-valuenow="30"  max={totalVote} value={option.votes}>
                                                        <div className="progress-bar-option " role="progressbar" name="progress" style={{backgroundColor: voteBarColorActive }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-sm-1 col-1 col-lg-1 col-md-1  voteDivOption p-0">
                                                    {option.votes}
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });

                    } else {

                        optionsListComponents = (this.props.optionsList || []).map((option, i) => {
                            var voteBarColor;
                            var optionOrderType;
                            if(this.props.question.optionDecorationValue === "Alphabet" ){
                                optionOrderType="a"
                            } else if(this.props.question.optionDecorationValue === "Number"){
                                optionOrderType="1"
                            } else if(this.props.question.optionDecorationValue === "Bullet"){
                                optionOrderType="disc"
                            }

                            if(this.props.optionsFor === "ArchivedSessions"){
                                if(option.isAnswer === true && this.props.currentEvent.showCorrectAnswerColor === "show"){                                    
                                    // voteBarColor = '#dc3545';
                                    voteBarColor = this.props.currentEvent.correctQuestionColor;
                                } else {
                                    voteBarColor = this.props.currentEvent.primaryColor;
                                }

                            } else {
                                //for the live session we only show the color when the user has stopped the live session
                                if(option.isAnswer === true && this.props.currentEvent.showCorrectAnswerColor === "show" && this.props.parentInfo.currentLiveSession.status === "stop"){                                    
                                    // voteBarColor = '#dc3545';
                                    voteBarColor = this.props.currentEvent.correctQuestionColor;
                                } else {
                                    voteBarColor = this.props.currentEvent.primaryColor;
                                }
                            }
                           
                            itemVote = 0;
                            for (let index in this.props.responsesArrayArchive) {
                                if (this.props.responsesArrayArchive[index].option === option.id) {
                                    itemVote += 1;
                                }
                            }

                            return (
                                <div className="row mb-2" key={option.id}>
                                    {(this.props.voteResultsDisplay === "Percentage") ? (
                                        <form className="col-12 col-sm-12 col-lg-12 pr-4">
                                            <div className="row">
                                                {(option.text === " ") ? (
                                                
                                                    (option.type === "like") ? (

                                                        <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                        </div>
                                                    
                                                    ) : (

                                                        <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                        </div>
                                                    
                                                    )
                                                
                                                ) : (

                                                    <div className={"optionDivOption mb-1 " + optionCol}>
                                                        {(this.props.question.optionDecorationValue === "None") ? (
                                                        
                                                            <div>{option.text}</div>
                                                        
                                                        ) : (
                                                    
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                    
                                                        )}
                                                    </div>
                                                
                                                )}
                                                <div className={"progressBarDiv pl-2 pr-2 " + progressCol}>
                                                    <Progress id="screenProgressOption" animated style={{ width: '100%'}} aria-valuenow="30" value={Math.round((itemVote / totalVote) * 100)}>
                                                        <div className="progress-bar-option " role="progressbar" name="progress" style={{ backgroundColor: voteBarColor }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-sm-1 col-1 col-lg-1 col-md-1 voteDivOption p-0">
                                                    {Math.round(((itemVote) / totalVote) * 100)}%
                                                </div>
                                            </div>
                                        </form>

                                    ) : (

                                        <form className="col-12 col-sm-12 col-lg-12 pr-4">
                                            <div className="row">
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div className={"optionDivOption pb-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2 text-success" ></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDivOption pt-2 pl-4 " + optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2 text-danger" ></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDivOption mb-1 " + optionCol}>
                                                        {(this.props.question.optionDecorationValue === "None")?(
                                                    
                                                            <div>{option.text}</div>
                                                        
                                                        ) : (                                             
                                                        
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li> 
                                                        
                                                        )}
                                                    </div>
                                                
                                                )}
                                                <div className={"progressBarDiv pl-2 pr-2 " + progressCol}>
                                                    <Progress id="screenProgressOption" animated style={{ width: '100%'}} aria-valuenow="30" max={totalVote} value={itemVote}>
                                                        <div className="progress-bar-option " role="progressbar" name="progress" style={{backgroundColor: voteBarColor }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-sm-1 col-1 col-lg-1 col-md-1 voteDivOption p-0">
                                                    {itemVote}
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });
                    }
                }
            }
            if (this.props.question === "") {
                optionsListComponents = (<p className="font-no-result text-center">No Live Question.</p>);
            }
        }
        else {
            /************** LOADER **********/
            optionsListComponents = (<div className="loader border-primary mx-auto">No Live Question.</div>);
        }
        var orderedOptionsListcomponent;
        if(this.props.question.optionDecorationValue === "Alphabet" || this.props.question.optionDecorationValue === "Number"){
            orderedOptionsListcomponent= <ol className="questionOptionsList" >
                                            {optionsListComponents}
                                        </ol>
        } else if (this.props.question.optionDecorationValue === "Bullet"){
            orderedOptionsListcomponent= <ul className="questionOptionsList" >
                                            {optionsListComponents}                                      
                                        </ul>
        } else {
            orderedOptionsListcomponent= <div>
                                            {optionsListComponents}
                                        </div>
        }
        
        return (
            <div>
                <div className="row mt-2">
                    <div className="col-sm-12 col-12 col-lg-12 col-md-12">
                        <div className="card votesCard ">
                            <div className="card-header  " style={{ backgroundColor: this.props.currentEvent.primaryColor }}>
                                {(this.props.optionsOrderStatus === 'Active') ? (

                                    <label style={{ color: this.props.currentEvent.textColor }}>Votes (Options Ordered by Votes) :</label>
                                
                                ) : (
                                
                                    <label style={{ color: this.props.currentEvent.textColor }}>Votes (Default Order of options) :</label>
                                
                                )}
                            </div>
                            <div className="card-body-option">
                                <div className="option-card-body">
                                    {orderedOptionsListcomponent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        questionList: state.questionReducer.questionList,
        currentEvent: state.eventReducer.currentEvent,
    };
    
};

export default connect(mapStateToProps, null)(Options);
import React, { Component } from 'react';
import 'react-tippy/dist/tippy.css';
import Chart from "react-google-charts";

class LiveCompareResultsComponentOld extends Component {

    render() {
        var maxValue = this.props.maxValue;
        
        return (
            <div className="mt-4">
                <div className="row QuestionTexLive m-3">
                    <div className="col-12" style={{color:this.props.currentEvent.backgroundTextColor, 
                        fontSize:this.props.questionForOptionsFontSize,
                        lineHeight:this.props.questionForOptionsLineHeight,
                    }}>
                        <b>{this.props.questionData.text}</b>
                    </div>
                </div>
                <div style={{paddingLeft:"5%" , display: 'flex', paddingRight:'30px'}}>
                    <Chart
                        backgroundImage={ "url(" + this.props.currentEvent.themeBigScreenImageUrl + ")"}
                        width="100%"
                        height="90vh"
                        paddingRight="10px"
                        chartType="BarChart"
                        color={this.props.currentEvent.textColor}
                        // loader={<div>Loading Chart</div>}
                        loader={
                            <div className="loaderContainer center loadingChart">
                                <span>Loading Chart</span>
                                <i className="fa fa-spinner fa-spin" ></i>
                            </div>
                        }
                        data={ this.props.barChartData}
                        options={{
                            'backgroundColor': 'transparent',
                            'is3D':true,
                            bar: {groupWidth: '75%'},
                            // title: 'Population of Largest U.S. Cities',
                            chartArea: {
                                width: '50%',
                                top:20,
                                color: this.props.currentEvent.backgroundTextColor,
                                fontName: 'Roboto',
                                fontSize: this.props.optionsFontSize,
                                lineHeight: this.props.optionsLineHeight,
                                bold: true, 
                            },
                            legend: {
                                textStyle: {
                                    color: this.props.currentEvent.backgroundTextColor,
                                    fontName: 'Roboto',
                                    fontSize: this.props.optionsFontSize,
                                    lineHeight: this.props.optionsLineHeight,
                                    bold: true,
                                }
                            },
                            annotations: {
                                // alwaysOutside: true,
                                textStyle: {
                                    color: this.props.currentEvent.backgroundTextColor,
                                    fontName: 'Roboto',
                                    fontSize: this.props.optionsFontSize,
                                    lineHeight: this.props.optionsLineHeight,
                                    auraColor: 'none',
                                },
                                // boxStyle: {
                                //     stroke: '#ccc',
                                //     strokeWidth: 1,
                                //     gradient: {
                                //       color1: '#f3e5f5',
                                //       color2: '#f3e5f5',
                                //       x1: '0%', y1: '0%',
                                //       x2: '100%', y2: '100%'
                                //     }
                                //   }
                            },
                            // colors: ['#b0120a', '#ffab91'],
                            colors:['#512566','#81cbe8'],
                            hAxis: {
                                width:"50%",
                                paddingRight: '100',marginRight: '100',
                                // title: 'Votes',
                                titleTextStyle: {
                                    fontName: 'Roboto',
                                    fontSize: this.props.optionsFontSize,
                                    lineHeight: this.props.optionsLineHeight,
                                    bold: true,
                                    italic: false,
                                    color: this.props.currentEvent.textColor,
                                },
                                textStyle: {
                                    // color: this.props.currentEvent.textColor,
                                    color: 'transparent',
                                    fontName: 'Roboto',
                                    fontSize: 20,
                                    bold: true,
                                },
                                baselineColor: 'transparent',
                                gridlineColor: 'transparent',
                                minValue: 0,
                                maxValue: maxValue,
                                format: '#\'%\''
                            },
                            vAxis: {
                                titleTextStyle: {
                                    fontName: 'Roboto',
                                    fontSize: this.props.optionsFontSize,
                                    lineHeight: this.props.optionsLineHeight,
                                    bold: true,
                                    italic: false,
                                    color: this.props.currentEvent.backgroundTextColor,
                                },
                                textStyle: {
                                    color: this.props.currentEvent.backgroundTextColor,
                                    fontName: 'Roboto',
                                    fontSize: this.props.optionsFontSize,
                                    lineHeight: this.props.optionsLineHeight,
                                    // bold: true,
                                    textAlign:"start"
                                },
                                // title: 'Options',
                            },
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default LiveCompareResultsComponentOld;
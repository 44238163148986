import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';



class ShareEventModal extends Component {


    constructor(props) {
        super(props);

        this.state = {
            currentEvent: '',
            shareStatus: "default",
            emailErrorMessage: "",
            sharedMembersModal: "sharedMembersModal",
            sharedEmailsList: [],
            itemToCancelShare: "default",
            sharedListView: "default",
            showEmails: true,
        };

        this.shareEventCheck = this.shareEventCheck.bind(this);
        this.showSharedMemebers = this.showSharedMemebers.bind(this);
        this.resetModal = this.resetModal.bind(this);
        this.cancelShare = this.cancelShare.bind(this);
        this.cancelShareModal = this.cancelShareModal.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
    }

    shareEventCheck() {
        var email = document.getElementById("shareEmailAddress").value;
        if (this.props.user.sEmail === email) {
            this.setState({ ...this.state, emailErrorMessage: "Please provide an email address different than yours." })
        } else {
            this.props.onShareEventEmailCheck(email, callback => {
                var sharedUserData = callback.data;
                var alreadyShared = false;
                for (var i = 0; i < this.props.sharedEmailsList.length; i++) {
                    if (this.props.sharedEmailsList[i].email === email) {
                        alreadyShared = true;
                        break;
                    }
                }

                if (alreadyShared === true) {
                    this.setState({ ...this.state, emailErrorMessage: "You have already shared this event with this email address." })
                } else if (callback.message === "Document Exists") {
                    this.setState({...this.state, showEmails:false})
                    this.props.onUpdateSharedEventsData("add", this.props.currentShareEvent.id, this.props.user, sharedUserData, result => {
                        if (result === "Success") {
                            this.setState({ ...this.state, shareStatus: "Success", showEmails:true});
                            document.getElementById("shareModalForm").reset();
                        }
                    });
                } else {
                    this.setState({ ...this.state, emailErrorMessage: "This email address does not exist." })
                }
            });


        }
    }

    resetModal() {
        if (document.getElementById("shareModalForm")) {
            document.getElementById("shareModalForm").reset();
        }

        this.setState({ ...this.state, shareStatus: "default" });
    }


    showSharedMemebers() {
        this.setState({ ...this.state, sharedListView: "show" });
    }

    cancelShareModal(sharedUser) {
        this.setState({ ...this.state, itemToCancelShare: sharedUser });
    }

    cancelShare() {
        this.props.onUpdateSharedEventsData("cancelShare", this.props.currentShareEvent.id, this.props.user, this.state.itemToCancelShare, result => {
        });
    }

    handleEventChange = (e) => {
        switch (e.target.name) {

            case "emailInput":
                this.setState({ ...this.state, [e.target.eventTitle]: e.target.value, eventBoxTitle: e.target.value, emailErrorMessage:"" });
                break;
                
            default:
            return true;
    }
}

    render() {
        var sharedEventsEmailsComponent;
        if (this.props.sharedEmailsList && this.props.sharedEmailsList.length > 0) {
            sharedEventsEmailsComponent = this.props.sharedEmailsList.map((item) => (
                <div className="row align-items-center">
                    <div className="col-10">
                        <div className="card p-2 mb-2">
                            {item.email}
                        </div>
                    </div>

                    <div className="col-2 pl-0 pr-0">
                        <i className="fas fa-times-circle text-danger mr-2 shareEmailIcons" data-toggle="modal" data-target="#cancelSharedEmail" onClick={() => { this.cancelShareModal(item) }}></i>
                        <a href={"mailto:" + item.email}><i className="fas fa-envelope text-success shareEmailIcons"></i></a>
                    </div>
                </div>
            ))
        }

        return (
            <div>
                <div className="modal fade" id="shareModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {(this.state.shareStatus === "default") ? (
                                <div>
                                    <div className="modal-header bg-primary p-2">
                                        <h4 className="modal-title pl-2" id="exampleModalLabel">Share Event</h4>
                                    </div>
                                    <div className="modal-body modalShare">
                                        <div className=" mb-3">Title:
                                    {(this.props.currentShareEvent && this.props.currentShareEvent !== "default") ? (
                                                <span className="text-success pl-2">
                                                    {this.props.currentShareEvent.title}
                                                </span>
                                            ) : (
                                                    <span></span>
                                                )}
                                        </div>
                                        <form id="shareModalForm">
                                            {(this.state.shareStatus === "default") ? (
                                                <div className="form-group mt-2">
                                                    <label htmlFor="shareEmailAddress">Email address:</label>
                                                    <input type="email" className="form-control" id="shareEmailAddress" aria-describedby="emailHelp" placeholder="Enter email" name="emailInput" onChange={this.handleEventChange} />
                                                    <div className="errorMessage">
                                                        {this.state.emailErrorMessage}
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div></div>
                                                )}

                                            {(this.props.sharedEmailsList && this.props.sharedEmailsList.length > 0 && this.state.showEmails === true) ? (
                                                <div>
                                                    <div className="mb-2 mt-2">
                                                        This event is already shared with the following {(this.props.sharedEmailsList.length === 1) ? ("member:") : ("members:")}
                                                    </div>
                                                    <div>
                                                        {sharedEventsEmailsComponent}
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div>

                                                    </div>
                                                )}
                                        </form>
                                    </div>

                                    <div className="modal-footer pt-0">
                                        <button type="button" className="btn btn-secondary" id="closeShareModal" data-dismiss="modal" onClick={this.resetModal}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={this.shareEventCheck}>Share</button>
                                    </div>
                                </div>

                            ) : (
                                    <div>
                                         <div className="modal-header bg-success p-2">
                                            <h4 className="modal-title pl-2" id="exampleModalLabel">Shared Successfully</h4>
                                        </div>
                                        <div className="modal-body modalShare">
                                            <div className=" mb-3">Title:
                                                {(this.props.currentShareEvent && this.props.currentShareEvent !== "default") ? (
                                                    <span className="text-success pl-2">
                                                        {this.props.currentShareEvent.title}
                                                    </span>
                                                ) : (
                                                        <span></span>
                                                    )}
                                            </div>
                                            {(this.props.sharedEmailsList && this.props.sharedEmailsList.length > 0) ? (
                                                <div>
                                                    <div className="mb-2 mt-2">
                                                        This event is already shared with the following {(this.props.sharedEmailsList.length === 1) ? ("member:") : ("members:")}
                                                    </div>
                                                    <div>
                                                        {sharedEventsEmailsComponent}
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div>

                                                    </div>
                                                )}
                                        </div>
                                        <div className="modal-footer pt-0">
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" id="closeShareModal" data-dismiss="modal" onClick={this.resetModal}>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>


            <div className="modal fade" id="cancelSharedEmail" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title" id="exampleModalLabel">Cancel Share:</h5>
                        </div>
                        <div className="modal-body">
                            Are you sure that you want to cancel sharing this event with: {this.state.itemToCancelShare.email} ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.cancelShare}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
        )
    }
}
const mapStateToProps = state => {
    return {
        currentShareEvent: state.eventReducer.currentShareEvent,
        sharedEmailsList: state.eventReducer.sharedEmailsList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShareEventEmailCheck: (email, callback) => dispatch(eventActions.shareEventEmailCheck(email, callback)),
        onUpdateSharedEventsData: (type, eventId, userData, sharedUserData, callback) => dispatch(eventActions.updateSharedEventsData(type, eventId, userData, sharedUserData, callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShareEventModal);
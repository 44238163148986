export const actionTypes = {

    /********************* Constants of the events actions ******************************/

    Fetch_ALL_EVENTS_SUCCESS: 'Fetch_ALL_EVENTS_SUCCESS',
    Fetch_ALL_EVENT_ELEMENTS_PARENT_SUCCESS:'Fetch_ALL_EVENT_ELEMENTS_PARENT_SUCCESS',
    SAVE_EVENT_ELEMENT_PARENT_SUCCESS:'SAVE_EVENT_ELEMENT_PARENT_SUCCESS',
    SAVE_EVENT_ELEMENT_CHILD_SUCCESS:'SAVE_EVENT_ELEMENT_CHILD_SUCCESS',
    FETCH_ALL_EVENT_ELEMENT_CHILDREN_SUCCESS:"FETCH_ALL_EVENT_ELEMENT_CHILDREN_SUCCESS",
    UPDATE_CHILD_ELEMENT_SUCCESS:"UPDATE_CHILD_ELEMENT_SUCCESS",
    GET_PARENT_ELEMENT_ON_CHANGE_SUCCESS:"GET_PARENT_ELEMENT_ON_CHANGE_SUCCESS",
    Fetch_ALL_EVENTS_ERROR: 'Fetch_ALL_EVENTS_ERROR',
    Fetch_SINGLE_EVENT_TREE_SUCCESS: 'Fetch_SINGLE_EVENT_TREE_SUCCESS',
    Fetch_SINGLE_EVENT_TREE_ERROR: 'Fetch_SINGLE_EVENT_TREE_ERROR',
    ADD_EVENT: 'ADD_EVENT',
    CANCEL_EVENT: 'CANCEL_EVENT',
    SAVE_EVENT_SUCCESS: 'SAVE_EVENT_SUCCESS',
    SAVE_EVENT_ELEMENT_SUCCESS: 'SAVE_EVENT_ELEMENT_SUCCESS',
    DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',
    FETCH_EVENT_INFO_CHANGE_SUCCESS:"FETCH_EVENT_INFO_CHANGE_SUCCESS",
    SET_CURRENT_EVENT_SUCCESS: 'SET_CURRENT_EVENT_SUCCESS',
    UPDATE_CURRENT_FIELD_SUCCESS: 'UPDATE_CURRENT_FIELD_SUCCESS',
    EVENT_DATA_UPDATED_SUCCESS: 'EVENT_DATA_UPDATED_SUCCESS',
    CODE_AVAILABILITY_SUCCESS: 'CODE_AVAILABILITY_SUCCESS',
    CODE_AVAILABILITY_FAILED:'CODE_AVAILABILITY_FAILED',
    UPDATE_EVENT_LOGO_FIELD_SUCCESS:'UPDATE_EVENT_LOGO_FIELD_SUCCESS',
    UPDATE_EVENT_SPLASH_IMAGE_FIELD_SUCCESS:'UPDATE_EVENT_SPLASH_IMAGE_FIELD_SUCCESS',
    UPDATE_EVENT_HOME_IMAGE_FIELD_SUCCESS:'UPDATE_EVENT_HOME_IMAGE_FIELD_SUCCESS',
    UPDATE_EVENT_THEME_PARTICIPANT_FIELD_SUCCESS:"UPDATE_EVENT_THEME_PARTICIPANT_FIELD_SUCCESS",
    UPDATE_EVENT_THEME_BIG_SCREEN_FIELD_SUCCESS:"UPDATE_EVENT_THEME_BIG_SCREEN_FIELD_SUCCESS",
    DELETE_SELECTED_VOTING_SESSION_FIELD_SUCCESS:'DELETE_SELECTED_VOTING_SESSION_FIELD_SUCCESS',
    DELETE_LIVE_SESSION_FIELD_SUCCESS: 'DELETE_LIVE_SESSION_FIELD_SUCCESS',
    EVENTS_INDEXES_UPDATED_SUCCESS:'EVENTS_INDEXES_UPDATED_SUCCESS',
    DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
    PUSH_MESSAGE_SUCCESS: 'PUSH_MESSAGE_SUCCESS',
    FETCH_PARTICIPANT_LIST_SUCCESS:"FETCH_PARTICIPANT_LIST_SUCCESS",
    LIBRARY_FILE_UPLOAD_SUCCESS:'LIBRARY_FILE_UPLOAD_SUCCESS',
    AGENDA_FILE_UPLOAD_SUCCESS:'AGENDA_FILE_UPLOAD_SUCCESS',
    SURVEY_FILE_UPLOAD_SUCCESS:"SURVEY_FILE_UPLOAD_SUCCESS",
    SAVE_LIBRARY_FILE_SUCCESS:'SAVE_LIBRARY_FILE_SUCCESS',
    SAVE_SURVEY_FILE_SUCCESS:"SAVE_SURVEY_FILE_SUCCESS",
    SAVE_AGENDA_FILE_SUCCESS:"SAVE_AGENDA_FILE_SUCCESS",
    SAVE_QRCODE_FILE_SUCCESS:"SAVE_QRCODE_FILE_SUCCESS",
    GET_ALL_LIBRARY_FILES_SUCCESS:'GET_ALL_LIBRARY_FILES_SUCCESS',
    GET_ALL_AGENDA_FILES_SUCCESS:'GET_ALL_AGENDA_FILES_SUCCESS',
    GET_AGENDA_INFO_ON_CHANGE_SUCCESS: 'GET_AGENDA_INFO_ON_CHANGE_SUCCESS',
    GET_ALL_SURVEY_FILES_SUCCESS:'GET_ALL_SURVEY_FILES_SUCCESS',
    DELETE_LIBRARY_FILE_SUCCESS:'DELETE_LIBRARY_FILE_SUCCESS',
    LIBRARY_FILE_INDEXES_UPDATED_SUCCESS:'LIBRARY_FILE_INDEXES_UPDATED_SUCCESS',
    SAVE_EVENT_INFO_FILE_SUCCESS:'SAVE_EVENT_INFO_FILE_SUCCESS',
    GET_ALL_EVENT_INFO_FILES_SUCCESS:'GET_ALL_EVENT_INFO_FILES_SUCCESS',
    GET_SURVEY_INFO_ON_CHANGE_SUCCESS:'GET_SURVEY_INFO_ON_CHANGE_SUCCESS',
    GET_LIBRARY_INFO_ON_CHANGE_SUCCESS:'GET_LIBRARY_INFO_ON_CHANGE_SUCCESS',
    GET_QRCODE_INFO_ON_CHANGE_SUCCESS:'GET_QRCODE_INFO_ON_CHANGE_SUCCESS',
    Fetch_ALL_PARTICIPANTS_LIST_SUCCESS:"Fetch_ALL_PARTICIPANTS_LIST_SUCCESS",
    EMAIL_AVAILABILITY_CHECK_SUCCESS:"EMAIL_AVAILABILITY_CHECK_SUCCESS",
    UPDATE_SHARED_EVENT_DATA_SUCCESS:"UPDATE_SHARED_EVENT_DATA_SUCCESS",
    FETCH_SHARED_EVENTS_SUCCESS:"FETCH_SHARED_EVENTS_SUCCESS",
    FETCH_SHARED_EMAILS_SUCCESS:"FETCH_SHARED_EMAILS_SUCCESS",
    EVENTS_ELEMENTS_INDEXES_UPDATED_SUCCESS:"EVENTS_ELEMENTS_INDEXES_UPDATED_SUCCESS",
    FETCH_ALL_MESSAGES_LIST: "FETCH_ALL_MESSAGES_LIST",
    SET_ELEMENT_LANGUAGE: "SET_ELEMENT_LANGUAGE",

    /********************* Constants of the questions actions  ************************/

    Fetch_ALL_QUESTIONS_SUCCESS: 'Fetch_ALL_QUESTIONS_SUCCESS',
    Fetch_ALL_QUESTIONS_ERROR: 'Fetch_ALL_QUESTIONS_ERROR',
    ADD_QUESTION: 'ADD_QUESTION',
    SAVE_QUESTION_SUCCESS: 'SAVE_QUESTION_SUCCESS',
    DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
    Fetch_ALL_QUESTION_OPTIONS_SUCCESS: 'Fetch_ALL_QUESTION_OPTIONS_SUCCESS',
    ADD_AND_DELETE_QUESTION: 'ADD_AND_DELETE_QUESTION',
    ADD_OPTION_SUCCESS: 'ADD_OPTION_SUCCESS',
    DELETE_OPTION_SUCCESS: 'DELETE_OPTION_SUCCESS',
    SAVE_VOTING_SESSION_SUCCESS: 'SAVE_VOTING_SESSION_SUCCESS',
    Fetch_ALL_VOTING_SESSION_SUCCESS: 'Fetch_ALL_VOTING_SESSION_SUCCESS',
    Fetch_ALL_VOTING_RESPONSES_SUCCESS: 'Fetch_ALL_VOTING_RESPONSES_SUCCESS',
    Fetch_ALL_RESPONSES_TEXT_SUCCESS:'Fetch_ALL_RESPONSES_TEXT_SUCCESS',
    FETCH_LIVE_QUESTIONS_INFO_SUCCESS: 'FETCH_LIVE_QUESTIONS_INFO_SUCCESS',
    STORE_SELECTED_LIVE_SESSION_SUCCESS: 'STORE_SELECTED_LIVE_SESSION_SUCCESS',
    UPDATE_QUESTION_FIELD_SUCCESS:'UPDATE_QUESTION_FIELD_SUCCESS',
    GET_CURRENT_STORED_SESSION_SUCCESS:'GET_CURRENT_STORED_SESSION_SUCCESS',
    DELETE_QUESTION_SESSION_SUCCESS:'DELETE_QUESTION_SESSION_SUCCESS',
    QUESTION_INDEXES_UPDATED_SUCCESS:'QUESTION_INDEXES_UPDATED_SUCCESS',
    Fetch_ALL_ARCHIVED_VOTING_RESPONSES_SUCCESS:'Fetch_ALL_ARCHIVED_VOTING_RESPONSES_SUCCESS',
    Fetch_ALL_ARCHIVED_RESPONSES_TEXT_SUCCESS:'Fetch_ALL_ARCHIVED_RESPONSES_TEXT_SUCCESS',
    OPTION_STATUS_UPDATED_SUCCESS:"OPTION_STATUS_UPDATED_SUCCESS",
    GET_LAST_VOTING_SESSION_SUCCESS:"GET_LAST_VOTING_SESSION_SUCCESS",
    Fetch_VOTING_SESSION_TO_COMPARE_SUCCESS:"Fetch_VOTING_SESSION_TO_COMPARE_SUCCESS",

    /********************* Constants of the survey actions  ************************/
    Fetch_ALL_SURVEY_QUESTIONS_SUCCESS:"Fetch_ALL_SURVEY_QUESTIONS_SUCCESS",
    SAVE_SURVEY_QUESTION_SUCCESS:"SAVE_SURVEY_QUESTION_SUCCESS",
    Fetch_ALL_SURVEY_QUESTION_OPTIONS_SUCCESS:"Fetch_ALL_SURVEY_QUESTION_OPTIONS_SUCCESS",
    DELETE_SURVEY_QUESTION_SUCCESS:"DELETE_SURVEY_QUESTION_SUCCESS",
    ADD_SURVEY_QUESTION_OPTION_SUCCESS:"ADD_SURVEY_QUESTION_OPTION_SUCCESS",
    SURVEY_QUESTION_INDEXES_UPDATED_SUCCESS:"SURVEY_QUESTION_INDEXES_UPDATED_SUCCESS",
    EXTERNAL_SURVEY_CONTENT_UPDATED_SUCCESS:'EXTERNAL_SURVEY_CONTENT_UPDATED_SUCCESS',
    GET_SURVEY_EXTERNAL_LINKS_SUCCESS:'GET_SURVEY_EXTERNAL_LINKS_SUCCESS',

    /********************** Constants of the Big Screen actions **********************/
    GET_EVENT_DATA: 'GET_EVENT_DATA',
    FETCH_QUESTION_DATA_SUCCESS: 'FETCH_QUESTION_DATA_SUCCESS',
    FETCH_ALL_QUESTION_OPTIONS_BIG_SCREEN_SUCCESS: 'FETCH_ALL_QUESTION_OPTIONS_BIG_SCREEN_SUCCESS',
    FETCH_ALL_LIVE_TWEETS_BIG_SCREEN_SUCCESS:'FETCH_ALL_LIVE_TWEETS_BIG_SCREEN_SUCCESS',
    FETCH_ALL_APPROVED_TWEETS_BIG_SCREEN_SUCCESS:'FETCH_ALL_APPROVED_TWEETS_BIG_SCREEN_SUCCESS',
    Fetch_LAST_VOTING_SESSION_SUCCESS:'Fetch_LAST_VOTING_SESSION_SUCCESS',


    /********************** User login or registeration constants ******************/
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    INVALID: 'ALERT_INVALID_FETCH_DATA',
    GET_USER_DATA_ON_CHANGE_SUCCESS:'GET_USER_DATA_ON_CHANGE_SUCCESS',

     /********************** Constants of the Tweet actions **********************/
     SET_CURRENT_TWEET_ELEMENT_SUCCESS:'SET_CURRENT_TWEET_ELEMENT_SUCCESS',
    SET_LIVE_TWEET_ID_SUCCESS: 'SET_LIVE_TWEET_ID_SUCCESS',
    GET_ALL_TWEETS_SUCCESS: 'GET_ALL_TWEETS_SUCCESS',
    UPDATE_TWEET_FIELD_SUCCESS: 'UPDATE_TWEET_FIELD_SUCCESS',
    DELETE_TWEET_SUCCESS:'DELETE_TWEET_SUCCESS',
    STOP_LIVE_TWEET_SUCCESS:'STOP_LIVE_TWEET_SUCCESS',
    SAVE_NEW_TWEET_SUCCESS:'SAVE_NEW_TWEET_SUCCESS',

    /********************* Constants of the elements items action  ************************/
    GET_ELEMENT_ITEM_ON_CHANGE_SUCCESS:"GET_ELEMENT_ITEM_ON_CHANGE_SUCCESS"

}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import 'react-tippy/dist/tippy.css';


class MultipleChoiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createdInputBox: false,
            optionsArray: [],
            saveBtnTitle: '',
            letMultipleChoiceValue: false,
            letMultipleChoiceStatus: 'default',
            correctAnswerStatus: 'default',
            alertType: '',
            questionModal: 'show',
            alertModal: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            correctAnswerBtn: "",
            correctAnswerClass: "",
            optionDecorationStatus:"default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default",
        };
        this.closeQuestion = this.closeQuestion.bind(this);
        this.alertSaveQuestion = this.alertSaveQuestion.bind(this);
        this.chooseCorrectAnswer = this.chooseCorrectAnswer.bind(this);
        this.optionDecorationSelected = this.optionDecorationSelected.bind(this);
        this.handleText = this.handleText.bind(this);

    }

    componentDidMount() {
        this.setState({ sortedOptionsList: this.props.optionsListNew });
    }

    handleText = (e) => {

        switch (e.target.name) {

            case "questionTextMultiple":
                this.setState({ ...this.state, [e.questionTextMultiple]: e.value });
                break;

            case "multipleChoiceLimitAnswer":
                this.setState({ ...this.state, [e.multipleChoiceLimitAnswer]: e.value });
                break;

            case "optionInput":
                this.props.optionsListNew[e.target.id].text = e.target.value;
                //this.setState({ [e.target.optionInput]: e.target.value, newValue: e.target.value });

                break;

            case "optionDecoration":
            this.setState({...this.state, 
                            optionDecorationStatus:"edited"});
            break;

            case "checkBoxMultipleChoice":
                for (var index = 0; index < this.props.optionsListNew.length; index++) {
                    this.props.optionsListNew[index].isAnswer = false;
                }
                if(e.target.checked === true){
                    this.setState({
                        ...this.state,
                        letMultipleChoiceValue: e.target.checked,
                        letMultipleChoiceStatus: 'Edited',
                        multipleChoiceLimitationStatus:"edited"
    
                    });
                    
                }else{
                    this.setState({
                        ...this.state,
                        letMultipleChoiceValue: e.target.checked,
                        letMultipleChoiceStatus: 'Edited',
                        multipleChoiceLimitationStatus:"default"

                    });
                }

                break;

            case "correctAnswer":
                this.setState({
                    ...this.state,
                    correctAnswerStatus: 'Edited',
                    correctAnswerAlert: 'done',
                })

                var indexCorrectAnswer = parseInt(e.target.id.split('-')[1],10);

                //we check if it is radio bax we need to make the others items' corrrect answer to false
                if (e.target.type === "radio") {
                    for (var index1 = 0; index1 < this.props.optionsListNew.length; index1++) {
                        // we used == to compare just values not the type of the indexes!!!!!!
                        if (this.props.optionsListNew[index1].optionsOrderIndex === indexCorrectAnswer) {
                            this.props.optionsListNew[index1].isAnswer = e.target.checked;
                        } else {
                            // in the single choice correct answer we make the correct answer of the other options to false
                            this.props.optionsListNew[index1].isAnswer = false;
                        }
                    }
                } else {
                    for (var index2 = 0; index2 < this.props.optionsListNew.length; index2++) {
                        if (this.props.optionsListNew[index2].optionsOrderIndex === indexCorrectAnswer) {
                            this.props.optionsListNew[index2].isAnswer = e.target.checked;
                        }
                    }
                }

                break;

            default:
                return true;
        }
    }

    chooseCorrectAnswer(option) {
        
        for (var index = 0; index < this.props.optionsListNew.length; index++) {
            this.props.optionsListNew[index].isAnswer = document.getElementById("correctAnswerRadio-" + this.props.optionsListNew[index].optionsOrderIndex).checked;
        }
    }

    alertUser() {
        // let questionText = document.getElementById("questionTextMultiple1").value;
        // if (questionText === "") {
        //     // alert("Please define the question's text.")
        //     this.setState({ alertType: 'emptyQuestionText', alertModal:'show' })
        // } else {
        if (this.state.correctAnswerAlert === 'default') {
            //we check if the user did not select any correct answer yet we alert him one time to do it before saving the question 
            this.setState({ correctAnswerAlert: 'alertUser', correctAnswerStatus: 'alerted' });
        } else {
            this.setState({ correctAnswerAlert: 'done' });
        }
    }

    alertSaveQuestion() {
        var multipleChoiceSelection;
        if (this.state.letMultipleChoiceStatus === 'default') {
            multipleChoiceSelection = this.props.question.letMultipleSelect;
        } else {
            multipleChoiceSelection = this.state.letMultipleChoiceValue
        }

        this.props.saveQuestionFunction('MultipleChoice', this.props.question, multipleChoiceSelection, "default");
        this.setState({
            letMultipleChoiceStatus: 'default',
            letMultipleChoiceValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default'

        });
    }

    saveQuestionFunction() {
        var multipleChoiceSelection;
        var optionDecorationValue;
        var multipleChoiceLimitAnswer;

        if (this.state.letMultipleChoiceStatus === 'default') {
            multipleChoiceSelection = this.props.question.letMultipleSelect;
        } else {
            multipleChoiceSelection = this.state.letMultipleChoiceValue;
        }

        if( multipleChoiceSelection === true){
            multipleChoiceLimitAnswer =  parseInt(document.getElementById("multipleChoiceLimitAnswer").value,10);
        }else{
            multipleChoiceLimitAnswer=0;
        }

        if (this.state.optionDecorationStatus === 'default') {
            optionDecorationValue = this.props.question.optionDecorationValue;
        } else {
            optionDecorationValue = this.state.optionDecorationValue;
        }

        this.props.saveQuestionFunction('MultipleChoice', this.props.question, multipleChoiceSelection, multipleChoiceLimitAnswer, "", optionDecorationValue, "default");
        this.setState({
            letMultipleChoiceStatus: 'default',
            letMultipleChoiceValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            optionDecorationStatus: "default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default"

        });
    }

    closeQuestion() {
        this.setState({
            letMultipleChoiceStatus: 'default',
            letMultipleChoiceValue: false,
            correctAnswerStatus: 'default',
            sortedOptionsList: 'default',
            correctAnswerAlert: 'default',
            optionDecorationStatus: "default",
            optionDecorationValue:"None",
            multipleChoiceLimitationStatus:"default"

        });
        this.props.closeQuestionFunction(this.state.letMultipleChoiceSelection)
    }

    optionDecorationSelected(type){
        this.setState({
            ...this.state, optionDecorationValue:type, optionDecorationStatus:"edited"
        });
    }


    onSortEnd = ({ oldIndex, newIndex }) => {
        var newSortedoptionsList = arrayMove(this.props.optionsListNew, oldIndex, newIndex);
        // this.props.onUpdateEventsIndexes(newSortedoptionsList);
        this.props.onSortEndOptions(newSortedoptionsList);
    };

    render() {
        var sortedOptionsList = this.props.optionsListNew;

        const DragHandle = SortableHandle(({ option }) => {
            return (
                <button type="button" className="rounded border-0 mr-sm-2 mt-1 dragOptionsbtn">
                    <i className="fa fa-fw fa-sort"></i>
                </button>
            )
        });

        const SortableItem = SortableElement(({ option, index }) => {
            var i = option.optionsOrderIndex;
            return (
                <li>
                    <div className="row mb-2 ml-1">
                        <div className="col-sm-11 col-11 col-md-11 col-lg-11">
                            <div className="row border optionsRow" >
                                <div className="col-sm-10 col-10 col-md-10 col-lg-10 p-1">
                                    <input type="text" className="form-control mb-1" id={i} placeholder="add an option" defaultValue={option.text} name="optionInput" onChange={this.handleText} />
                                </div>
                                <div className="col-sm-1 col-1 col-md-1 col-lg-1 pl-1 p-0">
                                    {(this.state.letMultipleChoiceStatus === "default") ? (
                                        <div>
                                            {(this.props.question.letMultipleSelect === true) ? (
                                                <div className="custom-control custom-checkbox" >
                                                    <input type="checkbox" className="custom-control-input correctAnswerRadioLabel" defaultChecked={option.isAnswer} id={'correctAnswerRadio-' + i} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label" htmlFor={'correctAnswerRadio-' + i} id="correctAnswerRadioLabel" ></label>
                                                </div>
                                            ) : (
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input correctAnswerRadioLabel" defaultChecked={option.isAnswer} id={'correctAnswerRadio-' + i} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className=" custom-control-label" id="correctAnswerRadioLabel" htmlFor={'correctAnswerRadio-' + i}></label>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {(this.state.letMultipleChoiceValue === true) ? (
                                                <div className="custom-control custom-checkbox" >
                                                    <input type="checkbox" className="custom-control-input correctAnswerRadioLabel" id={'correctAnswerRadio-' + i} checked={option.isAnswer} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className="custom-control-label" htmlFor={'correctAnswerRadio-' + i} id="correctAnswerRadioLabel" ></label>
                                                </div>
                                            ) : (
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input correctAnswerRadioLabel" id={'correctAnswerRadio-' + i} checked={option.isAnswer} name="correctAnswer" onChange={this.handleText}></input>
                                                    <label className=" custom-control-label" id="correctAnswerRadioLabel" htmlFor={'correctAnswerRadio-' + i}></label>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="col-sm-1 col-1 col-md-1 col-lg-1 pr-1 pl-1">
                                    <button type="button" className="rounded border-0 btn-danger mb-2 mt-2 " onClick={() => { this.props.deleteOptionFunction(i) }}>
                                        <i className="fas fa-trash-alt" id='trash'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1 col-1 col-md-1 col-lg-1 p-0 mt-2 optionsDragableRow">
                            <DragHandle
                                option={option}
                            />
                        </div>
                    </div>
                </li>
            );
        });



        const SortableList = SortableContainer(({ sortedOptionsList }) => {
            return (
                <div>
                    <ul id="questionsList">
                        {sortedOptionsList.map((option, index) => (
                            <SortableItem key={`item-${index}`} index={index} option={option} />
                        ))}
                    </ul>                   
                </div>
            );
        });

        const multipleChoiceComponent = (
            <div>
                {(this.state.letMultipleChoiceStatus === 'default') ? (
                    <div>
                        <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                            <input type="checkbox" className="custom-control-input" id="multipleChoiceSimple" defaultChecked={this.props.question.letMultipleSelect} name='checkBoxMultipleChoice' onChange={this.handleText} />
                            <label className="custom-control-label" htmlFor="multipleChoiceSimple">Let participants select multiple choices</label>
                        </div>
                        {(this.props.question.letMultipleSelect === true)?(
                             <div className="col-7 pl-4 pt-1 mb-2">
                                <input type="number" className="form-control" id="multipleChoiceLimitAnswer" name="multipleChoiceLimitAnswer" defaultValue={this.props.question.multipleChoiceLimitAnswer} onChange={this.handleText}/>
                            </div> 
                        ):(
                            <div></div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div className="custom-control custom-checkbox mr-sm-2 text-left ml-1">
                            <input type="checkbox" className="custom-control-input" id="multipleChoiceSimple" name='checkBoxMultipleChoice' checked={this.state.letMultipleChoiceValue} onChange={this.handleText} />
                            <label className="custom-control-label" htmlFor="multipleChoiceSimple">Let participants select multiple choices</label>
                        </div>
                        <div className="col-7 pl-4 pt-1 mb-2">
                            {(this.state.multipleChoiceLimitationStatus !== "default")?(
                                <input type="number" className="form-control" id="multipleChoiceLimitAnswer" name="multipleChoiceLimitAnswer" placeholder="Set number of selections" onChange={this.handleText}/>
                            ):(
                                <div></div>
                            )}
                        </div> 
                    </div> 
                )}
            </div>
        )

        return (
            <div className="modal fade" id="MultipleChoice" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered questionModalsDialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Multiple Choice Question</h5>
                        </div>
                        <div className="modal-body">
                            <div className="card-body text-center p-1">
                                <form id='multipleChoiceModalsForm'>
                                    <div className="row align-items-center">
                                        <div className="col-auto " id="questionTextMultiple">
                                            <input type="text" className="form-control mb-3 mr-sm-2" id="questionTextMultiple1" placeholder="What would you like to ask?" name="questionTextMultiple" defaultValue={this.props.question.text} onChange={this.handleText} />
                                        </div>
                                    </div>
                                    <div className=" row align-items-center">
                                        <div className="col-sm-1 col-1 col-md-1 col-lg-1 mr-1 ml-3">
                                            <button type="button" htmlFor="inputOption" className="rounded border-0 btn-primary mb-2 mr-sm-2 " onClick={() => { this.props.addNewOptionFunction(this.props.optionsListNew) }}><i className="fas fa-plus" ></i></button>
                                        </div>
                                        {(sortedOptionsList.length === 0)?(
                                            <div className="col-sm-10 col-10 col-md-10 col-lg-10 text-left pb-2 pl-2 text-secondary">
                                                Add an option
                                            </div>
                                        ):(
                                            <div className="col-sm-10 col-10 col-md-10 col-lg-10">
                                                <SortableList sortedOptionsList={sortedOptionsList} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col-12 text-left ml-3">
                                            {multipleChoiceComponent}
                                        </div>
                                    </div>

                                    <div className="row pt-1">
                                        <div className="col-12 text-left ml-3">
                                            {(this.state.optionDecorationStatus === 'default') ? (
                                                <div>
                                                    {(this.props.question.optionDecorationValue && this.props.question.optionDecorationValue !== "default" )?(
                                                        <label className="mr-2 ml-2">
                                                            {"Options Decoration ( " +this.props.question.optionDecorationValue+" )"}
                                                        </label>
                                                    ):(
                                                        <label className="mr-2 ml-2"> Options Decoration ( None )</label>
                                                    )}
                                                    <button type="button" className="btn btn-primary dropdown-toggle optionDecorationBtn" id="optionDecoration" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="optionDecorationBtnName">Choose</span>
                                                    </button>
                                                    <div className="dropdown-menu ml-2 pl-2 pr-2 optionDecoration" aria-labelledby="optionDecoration">
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Number")}}>Number <span className="optionDecorationItemEx">(1, 2,..)</span></button>
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Alphabet")}}>Alphabet <span className="optionDecorationItemEx">(a, b,...)</span></button>
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Bullet")}}>Bullet <span className="optionDecorationItemEx">( • )</span></button>
                                                        <button className="dropdown-item" type="button" onClick={()=>{this.optionDecorationSelected("None")}}>None</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {(this.state.optionDecorationValue !== "default" )?(
                                                        <label className="mr-2 ml-2">
                                                             {"Options Decoration ( " +this.state.optionDecorationValue+" )"}
                                                        </label>
                                                    ):(
                                                        <label className="mr-2 ml-2"> Options Decoration ( None )</label>
                                                    )}
                                                    <button type="button" className="btn btn-primary dropdown-toggle optionDecorationBtn" id="optionDecoration" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                       <span className="optionDecorationBtnName">Choose</span>
                                                    </button>
                                                    <div className="dropdown-menu ml-2 pl-2 pr-2 optionDecoration" aria-labelledby="optionDecoration">
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Number")}}>Number <span className="optionDecorationItemEx">(1, 2,..)</span></button>
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Alphabet")}}>Alphabet <span className="optionDecorationItemEx">(a, b,...)</span></button>
                                                        <button className="dropdown-item optionDecorationItem" type="button" onClick={()=>{this.optionDecorationSelected("Bullet")}}>Bullet <span className="optionDecorationItemEx">( • )</span></button>
                                                        <button className="dropdown-item" type="button" onClick={()=>{this.optionDecorationSelected("None")}}>None</button>
                                                    </div>
                                                </div>
                                             )} 
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeQuestion}>Cancel</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveQuestionFunction.bind(this)}>{this.props.saveBtnTitle}</button>
                        </div>
                    </div>
                </div>


                {/* <div className={"modal " + this.state.alertModal} id="correctAnswerModalAlert" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog alertDialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-3">
                                <h6 className="modal-title" id="exampleModalLabel">Correct Answer Alert !</h6>
                                <p className=" alertText pl-2">You can define the correct answer among the available choices !</p>
                                <div className="modal-footer p-0">
                                    <button type="button" className="btn btn-secondary alertAnswerBtn" data-dismiss="modal" onClick={() => { this.alertChooseAnswer('discard') }}>Discard & Save Question </button>
                                    <button type="button" className="btn btn-primary alertAnswerBtn" onClick={() => { this.alertChooseAnswer('defineAnswer') }}> Define Correct Answer</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        questionList: state.questionReducer.questionList,
        questionOptions: state.questionReducer.questionOptions,
    };
};

export default connect(mapStateToProps, null)(MultipleChoiceModal);

import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import {questionActions} from '../store/actions/QuestionActions';
import {Progress} from 'reactstrap';
import {Textfit} from 'react-textfit';
import AnswerText from '../Components/AnswerText';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import IntroductionPage from '../Components/IntroductionPage';
import Chart from "react-google-charts";
import firebase from '../config/firebase';

var firestore = firebase.firestore();

const data = [
    ["Year", "Visitations", {role: "style"}],
    ["2010", 10, "color: gray"],
    ["2020", 14, "color: #76A7FA"],
    ["2030", 16, "color: blue"],
    ["2040", 22, "stroke-color: #703593; stroke-width: 4; fill-color: #C5A5CF"],
    [
        "2050",
        28,
        "stroke-color: #871B47; stroke-opacity: 0.6; stroke-width: 8; fill-color: #BC5679; fill-opacity: 0.2"
    ]
];


class MultipleQuestionsComparison extends Component {

    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            sessionId: '',
            questionId: '',
            elementId: '',
            cardTitle: '',
            responsesArray: [],
            status: '',
            questionData: [],
            optionsArrayLive: [],
            currentEvent: 'default',
            parentInfo: 'default',
            barChartData: []

        }

        this.escFunction = this.escFunction.bind(this);
        this.getResponses = this.getResponses.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeInitQuestionList) {
            this.unsubscribeInitQuestionList();
        }

        if (this.unsubscribeInitQuestionOptions) {
            this.unsubscribeInitQuestionOptions();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: this.props.currentEvent});
        });

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({...this.state, parentInfo: parentInfo});

            if (this.unsubscribeInitQuestionList) {
                this.unsubscribeInitQuestionList();
            }
            this.props.onInitQuestionList(this.state.eventId, parentInfo.currentQuestionElement, (callbackQuestionsList, unsubscribe) => {
                this.unsubscribeInitQuestionList = unsubscribe;
                // var dataList= ['Question Text', 'Pre Test', 'Post Test'];
                var dataList = [['City', '2010 Population', '2000 Population']];
                this.setState({...this.state, sortedQuestionList: callbackQuestionsList});
                for (var index = 1; index < callbackQuestionsList.length; index++) {
                    if (callbackQuestionsList[index] && callbackQuestionsList[index].selectedToCompare === true && (callbackQuestionsList[index].type === "MultipleChoice" || callbackQuestionsList[index].type === "SimpleTextAndMultipleChoice")) {
                        this.props.onGetAllVotingSessionsToCompare(this.state.eventId, parentInfo.currentQuestionElement, callbackQuestionsList[index], votingListCallback => {
                            if (votingListCallback[1] && votingListCallback[1].id) {
                                this.getResponses(votingListCallback[1], this.state.eventId, parentInfo.currentQuestionElement, votingListCallback[1].question, resultCallback => {
                                    if (this.unsubscribeInitQuestionOptions) {
                                        this.unsubscribeInitQuestionOptions();
                                    }
                                    this.props.onInitQuestionOptions(resultCallback.eventId, resultCallback.idQuestionEl, resultCallback.question.id, (callbackOptionsList, unsubscribe) => {
                                        this.unsubscribeInitQuestionOptions = unsubscribe;
                                        var itemExists = false;
                                        if (resultCallback.responses.length > 0) {
                                            var item = [resultCallback.question.text]
                                            for (var m = 0; m < callbackOptionsList.length; m++) {
                                                var itemVote = 0;
                                                var option = callbackOptionsList[m];
                                                for (var j = 0; j < resultCallback.responses.length; j++) {
                                                    var response = resultCallback.responses[j];
                                                    if (option.id === response.option) {
                                                        itemVote += 1;
                                                    }
                                                }
                                                item.push(itemVote);
                                            }
                                            // var dataList = [['City', '2010 Population', '2000 Population'],["123", 0, 1], ["sample multiple question 2", 2, 0]];
                                            dataList = dataList.concat([item]);
                                            // dataList.push(item);
                                            this.setState({...this.state, barChartData: dataList});
                                        }
                                    });
                                });
                            }
                        });
                    }
                }
            });
        });
        /*************** BACK HISTORY BUTTON ****************/
        window.onpopstate = () => {
        }
    }

    getResponses(votingSession, eventId, idQuestionEl, question, callback) {
        if (votingSession && question) {
            firestore.collection("events/" + this.state.eventId + "/elements/default/elementsItems/" + idQuestionEl + "/questions/" + question.id + "/VotingSession/" + votingSession.id + "/Responses").get().then(querySnapShot => {
                let archivedResponsesList = [];
                if (question.type !== "SimpleTextQuestion") {
                    querySnapShot.forEach(document => {
                        let response = {
                            id: document.id,
                            option: document.data().option,
                            participantsId: document.data().participantsId,
                            isHidden: document.data().isHidden,
                        };
                        archivedResponsesList.push(response);
                    });
                } else {
                    querySnapShot.forEach(document => {
                        let response = {
                            id: document.id,
                            sAnswer: document.data().sAnswer,
                            sName: document.data().sName,
                            participantsId: document.data().participantsId,
                            dtmCreated: document.data().dtmCreated,
                            isHidden: document.data().isHidden,
                        };
                        archivedResponsesList.push(response);
                    });
                }
                callback({
                    session: votingSession,
                    question: question,
                    responses: archivedResponsesList,
                    eventId: eventId,
                    idQuestionEl: idQuestionEl
                });
            });
        }
    }

    escFunction(event) {

        if (event.keyCode === 27) {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }

    }

    render() {

        var middleContainerComponent = (
            <div>
                {/* <Chart chartType="BarChart" width="100%" height="400px" data={data} /> */}
                <Chart
                    // width={'500px'}
                    // height={'300px'}
                    width="100%"
                    height="400px"
                    chartType="BarChart"
                    backgroundColor="transparent"
                    loader={<div>Loading Chart</div>}
                    data={this.state.barChartData}
                    // data={[['City', '2010 Population', '2000 Population'],["123", 0, 1], ["sample multiple question 2", 2, 0]]}
                    // data={[
                    //     ['City', '2010 Population', '2000 Population'],
                    //     ['123', 0, 1],
                    //     ['Los Angeles, CA', 2, 4],
                    //     ['Chicago, IL', 3, 0],
                    //     ['Houston, TX', 1, 4],
                    //     ['Philadelphia, PA', 2, 6],
                    //   ]}
                    options={{
                        title: 'Population of Largest U.S. Cities',
                        chartArea: {width: '50%'},
                        hAxis: {
                            title: 'Votes for Options',
                            minValue: 0,
                        },
                        vAxis: {
                            title: 'Question Title',
                        },
                    }}
                    rootProps={{'data-testid': '1'}}
                />
            </div>
        )

        return (
            <div style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    (this.state.currentEvent.showIntroductionPage === "Yes") ? (

                        <div className="row containerIntroduction text-center"
                             style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                            <IntroductionPage
                                currentEventToIntroduction={this.state.currentEvent}
                            />
                        </div>

                    ) : (

                        <div>
                            <div className="LiveScreenContainer">
                                <div className="headerBigScreen"
                                     style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}>
                                    {(this.state.currentEvent.voteCounterStatus === 'Active' && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice")) ? (

                                        <div className="row">
                                            <div className="col-9 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})</div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                ) : (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                )}
                                            </div>
                                            <div className="col-3 text-center pt-4"></div>
                                        </div>

                                    ) : (

                                        <div className="row">
                                            <div className="col-12 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                ) : (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* middle container */}
                                {(this.state.currentEvent.themeBigScreenImageUrl === "default") ? (

                                    <div>
                                        {middleContainerComponent}
                                    </div>

                                ) : (

                                    <div className="imageTheme"
                                         style={{backgroundImage: "url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}}>
                                        {middleContainerComponent}
                                    </div>

                                )}
                            </div>
                        </div>
                    )
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventData: state.bigScreenReducer.eventData,
        questionOptions: state.bigScreenReducer.questionOptions,
        questionData: state.bigScreenReducer.questionData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(bigScreenActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onInitQuestionOptions: (eventId, idElement, questionId, callback) => dispatch(bigScreenActions.getAllQuestionOptionsScreen(eventId, idElement, questionId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onInitQuestionList: (eventId, idQuestionEl, callback) => dispatch(questionActions.getAllQuestions(eventId, idQuestionEl, callback)),
        onGetAllVotingSessionsToCompare: (eventId, idQuestionEl, question, callback) => dispatch(questionActions.getAllVotingSessionsToCompare(eventId, idQuestionEl, question, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleQuestionsComparison);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';

class EventInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            filePreviewStatus: 'default',
            selectedFileToUpload: [],
            sortedFilesList: [],
            editingItem: 'default',
        };
        this.handleDropFile = this.handleDropFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.saveEventInfoFile = this.saveEventInfoFile.bind(this);
        // this.removeFile = this.removeFile.bind(this);
        // this.editFileName = this.editFileName.bind(this);
        // this.saveEditedFile = this.saveEditedFile.bind(this);
    }

    handleUploadFile(e) {

        let filePath = "/files/" + e.target.files[0].name;
        let targetFile = e.target.files[0].name;
        var previewFile = document.getElementById('filePreview');
        var file = document.getElementById('inputFilePreview').files[0];
        var FileSize = Math.round(file.size / 1024); // 
        var validFileExtensions = ["All image formats", ".pdf"];
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png", "applicayion/pdf"]

        if (!fileTypes.includes(file.type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 3000 KB.");
        } else {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                previewFile.src = reader.result;
            }, false);

            if (file) {
                reader.readAsDataURL(file);
                
                this.setState({
                    ...this.state,
                    filePath: filePath,
                    filePreviewStatus: 'selectedFile',
                    uploadFiles: file,
                    selectedFileToUpload: file
                });
            
            }
        }
    }

    handleDropFile(files) {
        
        var validFileExtensions = ["All image formats", ".pdf"];
        var fileTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png", "application/pdf"]
        var FileSize = Math.round(files[0].size / 1024);
        let fileLocalPath = "/files/" + files[0].name;
        
        if (!fileTypes.includes(files[0].type)) {
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 3000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 100 KB.");
        } else {
            if (files[0]) {
                
                this.setState({
                    ...this.state,
                    filePath: fileLocalPath,
                    // uploadFiles: files[0],
                    uploadFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    filePreviewStatus: 'drag&drop',
                    selectedFileToUpload: files[0]
                });
            
            }
        }
    }

    saveEventInfoFile(){
        const imageFile = this.state.selectedFileToUpload;
        this.props.saveEventInfoFile(imageFile);
    }

    render() {
        
        const { uploadFiles } = this.state;
        let filePreviewComponent;
        if (this.state.filePreviewStatus === 'drag&drop') {
            filePreviewComponent = (
                <div >{uploadFiles.map((file) => <img src={file.preview} id="imagePreview" className='img-fluid img-thumbnail logoEditModalImage' />)}</div>
            )
        } else {
            filePreviewComponent = (
                <img src={"/files/file-upload.png"} id="imagePreview" className='img-fluid img-thumbnail logoEditModalImage' style={{ backgroundColor: '#e6e6e6' }} />
            )
        }

        return (
            <div>
                <form>
                    <div className="form-group">
                        <label htmlFor="titleInput">Title</label>
                        <input type="text" className="form-control" id="titleInput" placeholder="Title" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailAddressInfo">Email address</label>
                        <input type="email" className="form-control" id="emailAddressInfo" placeholder="name@example.com" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="optionInput">Option</label>
                        <div className="row  align-items-center">
                            <div className="col-11">
                                <input type="text" className="form-control" id="optionInput" placeholder="Add Option" required />
                            </div>
                            <div className="col-1">
                                <i className="fas fa-plus-square bg-white text-success optionAddIcon"></i>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="noteInfo">Note</label>
                        <textarea className="form-control" id="noteInfo" rows="3"></textarea>
                    </div>
                    <div className="row align-items-end">
                        <div className="col-2">
                            <label htmlFor="inputLogo">Choose Image</label>
                            <Dropzone
                                className="eventInfoDropZone d-flex text-center"
                                onDrop={this.handleDropFile}
                            >
                                <div>
                                    {filePreviewComponent}
                                </div>
                            </Dropzone>
                            <input type="file" className="form-control" id="inputFilePreview" hidden={true} placeholder="Logo" onChange={this.handleUploadFile} />
                        </div>
                        <div className="col-4 text-left p-0 pt-2">
                            {this.state.selectedFileToUpload.name}
                        </div>
                    </div>
                    <div className="form-group row pt-5">
                        <div className="col-12 text-center">
                            <button type="button" className="col-2 btn btn-secondary mr-2" onClick={()=>{this.props.cancelEventInfo()}}>Cancel</button>
                            <button type="button" className="col-2 btn btn-success" onClick={this.saveEventInfoFile}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
    };
};
const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfoComponent);
import React, {Component} from 'react';
import Header from '../Components/Header';
import Question from '../Components/Question';
import MultipleChoiceModal from '../Modals/MultipleChoiceModal';
import SimpleTextModal from '../Modals/SimpleTextModal';
import QuestionTypesModal from '../Modals/QuestionTypesModal';
import {questionActions} from '../store/actions/QuestionActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import HeaderTab from '../Components/HeaderTab';
import SingleQuestionComponent from '../Components/SingleQuestionComponent';
import EditModal from '../Modals/EditModal';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import QuestionToDelete from '../Modals/QuestionToDelete';
import SimpleTextAndMultipleChoice from '../Modals/SimpleTextAndMultipleChoice';
import ExportQuestionsModal from '../Modals/ExportQuestionsModal';
import CompareQuestionsModal from '../Modals/CompareQuestionsModal';
import firebase from '../config/firebase';
import RatingModal from '../Modals/RatingModal';


var firestore = firebase.firestore();


class QuestionsScreen extends Component {

    //Instaed of having the question component inside the header we need to seperate it 
    //because of having the possibility to set the state of the variables

    constructor(props) {
        super(props);

        this.state = {
            currentEvent: 'default',
            parentInfo: 'default',
            optionsArray: [],
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            idQuestionEl: this.props.match.params.idItem,
            modalType: '',
            optionId: '',
            deletedOptionsArray: [],
            multipleChoiceState: 'default',
            modalTypeSimple: false,
            saveBtnTitle: '',
            user: '',
            questionToDelete: '',
            deleteModalType: '',
            currentQuestionToEdit: 'default',
            sortedQuestionList: [],
            currentQuestion: '',
            correctAnswerList: [],
            tabScreenOpened: false,
            validVotingSessions: [],
            votingSessionList: [],
        }
        this.handleNewQuestionBtn = this.handleNewQuestionBtn.bind(this);
        this.getResponses = this.getResponses.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            if (this.unsubscribeGetEventInfoOnChange) {
                this.unsubscribeGetEventInfoOnChange();
            }
            //in the first initialization of the page we set the current event for the header and the other components
            this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeGetEventInfoOnChange = unsubscribe;
                this.setState({...this.state, user: user, currentEvent: this.props.currentEvent});
            });

            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({...this.state, parentInfo: parentInfo});
            });

            if (this.unsubscribeInitQuestionList) {
                this.unsubscribeInitQuestionList();
            }
            this.props.onInitQuestionList(this.state.eventId, this.state.idQuestionEl, (callbackQuestionsList, unsubscribe) => {
                this.unsubscribeInitQuestionList = unsubscribe;
                //Starting the live question
                // this.setState({ ...this.state, user: user, currentEvent:this.props.currentEvent });   
                this.setState({...this.state, sortedQuestionList: callbackQuestionsList});
            });
        } else {
            this.props.history.push('/login');

        }

        // window.onpopstate = (event) => {
        // 	//We call the close button to close the modal when the user clicks on the back btn on the browser use the back btn of the device
        // 	if (document.getElementById("closeNewMessageModal")) {
        //         document.getElementById("closeNewMessageModal").click();
        // 		this.props.history.goBack();            
        //     }

        //     // to close the edit event setting modal if it is open in any screen that we have the header tab
        //         if (document.getElementById("hidePopUpBtn")) {
        //             document.getElementById("hidePopUpBtn").click();  
        //             this.props.history.goBack();      
        //      }
        // };


    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeInitQuestionList) {
            this.unsubscribeInitQuestionList();
        }

        if (this.unsubscribeInitQuestionOptions) {
            this.unsubscribeInitQuestionOptions();
        }
    }

    saveQuestion(type, question, letMultipleChoiceSelection, multipleChoiceLimitAnswer, simpleTextAnswerMandatory, optionDecorationValue, layout) {
        if (this.state.saveBtnTitle === "Duplicate") {
            //create new question (duplicate question)
            //we set the question's id to zero so it will be trated as a new question to save it
            question.id = 0;
            question.showCorrectAnswerColor = "default"
        }
        var options = this.state.optionsArray;
        if (type === 'MultipleChoice') {
            let questionText = document.getElementById("questionTextMultiple1").value;

            let questionData = {
                id: question.id,
                firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                text: questionText,
                type: type,
                letMultipleSelect: letMultipleChoiceSelection,
                optionsValues: options,
                questionOrderIndex: question.questionOrderIndex,
                showCorrectAnswerColor: question.showCorrectAnswerColor,
                layout: "default",
                optionDecorationValue: optionDecorationValue,
                multipleChoiceLimitAnswer: multipleChoiceLimitAnswer
            };

            if (questionText !== "" && options.length !== 0) {
                this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, this.state.deletedOptionsArray, callback => {
                    document.getElementById('multipleChoiceModalsForm').reset();
                    if (question.id === 0) {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    } else {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    }
                });
            } else if (questionText !== "" && options.length === 0) {
                //If the user does not provide any options for the question we will save it as a simple question.
                let questionText = document.getElementById("questionTextMultiple1").value;
                let questionData = {
                    id: question.id,
                    text: questionText,
                    type: 'SimpleTextQuestion',
                    firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                    questionOrderIndex: question.questionOrderIndex,
                    layout: "default"
                };
                this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, 'SimpleTextQuestion', callback => {
                    document.getElementById('multipleChoiceModalsForm').reset();

                    if (question.id === 0) {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    } else {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    }
                });
            }
        } else if (type === 'SimpleTextQuestion') {
            let questionText = document.getElementById("simpleTextQuestion").value;
            let questionData = {
                id: question.id,
                firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                text: questionText,
                type: type,
                questionOrderIndex: question.questionOrderIndex,
                layout: "default"
            };
            this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, 'SimpleTextQuestion', callback => {
                document.getElementById('simpleQuestionModalsForm').reset();
            });
        } else if (type === "SimpleTextAndMultipleChoice") {
            let questionText = document.getElementById("questionTextMultipleComment").value;

            let questionData = {
                id: question.id,
                firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                text: questionText,
                type: type,
                letMultipleSelect: letMultipleChoiceSelection,
                optionsValues: options,
                questionOrderIndex: question.questionOrderIndex,
                showCorrectAnswerColor: question.showCorrectAnswerColor,
                simpleTextAnswerMandatory: simpleTextAnswerMandatory,
                layout: layout,
                optionDecorationValue: optionDecorationValue,
                multipleChoiceLimitAnswer: multipleChoiceLimitAnswer

            };

            if (questionText !== "" && options.length !== 0) {
                this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, this.state.deletedOptionsArray, callback => {
                    document.getElementById('multipleChoiceCommentModalsForm').reset();
                    if (question.id === 0) {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    } else {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    }
                });
            } else if (questionText !== "" && options.length === 0) {
                //If the user does not provide any options for the question we will save it as a simple question.
                let questionText = document.getElementById("questionTextMultipleComment").value;
                let questionData = {
                    id: question.id,
                    text: questionText,
                    type: 'SimpleTextQuestion',
                    firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                    questionOrderIndex: question.questionOrderIndex,
                    layout: layout
                };
                this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, 'SimpleTextQuestion', callback => {
                    document.getElementById('multipleChoiceCommentModalsForm').reset();

                    if (question.id === 0) {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    } else {
                        this.setState({
                            optionsArray: [],
                            multipleChoiceState: letMultipleChoiceSelection,
                        });
                    }
                });
            }
        } else if (type === 'RatingQuestion') {
            let questionText = document.getElementById("ratingTextQuestion").value;
            let numberOfStars = document.getElementById("ratingNumberofStars").value;
            let questionData = {
                id: question.id,
                firstVotingSessionInitialized: question.firstVotingSessionInitialized,
                text: questionText,
                type: type,
                questionOrderIndex: question.questionOrderIndex,
                layout: "default",
                numberOfStars: numberOfStars
            };
            this.props.onSaveQuestion(this.state.eventId, this.state.idQuestionEl, questionData, 'RatingQuestion', callback => {
                document.getElementById('ratingQuestionModalsForm').reset();
            });
        }
    }

    closeQuestion(letMultipleChoiceSelection) {
        document.getElementById('multipleChoiceModalsForm').reset();
        document.getElementById('simpleQuestionModalsForm').reset();
        this.setState({
            optionsArray: [],
            multipleChoiceState: letMultipleChoiceSelection,
            currentQuestion: 'default',
            modalType: ''
        });
    }

    deleteQuestionFunction(question) {
        this.setState({...this.state, questionToDelete: question});
    }

    deleteQuestion(question) {
        if (question.id === this.state.parentInfo.currentQuestion && question.id === this.state.parentInfo.currentLiveSession.liveQuestionId) {
            //we need to remove also the live quetion window so we update the current question field as default in firebase
            this.props.onDeleteQuestion(this.state.eventId, this.state.idQuestionEl, question);

            this.props.onUpdateElementData(this.state.eventId, "updateCurrentQuestionLiveOnDelete", this.state.idElement, "", callback => {
            });

            this.setState({...this.state, questionToDelete: ""});
        } else if (question.id === this.state.parentInfo.currentQuestion) {
            //we need to remove also the live quetion window so we update the current question field as default in firebase
            this.props.onDeleteQuestion(this.state.eventId, this.state.idQuestionEl, question);

            this.props.onUpdateElementData(this.state.eventId, "updateCurrentQuestionOnDelete", this.state.idElement, "", callback => {
            });
            this.setState({...this.state, questionToDelete: ""});
        } else {
            this.props.onDeleteQuestion(this.state.eventId, this.state.idQuestionEl, question);
            this.setState({...this.state, questionToDelete: ""});
        }
    }

    editQuestion(question) {
        if (question.type === "MultipleChoice") {
            //receive the list of the question answers (options)
            if (this.unsubscribeInitQuestionOptions) {
                this.unsubscribeInitQuestionOptions();
            }
            this.props.onInitQuestionOptions(this.state.eventId, this.state.idQuestionEl, question.id, (callback, unsubscribe) => {
                this.unsubscribeInitQuestionOptions = unsubscribe;
                var correctAnswers = [];
                for (var index = 0; index < callback.length; index++) {
                    if (callback[index] && callback[index].isAnswer === true) {
                        correctAnswers.push(callback[index]);
                    }
                }
                this.setState({
                    ...this.state,
                    currentQuestionToEdit: question,
                    saveBtnTitle: 'Update',
                    modalType: "MultipleChoice",
                    optionsArray: callback,
                    correctAnswerList: correctAnswers
                });
            });
        } else if (question.type === "SimpleTextQuestion") {
            this.setState({
                ...this.state,
                currentQuestionToEdit: question,
                saveBtnTitle: 'Update',
                modalType: "SimpleTextQuestion"
            });
        } else if (question.type === "SimpleTextAndMultipleChoice") {
            if (this.unsubscribeInitQuestionOptions) {
                this.unsubscribeInitQuestionOptions();
            }
            this.props.onInitQuestionOptions(this.state.eventId, this.state.idQuestionEl, question.id, (callback, unsubscribe) => {
                this.unsubscribeInitQuestionOptions = unsubscribe;
                var correctAnswers = [];
                for (var index = 0; index < callback.length; index++) {
                    if (callback[index] && callback[index].isAnswer === true) {
                        correctAnswers.push(callback[index]);
                    }
                }
                this.setState({
                    ...this.state,
                    currentQuestionToEdit: question,
                    saveBtnTitle: 'Update',
                    modalType: "SimpleTextAndMultipleChoice",
                    optionsArray: callback,
                    correctAnswerList: correctAnswers
                });
            });
        } else if (question.type === 'RatingQuestion') {
            this.setState({
                ...this.state,
                currentQuestionToEdit: question,
                saveBtnTitle: 'Update',
                modalType: "RatingQuestion",
            });
        }
    }

    duplicateQuestion(question) {
        var indexToDuplicate = this.state.sortedQuestionList.length + 1;
        let questionData = {
            id: 0,
            text: question.text,
            type: question.type,
            sort: question.sort,
            letMultipleSelect: question.letMultipleSelect,
            questionIsDeleted: false,
            firstVotingSessionInitialized: false,
            questionOrderIndex: indexToDuplicate,
            simpleTextAnswerMandatory: question.simpleTextAnswerMandatory,
            layout: question.layout,
            optionDecorationValue: question.optionDecorationValue,
            multipleChoiceLimitAnswer: question.multipleChoiceLimitAnswer,
        };

        if (questionData.type === "MultipleChoice") {
            //receive the list of the question answers (options)
            if (this.unsubscribeInitQuestionOptions) {
                this.unsubscribeInitQuestionOptions();
            }
            this.props.onInitQuestionOptions(this.state.eventId, this.state.idQuestionEl, question.id, (callback, unsubscribe) => {
                this.unsubscribeInitQuestionOptions = unsubscribe;
                this.setState({
                    ...this.state,
                    currentQuestionToEdit: questionData,
                    saveBtnTitle: 'Duplicate',
                    modalType: "MultipleChoice",
                    optionsArray: callback,
                });
            });
        } else if (questionData.type === "SimpleTextQuestion") {
            this.setState({
                ...this.state,
                currentQuestionToEdit: questionData,
                saveBtnTitle: 'Duplicate',
                modalType: "SimpleTextQuestion"
            });
        } else if (questionData.type === "SimpleTextAndMultipleChoice") {
            if (this.unsubscribeInitQuestionOptions) {
                this.unsubscribeInitQuestionOptions();
            }
            this.props.onInitQuestionOptions(this.state.eventId, this.state.idQuestionEl, question.id, (callback, unsubscribe) => {
                this.unsubscribeInitQuestionOptions = unsubscribe;
                this.setState({
                    ...this.state,
                    currentQuestionToEdit: questionData,
                    saveBtnTitle: 'Duplicate',
                    modalType: "SimpleTextAndMultipleChoice",
                    optionsArray: callback,
                });
            });
        } else if (questionData.type === 'RatingQuestion') {
            this.setState({
                ...this.state,
                currentQuestionToEdit: questionData,
                saveBtnTitle: 'Duplicate',
                modalType: "RatingQuestion",
            });
        }
    }

    handleNewQuestionBtn() {
        var indexToAdd = this.state.sortedQuestionList.length + 1;
        //this.props.history.push("/sort");
        // when we add the item we use the default values after that when we save or close the modal we will use the default keyword for differentiating the states!
        let questionData1 = {
            id: 0,
            text: '',
            firstVotingSessionInitialized: false,
            questionOrderIndex: indexToAdd,
            letMultipleSelect: false,
            showCorrectAnswerColor: "default",
            simpleTextAnswerMandatory: false,
            optionDecorationValue: "None",
            layout: "default",
            numberOfStars: 5,
        };
        this.setState({
            ...this.state, saveBtnTitle: 'Save',
            currentQuestionToEdit: questionData1,
            // currentQuestion: questionData1,
            // currentQuestionArchive: questionData1,
            optionsArray: [],
            correctAnswerList: [],
        });
    }


    selectedModal(type) {
        if (type === 'MultipleChoice') {
            this.setState({...this.state, modalType: "MultipleChoice"});
        } else if (type === 'SimpleTextQuestion') {
            this.setState({...this.state, modalType: "SimpleTextQuestion"});
        } else {
            this.setState({...this.state, modalType: "SimpleTextAndMultipleChoice"});
        }
    }

    addNewOption(optionsList) {
        var indexToAdd = optionsList.length;
        optionsList.push({id: "newValue", text: '', isAnswer: false, optionsOrderIndex: indexToAdd});
        this.setState({...this.state, optionsArray: optionsList});
    }

    addLikeDislikeOption() {
        var optionsList = [{
            id: "newValue",
            text: ' ',
            isAnswer: false,
            optionsOrderIndex: 0,
            type: "like"
        }, {id: "newValue", text: ' ', isAnswer: false, optionsOrderIndex: 1, type: "dislike"}]
        this.setState({...this.state, optionsArray: optionsList});
    }

    resetOptionsList() {
        //if the user uses the go back on adding new question we need also to delete the entered options
        this.setState({...this.state, optionsArray: []});
    }

    deleteOption(i) {
        //we need to find the item to delete based on its index which is the optionsOrder
        for (var index = 0; index < this.state.optionsArray.length; index++) {
            if (this.state.optionsArray[index].optionsOrderIndex === i) {
                let optionsArray = [
                    ...this.state.optionsArray.slice(0, index),
                    ...this.state.optionsArray.slice(index + 1)
                ]
                this.state.deletedOptionsArray.push(this.state.optionsArray[index])
                this.setState({
                    optionsArray
                })
            }
        }
    }


    goToTweetScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);
        }
    }

    setArchivedSession(question) {
        //update the event with the selected question's id and the first session 
        // let value = { idQuestionEl: this.state.idQuestionEl, questionId: question.id }
        // this.props.onUpdateCurrentField("setArchivedQuestion", this.state.eventId, value, callback => {
        // });
        let value = {
            currentQuestion: question.id,
            currentQuestionElement: this.state.idQuestionEl,
        }
        this.props.onUpdateElementData(this.state.eventId, "setArchivedQuestion", this.state.parentInfo.id, value, callback => {
        });

        this.setState({...this.state, currentQuestion: question});

    }

    goToBigScreen() {
        if (this.props.currentEvent.screenNumber === "Two") {
            // this.props.history.push("/event/" + this.state.eventId + "/liveQuestion");
            //Open the big screen page on the new Tab
            var win = window.open("/event/" + this.state.eventId + "/liveQuestion", '_blank');
            win.focus();
        } else {
            //we update the current field to remove the current live tweet session so the only live session will become the question
            this.props.onUpdateCurrentField("liveScreen-question", this.state.eventId, this.props.currentEvent.currentQuestionElement, callback => {
                // this.props.history.push("/event/" + this.state.eventId + "/live");
            });

            if (this.props.updatedCurrentFiledstatus === 'success') {
                //Open the big screen page on the new Tab   
                var win = window.open("/event/" + this.state.eventId + "/live", '_blank');
                win.focus();
            }
        }
    }

    handleEditEvent() {
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }


    onSortEnd = ({oldIndex, newIndex}) => {
        const {sortedQuestionList} = this.state;
        var newSortedList = arrayMove(sortedQuestionList, oldIndex, newIndex);
        this.props.onUpdateQuestionsIndexes(this.props.currentEvent.id, this.state.parentInfo.currentQuestionElement, newSortedList);
        this.setState({
            ...this.state, sortedQuestionList: arrayMove(sortedQuestionList, oldIndex, newIndex)
        });
    };

    onSortEndOptions(sortedOptionsList) {
        this.setState({
            optionsArray: sortedOptionsList
        })
    };


    goIntroductionPage() {
        if (this.state.tabScreenOpened === false) {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                var win = window.open("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/live", '_blank');
                win.focus();
                this.setState({...this.state, tabScreenOpened: true})
            });
        } else {
            this.props.onUpdateCurrentField("updateShowIntroductionPage", this.state.eventId, "Yes", callback => {
                this.setState({...this.state, tabScreenOpened: true})
            });
        }
    }

    getFirstQuestionVotingSessionsLength(question) {

    }

    getVotingSessions(question) {
        var validVotingSessions = [];

        this.props.onGetAllVotingSessions(this.state.eventId, this.state.idQuestionEl, question.id, votingSessionsCallback => {
            for (var i = 0; i < votingSessionsCallback.length; i++) {
                this.getResponses(votingSessionsCallback[i], question, resultCallback => {
                    if (resultCallback.responses.length > 0) {
                        validVotingSessions.push(resultCallback);
                        this.setState({
                            ...this.state,
                            validVotingSessions,
                        });
                    }
                });
            }
        });
    }

    getResponses(votingSession, question, callback) {

        firestore.collection("events/" + this.state.eventId + "/elements/default/elementsItems/" + this.state.idQuestionEl + "/questions/" + question.id + "/VotingSession/" + votingSession.id + "/Responses")
            .get().then(querySnapShot => {
            let archivedResponsesList = [];
            if (question.type !== "SimpleTextQuestion") {
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        option: document.data().option,
                        participantsId: document.data().participantsId,
                        isHidden: document.data().isHidden,
                        sName: document.data().sName,
                    };
                    archivedResponsesList.push(response);
                });
            } else {
                querySnapShot.forEach(document => {
                    let response = {
                        id: document.id,
                        sAnswer: document.data().sAnswer,
                        sName: document.data().sName,
                        participantsId: document.data().participantsId,
                        dtmCreated: document.data().dtmCreated,
                        isHidden: document.data().isHidden,
                    };
                    archivedResponsesList.push(response);
                });
            }

            callback({
                session: votingSession,
                question: question,
                responses: archivedResponsesList
            });
        });
    }

    resultsToCompare(selectedQuestions, selectedSessions, mainQuestionConsidered) {
        for (var index = 1; index < selectedQuestions.length; index++) {
            if (selectedQuestions[index] !== null, selectedQuestions[index].selected && selectedQuestions[index].type !== "SimpleTextQuestion") {
                if (selectedQuestions[index].id === mainQuestionConsidered.id) {
                    var selectedSessionsTime = [];
                    for (var i = 0; i < selectedSessions.length; i++) {
                        selectedSessionsTime.push({timeStamp: selectedSessions[i].timeStamp});
                    }
                    this.props.onUpdateQuestionField("mainSelectedQuestionToCompare", this.state.eventId, this.state.idQuestionEl, selectedQuestions[index], selectedSessionsTime, callback => {
                    });
                } else {
                    this.props.onUpdateQuestionField("selectedToCompare", this.state.eventId, this.state.idQuestionEl, selectedQuestions[index], selectedQuestions[index].selected, callback => {
                    });
                }

            } else {
                //we update also the previous selected questions to compare to false
                this.props.onUpdateQuestionField("selectedToCompare", this.state.eventId, this.state.idQuestionEl, selectedQuestions[index], false, callback => {
                });
            }

        }
        this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/multipleQuestionsComparison");

    }


    render() {
        var selectedLanguageStrings;
        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        const {sortedQuestionList} = this.state;
        // const DragHandle = SortableHandle(() => <span>::</span>); // This can be any component you want
        const DragHandle = SortableHandle(({question}) => {
            return (
                <div className="col-12 d-flex justify-content-left dragableContainer">
                    <div className="grid">
                        <div className="row">
                            <div className="col-12 text-left pl-3 pr-3">
                                <h5>{question.text}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        const SortableItem = SortableElement(({question}) => {
            var borderCardColor;
            var borderPadding;
            if (question.id === this.state.parentInfo.currentLiveSession.liveQuestionId && this.state.parentInfo.currentLiveSession.status === "live") {
                borderCardColor = '#28a745'
                borderPadding = "1px";
            } else {
                borderCardColor = "#00000020"
                borderPadding = "1px";
            }

            return (
                <li>
                    <div className="row mb-2" id={'question' + question.id}>
                        <div className="col-12">
                            <div className="card" style={{borderColor: borderCardColor, borderWidth: borderPadding}}>
                                <div className="card-body p-3">
                                    <div className="row">
                                        <DragHandle
                                            question={question}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-7 col-sm-7 col-md-9 col-lg-8 col-xl-9 pl-3">
                                            {(question.type === 'MultipleChoice') ? (
                                                <form className="form-inline">
                                                    <i className="rounded text-white fas fa-tasks questionIcon mr-2 bg-primary p-1"></i>
                                                    <div className=" questionTypeText text-primary">Multiple Choice
                                                    </div>
                                                </form>
                                            ) : (
                                                (question.type === 'SimpleTextAndMultipleChoice') ? (
                                                    <form className="form-inline">
                                                        <i className="rounded text-white far fa-comments questionIcon mr-2 bg-success p-1"></i>
                                                        <div className=" questionTypeText text-success">Multiple Choice
                                                            & Free Comment
                                                        </div>
                                                    </form>
                                                ) : (
                                                    (question.type === 'RatingQuestion') ? (
                                                        <form className="form-inline">
                                                            <i className="rounded text-white far fa-star questionIcon mr-2 bg-danger p-1"></i>
                                                            <div className=" questionTypeText text-danger">Rating</div>
                                                        </form>

                                                    ) : (
                                                        <form className="form-inline">
                                                            <i className="rounded text-white fas fa-align-left questionIcon mr-2 bg-warning p-1 pr-2"></i>
                                                            <div className=" questionTypeText text-warning">Simple
                                                                Text
                                                            </div>
                                                        </form>
                                                    )
                                                )
                                            )}
                                        </div>
                                        <div
                                            className="col-5 col-sm-5 col-md-3 col-lg-4 col-xl-3 d-flex justify-content-center  questionFunctionsContainer">
                                            <Question
                                                question={question}
                                                modalType={this.state.modalType}
                                                editFunction={this.editQuestion.bind(this)}
                                                setArchivedSession={this.setArchivedSession.bind(this)}
                                                duplicateQuestion={this.duplicateQuestion.bind(this)}
                                                event={this.props.currentEvent}
                                                deleteQuestionFunction={this.deleteQuestionFunction.bind(this)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });

        const SortableList = SortableContainer(({sortedQuestionList}) => {
            return (
                <ul id="questionsList" className="roomQuestions">
                    {sortedQuestionList.map((question, index) => (
                        <SortableItem key={`item-${index}`} index={index} question={question}/>
                    ))}
                </ul>
            );
        });


        return (

            <div>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <div>
                        <Header
                            page="QuestionScreen"
                            goToBigScreen={this.goToBigScreen.bind(this)}
                            goIntroductionPage={this.goIntroductionPage.bind(this)}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"question"}

                        />
                        <HeaderTab
                            goToTweetScreen={this.goToTweetScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            navItemQuestion={"border-bottom"}
                            activeScreen={'question'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}

                        />
                        <main role="main">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6 col-sm-3 text-center text-sm-left">
                                        <button type="button" data-toggle="modal" data-target="#QuestionTypesModal"
                                                className="btn btn-outline-primary float-left mb-3"
                                                onClick={this.handleNewQuestionBtn}>
                                            <i className="fas fa-plus"></i>
                                            <strong>{selectedLanguageStrings.new_question}</strong>
                                        </button>
                                    </div>
                                    <div className="col-6 col-sm-9 text-center text-sm-right">
                                        <button type="button"
                                                className="col-12 col-sm-auto btn btn-warning mr-sm-3 px-sm-2 d-none"
                                                data-toggle="modal" data-target="#compareQuestionsModal">
                                            <b>Compare Results </b>
                                        </button>

                                        <button type="button"
                                                className="col-12 col-sm-auto btn btn-primary my-2 my-sm-0"
                                                data-toggle="modal" data-target="#exportQuestionsModal">
                                            Export in Excel
                                        </button>
                                    </div>

                                </div>
                                <div className="row footerPadding">
                                    <div className="col-12 col-sm-12 col-lg-6">
                                        <div className="container-tweet">
                                            <h2 className="mb-3"><b> {selectedLanguageStrings.list_questions}:</b></h2>
                                            <SortableList sortedQuestionList={sortedQuestionList}
                                                          onSortEnd={this.onSortEnd} useDragHandle={true}/>
                                        </div>
                                        <div className="row ">
                                            {(this.state.sortedQuestionList.length > 0) ? (
                                                <div className="col-12">
                                                    <Tooltip
                                                        // options
                                                        title="Add new question"
                                                        position="right"
                                                        trigger={"mouseenter"}
                                                        distance="5"
                                                        animation={"fade"}
                                                        animateFill={false}
                                                        followCursor={true}
                                                        duration={1200}
                                                    >
                                                        <button type="button" data-toggle="modal"
                                                                data-target="#QuestionTypesModal"
                                                                className="btn round float-left mb-3 ml-2 addQuestionRoundbtn"
                                                                onClick={this.handleNewQuestionBtn}>
                                                            <i className="fas fa-plus"></i></button>
                                                    </Tooltip>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-lg-6">
                                        <div className="container-tweet mb-4" id="container-tweet-half">
                                            <h2 className="mb-3"><b>{selectedLanguageStrings.active_question} : </b>
                                            </h2>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    {(this.state.parentInfo.currentQuestion !== 'default') ? (
                                                        <div>
                                                            <SingleQuestionComponent
                                                                firstVotingSessionInitialized={this.state.currentQuestion.firstVotingSessionInitialized}
                                                                idElement={this.state.idElement}
                                                                idQuestionEl={this.state.idQuestionEl}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>

                        <QuestionTypesModal
                            modalSelectedFunction={this.selectedModal.bind(this)}
                        />

                        <MultipleChoiceModal
                            type='MultipleChoice'
                            optionId={this.state.optionId}
                            modalType={this.state.modalType}
                            question={this.state.currentQuestionToEdit}
                            optionsListNew={this.state.optionsArray}
                            saveBtnTitle={this.state.saveBtnTitle}
                            multipleChoiceState={this.state.multipleChoiceState}
                            saveQuestionFunction={this.saveQuestion.bind(this)}
                            closeQuestionFunction={this.closeQuestion.bind(this)}
                            addNewOptionFunction={this.addNewOption.bind(this)}
                            deleteOptionFunction={this.deleteOption.bind(this)}
                            onSortEndOptions={this.onSortEndOptions.bind(this)}
                            correctAnswerList={this.state.correctAnswerList}
                        />

                        <SimpleTextModal
                            type='SimpleTextQuestion'
                            modalType={this.state.modalType}
                            question={this.state.currentQuestionToEdit}
                            saveBtnTitle={this.state.saveBtnTitle}
                            saveQuestionFunction={this.saveQuestion.bind(this)}
                            closeQuestionFunction={this.closeQuestion.bind(this)}
                        />

                        <SimpleTextAndMultipleChoice
                            type='SimpleTextAndMultipleChoice'
                            optionId={this.state.optionId}
                            modalType={this.state.modalType}
                            question={this.state.currentQuestionToEdit}
                            optionsListNew={this.state.optionsArray}
                            saveBtnTitle={this.state.saveBtnTitle}
                            multipleChoiceState={this.state.multipleChoiceState}
                            saveQuestionFunction={this.saveQuestion.bind(this)}
                            closeQuestionFunction={this.closeQuestion.bind(this)}
                            addNewOptionFunction={this.addNewOption.bind(this)}
                            deleteOptionFunction={this.deleteOption.bind(this)}
                            onSortEndOptions={this.onSortEndOptions.bind(this)}
                            correctAnswerList={this.state.correctAnswerList}
                            resetOptionsListFunction={this.resetOptionsList.bind(this)}
                            addLikeDislikeOptionFunction={this.addLikeDislikeOption.bind(this)}
                        />

                        <RatingModal
                            type='RatingQuestion'
                            modalType={this.state.modalType}
                            question={this.state.currentQuestionToEdit}
                            saveBtnTitle={this.state.saveBtnTitle}
                            saveQuestionFunction={this.saveQuestion.bind(this)}
                            closeQuestionFunction={this.closeQuestion.bind(this)}
                        />

                        <EditModal
                            //we need to pass the event because the event on the main screen is not the same as the currentEvent
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"QuestionsScreen"}
                        />

                        <QuestionToDelete
                            questionToDelete={this.state.questionToDelete}
                            deleteQuestion={this.deleteQuestion.bind(this)}
                        />
                        <ExportQuestionsModal
                            questionsList={this.state.sortedQuestionList}
                            event={this.state.currentEvent}
                            parentInfo={this.state.parentInfo}
                            idQuestionEl={this.state.idQuestionEl}
                            getVotingSessions={this.getVotingSessions.bind(this)}
                            validVotingSessions={this.state.validVotingSessions}
                        />

                        <CompareQuestionsModal
                            questionsList={this.state.sortedQuestionList}
                            event={this.state.currentEvent}
                            parentInfo={this.state.parentInfo}
                            idQuestionEl={this.state.idQuestionEl}
                            getVotingSessions={this.getVotingSessions.bind(this)}
                            validVotingSessions={this.state.validVotingSessions}
                            resultsToCompare={this.resultsToCompare.bind(this)}
                        />


                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        questionList: state.questionReducer.questionList,
        questionOptions: state.questionReducer.questionOptions,
        currentEvent: state.eventReducer.currentEvent,
        updatedCurrentFiledstatus: state.eventReducer.updatedCurrentFiledstatus,
        eventCodeAvailability: state.eventReducer.eventCodeAvailability

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitQuestionList: (eventId, idQuestionEl, callback) => dispatch(questionActions.getAllQuestions(eventId, idQuestionEl, callback)),
        onSaveQuestion: (eventId, idQuestionEl, question, deletedOptionsArray, callback) => dispatch(questionActions.saveQuestion(eventId, idQuestionEl, question, deletedOptionsArray, callback)),
        onDeleteQuestion: (eventId, idQuestionEl, question) => dispatch(questionActions.deleteQuestion(eventId, idQuestionEl, question)),
        onInitQuestionOptions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllQuestionOptions(eventId, idQuestionEl, questionId, callback)),
        onSaveVotingSession: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.saveVotingSession(eventId, idQuestionEl, questionId, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
        onUpdateQuestionField: (option, eventId, idQuestionEl, question, value, callback) => dispatch(questionActions.updateQuestionField(option, eventId, idQuestionEl, question, value, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onUpdateQuestionsIndexes: (eventId, idQuestionEl, newSortedQuestionsList) => dispatch(questionActions.updateQuestionsIndexes(eventId, idQuestionEl, newSortedQuestionsList)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onUpdateElementData: (eventId, type, parentNode, childNode, callback) => dispatch(eventActions.updateElementData(eventId, type, parentNode, childNode, callback)),
        onGetAllVotingSessions: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getAllVotingSessions(eventId, idQuestionEl, questionId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsScreen);
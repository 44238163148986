import { actionTypes } from '../actions/actionTypes';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user} : {};

export function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTER_REQUEST:
      return { registering: true };
    case actionTypes.REGISTER_SUCCESS:
      return {};
    case actionTypes.REGISTER_FAILURE:
      return {}
     /***************  Log in  *************************/
    case actionTypes.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case actionTypes.LOGIN_FAILURE:
      return {};

    case actionTypes.LOGOUT:
       return {};

    case actionTypes.UPDATE_USER_DATA_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

    case actionTypes.GET_USER_DATA_ON_CHANGE_SUCCESS:
      return {
        ...state,
        userDataOnChange: action.userData
      };
    default:
      return state
  }
}
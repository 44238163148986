import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Event from '../Components/Event';
import '@syncfusion/ej2-react-navigations/styles/bootstrap.css';
import "@syncfusion/ej2-react-calendars/styles/bootstrap.css";
import EditModal from '../Modals/EditModal';
import DeleteModal from '../Modals/DeleteModal';
import '../style.css';
import Sidebar from "react-sidebar";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {authenticationActions} from '../store/actions/AuthenticationActions';
import {Link} from 'react-router-dom';
import ShareEventModal from '../Modals/ShareEventModal';

class MainScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentEvent: '',
            saveBtnTitle: '',
            optionsOrderStatus: 'default',
            voteCounterStatus: 'default',
            tweetsToShow: 'default',
            imageCode: '',
            userId: '',
            user: '',
            eventLogoList: '',
            sidebarOpen: false,
            toggleActive: false,
            textToggle: 'OFF',
            eventsListFetched: 'default',
            sortedEventList: [],
            sharedEmailsList: []
        };

        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleNewEventBtn = this.handleNewEventBtn.bind(this)
        this.viewEvent = this.viewEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.closePlanModal = this.closePlanModal.bind(this);
        this.shareEvent = this.shareEvent.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (this.unsubscribeGetUserDataOnChange) {
                this.unsubscribeGetUserDataOnChange();
            }
            this.props.onGetUserDataOnChange(user.userId, (userData, unsubscribe) => {
                this.unsubscribeGetUserDataOnChange = unsubscribe;
                userData.userId = userData.id;
                localStorage.setItem('user', JSON.stringify({
                    'userId': userData.id,
                    'sFirstName': userData.sFirstName,
                    'sLastName': userData.sLastName,
                    'sEmail': userData.sEmail,
                    'sType': userData.sType,
                }));
                this.setState({...this.state, userId: userData.id, user: userData});
            });
            if (this.unsubscribeInitEventList) {
                this.unsubscribeInitEventList();
            }
            this.props.onInitEventList(user.userId, (eventsListCallback, unsubscribe) => {
                this.unsubscribeInitEventList = unsubscribe;
                this.setState({eventsListFetched: "", sortedEventList: eventsListCallback});
            });
        } else {
            this.props.history.push('/login');
        }

    }

    componentWillUnmount() {
        if (this.unsubscribeGetUserDataOnChange) {
            this.unsubscribeGetUserDataOnChange();
        }

        if (this.unsubscribeInitEventList) {
            this.unsubscribeInitEventList();
        }
    }

    deleteEvent(event) {
        this.setState({...this.state, currentEvent: event});
        if (event.id === 0) {
            this.props.onCancelEvent();
        } else {

        }
    }

    shareEvent(event) {
        if (this.unsubscribeGetListOfSharedEmails) {
            this.unsubscribeGetListOfSharedEmails();
        }
        this.props.onGetListOfSharedEmails(event, unsubscribe => this.unsubscribeGetListOfSharedEmails = unsubscribe);
    }

    modalDeleteEvent(event) {
        this.props.onDeleteEvent(event, () => {

        });
    }

    closeEditModal() {
        if (document.getElementById('eventModalsForm')) {
            this.setState({...this.state, openModal: false});
            document.getElementById('eventModalsForm').reset();
        }
    }

    handleEditBtn(event) {
        this.props.setCurrentEventId(event.id, callback => {
            var img = document.getElementById('imagePreview');
            if (event.eventLogoUrl !== 'default') {
                img.src = event.eventLogoUrl;
            } else {
                img.src = "/files/LogoTemp.png";
            }
        });
        var selectedLanguageStrings = englishStrings;
        this.setState({...this.state, currentEvent: event, saveBtnTitle: selectedLanguageStrings.update});

    }


    handleNewEventBtn() {
        this.props.onAddEvent(callback => {
            //var indexToAdd = this.state.sortedEventList.length + 1;
            callback.eventOrderIndex = Math.max(...this.state.sortedEventList.map(list => list.eventOrderIndex)) + 1;
            //var selectedLanguageStrings = englishStrings;
            // this.state.currentEvent.titleStatus = "is-invalid";
            this.setState({...this.state, currentEvent: callback, saveBtnTitle: englishStrings.create});
            // var img = document.getElementById('imagePreview');
            // img.src = "/files/file-upload.png";
        });
    }

    viewEvent(event) {

        this.props.history.push("/event/" + event.id + "/");
        this.props.setCurrentEventId(event.id, callback => {
            this.props.history.push("/event/" + event.id + "/");
        });

    }

    onSetSidebarOpen(open) {
        this.setState({sidebarOpen: open});
    }

    onToggle() {

        if (this.state.toggleActive === true) {
            this.setState({toggleActive: false, textToggle: 'OFF'});
        } else {
            this.setState({toggleActive: true, textToggle: 'ON'});
        }

    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const {sortedEventList} = this.state;
            var newSortedEventList = arrayMove(sortedEventList, oldIndex, newIndex);
            newSortedEventList = newSortedEventList.reverse();
            this.props.onUpdateEventsIndexes(newSortedEventList);
            this.setState({
                sortedEventList: newSortedEventList
            });
        }
    };

    closePlanModal() {
        document.getElementById("closePlanModal").click();
    }

    render() {
        const {sortedEventList} = this.state;
        let eventListComponents;
        var selectedLanguageStrings = englishStrings;
        const DragHandle = SortableHandle(({event}) => {
            return (
                <div className="col-3 col-sm-3 col-md-2 col-lg-1 pl-1 pr-1 my-auto d-flex eventColumnLogo">
                    {(event.eventLogoUrl !== "default") ? (
                        <img src={event.eventLogoUrl} className="event-logo-small" alt="Logo"/>
                    ) : (
                        <img src={"/files/LogoTemp.png"} className="event-logo-small" alt="Logo"/>
                    )}
                </div>
            )
        });
        const SortableItem = SortableElement(({event, index}) => {

            return (
                <li>
                    {(event.eventStatus && event.eventStatus === "Archived") ? (

                        <div className="align-items-center row mb-3">
                            <div className="col-10 col-sm-10 col-md-11 col-lg-11">
                                <div className="card event-card" data-toggle="modal" data-target="#archivedEventModal">
                                    <div className="card-body p-2">
                                        <Tooltip
                                            // options
                                            title="Archived event."
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="-50"
                                            animation={"fade"}
                                            animateFill={false}
                                            duration={400}
                                            delay={1000}
                                            // followCursor={true}
                                        >
                                            <div className="row pl-3">
                                                <DragHandle
                                                    event={event}
                                                />
                                                <div className="col-8 col-sm-8 col-md-10 col-lg-11 my-auto pl-1 pr-1 "
                                                     id="eventColumnTitle">
                                                    <b>{event.title}</b>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-left pl-2">
                                <Tooltip
                                    // options
                                    title="View and download the results"
                                    position="top"
                                    trigger={"mouseenter"}
                                    distance="-50"
                                    animation={"fade"}
                                    animateFill={false}
                                    duration={400}
                                    delay={1000}
                                    // followCursor={true}
                                >
                                    <button type="button" className="rounded border-0 btn-primary  mr-2"
                                            id="eventDeleteBtn" onClick={() => {
                                        this.viewEvent(event)
                                    }}>
                                        <i className="fas fa-file-download text-white downloadResults"></i>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>

                    ) : (

                        <div className="align-items-center row mb-3">
                            <div className="col-10 col-sm-10 col-md-11 col-lg-11">
                                <div className="card event-card" onClick={() => {
                                    this.viewEvent(event)
                                }}>
                                    <div className="card-body p-2">
                                        <Tooltip
                                            // options
                                            title="Enter to add Question and Tweet sessions or edit this event."
                                            position="top"
                                            trigger={"mouseenter"}
                                            distance="-50"
                                            animation={"fade"}
                                            animateFill={false}
                                            duration={400}
                                            delay={1000}
                                            // followCursor={true}
                                        >
                                            <div className="row pl-3">
                                                <DragHandle
                                                    event={event}
                                                />
                                                <div className="col-8 col-sm-8 col-md-10 col-lg-11 my-auto pl-1 pr-1 "
                                                     id="eventColumnTitle">
                                                    <b>{event.title}</b>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-left pl-2">
                                <button type="button" className="rounded border-0 btn-success  mr-2" id="eventDeleteBtn"
                                        data-toggle="modal" data-target="#shareModal" onClick={() => {
                                    this.shareEvent(event)
                                }}>
                                    <i className="fas fa-share-alt"></i>
                                </button>
                                <button type="button" className="rounded border-0 btn-danger" id="eventDeleteBtn"
                                        data-toggle="modal" data-target="#deleteModal" onClick={() => {
                                    this.deleteEvent(event)
                                }}>
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </li>
            );
        });

        const SortableList = SortableContainer(({sortedEventList}) => {
            return (
                <ul id="questionsList">
                    {sortedEventList.map((event, index) => (
                        <SortableItem key={`item-${index}`} index={index} event={event}/>
                    ))}
                </ul>
            );
        });

        var selectedLanguageStrings;
        if (this.props.currentEvent && this.props.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (
            <div>
                {(this.state.eventsListFetched === 'default') ? (

                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    <div>
                        <Header
                            page="MainScreen"
                            eventId={this.state.currentEvent.id}
                            onSetSidebarOpen={this.onSetSidebarOpen}
                        />
                        <div>
                            <nav className="nav-bar-tab" id="nav-bar-tab">
                                <div className="container" id="navContainer">
                                    <div className="row mt-1 itemsNav">
                                        <div className="col-6 text-center  pl-0 pr-0">
                                            <div className="eventsTab">
                                                <label
                                                    className="text-secondary pointer">{selectedLanguageStrings.your_events}</label>
                                            </div>
                                            <div className="border-bottom" id="borderLine"></div>
                                        </div>
                                        <div className="col-6 text-center">
                                            <Link to={"/shared"}>
                                                <div className="eventsTab">
                                                    <label
                                                        className="text-primary pointer">{selectedLanguageStrings.shared_events}</label>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <main role="main">
                            <div className="container">
                                <div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            {(this.props.user.sType === "Basic" && this.state.sortedEventList.length > 1) ? (

                                                <button type="button" data-toggle="modal" data-target="#planEmailModal"
                                                        className="btn btn-outline-primary float-left">
                                                    <i className="fas fa-plus"></i><strong>{selectedLanguageStrings.add_new_event}</strong>
                                                </button>

                                            ) : (

                                                <button type="button" data-toggle="modal" data-target="#editModal"
                                                        className="btn btn-outline-primary float-left"
                                                        onClick={this.handleNewEventBtn}>
                                                    <i className="fas fa-plus"></i><strong>{selectedLanguageStrings.add_new_event}</strong>
                                                </button>

                                            )}
                                        </div>
                                    </div>
                                    {/* //{eventListComponents} */}
                                    <div className="row">
                                        <div className="col-12">
                                            <SortableList sortedEventList={sortedEventList} onSortEnd={this.onSortEnd}
                                                          useDragHandle={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={this.state.saveBtnTitle}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.props.user}
                            page={"MainScreen"}
                        />
                        <DeleteModal
                            event={this.state.currentEvent}
                            modalDeleteFunction={this.modalDeleteEvent.bind(this)}
                            deleteModalQuote={"Are you sure you want to delete this event?"}
                            deleteModalTitle={'Delete Event'}
                        />
                        <ShareEventModal
                            event={this.state.currentEvent}
                            sharedEmailsList={this.state.sharedEmailsList}
                            user={this.props.user}
                        />
                        <div className="modal fade" id="planEmailModal" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Plan Status</h5>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body emailPlanModal mx-2">
                                        <p className="mb-3">
                                            You reached the maximum number of events for the trial version.
                                        </p>
                                        <p>
                                            For further information regarding to our plans
                                            please contat us through the following email address: <a
                                            href="mailto:info@netizens.it" target="_top">info@netizens.it</a>
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                                id="closePlanModal">Close
                                        </button>
                                        <button type="button" className="btn btn-primary text-white"
                                                onClick={this.closePlanModal}><a href="mailto:info@netizens.it"
                                                                                 target="_top"
                                                                                 className="emailPlanLink">Send
                                            Email</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="archivedEventModal" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-primary text-white">
                                        <h5 className="modal-title" id="exampleModalLabel">Access denied</h5>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body emailPlanModal mx-2">
                                        <p className="mb-3">
                                            Regarding the expiration of your plan, this event has been archived and you
                                            can only access the results.
                                        </p>
                                        <p>
                                            For further information regarding to our plans
                                            please contat us through the following email address: <a
                                            href="mailto:info@netizens.it" target="_top">info@netizens.it</a>
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                                id="closePlanModal">Close
                                        </button>
                                        <button type="button" className="btn btn-primary text-white"
                                                onClick={this.closePlanModal}>
                                            <a href="mailto:info@netizens.it" target="_top" className="emailPlanLink">Send
                                                Email</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {user} = state.authenticationReducer;
    const {eventCodeAvailability} = state.eventReducer;

    return {
        eventList: state.eventReducer.eventList,
        user,
        eventCodeAvailability,
        currentEvent: state.eventReducer.currentEvent,
    };

};

const mapDispatchToProps = dispatch => {

    return {
        onAddEvent: (callback) => dispatch(eventActions.addEvent(callback)),
        onInitEventList: (userId, callback) => dispatch(eventActions.getAllEvents(userId, callback)),
        onCancelEvent: () => dispatch(eventActions.cancelEvent()),
        onSaveEvent: (userId, event, callback) => dispatch(eventActions.saveEvent(userId, event, callback)),
        onDeleteEvent: (event, callback) => dispatch(eventActions.deleteEvent('iE8HlfnSDcc2njpztun0tvydO1s2', event, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        onUploadEventLogo: (userId, eventId, logoFile, callback) => dispatch(eventActions.uploadEventLogo(userId, eventId, logoFile, callback)),
        onUpdateEventsIndexes: (newSortedEventList) => dispatch(eventActions.updateEventsIndexes(newSortedEventList)),
        onGetUserDataOnChange: (userId, callback) => dispatch(authenticationActions.getUserDataOnChange(userId, callback)),
        onShareEventEmailCheck: (email, callback) => dispatch(eventActions.shareEventEmailCheck(email, callback)),
        onUpdateSharedEventsData: (eventId, userData, sharedUserData, comment, callback) => dispatch(eventActions.updateSharedEventsData(eventId, userData, sharedUserData, comment, callback)),
        onGetListOfSharedEmails: (event, callback) => dispatch(eventActions.getListOfSharedEmails(event, callback)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
import firebase from 'firebase';
import 'firebase/firestore/dist/index.cjs';
import 'firebase/database/dist/index.cjs';
import 'firebase/database';
import 'firebase/auth';

// Initialize Firebase 
var config = { 
apiKey: "AIzaSyCSTM1xFwbIFVqnw-aUEA5Y8vRZvFM-3Ew", 
authDomain: "kpad-netizens.firebaseapp.com", 
databaseURL: "https://kpad-netizens.firebaseio.com", 
projectId: "kpad-netizens", 
storageBucket: "kpad-netizens.appspot.com", 
messagingSenderId: "428049299436" 
}; 
//  // firebase.initializeApp(config); 
// firebase.initializeApp(config)
 // firebase.initializeApp(config); 
 export default firebase.initializeApp(config);

export const firestore = firebase.firestore();
//firestore.settings({timestampsInSnapshots: true});
// export default firestore;

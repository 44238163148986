import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventActions} from '../store/actions/EventActions';
import Header from '../Components/Header';
import Dropzone from 'react-dropzone';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import HeaderTab from '../Components/HeaderTab';
import EditModal from '../Modals/EditModal';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import Flag from 'react-world-flags';

class AgendaScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            agendaId: this.props.match.params.idItem,
            user: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            filePreviewStatus: 'default',
            selectedFileToUpload: [],
            sortedFilesList: [],
            editingItem: 'default',
            currentEvent: 'default',
            parentInfo: 'default',
            selectedLanguageStatus: "default",
            saveLinkStatus: "default",
            agendaInfo: ''
        };

        this.handleDropFile = this.handleDropFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.editFileName = this.editFileName.bind(this);
        this.saveEditedFile = this.saveEditedFile.bind(this);
        this.cancelFile = this.cancelFile.bind(this);
        this.onSelectLanguage = this.onSelectLanguage.bind(this);
        this.saveLink = this.saveLink.bind(this);
        this.handleText = this.handleText.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({...this.state, user: user});

        if (this.unsubscribeGetAgendaFiles) {
            this.unsubscribeGetAgendaFiles();
        }
        this.props.onGetAgendaFiles(this.state.eventId, this.state.agendaId, (filesList, unsubscribe) => {
            this.unsubscribeGetAgendaFiles = unsubscribe;
            this.setState({...this.state, sortedFilesList: filesList, selectedLanguageStatus: "default"});
        });

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
        });

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({...this.state, parentInfo: parentInfo});
        });

        if (this.unsubscribeGetAgendaInfoOnChange) {
            this.unsubscribeGetAgendaInfoOnChange();
        }
        this.props.onGetAgendaInfoOnChange(this.state.eventId, this.state.agendaId, (agendaInfo, unsubscribe) => {
            this.unsubscribeGetAgendaInfoOnChange = unsubscribe;
            this.setState({...this.state, agendaInfo: agendaInfo});
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetAgendaFiles) {
            this.unsubscribeGetAgendaFiles();
        }

        if (this.unsubscribeGetAgendaInfoOnChange) {
            this.unsubscribeGetAgendaInfoOnChange();
        }
    }

    handleUploadFile(e) {
        let filePath = "/files/" + e.target.files[0].name;
        let targetFile = e.target.files[0].name;

        var previewFile = document.getElementById('filePreview');
        var file = document.getElementById('inputFilePreview').files[0];
        // var FileSize = file.size / 1024 / 1024; // in MB
        var FileSize = Math.round(file.size / 1024); // 
        var validFileExtensions = ["All image formats", "All Video Formats", "All Audio Formats", ".pdf", ".txt"];
        var fileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/bmp",
            "image/gif",
            "image/png",
            "video/*",
            "audio/*",
            "audio/mp3",
            "video/mp4",
            "video/flv",
            "application/pdf",
            "text/plain",
        ]

        if (!fileTypes.includes(file.type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + file.name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 4000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 4mb.");
        } else {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                previewFile.src = reader.result;
            }, false);

            if (file) {
                reader.readAsDataURL(file);
                // this.props.uploadLogo(file);
                this.setState({
                    ...this.state,
                    filePath: filePath,
                    filePreviewStatus: 'selectedFile',
                    uploadFiles: file,
                    selectedFileToUpload: file
                });
            }
        }
    }


    handleDropFile(files) {

        var validFileExtensions = ["All image formats", "All Video Formats", "All Audio Formats", ".pdf", ".txt"];
        var fileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/bmp",
            "image/gif",
            "image/png",
            "audio/mp3",
            "video/mp4",
            "video/flv",
            "audio/*",
            "application/pdf",
            "text/plain",
        ]
        var FileSize = Math.round(files[0].size / 1024);
        let fileLocalPath = "/files/" + files[0].name;
        if (!fileTypes.includes(files[0].type)) {
            //alert('The selected file is not an image. Please upload an image with the correct type.');
            alert("Sorry, " + files[0].name + " is invalid, allowed extensions are: " + validFileExtensions.join(", "));
        } else if (FileSize > 4000) {
            alert('The size of this file is: ' + FileSize + " KB, please choose a file less than 4mb.");
        } else {
            if (files[0]) {
                this.setState({
                    ...this.state,
                    filePath: fileLocalPath,
                    // uploadFiles: files[0],
                    uploadFiles: files.map(file => ({
                        ...file,
                        preview: URL.createObjectURL(file)
                    })),
                    filePreviewStatus: 'drag&drop',
                    selectedFileToUpload: files[0]
                });
            }
        }
    }


    saveFile() {
        //this.props.onSaveEvent(this.props.user.userId, eventData, callback => {
        const file = this.state.selectedFileToUpload;
        var addOrderIndex = this.state.sortedFilesList.length + 1;
        let fileData = {
            name: file.name,
            type: file.type,
            sParentId: this.state.idElement,
            fileOrderIndex: addOrderIndex,
            language: "English"
        }
        this.props.onSaveAgendaFile(this.state.eventId, this.state.agendaId, fileData, 'newFile', savedItem => {
            if (savedItem.message === "success") {
                this.props.onUploadAgendaFile(this.state.user.id, this.state.eventId, this.state.agendaId, savedItem.data.id, "ImageFile", file, callback => {
                    if (callback === 'success') {
                        this.setState({
                            ...this.state,
                            filePreviewStatus: 'default',
                            uploadFiles: [],
                            fileStatus: 'default',
                            filePath: 'default',
                            selectedFileToUpload: [],
                        })
                    }
                });
            }
        });
    }

    handleText = (e) => {

        switch (e.target.name) {
            case "LinkAddressAgenda":
                if (e.value !== "") {
                    this.setState({...this.state, saveLinkStatus: "available", [e.questionTextMultiple]: e.value});
                }
                break;

            default:
                return true;
        }

    }


    saveLink() {

        let LinkAddressAgenda = document.getElementById('LinkAddressAgenda').value;
        const file = this.state.selectedFileToUpload;
        var addOrderIndex = this.state.sortedFilesList.length + 1;
        let fileData = {
            name: LinkAddressAgenda,
            type: "link",
            sParentId: this.state.idElement,
            fileOrderIndex: addOrderIndex,
            language: "English",
            fileUrl: LinkAddressAgenda
        }
        this.props.onSaveAgendaFile(this.state.eventId, this.state.agendaId, fileData, 'saveAgendaLink', savedItem => {
            if (savedItem.message === "success") {
                document.getElementById('LinkAddressAgenda').value = '';
                this.setState({...this.state, saveLinkStatus: "default"});
            }
        });

    }

    onSelectLanguage(file, type) {

        file.language = type;
        this.props.onSaveAgendaFile(this.state.eventId, this.state.agendaId, file, 'languageEdited', savedItem => {
            this.setState({...this.state, selectedLanguageStatus: "edited"});
        });

    }

    cancelFile() {

        this.setState({
            ...this.state,
            filePreviewStatus: 'default',
            uploadFiles: [],
            fileStatus: 'default',
            filePath: 'default',
            selectedFileToUpload: [],
        })

    }

    removeFile(file) {
        this.props.onRemoveLibraryFile(this.state.eventId, this.state.agendaId, file.id);
    }

    onSortEnd = ({oldIndex, newIndex}) => {

        const {sortedFilesList} = this.state;
        var newSortedList = arrayMove(sortedFilesList, oldIndex, newIndex);
        this.props.onUpdateFilesIndexes(this.state.eventId, this.state.agendaId, newSortedList);
        this.setState({
            ...this.state, sortedFilesList: arrayMove(sortedFilesList, oldIndex, newIndex)
        });

    };

    editFileName(file) {
        this.setState({
            ...this.state, editingItem: file
        });
    }

    saveEditedFile(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            var fileInputName = document.getElementById("fileInputName").value;
            let fileData = this.state.editingItem;
            fileData.name = fileInputName;
            this.props.onSaveAgendaFile(this.state.eventId, this.state.agendaId, fileData, 'nameEdited', savedItem => {
                this.setState({
                    ...this.state, editingItem: 'default'
                });
            });
        }
    }

    goToTweetScreen() {
        if (this.state.parentInfo.currentTweetElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    goToQuestionScreen() {
        if (this.state.parentInfo.currentQuestionElement !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }
    }

    goToSurveyScreen() {
        if (this.state.parentInfo.currentSurveyElement.id !== 'default') {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/survey/" + this.state.parentInfo.currentSurveyElement.id);
        }
    }

    handleEditEvent() {
        // this.props.setCurrentEventId(this.state.currentEvent.id, callback => {
        //     var img = document.getElementById('imagePreview');
        //     if (this.state.currentEvent.eventLogoUrl !== 'default') {
        //         img.src = this.state.currentEvent.eventLogoUrl;
        //     } else {
        //         img.src = "/files/LogoTemp.png";
        //     }
        // });
        this.setState({...this.state, saveBtnTitle: 'Update'});
    }

    setLanguage(e) {
        let lang = e.target.value;
        this.props.setElementLanguage(this.state.eventId, this.state.agendaId, lang);
    }

    render() {

        var selectedLanguageStrings;
        if (this.state.currentEvent && this.state.currentEvent.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }
        const {uploadFiles} = this.state;
        let filePreviewComponent;
        var imagetypes = ["image/*"]
        if (this.state.filePreviewStatus === 'drag&drop') {
            filePreviewComponent = (
                <div>{uploadFiles.map((file) => <img src={file.preview} id="filePreview"
                                                     className='img-fluid img-thumbnail logoEditModalImageAgenda'/>)}</div>
            )
        } else {
            filePreviewComponent = (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <img src={"/files/file-upload.png"} id="filePreview"
                                 className='img-fluid img-thumbnail logoEditModalImageAgenda'
                                 style={{backgroundColor: '#e6e6e6'}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <label className="px-2 agenda-upload-Text">{selectedLanguageStrings.drag_and_drop}</label>
                        </div>
                    </div>
                </div>
            )
        }
        var sortedFilesList = this.state.sortedFilesList;
        var SortableList;
        var fileTypeComponent;
        var languageName;
        let imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];


        const archivedFileList = sortedFilesList.map(file => {
            if (file.type === "link") {
                fileTypeComponent = <i className="fas fa-link text-primary linkIcon"></i>
            } else if (imageTypes.includes(file.type)) {
                fileTypeComponent = <i className="fas fa-file-image text-success"></i>
            } else if (file.type === "application/pdf") {
                fileTypeComponent = <i className="fas fa-file-pdf text-danger"></i>
            } else if (file.type === "text/plain") {
                fileTypeComponent = <i className="fas fa-file-alt text-info"></i>
            } else {
                fileTypeComponent = <i className="fas fa-file-download text-secondary"></i>
            }
            return (
                <div className="row pb-3">
                    <div className="col-auto text-center pr-0 card-body-files-icons pl-2">
                        {fileTypeComponent}
                    </div>
                    <div className="col">
                        <div className="card card-body-files">
                            <a href={file.fileUrl} target="_blank">
                                {file.name}
                            </a>
                        </div>
                    </div>
                </div>
            )
        })

        if (this.state.sortedFilesList && this.state.sortedFilesList.length > 0) {
            SortableList = SortableContainer(({sortedFilesList}) => {
                return (
                    <ul className="pl-0">
                        {sortedFilesList.map((file, index) => (
                            <SortableItem key={`item-${index}`} index={index} file={file}/>
                        ))}
                    </ul>
                );
            });


            const SortableItem = SortableElement(({file}) => {
                if (file.type === "link") {
                    fileTypeComponent = <i className="fas fa-link text-primary linkIcon"></i>
                } else if (imageTypes.includes(file.type)) {
                    fileTypeComponent = <i className="fas fa-file-image text-success"></i>
                } else if (file.type === "application/pdf") {
                    fileTypeComponent = <i className="fas fa-file-pdf text-danger"></i>
                } else if (file.type === "text/plain") {
                    fileTypeComponent = <i className="fas fa-file-alt text-info"></i>
                } else {
                    fileTypeComponent = <i className="fas fa-file-download text-secondary"></i>
                }

                return (
                    <div className="row align-items-center pb-3">
                        <div className="col-auto text-center pr-0 card-body-files-icons pl-2">
                            {fileTypeComponent}
                        </div>
                        {(this.state.editingItem.id === file.id) ? (

                            <div className="col labelFolder pl-0 ">
                                <div className="input-group ml-2">
                                    <input type="text" className="form-control" onKeyUp={this.saveEditedFile}
                                           id="fileInputName" defaultValue={file.name} aria-label="Title"
                                           aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button> */}
                                    </div>
                                </div>
                            </div>

                        ) : (

                            <div className="col pl-2">
                                <div className="card card-body-files">
                                    <div className="row">
                                        <div className="col">
                                            <a href={file.fileUrl} target="_blank">
                                                {file.name}
                                            </a>
                                        </div>

                                        <div className="col dragableContainer">
                                            <DragHandle
                                                file={file}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}
                        <div className="col text-left pl-0">
                            <button style={{color: "#333030"}}
                                    className="col-5 btn btn-secondary dropdown-toggle p-2 mr-2" type="button"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                <b className="pr-2">Language:</b> {file.language}
                            </button>
                            <div className="col dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button className="dropdown-item p-2" type="button" onClick={() => {
                                    this.onSelectLanguage(file, 'English')
                                }}>
                                    <Flag code="gb" className="flagImages"/>
                                    {selectedLanguageStrings.english}
                                </button>
                                <button className=" dropdown-item p-2" type="button" onClick={() => {
                                    this.onSelectLanguage(file, 'Italian')
                                }}>
                                    <Flag code="it" className="flagImages"/>
                                    {selectedLanguageStrings.italian}
                                </button>
                            </div>
                            <button className="btn bg-primary text-white px-3 mr-2 fileBtnTexts" onClick={() => {
                                this.editFileName(file)
                            }}>
                                <i className="fas fa-pencil-alt"></i>
                            </button>
                            <button className="btn bg-danger text-white px-3 fileBtnTexts" onClick={() => {
                                this.removeFile(file)
                            }}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                )
            });

            const DragHandle = SortableHandle(({file}) => {
                return (
                    <div></div>
                )
            });
        }

        return (
            <div>
                {(this.state.currentEvent === 'default') ? (

                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    <div>
                        <Header
                            page="LibraryScreen"
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                            item={"agenda"}
                        />
                        <HeaderTab
                            page="LibraryScreen"
                            goToQuestionScreen={this.goToQuestionScreen.bind(this)}
                            goToSurveyScreen={this.goToSurveyScreen.bind(this)}
                            goToTweetScreen={this.goToTweetScreen.bind(this)}
                            activeScreen={'library'}
                            tabColor={"text-primary"}
                            handleEditEvent={this.handleEditEvent.bind(this)}
                            parentInfo={this.state.parentInfo}
                        />
                        <main role="main">
                            {(this.state.currentEvent.eventStatus === "Archived") ? (

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 pb-3 fileListHeader">
                                            {selectedLanguageStrings.title_uploaded_files}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {(this.state.currentEvent.eventStatus === "Archived") ? (

                                            (this.state.sortedFilesList && this.state.sortedFilesList.length > 0) ? (
                                                <div className="col-12">
                                                    {archivedFileList}
                                                </div>

                                            ) : (

                                                <div>
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            No Uploaded file.
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        ) : (

                                            <div className="col-12">
                                                {(this.state.sortedFilesList && this.state.sortedFilesList.length > 0) ? (
                                                    <SortableList sortedFilesList={sortedFilesList}
                                                                  onSortEnd={this.onSortEnd} useDragHandle={true}/>
                                                ) : (

                                                    <div>
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                No Uploaded file.
                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                            ) : (

                                <div className="container">
                                    <div className="row pt-2 pb-3">
                                        <div className="col">
                                            <div className="fileListHeader">Upload the file(s) of the agenda or insert
                                                its link(s):
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <select className="form-control d-table" id="lang-select"
                                                    value={this.state.agendaInfo.language}
                                                    onChange={this.setLanguage.bind(this)}>
                                                <option value="All">All language</option>
                                                <option value="English">English</option>
                                                <option value="Italian">Italian</option>
                                            </select>
                                        </div>
                                    </div>
                                    {(this.state.selectedFileToUpload.length === 0) ? (

                                        <div className="row align-items-center">
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 pr-0">
                                                <label htmlFor="agendaLinkAddress"
                                                       className="col-form-label">{selectedLanguageStrings.upload_agenda_file}</label>
                                            </div>
                                            <div className="col-2">
                                                <Dropzone
                                                    className="inputFileDropZone d-flex text-center"
                                                    onDrop={this.handleDropFile}
                                                >
                                                    <div className="text-center p-0 my-auto">
                                                        {/* {(uploadFiles.type === "application/pdf") ? ( */}
                                                        <div>
                                                            {filePreviewComponent}
                                                        </div>
                                                    </div>
                                                </Dropzone>
                                                <input type="file" className="form-control" id="inputFilePreview"
                                                       hidden={true} placeholder="Logo"
                                                       onChange={this.handleUploadFile}/>
                                            </div>
                                        </div>

                                    ) : (

                                        <div className="row align-items-center">
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 pr-0">
                                                <label htmlFor="agendaLinkAddress"
                                                       className="col-form-label">{selectedLanguageStrings.upload_agenda_file}</label>
                                            </div>
                                            <div className="col-4">
                                                <b>{selectedLanguageStrings.file_name}: </b>{this.state.selectedFileToUpload.name}
                                            </div>
                                            <div className="col-3 pt-2">
                                                <button type="button"
                                                        className="btn bg-secondary text-white px-2 pt-2 mr-3 px-3"
                                                        onClick={this.cancelFile}>
                                                    <i className="fas fa-times pr-2 pl-1 text-white"></i> {selectedLanguageStrings.cancel}&nbsp;
                                                </button>

                                                <button type="button" className="btn bg-success text-white pt-2 px-2"
                                                        onClick={this.saveFile}>
                                                    <i className="fas fa-arrow-up pr-2 pl-1 text-white "></i> {selectedLanguageStrings.upload_file}&nbsp;
                                                </button>
                                            </div>
                                        </div>

                                    )}
                                    <div className="row pt-2">
                                        <div className="col-12">
                                            <div className="form-group row mt-3">
                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 pr-0">
                                                    <label htmlFor="LinkAddressAgenda"
                                                           className="col-form-label">{selectedLanguageStrings.agenda_link_address}</label>
                                                </div>
                                                <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                                                    <input type="text" className="form-control" id="LinkAddressAgenda"
                                                           name="LinkAddressAgenda" placeholder="URL"
                                                           onChange={this.handleText}/>
                                                </div>

                                                {(this.state.saveLinkStatus === "default") ? (

                                                    <button type="button" className="btn bg-secondary text-white px-4"
                                                            disabled>
                                                        Save
                                                    </button>

                                                ) : (

                                                    <button type="button" className="btn bg-success text-white px-4"
                                                            onClick={this.saveLink}>
                                                        Save
                                                    </button>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 pt-3 pb-3 fileListHeader">
                                            {selectedLanguageStrings.title_uploaded_files}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {(this.state.sortedFilesList && this.state.sortedFilesList.length > 0) ? (
                                                <SortableList sortedFilesList={sortedFilesList}
                                                              onSortEnd={this.onSortEnd} useDragHandle={true}/>
                                            ) : (
                                                <div>
                                                    {/* <h5> No uploaded File Yet.</h5> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="container container-screen-iframe">
                                            {/* <iframe id="serviceFrameSend" src={this.props.changedEventInfo.agendaLinkAddress} frameborder="0" /> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </main>
                        <EditModal
                            event={this.state.currentEvent}
                            saveBtnTitle={'Update'}
                            eventCodeAvailability={this.props.eventCodeAvailability}
                            user={this.state.user}
                            page={"AgendaScreen"}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUploadAgendaFile: (userId, eventId, elementId, itemId, type, file, callback) => dispatch(eventActions.uploadAgendaFile(userId, eventId, elementId, itemId, type, file, callback)),
        onSaveAgendaFile: (eventId, agendaId, fileData, type, callback) => dispatch(eventActions.saveAgendaFile(eventId, agendaId, fileData, type, callback)),
        onGetAgendaFiles: (eventId, agendaId, callback) => dispatch(eventActions.getAgendaFiles(eventId, agendaId, callback)),
        onRemoveLibraryFile: (eventId, agendaId, fileId) => dispatch(eventActions.removeLibraryFile(eventId, agendaId, fileId)),
        onUpdateFilesIndexes: (eventId, agendaId, newSortedList) => dispatch(eventActions.updateLibraryFileIndexes(eventId, agendaId, newSortedList)),
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        setCurrentEventId: (eventId, callback) => dispatch(eventActions.setCurrentEventId(eventId, callback)),
        setElementLanguage: (eventId, agendaId, lang) => dispatch(eventActions.setElementLanguage(eventId, agendaId, lang)),
        onGetAgendaInfoOnChange: (eventId, agendaId, callback) => dispatch(eventActions.getAgendaInfoOnChange(eventId, agendaId, callback))
    };
};

const mapStateToProps = state => {
    return {
        currentEvent: state.eventReducer.currentEvent,
        agendaFilesList: state.eventReducer.agendaFilesList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaScreen);
import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import {questionActions} from '../store/actions/QuestionActions';
import {Progress} from 'reactstrap';
import {Textfit} from 'react-textfit';
import AnswerText from '../Components/AnswerText';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import IntroductionPage from '../Components/IntroductionPage';


class LiveQuestionScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            sessionId: '',
            questionId: '',
            elementId: '',
            cardTitle: '',
            responsesArray: [],
            status: '',
            questionData: [],
            optionsArrayLive: [],
            currentEvent: 'default',
            parentInfo: 'default',
            toggleActive: true,
            responsesArraySimpleText: [],
            optionCol: '',
            progressCol: '',
            progressbarHeight: '',
            optionFontSize: '',
            questionSize: '',
            statusTweet: '',
            overFlowY: '',
            optionsFontSize: '',
            optionsPadding: '',
            optionsLineHeight: '',
            optionsContainerPaddingTop: '',
            questionForOptionsFontSize: '40px',
            questionForOptionsLineHeight: '42px',
            optionsTextIndent: '',
            optionBulletFontSize: '',
        }

        this.escFunction = this.escFunction.bind(this);
        this.textResizeFunction = this.textResizeFunction.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeInitQuestionOptions) {
            this.unsubscribeInitQuestionOptions();
        }

        if (this.unsubscribeGetQuestionInfoOnChange) {
            this.unsubscribeGetQuestionInfoOnChange();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                if (parentInfo.currentQuestion && parentInfo.currentQuestion === parentInfo.bigScreenStatus.questionId) {
                    this.props.onGetQuestionInfo(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, callBackQuestionData => {
                        if (callBackQuestionData.firstVotingSessionInitialized === false) {
                            // the user can update the question as long as the first voting session has not been initialized so we receive the question changes 
                            //otherwise we only fetch the question data without snapshot
                            if (this.unsubscribeGetQuestionInfoOnChange) {
                                this.unsubscribeGetQuestionInfoOnChange();
                            }
                            this.props.onGetQuestionInfoOnChange(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, questionData => {
                                if (this.unsubscribeInitQuestionOptions) {
                                    this.unsubscribeInitQuestionOptions();
                                }
                                this.props.onInitQuestionOptions(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (callbackOptionsList, unsubscribe) => {
                                    this.unsubscribeInitQuestionOptions = unsubscribe;
                                    this.setState({
                                        ...this.state,
                                        questionData: questionData,
                                        currentEvent: callback,
                                        optionsArrayLive: callbackOptionsList,
                                        parentInfo: parentInfo
                                    });
                                    this.textResizeFunction(questionData, callbackOptionsList);
                                });
                            });
                        } else {
                            if (this.unsubscribeInitQuestionOptions) {
                                this.unsubscribeInitQuestionOptions();
                            }
                            this.props.onInitQuestionOptions(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (callbackOptionsList, unsubscribe) => {
                                this.unsubscribeInitQuestionOptions = unsubscribe;
                                this.setState({
                                    ...this.state,
                                    questionData: callBackQuestionData,
                                    currentEvent: callback,
                                    optionsArrayLive: callbackOptionsList,
                                    parentInfo: parentInfo
                                });
                                this.textResizeFunction(callBackQuestionData, callbackOptionsList);
                                //we get the responses
                                if (callBackQuestionData.type === "MultipleChoice" || callBackQuestionData.type === "SimpleTextAndMultipleChoice") {
                                    if (parentInfo.bigScreenStatus.status === "liveQuestion") {
                                        this.props.onGetResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentLiveSession.id, callbackResponses => {
                                            this.setState({
                                                ...this.state,
                                                responsesArray: callbackResponses,
                                                currentEvent: callback,
                                                parentInfo: parentInfo
                                            });
                                        });
                                    } else {
                                        //we check if the user changes the question while the big screen status is the archived question 
                                        //we will first fetch the id of the last voting session and will get the responses
                                        if (parentInfo.currentSelectedLiveSession === 'default') {
                                            this.props.onGetLastVotingSessionId(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData.id, lastVotingsession => {
                                                this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, lastVotingsession.id, callbackResponses => {
                                                    this.setState({
                                                        ...this.state,
                                                        responsesArray: callbackResponses,
                                                        currentEvent: callback,
                                                        parentInfo: parentInfo
                                                    });
                                                });
                                            });
                                        } else {
                                            this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentSelectedLiveSession, callbackResponses => {
                                                this.setState({
                                                    ...this.state,
                                                    responsesArray: callbackResponses,
                                                    currentEvent: callback,
                                                    parentInfo: parentInfo
                                                });
                                            });
                                            // this.props.onGetArchivedResponses(this.state.eventId, callback.currentQuestionElement, this.state.questionData, callback.currentSelectedLiveSession, callbackResponses => {
                                            //     this.setState({ ...this.state, responsesArray: callbackResponses, currentEvent: callback, });
                                            // });
                                        }
                                    }
                                } else {
                                    if (parentInfo.bigScreenStatus.status === "liveQuestion") {
                                        this.props.onGetResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentLiveSession.id, callbackResponses => {
                                            this.setState({
                                                ...this.state,
                                                responsesArraySimpleText: callbackResponses,
                                                currentEvent: callback,
                                                parentInfo: parentInfo
                                            });
                                        });
                                    } else {
                                        this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentSelectedLiveSession, callbackResponses => {
                                            this.setState({
                                                ...this.state,
                                                responsesArraySimpleText: callbackResponses,
                                                currentEvent: callback,
                                                parentInfo: parentInfo
                                            });
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
                /*************** BACK HISTORY BUTTON ****************/
                window.onpopstate = () => {
                }
            });
        });
    }

    textResizeFunction(question, questionOptions) {
        var overFlowY = "";
        var progressbarHeight = "";
        var longestCharLength = 0;
        var optionsFontSize = "";
        var optionsPadding = "";
        var optionsLineHeight = "";
        var optionsContainerPaddingTop = "";
        var questionForOptionsFontSize = '';
        var questionForOptionsLineHeight = "";
        var optionsTextIndent = "-30px";
        var optionBulletFontSize = "";

        for (var i = 0; i < questionOptions.length; i++) {
            if (questionOptions[i]) {
                if (longestCharLength < questionOptions[i].text.length) {
                    longestCharLength = questionOptions[i].text.length
                }
            }
        }

        if (questionOptions.length > 15) {
            overFlowY = "scroll";
            questionForOptionsFontSize = '45px';
            questionForOptionsLineHeight = '47px';
            optionsFontSize = "35px";
            optionBulletFontSize = "22px";
            optionsLineHeight = '37px';
            progressbarHeight = '50px';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "10px";
        } else if (questionOptions.length > 11) {
            questionForOptionsFontSize = '2.4vw';
            questionForOptionsLineHeight = '2.5vw';
            optionsFontSize = "2.0vw";
            optionBulletFontSize = "22px";
            optionsLineHeight = '2.4vw';
            progressbarHeight = '3.2vh';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "10px";
        } else if (questionOptions.length > 9) {
            questionForOptionsFontSize = '2.4vw';
            questionForOptionsLineHeight = '2.5vw';
            optionsFontSize = "2.0vw";
            optionBulletFontSize = "20px";
            optionsLineHeight = '2.4vw';
            progressbarHeight = '3.2vh';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "8px";
        } else if (questionOptions.length >= 5) {
            questionForOptionsFontSize = '2.4vw';   //3.5   (original values)
            questionForOptionsLineHeight = '2.5vw';   //4
            optionsFontSize = "2.3vw";              //2.8
            optionBulletFontSize = "25px";           //25px
            optionsLineHeight = '2.4vw';            //3.2
            // progressbarHeight = '60px';
            progressbarHeight = '50px';             //50px
            optionsPadding = '20px';                //20px
            optionsContainerPaddingTop = "20px";    //20px
        } else if (longestCharLength > 200) {
            questionForOptionsFontSize = '2.4vw';     //3
            questionForOptionsLineHeight = '2.5vw'; //3.3
            optionsFontSize = "2.3vw";              //2.3
            optionBulletFontSize = "25px";           //2.3
            optionsLineHeight = '2.4vw';            //2.3
            progressbarHeight = '50px';             //50
            optionsPadding = '20px';                //10
            optionsContainerPaddingTop = "20px";    //10
        } else {
            questionForOptionsFontSize = '2.4vw';   //3.5
            questionForOptionsLineHeight = '2.5vw'; //3.8
            optionsFontSize = "2.3vw";              //2.7
            optionBulletFontSize = "25px";           //25
            optionsLineHeight = '2.4vw';            //3
            progressbarHeight = '50px';             //50
            optionsPadding = '20px';                //10
            optionsContainerPaddingTop = "20px";    //10
        }
        if (longestCharLength > 54) {
            this.setState({
                progressbarHeight: progressbarHeight,
                optionCol: 'col-7',
                progressCol: 'col-5',
                progressBarWidth: '85%',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionBulletFontSize
            });
        } else if (longestCharLength >= 15 && longestCharLength < 55) {
            this.setState({
                progressbarHeight: progressbarHeight,
                optionCol: 'col-5 ',
                progressCol: 'col-7 ',
                progressBarWidth: '85%',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionBulletFontSize
            });
        } else if (longestCharLength >= 5 && longestCharLength < 15) {
            this.setState({
                progressbarHeight: progressbarHeight,
                progressBarWidth: '85%',
                optionCol: ' col-4 ',
                progressCol: 'col-8 ',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionBulletFontSize
            });
        } else if (longestCharLength === 1) {
            //like and dislike options without title
            this.setState({
                progressbarHeight: progressbarHeight,
                progressBarWidth: '85%',
                optionCol: 'col-1',
                progressCol: 'col-11',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionBulletFontSize
            });
        } else {
            this.setState({
                progressbarHeight: progressbarHeight,
                progressBarWidth: '85%',
                optionCol: 'col-3',
                progressCol: 'col-9',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionBulletFontSize
            });
        }
    }

    escFunction(event) {

        if (event.keyCode === 27) {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
        }

    }

    render() {

        let optionsListComponents = [];
        let totalVote = 0;
        if (this.state.optionsArrayLive) {
            if (this.state.responsesArray) {
                if (this.state.responsesArray.length === 0) {
                    optionsListComponents = (this.state.optionsArrayLive || []).map((option, i) => {
                        var optionDecoration;
                        var alphabetList = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

                        if (this.state.questionData.optionDecorationValue === "Alphabet") {
                            optionDecoration =
                                <label>{alphabetList[option.optionsOrderIndex] + " - "}{option.text}</label>
                        } else if (this.state.questionData.optionDecorationValue === "Number") {
                            var optionNo = parseInt(option.optionsOrderIndex) + 1;
                            optionDecoration = <label>{optionNo + " . "}{option.text}</label>
                        } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                            optionDecoration = <label><i className="fas fa-circle pr-3"
                                                         style={{fontSize: this.state.optionBulletFontSize,}}></i>{option.text}
                            </label>;
                        } else {
                            optionDecoration = "";
                        }

                        return (
                            <div className="row" style={{padding: this.state.optionsPadding}}>
                                {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                    // // <Progress id="screenProgress" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">&nbsp; &nbsp; 0%</Progress>
                                    <form className="col-12">
                                        <div className="row align-items-center">
                                            {/* <div className={"optionDiv mb-1 " + this.state.optionCol} style={{ fontSize: this.state.optionFontSize }}> */}
                                            {/* <div className={"optionDiv mb-1 " + this.state.optionCol} >
                                                {option.text}
                                            </div> */}
                                            {(option.text === " ") ? (

                                                (option.type === "like") ? (

                                                    <div className={"optionDiv pb-2 pl-4  " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                    </div>

                                                ) : (

                                                    <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                    </div>

                                                )

                                            ) : (

                                                <div className={"optionDiv mb-1  " + this.state.optionCol}>
                                                    {/* {option.text} */}
                                                    {optionDecoration}
                                                </div>

                                            )}
                                            <div className={"progressBarDiv pr-2  " + this.state.progressCol}>
                                                <Progress id="screenProgress" animated style={{
                                                    width: this.state.progressBarWidth,
                                                    height: this.state.progressbarHeight,
                                                    marginRight: '10px'
                                                }} aria-valuenow="30">
                                                    <div className="progress-bar " role="progressbar" name="progress"
                                                         style={{
                                                             height: this.state.progressbarHeight,
                                                             backgroundColor: this.state.currentEvent.textColor
                                                         }} aria-valuenow="90"></div>
                                                </Progress>
                                                <div className="pl-5">0%</div>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form className="col-12">
                                        <div className="row align-items-center">
                                            {/* <div className={"optionDiv mb-1 optionDivMob " + this.state.optionCol} style={{ fontSize: this.state.optionFontSize }}> */}
                                            {/* <div className={"optionDiv mb-1 optionDivMob " + this.state.optionCol} >
                                                {option.text}
                                            </div> */}
                                            {(option.text === " ") ? (

                                                (option.type === "like") ? (

                                                    <div
                                                        className={"optionDiv pb-2 pl-4 optionDivMob  " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                    </div>

                                                ) : (

                                                    <div
                                                        className={"optionDiv pt-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                    </div>

                                                )

                                            ) : (

                                                <div className={"optionDiv mb-1 optionDivMob " + this.state.optionCol}>
                                                    {/* {option.text} */}
                                                    {optionDecoration}
                                                </div>

                                            )}
                                            <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                <Progress id="screenProgress" animated style={{
                                                    width: this.state.progressBarWidth,
                                                    height: this.state.progressbarHeight,
                                                    marginRight: '10px'
                                                }} aria-valuenow="30">
                                                    <div className="progress-bar " role="progressbar" name="progress"
                                                         style={{
                                                             height: this.state.progressbarHeight,
                                                             backgroundColor: this.state.currentEvent.textColor
                                                         }} aria-valuenow="90"></div>
                                                </Progress>
                                                <div className="pl-5">0</div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )
                    });
                } else {
                    // we remove the repetitive participants id that may happen because of the multiple choices selection
                    var uniqueResponses = [];
                    for (var index = 0; index < this.state.responsesArray.length; index++) {
                        if (!uniqueResponses.includes(this.state.responsesArray[index].participantsId)) {
                            //if the list of the participants does not have this participants id we add it to the list 
                            uniqueResponses.push(this.state.responsesArray[index].participantsId);
                        }
                    }
                    totalVote += uniqueResponses.length;

                    var progressBarWidth;
                    if (totalVote < 10) {
                        progressBarWidth = '98%'
                    } else if (9 < totalVote < 100) {
                        progressBarWidth = '90%'
                    } else {
                        progressBarWidth = '85%'
                    }
                    var sortedOptions = [];
                    //Check the user settings for ordering the question options based on the number of votes
                    if (this.state.currentEvent.optionsOrderStatus === 'Active') {                        //To sort the responses list in decreasing order, the item[0] will have the highest vote */}
                        for (var i = 0; i < this.state.optionsArrayLive.length; i++) {
                            var itemVote = 0;
                            for (let index in this.state.responsesArray) {
                                if (this.state.responsesArray[index].option === this.state.optionsArrayLive[i].id) {
                                    itemVote += 1;
                                }
                            }
                            sortedOptions.push({
                                text: this.state.optionsArrayLive[i].text,
                                votes: itemVote,
                                isAnswer: this.state.optionsArrayLive[i].isAnswer,
                                type: this.state.optionsArrayLive[i].type
                            })
                        }
                        ;
                        sortedOptions.sort(function (a, b) {
                            return a.votes > b.votes ? -1 : 1
                        });
                        optionsListComponents = (sortedOptions || []).map((option, i) => {
                            var optionDecoration;
                            var alphabetList = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

                            if (this.state.questionData.optionDecorationValue === "Alphabet") {
                                optionDecoration =
                                    <label>{alphabetList[option.optionsOrderIndex] + " - "}{option.text}</label>
                            } else if (this.state.questionData.optionDecorationValue === "Number") {
                                var optionNo = parseInt(option.optionsOrderIndex) + 1;
                                optionDecoration = <label>{optionNo + " . "}{option.text}</label>
                            } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                                optionDecoration = <label><i className="fas fa-circle pr-3"
                                                             style={{fontSize: this.state.optionBulletFontSize,}}></i>{option.text}
                                </label>;
                            } else {
                                optionDecoration = "";
                            }
                            var voteBarColor;
                            if (option.isAnswer === true && this.state.currentEvent.showCorrectAnswerColor === "show" && ((this.state.parentInfo.bigScreenStatus.status === "archivedQuestion") || (this.state.parentInfo.bigScreenStatus.status === "liveQuestion" && this.state.parentInfo.currentLiveSession.status === "stop"))) {
                                voteBarColor = this.state.currentEvent.correctQuestionColor;
                            } else {
                                voteBarColor = this.state.currentEvent.textColor;
                            }
                            return (
                                <div className="row " style={{
                                    padding: this.state.optionsPadding
                                }}>
                                    {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {/* <div className={"optionDiv mb-1 " + this.state.optionCol} >
                                                    {option.text}
                                                </div> */}
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div
                                                            className={"optionDiv pb-2 pl-4   " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1  " + this.state.optionCol}>
                                                        {/* {option.text} */}
                                                        {optionDecoration}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30"
                                                              value={Math.round((option.votes / totalVote) * 100)}>
                                                        <div className="progress-bar" role="progressbar" name="progress"
                                                             style={{
                                                                 height: this.state.progressbarHeight,
                                                                 backgroundColor: voteBarColor
                                                             }} aria-valuenow="90"></div>
                                                    </Progress>
                                                    <div
                                                        className="pl-5"> {Math.round(((option.votes) / totalVote) * 100)}%
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    ) : (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {/* <div className={"optionDiv mb-1 " + this.state.optionCol} >
                                                    {option.text}
                                                </div> */}
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div
                                                            className={"optionDiv pb-2 pl-4   " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1  " + this.state.optionCol}>
                                                        {option.text}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30" max={totalVote} value={option.votes}>
                                                        <div className="progress-bar " role="progressbar"
                                                             name="progress" style={{
                                                            height: this.state.progressbarHeight,
                                                            backgroundColor: voteBarColor
                                                        }} aria-valuenow="90"></div>
                                                    </Progress>
                                                    <div className="pl-5">{option.votes}</div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });
                    } else {
                        optionsListComponents = (this.state.optionsArrayLive || []).map((option, i) => {
                            var optionDecoration;
                            var alphabetList = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

                            if (this.state.questionData.optionDecorationValue === "Alphabet") {
                                optionDecoration =
                                    <label>{alphabetList[option.optionsOrderIndex] + " - "}{option.text}</label>
                            } else if (this.state.questionData.optionDecorationValue === "Number") {
                                var optionNo = parseInt(option.optionsOrderIndex) + 1;
                                optionDecoration = <label>{optionNo + " . "}{option.text}</label>
                            } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                                optionDecoration = <label><i className="fas fa-circle pr-3"
                                                             style={{fontSize: this.state.optionBulletFontSize,}}></i>{option.text}
                                </label>;
                            } else {
                                optionDecoration = "";
                            }
                            var voteBarColor;
                            if (option.isAnswer === true && this.state.currentEvent.showCorrectAnswerColor === "show" && ((this.state.parentInfo.bigScreenStatus.status === "archivedQuestion") || (this.state.parentInfo.bigScreenStatus.status === "liveQuestion" && this.state.parentInfo.currentLiveSession.status === "stop"))) {
                                voteBarColor = this.state.currentEvent.correctQuestionColor;
                            } else {
                                voteBarColor = this.state.currentEvent.textColor;
                            }
                            itemVote = 0;
                            for (let index in this.state.responsesArray) {
                                if (this.state.responsesArray[index].option === option.id) {
                                    itemVote += 1;
                                }
                            }

                            return (
                                <div className="row " style={{padding: this.state.optionsPadding}}>
                                    {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {/* <div className={"optionDiv mb-1 optionDivMob " + this.state.optionCol} >
                                                    {option.text}
                                                </div> */}
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div
                                                            className={"optionDiv pb-2 pl-4 optionDivMob  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div
                                                            className={"optionDiv pt-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div
                                                        className={"optionDiv mb-1 optionDivMob " + this.state.optionCol}>
                                                        {/* {option.text} */}
                                                        {optionDecoration}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30"
                                                              value={Math.round((itemVote / totalVote) * 100)}>
                                                        <div className="progress-bar" role="progressbar" name="progress"
                                                             style={{
                                                                 height: this.state.progressbarHeight,
                                                                 backgroundColor: voteBarColor
                                                             }} aria-valuenow="90"></div>
                                                    </Progress>
                                                    <div
                                                        className="pl-5"> {Math.round(((itemVote) / totalVote) * 100)}%
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    ) : (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {/* <div className={"optionDiv mb-1 " + this.state.optionCol} >
                                                    {option.text}
                                                </div> */}
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div
                                                            className={"optionDiv pb-2 pl-4   " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1  " + this.state.optionCol}>
                                                        {/* {option.text} */}
                                                        {optionDecoration}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30" max={totalVote} value={itemVote}>
                                                        <div className="progress-bar " role="progressbar"
                                                             name="progress" style={{
                                                            height: this.state.progressbarHeight,
                                                            backgroundColor: voteBarColor
                                                        }} aria-valuenow="90"></div>
                                                    </Progress>
                                                    <div className="pl-5"> {itemVote} </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });
                    }
                }
            }
        }
        var middleContainerComponent = (
            <div className="containerMiddle" id="containerMiddle"
                 style={{
                     color: this.state.currentEvent.backgroundTextColor,
                     overflowY: this.state.overFlowY,
                 }}>
                {/* {(this.state.status !== 'NoLiveSession') ? ( */}
                <div id="containerMiddleFont">
                    {(this.state.questionData && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice")) ? (

                        <div>
                            <div className="row mb-4 mt-4 ">
                                {(optionsListComponents.length !== 0) ? (

                                    <div className="col-12 text-left pl-3"
                                         style={{
                                             fontSize: this.state.questionForOptionsFontSize,
                                             lineHeight: this.state.questionForOptionsLineHeight
                                         }}
                                    >
                                        {/* <label style={{ fontSize: this.state.questionSize }}><b>{this.state.questionData.text}</b></label> */}
                                        <b>{this.state.questionData.text}</b>
                                    </div>

                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="row ml-4 mr-2"
                                 style={{
                                     color: this.state.currentEvent.backgroundTextColor,
                                     paddingTop: this.state.optionsContainerPaddingTop,
                                     fontSize: this.state.optionsFontSize,
                                     lineHeight: this.state.optionsLineHeight

                                 }}
                            >
                                <div className="col-12"
                                     style={{textIndent: this.state.optionsTextIndent}}>
                                    {optionsListComponents}
                                </div>
                            </div>
                        </div>

                    ) : (

                        <div className="col-12" style={{color: this.state.currentEvent.backgroundTextColor}}>
                            <div className="row mt-3">
                                <b>{this.state.questionData.text}</b>
                            </div>
                            <div className="col-12 mt-4">
                                <AnswerText
                                    question={this.state.questionData}
                                    responsesArray={this.state.responsesArraySimpleText}
                                    primaryColor={this.state.currentEvent.primaryColor}
                                    textColor={this.state.currentEvent.textColor}
                                />
                            </div>
                        </div>

                    )}
                </div>
            </div>
        )

        return (
            <div style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                {(this.state.currentEvent === 'default') ? (

                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    (this.state.currentEvent.showIntroductionPage === "Yes") ? (

                        <div className="row containerIntroduction text-center"
                             style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                            <IntroductionPage
                                currentEventToIntroduction={this.state.currentEvent}
                            />
                        </div>

                    ) : (

                        <div>
                            <div className="LiveScreenContainer">
                                <div className="headerBigScreen"
                                     style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}>
                                    {(this.state.currentEvent.voteCounterStatus === 'Active' && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice")) ? (

                                        <div className="row">
                                            <div className="col-9 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})</div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                ) : (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                )}
                                            </div>
                                            <div className="col-3 text-center pt-4">
                                                <div className="bigScreenVoteNo"
                                                     style={{color: this.state.currentEvent.headerTextColor}}>Votes No.
                                                    : {totalVote} </div>
                                            </div>
                                        </div>

                                    ) : (

                                        <div className="row">
                                            <div className="col-12 text-left p-1">
                                                {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={this.state.currentEvent.eventLogoUrl}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                ) : (

                                                    <form className="form-inline pl-4">
                                                        <div className="logoHeader">
                                                            <img src={"/files/LogoTemp.png"}
                                                                 className='img-fluid img-thumbnail' alt="Logo"
                                                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                        </div>
                                                        {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                            <div>
                                                                <div className="bigScreenTitle"
                                                                     style={{color: this.state.currentEvent.headerTextColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                                                </div>
                                                            </div>

                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </form>

                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {/* </div> */}
                                </div>
                                {/* middle container */}
                                {(this.state.currentEvent.themeBigScreenImageUrl === "default") ? (

                                    <div>
                                        {middleContainerComponent}
                                    </div>

                                ) : (

                                    <div className="imageTheme"
                                         style={{backgroundImage: "url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}}>
                                        {middleContainerComponent}
                                    </div>

                                )}
                                {/* <div className="footerBigScreen" style={{ backgroundColor: this.state.currentEvent.primaryColor }}></div> */}
                            </div>
                        </div>

                    )

                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventData: state.bigScreenReducer.eventData,
        questionOptions: state.bigScreenReducer.questionOptions,
        questionData: state.bigScreenReducer.questionData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(bigScreenActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetQuestion: (eventId, elementId, questionId, callback) => dispatch(bigScreenActions.getQuestion(eventId, elementId, questionId, callback)),
        onGetQuestionInfo: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfo(eventId, idQuestionEl, questionId, callback)),
        onGetResponses: (eventId, idElement, questionId, votingId, callback) => dispatch(questionActions.getResponses(eventId, idElement, questionId, votingId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onInitQuestionOptions: (eventId, idElement, questionId, callback) => dispatch(bigScreenActions.getAllQuestionOptionsScreen(eventId, idElement, questionId, callback)),
        onGetLastVotingSessionId: (eventId, idElement, questionId, callback) => dispatch(bigScreenActions.getLastVotingSessionId(eventId, idElement, questionId, callback)),
        onGetQuestionInfoOnChange: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfoOnChange(eventId, idQuestionEl, questionId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveQuestionScreen);
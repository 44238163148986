import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import {Textfit} from 'react-textfit';


class LiveTweetScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            elementId: '',
            responsesArray: [],
            currentEvent: '',
            currentTweetElement: '',
            width: 0,
            height: 0,
            tweetsList: [],
        }

        this.escFunction = this.escFunction.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.addEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback, currentTweetElement: callback.currentTweetElement});
            if (callback.currentTweetElement !== "default") {
                if (this.unsubscribeGetAllLiveTweetsList) {
                    this.unsubscribeGetAllLiveTweetsList();
                }
                this.props.onGetAllLiveTweetsList(this.state.eventId, callback.currentTweetElement, unsubscribe => this.unsubscribeGetAllLiveTweetsList = unsubscribe);
            }
        })
        /*************** BACK HISTORY BUTTON ****************/
        window.onpopstate = () => {
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetAllLiveTweetsList) {
            this.unsubscribeGetAllLiveTweetsList();
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.history.push("/event/" + this.state.eventId + "/tweet/" + this.state.currentEvent.currentTweetElement);
        }

    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }


    render() {

        let backgroundColor;
        let liveTweetsListComponents = [];
        var tweetCardSize;
        if (this.props.liveTweetsList) {
            if (this.props.liveTweetsList.length <= 8) {
                tweetCardSize = 'cardTextTweetBig'
            } else {
                tweetCardSize = 'cardBodyTweetSmall'
            }

            liveTweetsListComponents = (this.props.liveTweetsList || []).map((tweet, i) => {

                return (
                    <div className="row mb-3">
                        {(tweet.bArchived === false) ? (

                            <div className="col-lg-12 col-sm-12">
                                <div className="card ">
                                    <div className="cardTextTweet">
                                        <div className="cardTextTweetHeader px-3 text-white py-2"
                                             style={{backgroundColor: this.state.currentEvent.primaryColor}}><i
                                            className="fas fa-user mr-2"
                                            style={{color: this.state.currentEvent.textColor}}></i><b
                                            style={{color: this.state.currentEvent.textColor}}>{tweet.participantDefinedName}</b>
                                        </div>
                                        <div className="cardTextTweetBody px-3 pb-2 pt-2"
                                             style={{color: this.state.currentEvent.textColor}}>{tweet.sText}</div>
                                    </div>
                                    {/* {(liveTweetsListComponents.length <= 8) ? (
                                        <div className={"cardTextTweet " + tweetCardSize}>
                                            {(tweet.participantDefinedName !== "") ? (
                                                <div> From {tweet.participantDefinedName}: {tweet.sText}</div>
                                            ) : (
                                                    <div>{tweet.sText}</div>
                                                )}
                                        </div>
                                    ) : (
                                            <div className={"cardTextTweet " + tweetCardSize}>
                                                {(tweet.participantDefinedName !== "") ? (
                                                    <div> From {tweet.participantDefinedName}: {tweet.sText}</div>
                                                ) : (
                                                        <p>{tweet.sText}</p>
                                                    )}
                                            </div>
                                        )} */}

                                </div>
                            </div>

                        ) : (
                            <div></div>
                        )}
                    </div>
                )
            });
        }

        if (this.state.currentEvent.eventLogoUrl && this.state.currentEvent.primaryColor && this.state.currentEvent.title) {
            return (
                <div style={{backgroundColor: this.state.currentEvent.backgroundColor}} className="prova">
                    <div className="LiveScreenContainer">
                        <div className="headerBigScreen"
                             style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                            <div className="row">
                                <div className="col-12 text-left" style={{color: this.state.currentEvent.textColor}}>
                                    {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                        <form className="form-inline">
                                            <div className="logoHeader">
                                                <img src={this.state.currentEvent.eventLogoUrl}
                                                     className='img-fluid img-thumbnail' alt="Logo"
                                                     style={{backgroundColor: this.state.currentEvent.primaryColor}}/>
                                            </div>
                                            {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                <div className="bigScreenTitle"
                                                     style={{color: this.state.currentEvent.textColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})</div>

                                            ) : (
                                                <div></div>
                                            )}
                                        </form>

                                    ) : (

                                        <form className="form-inline">
                                            <div className="logoHeader">
                                                <img src={"/files/LogoTemp.png"} className='img-fluid img-thumbnail'
                                                     alt="Logo"
                                                     style={{backgroundColor: this.state.currentEvent.primaryColor}}/>
                                            </div>
                                            <div className="bigScreenTitle"
                                                 style={{color: this.state.currentEvent.textColor}}>{this.state.currentEvent.title} (#{this.state.currentEvent.eventCode})
                                            </div>
                                        </form>

                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="containerMiddleTweet">
                            <div className="row mb-5">
                                {(liveTweetsListComponents.length > 0) ? (

                                    <div className="col-12"
                                         style={{color: this.state.currentEvent.backgroundTextColor}}>
                                        <div className="mt-3">
                                            {/* <h2 className="mb-3 headerTextStyle" ><b>Live Tweets</b>
                                                <div className="borderLine" style={{ backgroundColor: this.state.currentEvent.backgroundTextColor }}></div></h2> */}
                                            <div className="mb-3 headerTextStyle d-none"><b>Live Tweets</b></div>
                                            {liveTweetsListComponents}
                                        </div>
                                    </div>

                                ) : (

                                    <div className="centerCard">
                                        <div className="card text-center p-2 bg-white">
                                            <div className="card-body"
                                                 style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                                                <h3>
                                                    <p className="card-text"
                                                       style={{color: this.state.currentEvent.textColor}}>No live Tweet
                                                        session is available for this event yet.</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                        <div className="footerBigScreen"
                             style={{backgroundColor: this.state.currentEvent.primaryColor}}></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="loaderContainer center">
                    <div className="loader border-primary  my-auto"></div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        eventData: state.bigScreenReducer.eventData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetAllLiveTweetsList: (eventId, idElement, callback) => dispatch(bigScreenActions.getAllLiveTweetsList(eventId, idElement, callback)),
        onUpdateCurrentField: (option, eventId, value, callback) => dispatch(eventActions.updateCurrentField(option, eventId, value, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveTweetScreen);
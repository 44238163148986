import React, { Component } from 'react';
import styled from 'styled-components';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import ChildElement from '../Components/ChildElement';
import ParentElement from '../Components/ParentElement';
import ParentComponent from './ParentComponent';
import ChildComponent from './ChildComponent';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const getPaddingLeft = (level, type) => {

  let paddingLeft = level * 30;
  // if (type === 'file') paddingLeft += 20;
  // if (type !== 'folder') paddingLeft += 45;
  // if (type === 'folder') paddingLeft += 75;
  // if (type === 'room') paddingLeft += 15;
  return paddingLeft;

}

// const StyledTreeNode = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 5px 8px;
//   padding-left: ${props => getPaddingLeft(props.level, props.type)}px;

//   &:hover {
//     background: lightgray;
//   }
// `;

const StyledTreeNode = styled.div`

  // padding: 5px 8px;
  padding-left: ${props => getPaddingLeft(props.level, props.type)}px;

  // &:hover {
  //   background: lightgray;
  // }
`;

const NodeIcon = styled.div`
  font-size: 20px;
  // margin-top:10px;
  cursor:pointer;
  // margin-right: ${props => props.marginRight ? props.marginRight : 3}px;
`;

const RootElement = styled.div`
  font-size: 15px;
  // margin-right: ${props => props.marginRight ? props.marginRight : 5}px;
`;


// const getNodeLabel = (node) => last(node.path.split('/'));
const getNodeLabel = (node) => node.title;

// const TreeNode = (props) => {
class TreeNode extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nodes: [],
      currentItem: '',
      editingId: 'default'
      // nodes: data,
    };

  }
  
  render() {

        const {
            node,
            getChildNodes,
            level,
            onToggle,
            onNodeSelect,
            setCurrentItem,
            saveEditedItem,
            editingId,
            editItem,
            currentEvent,
            showItem,
            draggedChildElement,
            onSortEndChild,
            getTweetsToDownload,
            getQuestionsToDownload
        } = this.props;


    const DragHandle = SortableHandle(({ childNode }) => {
      return (
        <i className="fas fa-arrows-alt-v moveIconElements px-2 py-4 py-lg-3"></i>
      )
    });

    const SortableItem = SortableElement(({ childNode, index }) => {
      return (
        <li>
          <div className="row">
            <div className="col">
              <TreeNode
                key={childNode.id}
                {...this.props}
                node={childNode}
                level={level + 1}
              />
            </div>
            {(currentEvent.eventStatus === "Archived")?(
              <div></div>
            ) : (

              <div className="col-auto pl-0">
                <DragHandle
                  childNode={childNode}
                />
              </div>

            )}
          </div>
        </li>
      );
    });

    const SortableList = SortableContainer(() => {
      if (node.isOpen) {
        return (
          <ul id="questionsList">
            {node.children.map((childNode, index) => (
                            <SortableItem key={`item-${index}`} index={index} childNode={childNode}
                                          collection={childNode.sParentId}/>
            ))}
          </ul>
        );
      } else {
        //return (<div></div>);
        return null;
      }
    });

    return (
      <React.Fragment>
        <StyledTreeNode level={level} type={node.sType} className="eventNodesContainer">
          <div className="row"  >
            {(node.sType === 'event') ? (

              <div className="col-auto" role="button" onClick={() => onNodeSelect(node)}>
                <NodeIcon onClick={() => onToggle(node)}>
                                    {node.sType === 'event' && node.children.length > 0 && (node.isOpen ?
                                        <i className="fas fa-chevron-down py-3 py-lg-4"></i> :
                                        <i className="fas fa-chevron-right py-3 py-lg-4 mr-5px"></i>)}
                </NodeIcon>
              </div>

            ) : (

              <div className="col-auto" role="button" onClick={() => onNodeSelect(node)}>
                <NodeIcon onClick={() => onToggle(node)}>
                                    {node.sType === 'folder' && node.children.length > 0 && (node.isOpen ?
                                        <i className="fas fa-chevron-down py-4 py-lg-3"></i> :
                                        <i className="fas fa-chevron-right py-4 py-lg-3 mr-5px"></i>)}
                                    {node.sType === 'room' && node.children.length > 0 && (node.isOpen ?
                                        <i className="fas fa-chevron-down py-4 py-lg-3"></i> :
                                        <i className="fas fa-chevron-right py-4 py-lg-3 mr-5px"></i>)}
                                    {node.sType === 'folder' && node.children.length === 0 &&
                                        <i className="fas fa-minus py-3"></i>}
                                    {node.sType === 'room' && node.children.length === 0 &&
                                        <i className="fas fa-minus py-3"></i>}
                </NodeIcon>
              </div>

            )}
            <div className="col">
              {(node.sType === "folder" || node.sType === "room" || node.sType === "event") ? (

                <ParentComponent
                  parentNode={node}
                  event={currentEvent}
                  getPaddingLeft={getPaddingLeft}
	                setCurrentItem={() => {
	                    setCurrentItem(node)
	                }}
	                saveEditedItem={() => {
	                    saveEditedItem(node)
	                }}
	                editItem={() => {
	                    editItem(node)
	                }}
                  editingId={editingId}
	                draggedChildElement={(parentNode, childNode) => {
	                    draggedChildElement(parentNode, childNode)
	                }}
	                onToggle={() => {
	                    onToggle(node)
	                }}
                />

              ) : (

                <ChildComponent
                  childNode={node}
                  event={currentEvent}
                  id={node.id}
	                setCurrentItem={() => {
	                    setCurrentItem(node)
	                }}
	                saveEditedItem={() => {
	                    saveEditedItem(node)
	                }}
	                editItem={() => {
	                    editItem(node)
	                }}
                  editingId={editingId}
                    showItem={() => {
                        showItem(node)
                    }}
                    getTweetsToDownload={() => {
                        getTweetsToDownload(node)
                    }}
                    getQuestionsToDownload={() => {
                        getQuestionsToDownload(node)
                    }}
                />
              )}
            </div>
          </div>
        </StyledTreeNode>
        <div className="parentDiv" id={node.id}>
          <SortableList onSortEnd={onSortEndChild}  useDragHandle={true} />
        </div>
      </React.Fragment>
    );
  }
}

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  currentEvent: PropTypes.object.isRequired,
  getChildNodes: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  onNodeSelect: PropTypes.func.isRequired,
  setCurrentItem: PropTypes.func.isRequired,
  saveEditedItem: PropTypes.func.isRequired,
  editingId: PropTypes.number.isRequired,
  editItem: PropTypes.func.isRequired,
  showItem: PropTypes.func.isRequired,
  draggedChildElement: PropTypes.func.isRequired,
  onSortEndChild: PropTypes.func.isRequired,
  getTweetsToDownload: PropTypes.func.isRequired,
  getQuestionsToDownload: PropTypes.func.isRequired
};

TreeNode.defaultProps = {
  level: 0,
};

export default TreeNode;

import { actionTypes } from '../actions/actionTypes';

let initialState = {
    questionList: null,
    questionOptions: [],
    approvedTweetsList:[],
};

export function bigScreenReducer(state = initialState, action) {

    switch (action.type) {

        case actionTypes.FETCH_ALL_QUESTION_OPTIONS_BIG_SCREEN_SUCCESS:
            return {
                ...state,
                questionOptions: action.questionOptions,
            };

        case actionTypes.FETCH_QUESTION_DATA_SUCCESS:
            return {
                ...state,
                questionData: action.questionData
            }
        case actionTypes.GET_EVENT_DATA:
            return {
                ...state,
                eventData: action.eventData
            }

        case actionTypes.FETCH_ALL_LIVE_TWEETS_BIG_SCREEN_SUCCESS:
            return {
                ...state,
                liveTweetsList: action.liveTweetsList
            }

        case actionTypes.FETCH_ALL_APPROVED_TWEETS_BIG_SCREEN_SUCCESS:
            return {
                ...state,
                approvedTweetsList: action.approvedTweetsList
            }

        default:
            return state;
    }
};
import { actionTypes } from '../actions/actionTypes';

let initialState = {
    surveyQuestionList: null,
    questionOptions: [],
    votingSessionsList: [],
    responsesList: [],
    surveyExtrenalLinks:[]
};

export function surveyReducer(state = initialState, action) {

    switch (action.type) {

        case actionTypes.Fetch_ALL_SURVEY_QUESTIONS_SUCCESS:
            return {
                ...state,
                surveyQuestionList: action.surveyQuestionList,
            };

        case actionTypes.SAVE_SURVEY_QUESTION_SUCCESS:
            var index1;

            index1 = state.surveyQuestionList.findIndex((elem) => {
                if (elem && elem.id === action.questionData.id) {
                    return true;
                }
                return false;
            });

            if (index1 !== (-1)) {
                return {
                    ...state, surveyQuestionList: [
                        //before the one we are updating
                        ...state.surveyQuestionList.slice(0, index1),
                        {
                            ...state.surveyQuestionList[index1],
                            id: action.questionData.id,
                            text: action.questionData.text,
                            type: action.questionData.type,
                            letMultipleSelect: action.questionData.letMultipleSelect,
                            optionsValues: action.questionData.optionsValues,
                            questionOrderIndex: action.questionData.questionOrderIndex,
                            showCorrectAnswerColor: action.questionData.showCorrectAnswerColor
                        }, ...state.surveyQuestionList.slice(index1 + 1)
                    ],
                }
            }
            break;

        case actionTypes.Fetch_ALL_SURVEY_QUESTION_OPTIONS_SUCCESS:
            var questionIndex = state.surveyQuestionList.findIndex((elem) => {
                if (elem && elem.id === action.optionsData.id) {
                    return true;
                }
                return false;
            });

            if (questionIndex !== (-1)) {
                return {
                    ...state, surveyQuestionList: [
                        //before the one we are updating
                        ...state.surveyQuestionList.slice(0, questionIndex),
                        {
                            ...state.surveyQuestionList[questionIndex],
                            optionsValues: action.optionsData.optionsValues
                        }, ...state.surveyQuestionList.slice(questionIndex + 1)
                    ], questionOptions: action.optionsData.optionsValues,
                }
            }else{
                return state;
            }

        case actionTypes.ADD_SURVEY_QUESTION_OPTION_SUCCESS:

            let indexOption = (state.questionOptions.length);
            if (indexOption !== (-1)) {

                return {
                    ...state, questionOptions: [
                        ...state.questionOptions.slice(0, indexOption),//before the one we are updating
                        {
                            ...state.surveyQuestionList[indexOption],
                            id: 'newValue',
                            text: '',
                        }
                    ]
                }

            };

            return state;


        case actionTypes.DELETE_SURVEY_QUESTION_SUCCESS:

            return {
                surveyQuestionList: state.surveyQuestionList.filter(question => question.id !== action.id)
            };

        case actionTypes.DELETE_OPTION_SUCCESS:

            return state;

        case actionTypes.SAVE_VOTING_SESSION_SUCCESS:
            return state;
        

        case actionTypes.SURVEY_QUESTION_INDEXES_UPDATED_SUCCESS:
        return state;

        case actionTypes.EXTERNAL_SURVEY_CONTENT_UPDATED_SUCCESS:
        return state;

        case actionTypes.GET_SURVEY_EXTERNAL_LINKS_SUCCESS:
        return{
            ...state,
            surveyExtrenalLinks: action.surveyExtrenalLinks
        }


        default:
            return state;
    }
};

import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {connect} from 'react-redux';
import {Textfit} from 'react-textfit';
import {eventActions} from '../store/actions/EventActions';
import moment from 'moment';


class LiveTweetScreenModerator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            idItem: this.props.match.params.idItem,
            elementId: '',
            responsesArray: [],
            currentEvent: '',
            currentTweetElement: '',
            width: 0,
            height: 0,
            toggleActive: true,
            tweetsList: [],
            tweetsToShow: "Approved",
            addedClassTweetsToShow: ' recent ',
            parentInfo: '',

        }
        this.escFunction = this.escFunction.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getTweetsToShow = this.getTweetsToShow.bind(this);
        this.sendTweetToScreen = this.sendTweetToScreen.bind(this);
        this.stopTweetToScreen = this.stopTweetToScreen.bind(this);

    }

    componentDidMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.addEventListener("keydown", this.escFunction, false);
        this.props.onEventInfoChange(this.state.eventId, callback => {
            this.setState({...this.state, currentEvent: callback});
            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({
                    ...this.state,
                    parentInfo: parentInfo,
                    currentTweetElement: parentInfo.currentTweetElement
                });
                if (parentInfo.currentTweetElement !== "default") {
                    if (this.unsubscribeGetAllLiveTweetsList) {
                        this.unsubscribeGetAllLiveTweetsList();
                    }
                    this.props.onGetAllLiveTweetsList(this.state.eventId, parentInfo.currentTweetElement, unsubscribe => this.unsubscribeGetAllLiveTweetsList = unsubscribe);

                    if (this.unsubscribeGetAllApprovedTweetsList) {
                        this.unsubscribeGetAllApprovedTweetsList();
                    }
                    this.props.onGetAllApprovedTweetsList(this.state.eventId, parentInfo.currentTweetElement, unsubscribe => this.unsubscribeGetAllApprovedTweetsList = unsubscribe);

                    this.setState({...this.state, tweetsToShow: 'Approved', addedClassTweetsToShow: " recent "});
                }
            });
        })
        /*************** BACK HISTORY BUTTON ****************/
        window.onpopstate = () => {
        }

    }

    componentWillUnmount() {
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetAllLiveTweetsList) {
            this.unsubscribeGetAllLiveTweetsList();
        }

        if (this.unsubscribeGetAllApprovedTweetsList) {
            this.unsubscribeGetAllApprovedTweetsList();
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
        }
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    getTweetsToShow(option) {
        switch (option) {
            case 'TweetsToShow':
                if (this.state.tweetsToShow === 'Approved') {
                    //this.props.onGetAllLiveTweetsList(this.state.eventId, this.state.currentTweetElement);
                    this.setState({...this.state, tweetsToShow: 'Live', addedClassTweetsToShow: " "});
                } else if (this.state.tweetsToShow === 'Live') {
                    //this.props.onGetAllApprovedTweetsList(this.state.eventId, this.state.currentTweetElement);
                    this.setState({...this.state, tweetsToShow: 'Approved', addedClassTweetsToShow: " recent "});
                }
                break;

            default:
                return true;
        }
    }

    sendTweetToScreen(tweet) {
        this.props.onUpdateTweetFields(this.state.eventId, this.state.parentInfo.currentTweetElement, tweet.id, 'liveTrue');
    }

    stopTweetToScreen(tweet) {
        this.props.onUpdateTweetFields(this.state.eventId, this.state.parentInfo.currentTweetElement, tweet.id, 'liveFalse');
    }

    render() {
        let backgroundColor;
        let TweetsListComponents = [];
        var tweetCardSize;

        //Compose list of tweets Approved/Live based on selection to be rendered below.
        if (this.props.liveTweetsList || this.props.approvedTweetsList) {
            //FC: commented code because not used.
            /*
            if (this.props.liveTweetsList.length <= 8) {
                tweetCardSize = 'cardTextTweetBig'
            } else {
                tweetCardSize = 'cardBodyTweetSmall'
            }
            */
            if (this.state.tweetsToShow === "Approved") {
                // moderate option is On so we nee to show all the approved tweets
                TweetsListComponents = (this.props.approvedTweetsList || []).map((tweet, i) => {
                    return (
                        <div className="row mb-3" key={i}>
                            {(tweet.bArchived === false) ? (

                                <div className="col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="cardTextTweet">
                                            <div className="cardTextTweetHeader px-3 text-white py-2"
                                                 style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <i className="fas fa-user mr-2"
                                                           style={{color: this.state.currentEvent.textColor}}></i>
                                                        <b style={{color: this.state.currentEvent.textColor}}>{tweet.participantDefinedName}</b>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        {(tweet.live && tweet.live === true) ? (

                                                            <div className="hours-single-tweet">
                                                                <label className="pr-2 mb-0"
                                                                       style={{color: this.state.currentEvent.textColor}}>
                                                                    {/* {moment(tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")} */}
                                                                </label>
                                                                <button className="stop-icon-tweet rounded btn-danger"
                                                                        onClick={() => {
                                                                            this.stopTweetToScreen(tweet)
                                                                        }}>
                                                                    <i className="fas fa-stop-circle"></i>
                                                                </button>
                                                            </div>

                                                        ) : (

                                                            <div className="hours-single-tweet"
                                                                 style={{color: this.state.currentEvent.textColor}}>
                                                                <label className="pr-2 mb-0">
                                                                    {/* {moment(tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")} */}
                                                                </label>
                                                                <button
                                                                    className="screen-icon-tweet rounded btn-primary "
                                                                    onClick={() => {
                                                                        this.sendTweetToScreen(tweet)
                                                                    }}>
                                                                    <i className="fas fa-desktop"></i>
                                                                </button>
                                                            </div>

                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cardTextTweetBody px-3 pb-2"
                                                 style={{color: this.state.currentEvent.primaryColor}}>
                                                {tweet.sText}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    )
                });
            } else if (this.state.tweetsToShow === "Live") {
                TweetsListComponents = (this.props.liveTweetsList || []).map((tweet, i) => {

                    return (
                        <div className="row mb-3" key={i}>
                            {(tweet.bArchived === false) ? (

                                <div className="col-lg-12 col-sm-12">
                                    <div className="card ">
                                        <div className="cardTextTweet">
                                            <div className="cardTextTweetHeader px-3 text-white py-2"
                                                 style={{backgroundColor: this.state.currentEvent.primaryColor}}><i
                                                className="fas fa-user mr-2"
                                                style={{color: this.state.currentEvent.textColor}}></i><b
                                                style={{color: this.state.currentEvent.textColor}}>{tweet.participantDefinedName}</b>
                                            </div>
                                            <div className="cardTextTweetBody px-3 pb-2 pt-2"
                                                 style={{color: this.state.currentEvent.primaryColor}}>{tweet.sText}</div>
                                        </div>
                                    </div>
                                </div>

                            ) : (
                                <div></div>
                            )}
                        </div>
                    )
                });
            }
        }
        if (this.state.currentEvent.eventLogoUrl && this.state.currentEvent.primaryColor && this.state.currentEvent.title) {

            return (
                <div className="container-fluid p-0">
                    <div style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                        <div className="LiveScreenContainer">
                            <div className="headerBigScreen fluid-container"
                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}>
                                <div className="row">
                                    <div className="col-10 text-left"
                                         style={{color: this.state.currentEvent.headerTextColor}}>
                                        {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                            <form className="form-inline">
                                                <div className="logoHeader pl-4">
                                                    {/* <img src={this.state.currentEvent.eventLogoUrl} className='img-fluid img-thumbnail' alt="Logo" style={{ backgroundColor: this.state.currentEvent.primaryColor }} /> */}
                                                </div>
                                                {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                    <div className="bigScreenTitle Moderator"
                                                         style={{color: this.state.currentEvent.headerTextColor}}>
                                                        {this.state.currentEvent.title}
                                                    </div>

                                                ) : (
                                                    <div></div>
                                                )}
                                            </form>

                                        ) : (

                                            <form className="form-inline">
                                                <div className="logoHeader pl-4">
                                                    {/* <img src={"/files/LogoTemp.png"} className='img-fluid img-thumbnail' alt="Logo" style={{ backgroundColor: this.state.currentEvent.primaryColor }} /> */}
                                                </div>
                                                {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                    <div className="bigScreenTitle Moderator"
                                                         style={{color: this.state.currentEvent.headerTextColor}}>
                                                        {this.state.currentEvent.title}
                                                    </div>

                                                ) : (
                                                    <div></div>
                                                )}
                                            </form>

                                        )}
                                    </div>
                                    <div className="col-2 text-center">
                                        <div
                                            className="d-flex justify-content-center align-items-center toggleBtnBigScreen">
                                            <button type="button" className="text-center tweetsToShowBigScreen "
                                                    id="removeBtnShadow" onClick={() => {
                                                this.getTweetsToShow('TweetsToShow')
                                            }}>
                                                <div className={"switch " + this.state.addedClassTweetsToShow}
                                                     style={{backgroundColor: this.state.currentEvent.textColor}}>
                                                    <div className="textToggleOrderBigScreen"
                                                         style={{color: this.state.currentEvent.primaryColor}}>
                                                        {/* <strong>{this.state.tweetsToShow}</strong></div> */}
                                                        {(this.state.tweetsToShow === "Approved") ? (
                                                            <strong>Approved</strong>
                                                        ) : (
                                                            <strong>Live</strong>
                                                        )}
                                                    </div>
                                                    <div className="inputOrderBigScreen"
                                                         style={{backgroundColor: this.state.currentEvent.primaryColor}}></div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(this.state.currentEvent.themeBigScreenImageUrl === "default") ? (

                                <div className="containerMiddle">
                                    <div className="row mb-5">
                                        <div className="col-12 mt-3"
                                             style={{color: this.state.currentEvent.backgroundTextColor}}>
                                            {
                                                (this.state.tweetsToShow === "Approved") ? (
                                                    <div className="mb-3 headerTextStyle d-none"><b>Approved Tweets</b>
                                                    </div>
                                                ) : (
                                                    <div className="mb-3 headerTextStyle d-none"><b>Live Tweets</b>
                                                    </div>
                                                )
                                            }
                                            {TweetsListComponents}
                                        </div>
                                    </div>
                                </div>

                            ) : (

                                <div className="imageTheme"
                                     style={{backgroundImage: "url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}}>
                                    <div className="containerMiddle">
                                        <div className="row mb-5">
                                            <div className="col-12 mt-3"
                                                 style={{color: this.state.currentEvent.backgroundTextColor}}>
                                                {
                                                    (this.state.tweetsToShow === "Approved") ? (
                                                        <div className="mb-3 headerTextStyle d-none"><b>Approved
                                                            Tweets</b></div>
                                                    ) : (
                                                        <div className="mb-3 headerTextStyle d-none"><b>Live Tweets</b>
                                                        </div>
                                                    )
                                                }
                                                {TweetsListComponents}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                            <div className="footerBigScreen"
                                 style={{backgroundColor: this.state.currentEvent.primaryColor}}></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="loaderContainer center">
                    <div className="loader border-primary  my-auto"></div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {

    return {
        eventData: state.bigScreenReducer.eventData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        onGetAllLiveTweetsList: (eventId, idElement, callback) => dispatch(bigScreenActions.getAllLiveTweetsList(eventId, idElement, callback)),
        onGetAllApprovedTweetsList: (eventId, idElement, callback) => dispatch(bigScreenActions.getAllApprovedTweetsList(eventId, idElement, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onUpdateTweetFields: (eventId, idTweetEl, tweetId, field) => dispatch(eventActions.updateTweetFields(eventId, idTweetEl, tweetId, field)),
    };

};
export default connect(mapStateToProps, mapDispatchToProps)(LiveTweetScreenModerator);
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { DragSource } from 'react-dnd';
import '../assets/touchy.js';

const Types = {

    ITEM: 'ChildComponent'

}


const itemSource = {

    beginDrag(props) {

        const item = { id: props.id, childNode: props.childNode };
        // we call the function to remove the item here

        return item;

    },
    endDrag(props, monitor) {
        if (!monitor.didDrop()) {
            // You can check whether the drop was successful
            // or if the drag ended but nobody handled the drop

            //the item has been moved properly
            // console.log('drag ended drop monitor.didDrop()..........', monitor.didDrop());

            return;
        }

        // When dropped on a compatible target, do something.
        // Read the original dragged item from getItem():
        const item = monitor.getItem();


        // You may also read the drop result from the drop target
        // that handled the drop, if it returned an object from
        // its drop() method.
        const dropResult = monitor.getDropResult();
        // console.log('dropResult monitor.getDropResult()', monitor.getDropResult());

    }

}


function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

class ChildComponent extends Component {
    componentDidMount() {
        ReactDOM.findDOMNode(this).addEventListener("doubleTap", () => {this.props.showItem(this.props.childNode);}, false); 
    }

    componentWillUnmount() {
        ReactDOM.findDOMNode(this).removeEventListener("doubleTap", () => {this.props.showItem(this.props.childNode);});
    }

    saveItem(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            this.props.saveEditedItem(this.props.parentNode)
        }
    }

    render() {
        // Your component receives its own props as usual
        const { id } = this.props;

        // These props are injected by React DnD,
        // as defined by your `collect` function above:
        const { isDragging, connectDragSource } = this.props;
        var childIcon;

        if (this.props.childNode.sType === "question") {
            childIcon = (
                <i className="fas fa-question text-primary childIcons"></i>
            )
        } else if (this.props.childNode.sType === "tweet") {
            childIcon = (
                <i className="fas fa-comments pr-2 text-success childIcons"></i>
            )
        } else if (this.props.childNode.sType === "survey") {
            childIcon = (
                <i className="far fa-list-alt text-info childIcons"></i>
            )
        } else if (this.props.childNode.sType === "eventInfo") {
            childIcon = (
                <i className="far fa-newspaper eventInfoIcon"></i>
            )
        } else if (this.props.childNode.sType === "library") {
            childIcon = (
                //  <i className="fas fa-align-right text-warning libraryIconItem"></i>	
                <i className="fas fa-book-open libraryIcon libraryIconItem"></i>
            )
        } else if (this.props.childNode.sType === "agenda") {
            childIcon = (
                <i className="fas fa-calendar-alt text-warning childIcons pl-1"></i>
            )
        } else if (this.props.childNode.sType === "qrcode") {
            childIcon = (
                <i className="fas fa-qrcode childIcons pl-1"></i>
            )
        }

        return connectDragSource(
            // return (
            <div>
                {(this.props.childNode.sParentId === "default") ? (

                    <div className="row rootElementChild" >
                        <div className="col-9 col-lg-9 col-md-9 col-sm-9">
                            <div className="row">
                                <div className="col-5 pr-0  pt-1 iconColumnChild">
                                    {childIcon}
                                </div>
                                {(this.props.editingId === this.props.childNode.id) ? (

                                    <div className=" col-7 labelFolder">
                                        <div className="input-group ml-2">
                                            <input type="text" className="form-control" onKeyUp={this.saveItem.bind(this)} id="childInputTitle" defaultValue={this.props.childNode.title} aria-label="Title" aria-describedby="basic-addon2" />
                                            <div className="input-group-append">
                                                {/* <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button> */}
                                            </div>
                                        </div>
                                    </div>

                                ) : (

                                    <div className=" col-10 labelChild"> {this.props.childNode.title}</div>

                                )}
                            </div>
                        </div>
                        {(this.props.event.eventStatus === "Archived") ? (

                            <div className="col-12 col-lg-3 col-md-3 col-sm-3 text-right pr-0">
                                {(this.props.childNode.sType === "tweet" || this.props.childNode.sType === "question") ? (

                                    (this.props.childNode.sType === "tweet") ? (

                                        <button className="bg-primary itemsFunctionsBtnDel" onClick={() => { this.props.getTweetsToDownload(this.props.childNode) }}>
                                            <i className="fas fa-file-download"></i>
                                        </button>

                                    ) : (

                                        <button className="bg-primary itemsFunctionsBtnDel" data-toggle="modal" data-target="#exportQuestionsModal"  onClick={() => { this.props.getQuestionsToDownload(this.props.childNode) }}>
                                            <i className="fas fa-file-download"></i>
                                        </button>

                                    )

                                ) : (

                                    <button className="btn-success itemsFunctionsBtn" onClick={() => { this.props.showItem(this.props.childNode) }}>
                                        <i className="fas fa-eye bg-success"></i>
                                    </button>

                                )}
                             </div>

                        ) : (

                            <div className="col-12 col-lg-3 col-md-3 col-sm-3 text-right pr-0">
                                <button className="btn-primary itemsFunctionsBtn" onClick={() => { this.props.editItem(this.props.childNode) }}>
                                    <i className="fas fa-pen bg-primary "></i>
                                </button>

                                <button className="btn-success itemsFunctionsBtn" onClick={() => { this.props.showItem(this.props.childNode) }}>
                                    <i className="fas fa-eye bg-success"></i>
                                </button>

                                <button className="btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={() => { this.props.setCurrentItem(this.props.childNode) }} >
                                    <i className="fas fa-trash bg-danger"></i>
                                </button>
                            </div>
                        )}
                    </div>

                ) : (

                    <div className="row eventElementChild " >
                        <div className="col-12 col-lg-9 col-md-8 col-sm-8 ">
                            <div className="row">
                                <div className="col-4 col-lg-4 col-md-3 col-sm-3 pr-0 pt-1 iconColumnChild">
                                    {childIcon}
                                </div>
                                {(this.props.editingId === this.props.childNode.id) ? (

                                    <div className="col-8 col-lg-7 col-md-6 col-sm-6 labelFolder ">
                                        <div className="input-group ml-2" >
                                            <input type="text" className="form-control" onKeyUp={this.saveItem.bind(this)} id="childInputTitle" defaultValue={this.props.childNode.title} aria-label="Title" aria-describedby="basic-addon2" />
                                            <div className="input-group-append">
                                                    {/* <button className="btn btn-outline-primary" type="button" onClick={()=> {this.props.saveEditedItem(this.props.childNode)}}>Save</button> */}
                                            </div>
                                        </div>
                                    </div>
        
                                ) : (

                                    <div className="col-10 col-lg-8 col-md-8 col-sm-6 labelChild"> {this.props.childNode.title}</div>

                                )}
                            </div>
                        </div>
                        {(this.props.event.eventStatus === "Archived") ? (

                            <div className="col-12 col-lg-3 col-md-3 col-sm-3 text-right pr-0">
                                {(this.props.childNode.sType === "tweet" || this.props.childNode.sType === "question") ? (

                                    (this.props.childNode.sType === "tweet" ) ? (

                                        <button className="bg-primary itemsFunctionsBtnDel" onClick={() => { this.props.getTweetsToDownload(this.props.childNode) }}>
                                            <i className="fas fa-file-download"></i>
                                        </button>

                                    ) : (

                                        <button className="bg-primary itemsFunctionsBtnDel" data-toggle="modal" data-target="#exportQuestionsModal" onClick={() => { this.props.getQuestionsToDownload(this.props.childNode) }}>
                                            <i className="fas fa-file-download"></i>
                                        </button>
                                    
                                    )

                                ) : (

                                    <button className="btn-success itemsFunctionsBtn" onClick={() => { this.props.showItem(this.props.childNode) }}>
                                        <i className="fas fa-eye bg-success"></i>
                                    </button>
                                    
                                )}
                            </div>
                            
                        ) : (

                            <div className="col-12 col-lg-3 col-md-4 col-sm-4 text-right pr-0">
                                <button className="btn-primary itemsFunctionsBtn" onClick={() => { this.props.editItem(this.props.childNode) }}>
                                    <i className="fas fa-pen bg-primary "></i>
                                </button>
                                
                                <button className="btn-success itemsFunctionsBtn" onClick={() => { this.props.showItem(this.props.childNode) }}>
                                    <i className="fas fa-eye bg-success"></i>
                                </button>

                                <button className="btn-danger  itemsFunctionsBtnDel" data-toggle="modal" data-target="#deleteEventItemModal" onClick={() => { this.props.setCurrentItem(this.props.childNode) }} >
                                    <i className="fas fa-trash bg-danger"></i>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(ChildComponent);
import {actionTypes} from './actionTypes';
import {eventFunctions} from '../../services/EventFunctions';
import moment from 'moment';


export const eventActions = {
    getAllEvents,
    getEventElementParents,
    saveEventElementParent,
    getEventElementChildren,
    saveEventElementChild,
    getParentInfoOnChange,
    updateTreeIndexes,
    updateElementData,
    setElementLanguage,
    addEvent,
    cancelEvent,
    saveEvent,
    deleteEvent,
    getEventInfoOnChange,
    setCurrentEventId,
    checkCodeAvailability,
    setLiveTweetList,
    getAllTweets,
    updateTweetFields,
    deleteTweet,
    saveNewTweet,
    updateCurrentField,
    uploadEventLogo,
    updateEventsIndexes,
    sendNewMessage,
    getParticipantsList,
    uploadLibraryFile,
    uploadAgendaFile,
    uploadSurveyFile,
    saveLibraryFile,
    saveAgendaFile,
    saveSurveyFile,
    saveQRCodeFile,
    getLibraryFiles,
    getAgendaFiles,
    getAgendaInfoOnChange,
    getSurveyFiles,
    removeLibraryFile,
    updateLibraryFileIndexes,
    saveEventInfofile,
    getEventInfoFiles,
    getSurveyInfoOnChange,
    getLibraryInfoOnChange,
    getQRCodeInfoOnChange,
    shareEventEmailCheck,
    updateSharedEventsData,
    sharedEventsList,
    getListOfSharedEmails,
    updateEventElementIndexes,
    getMessageList,
    deleteMessage,
    pushMessage,
    GetElementItemInfoOnChange,
    updateServerHeartBeatCounter
};


//Get all the events of a certain user
function getAllEvents(userId, callback) {
    return dispatch => {
        eventFunctions.getEvents(userId,
            (eventList, unsubscribe) => {
                dispatch(success(eventList));
                callback(eventList, unsubscribe);
            },
            error => dispatch(failure(error))
        );
    };


    function success(eventList) {
        return {type: actionTypes.Fetch_ALL_EVENTS_SUCCESS, eventList}
    }

    function failure(error) {
        return {type: actionTypes.Fetch_ALL_EVENTS_ERROR, error}
    }
}


function getEventElementParents(eventId, callback) {
    return dispatch => {
        eventFunctions.getEventElementParents(eventId,
            (eventElementsList, unsubscribe) => {
                dispatch(success(eventElementsList));
                callback(eventElementsList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(eventElementsList) {
        return {type: actionTypes.Fetch_ALL_EVENT_ELEMENTS_PARENT_SUCCESS, eventElementsList}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}

function getParentInfoOnChange(eventId, elementId, callback) {
    return dispatch => {
        eventFunctions.getParentInfoOnChange(eventId, elementId,
            (parentData, unsubscribe) => {
                dispatch(success(parentData));
                callback(parentData, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(parentData) {
        return {type: actionTypes.GET_PARENT_ELEMENT_ON_CHANGE_SUCCESS, parentData}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}

function getSurveyInfoOnChange(eventId, elementId, callback) {
    return dispatch => {
        eventFunctions.getSurveyInfoOnChange(eventId, elementId,
            (surveyInfo, unsubscribe) => {
                dispatch(success(surveyInfo));
                callback(surveyInfo, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(surveyInfo) {
        return {type: actionTypes.GET_SURVEY_INFO_ON_CHANGE_SUCCESS, surveyInfo}
    }
}

function getLibraryInfoOnChange(eventId, elementId, callback) {
    return dispatch => {
        eventFunctions.getLibraryInfoOnChange(eventId, elementId,
            (libraryInfo, unsubscribe) => {
                dispatch(success(libraryInfo));
                callback(libraryInfo, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(libraryInfo) {
        return {type: actionTypes.GET_LIBRARY_INFO_ON_CHANGE_SUCCESS, libraryInfo}
    }
}

function getQRCodeInfoOnChange(eventId, elementId, callback) {
    return dispatch => {
        eventFunctions.getQRCodeInfoOnChange(eventId, elementId,
            (qrcodeInfo, unsubscribe) => {
                dispatch(success(qrcodeInfo));
                callback(qrcodeInfo, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(qrcodeInfo) {
        return {type: actionTypes.GET_QRCODE_INFO_ON_CHANGE_SUCCESS, qrcodeInfo}
    }
}

function saveEventElementParent(eventId, elementData, callback) {
    return dispatch => {
        eventFunctions.saveEventElementParent(eventId, elementData).then(
            result => {
                if (elementData.id === 0) {
                    elementData.id = result.id;
                }
                dispatch(success(elementData));
                callback(elementData);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(elementData) {
        return {type: actionTypes.SAVE_EVENT_ELEMENT_PARENT_SUCCESS, elementData}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}

function getEventElementChildren(eventId, callback) {
    return dispatch => {
        eventFunctions.getEventElementChildren(eventId,
            (eventElementsChildrenList, unsubscribe) => {
                dispatch(success(eventElementsChildrenList));
                callback(eventElementsChildrenList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(eventElementsChildrenList) {
        return {type: actionTypes.FETCH_ALL_EVENT_ELEMENT_CHILDREN_SUCCESS, eventElementsChildrenList}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}

function saveEventElementChild(eventId, elementData, callback) {
    return dispatch => {
        eventFunctions.saveEventElementChild(eventId, elementData).then(
            result => {
                if (elementData.id === 0) {
                    elementData.id = result.id;
                }
                dispatch(success(elementData));
                callback(elementData);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(elementData) {
        return {type: actionTypes.SAVE_EVENT_ELEMENT_CHILD_SUCCESS, elementData}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}


function updateElementData(eventId, type, parentNode, childNode, callback) {
    return dispatch => {
        eventFunctions.updateElementData(eventId, type, parentNode, childNode).then(
            result => {
                dispatch(success());
                callback('success');
            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.UPDATE_CHILD_ELEMENT_SUCCESS}
    }
}

function setElementLanguage(eventId, elementId, lang) {
    return dispatch => {
        eventFunctions.setElementLanguage(eventId, elementId, lang).then(
            result => {
                dispatch(success());
            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.SET_ELEMENT_LANGUAGE}
    }
}

function updateTreeIndexes(eventId, treeData, callback) {

    return dispatch => {
        eventFunctions.updateTreeIndexes(eventId, treeData,
            callback => {
                dispatch(success());

            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.UPDATE_CHILD_ELEMENT_SUCCESS}
    }
}

function saveLibraryFile(eventId, libraryId, fileData, type, callback) {
    return dispatch => {
        eventFunctions.saveLibraryFile(eventId, libraryId, fileData, type).then(
            result => {
                dispatch(success());
                callback({message: "success", data: result});

            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.SAVE_LIBRARY_FILE_SUCCESS}
    }
}

function saveAgendaFile(eventId, agendaId, fileData, type, callback) {
    return dispatch => {
        eventFunctions.saveAgendaFile(eventId, agendaId, fileData, type).then(
            result => {
                dispatch(success());
                callback({message: "success", data: result});

            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.SAVE_AGENDA_FILE_SUCCESS}
    }
}

function saveSurveyFile(eventId, surveyId, fileData, type, callback) {
    return dispatch => {
        eventFunctions.saveSurveyFile(eventId, surveyId, fileData, type).then(
            result => {
                dispatch(success());
                callback({message: "success", data: result});

            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.SAVE_SURVEY_FILE_SUCCESS}
    }
}

function saveQRCodeFile(eventId, qrcodeId, fileData, type, callback) {
    return dispatch => {
        eventFunctions.saveQRCodeFile(eventId, qrcodeId, fileData, type).then(
            result => {
                dispatch(success());
                callback({message: "success", data: result});

            },
            // error => dispatch(failure(error))
        );
    };

    function success() {
        return {type: actionTypes.SAVE_QRCODE_FILE_SUCCESS}
    }
}

function getLibraryFiles(eventId, libraryId, callback) {
    return dispatch => {
        eventFunctions.getLibraryFiles(eventId, libraryId,
            (libraryFilesList, unsubscribe) => {
                callback(libraryFilesList, unsubscribe);
                dispatch(success(libraryFilesList));
            },
            // error => dispatch(failure(error))
        );
    };

    function success(libraryFilesList) {
        return {type: actionTypes.GET_ALL_LIBRARY_FILES_SUCCESS, libraryFilesList}
    }
}

function getAgendaFiles(eventId, agendaId, callback) {
    return dispatch => {
        eventFunctions.getAgendaFiles(eventId, agendaId,
            (agendaFilesList, unsubscribe) => {
                callback(agendaFilesList, unsubscribe);
                dispatch(success(agendaFilesList));
            },
            // error => dispatch(failure(error))
        );
    };

    function success(agendaFilesList) {
        return {type: actionTypes.GET_ALL_AGENDA_FILES_SUCCESS, agendaFilesList}
    }
}

function getAgendaInfoOnChange(eventId, agendaId, callback) {
    return dispatch => {
        eventFunctions.getSurveyInfoOnChange(eventId, agendaId,
            (agendaInfo, unsubscribe) => {
                dispatch(success(agendaInfo));
                callback(agendaInfo, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(agendaInfo) {
        return {type: actionTypes.GET_AGENDA_INFO_ON_CHANGE_SUCCESS, agendaInfo}
    }
}

function getSurveyFiles(eventId, surveyId, callback) {
    return dispatch => {
        eventFunctions.getSurveyFiles(eventId, surveyId,
            (surveyFilesList, unsubscribe) => {
                callback(surveyFilesList, unsubscribe);
                dispatch(success(surveyFilesList));
            },
            // error => dispatch(failure(error))
        );
    };

    function success(surveyFilesList) {
        return {type: actionTypes.GET_ALL_SURVEY_FILES_SUCCESS, surveyFilesList}
    }
}

function updateLibraryFileIndexes(eventId, libraryId, newSortedFilesList) {
    return dispatch => {
        eventFunctions.updateLibraryFileIndexes(eventId, libraryId, newSortedFilesList,
            callback => {
                dispatch(success());
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.LIBRARY_FILE_INDEXES_UPDATED_SUCCESS}
    }
}

function getEventInfoFiles(eventId, eventInfoId, callback) {
    return dispatch => {
        eventFunctions.getLibraryFiles(eventId, eventInfoId,
            (eventInfoFilesList, unsubscribe) => {
                callback(eventInfoFilesList, unsubscribe);
                dispatch(success(eventInfoFilesList));


            },
            // error => dispatch(failure(error))
        );
    };

    function success(eventInfoFilesList) {
        return {type: actionTypes.GET_ALL_EVENT_INFO_FILES_SUCCESS, eventInfoFilesList}
    }
}

function saveEventInfofile(eventId, eventInfoElementId, eventInfoData, file, type, callback) {
    return dispatch => {
        eventFunctions.saveEventInfofile(eventId, eventInfoElementId, eventInfoData, file, type,
            eventInfoData => {
                dispatch(success());
                callback(eventInfoData)
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.SAVE_EVENT_INFO_FILE_SUCCESS}
    }
}


function addEvent(callback) {
    var eventCode = Math.random().toString(36).substring(2, 7);
    return dispatch => {
        eventFunctions.checkCodeAvailability(eventCode).then(
            result => {
                if (result.docs.length === 0) {
                    //default event values
                    let eventData = {
                        id: 0,
                        title: "",
                        eventCode: eventCode,
                        dtmStart: moment().format("MM/DD/YYYY"),
                        dtmEnd: moment().format("MM/DD/YYYY"),
                        screenNumber: "One",
                        voteResultsDisplay: "Percentage",
                        primaryColor: "#002455",
                        headerBackgroundColor: "#002455",
                        headerTextColor: "#FFFFFF",
                        correctQuestionColor: '#28a745',
                        textColor: "#FFFFFF",
                        backgroundColor: "#ffffff",
                        backgroundTextColor: "#000000",
                        eventLogoUrl: "default",
                        splashImageUrl: "default",
                        homeImageUrl: "default",
                        themeBigScreenImageUrl: "default",
                        themeParticipantImageUrl: "default",
                        titleStatus: 'is-invalid',
                        tweetsToShow: "Live",
                        autoApproveTweets: "With Moderator",
                        optionsOrderStatus: "Disable",
                        showTweetsToParticipants: "Yes",
                        voteCounterStatus: "Disable",
                        showEventInfoStatus: 'Active',
                        selectedLanguage: "English",
                        showProfileToParticipants: 'default',
                        showEmailToParticipants: 'default',
                        participantsNumberLimit: 6,
                        eventStatus: "Trial",
                        showHeaderBar: "Active",
                        automaticJumpPoll: "Active",
                    }

                    dispatch(success(eventData));
                    callback(eventData);
                } else {
                    addEvent();
                }
            },
            error => {
                //dispatch(failure(error));
            }
        )
    };

    function success(event) {
        return {type: actionTypes.ADD_EVENT, event}
    }
};


function cancelEvent() {
    return {
        type: actionTypes.CANCEL_EVENT,
    };
}


//Check the availability of the user inserted event code
function checkCodeAvailability(event, eventCode, callback) {

    return dispatch => {
        eventFunctions.checkCodeAvailability(eventCode).then(
            result => {
                if (result.docs[0] && result.docs[0].data()) {
                    if (result.docs.length === 0 || result.docs[0].id === event.id || result.docs[0].data().eventIsDeleted === true) {
                        dispatch(success(event));
                        callback('success');
                    } else {
                        //The user provided code already exists in the firebase ask the user to provide another one
                        dispatch(codeFail(event));
                        callback('codeNotAvailable');
                    }
                } else {
                    if (result.docs.length === 0 || result.docs[0].id === event.id) {
                        dispatch(success(event));
                        callback('success');
                    } else {
                        //The user provided code already exists in the firebase ask the user to provide another one
                        dispatch(codeFail(event));
                        callback('codeNotAvailable');
                    }
                }
            },
            error => {
                callback(error);
                //dispatch(failure(error));
            });
    };

    function success(event) {
        return {type: actionTypes.CODE_AVAILABILITY_SUCCESS, event}
    }

    function codeFail(event) {
        return {type: actionTypes.CODE_AVAILABILITY_FAILED, event}
    }
}


function saveEvent(userId, event, callback) {

    return dispatch => {
        eventFunctions.saveEvent(userId, event)
            .then(
                saveResult => {
                    var eventData;
                    if (event.id === 0) {
                        eventData = {
                            id: saveResult.id,
                            title: event.title,
                            eventCode: event.eventCode,
                            dtmStart: event.dtmStart,
                            dtmEnd: event.dtmEnd,
                            voteCounterStatus: event.voteCounterStatus,
                            optionsOrderStatus: event.optionsOrderStatus,
                            showTweetsToParticipants: event.showTweetsToParticipants,
                            showEventInfoStatus: event.showEventInfoStatus,
                            screenNumber: event.screenNumber,
                            voteResultsDisplay: event.voteResultsDisplay,
                            eventOrderIndex: event.eventOrderIndex,
                            showProfileToParticipants: event.showProfileToParticipants,
                            showEmailToParticipants: event.showEmailToParticipants,
                            showHeaderBar: event.showHeaderBar,
                            automaticJumpPoll: event.automaticJumpPoll,
                        };
                        callback(eventData);
                        dispatch(success(eventData));
                        let status = 'create';
                        //Create the element root for the tree
                        eventFunctions.createElementRoot(saveResult.id, event.title, status).then(
                            saveElementResult => {
                                dispatch(successElementAdded());
                            },
                            error => {
                            });
                    } else {
                        eventData = {
                            id: event.id,
                            title: event.title,
                            eventCode: event.eventCode,
                            dtmStart: event.dtmStart,
                            dtmEnd: event.dtmEnd,
                            voteCounterStatus: event.voteCounterStatus,
                            optionsOrderStatus: event.optionsOrderStatus,
                            showTweetsToParticipants: event.showTweetsToParticipants,
                            showEventInfoStatus: event.showEventInfoStatus,
                            screenNumber: event.screenNumber,
                            voteResultsDisplay: event.voteResultsDisplay,
                            currentTweetElement: event.currentTweetElement,
                            currentSurveyElement: event.currentSurveyElement,
                            currentQuestionElement: event.currentQuestionElement,
                            eventOrderIndex: event.eventOrderIndex,
                            showProfileToParticipants: event.showProfileToParticipants,
                            showEmailToParticipants: event.showEmailToParticipants,
                            showHeaderBar: event.showHeaderBar,
                            automaticJumpPoll: event.automaticJumpPoll,
                        };
                        callback(eventData);
                        dispatch(success(eventData));
                    }

                },
                error => {
                    callback(error);
                    //dispatch(failure(error));
                });
    };

    function success(eventData) {
        return {type: actionTypes.SAVE_EVENT_SUCCESS, eventData}
    }

    function successElementAdded() {
        return {type: actionTypes.SAVE_EVENT_ELEMENT_SUCCESS}
    }

};

function deleteEvent(userId, event, callback) {
    return dispatch => {
        eventFunctions.deleteEvent(userId, event)
            .then(
                result => {
                    dispatch(success(event.id));
                    callback();
                },
                error => {
                    dispatch(failure(event.id, error));
                }
            );
    };

    function success(id) {
        return {type: actionTypes.DELETE_EVENT_SUCCESS, id}
    }

    function failure(id, error) {
        return {type: actionTypes.DELETE_EVENT_ERROR, id, error}
    }

}

function getEventInfoOnChange(eventId, callback) {
    return dispatch => {
        eventFunctions.getEventInfoOnChange(eventId,
            (eventInfo, unsubscribe) => {
                dispatch(success(eventInfo));
                callback(eventInfo, unsubscribe);
            },
            error => {
                // dispatch(failure(error))
            }
        );
    };

    function success(currentEvent) {
        return {type: actionTypes.FETCH_EVENT_INFO_CHANGE_SUCCESS, currentEvent}
    }
}


function setCurrentEventId(eventId, callback) {
    return dispatch => {
        dispatch(success(eventId));
        callback('success')
    }

    function success(eventId) {
        return {type: actionTypes.SET_CURRENT_EVENT_SUCCESS, eventId}
    }
}

function setLiveTweetList(eventId, liveTweetsIds) {
    return dispatch => {
        eventFunctions.setLiveTweetList(eventId, liveTweetsIds)
            .then(
                result => {
                    dispatch(success());
                },
                error => {
                    //   dispatch(failure(error));
                }
            );
    };

    function success() {
        return {type: actionTypes.SET_LIVE_TWEET_ID_SUCCESS}
    }
}

function getAllTweets(eventId, idTweetEl, callback) {
    return dispatch => {
        eventFunctions.getAllTweets(eventId, idTweetEl,
            (resultList, unsubscribe) => {
                dispatch(success(resultList));
                callback(resultList, unsubscribe);
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success(tweetsList) {
        return {type: actionTypes.GET_ALL_TWEETS_SUCCESS, tweetsList}
    }
}

function updateTweetFields(eventId, tweetIdEl, tweetId, field) {
    return dispatch => {
        eventFunctions.updateTweetFields(eventId, tweetIdEl, tweetId, field).then(
            result => {
                dispatch(success());
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success() {
        return {type: actionTypes.UPDATE_TWEET_FIELD_SUCCESS}
    }
}

function deleteTweet(eventId, idTweetEl, tweetId) {
    return dispatch => {
        eventFunctions.deleteTweet(eventId, idTweetEl, tweetId).then(
            result => {
                dispatch(success());
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success() {
        return {type: actionTypes.DELETE_TWEET_SUCCESS}
    }
}

function saveNewTweet(user, eventId, idTweetEl, tweetInputText, tweetInputName) {
    return dispatch => {
        eventFunctions.saveNewTweet(user, eventId, idTweetEl, tweetInputText, tweetInputName).then(
            result => {
                dispatch(success());
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success() {
        return {type: actionTypes.SAVE_NEW_TWEET_SUCCESS}
    }
}

function updateCurrentField(option, eventId, value, callback) {
    return dispatch => {
        eventFunctions.updateCurrentField(option, eventId, value)
            .then(
                result => {
                    dispatch(success());
                    callback('success');
                },
                error => {

                }
            )
    };

    function success() {
        return {type: actionTypes.UPDATE_CURRENT_FIELD_SUCCESS}
    }
}

function uploadEventLogo(userId, eventId, type, imageFile, callback) {
    return dispatch => {
        eventFunctions.uploadEventLogo(userId, eventId, type, imageFile, callbackUrl => {
            if (type === "SPLASH") {
                dispatch(successSplash({id: eventId, url: callbackUrl}));
            } else if (type === "LOGO") {
                dispatch(successLogo({id: eventId, url: callbackUrl}));
            } else if (type === "SPLASH&LOGO") {
                if (callbackUrl.type === "SPLASH") {
                    dispatch(successSplash({id: eventId, url: callbackUrl.url}));
                } else if (callbackUrl.type === "LOGO") {
                    dispatch(successLogo({id: eventId, url: callbackUrl.url}));

                }
            } else if (type === "HOME") {
                dispatch(successHome({id: eventId, url: callbackUrl}));
            }

        })
    };


    function successLogo(logoData) {
        return {type: actionTypes.UPDATE_EVENT_LOGO_FIELD_SUCCESS, logoData}
    }

    function successSplash(splashImage) {
        return {type: actionTypes.UPDATE_EVENT_SPLASH_IMAGE_FIELD_SUCCESS, splashImage}
    }

    function successHome(homeImage) {
        return {type: actionTypes.UPDATE_EVENT_HOME_IMAGE_FIELD_SUCCESS, homeImage}
    }

}

function uploadLibraryFile(userId, eventId, elementId, itemId, type, file, callback) {
    return dispatch => {
        eventFunctions.uploadLibraryFile(userId, eventId, elementId, itemId, type, file, callbackUrl => {
            dispatch(success());
            callback('success')
        });

        function success() {
            return {type: actionTypes.LIBRARY_FILE_UPLOAD_SUCCESS}
        }

    }
}

function uploadAgendaFile(userId, eventId, elementId, itemId, type, file, callback) {
    return dispatch => {
        eventFunctions.uploadAgendaFile(userId, eventId, elementId, itemId, type, file, callbackUrl => {
            dispatch(success());
            callback('success')
        });

        function success() {
            return {type: actionTypes.AGENDA_FILE_UPLOAD_SUCCESS}
        }

    }
}


function uploadSurveyFile(userId, eventId, elementId, itemId, type, file, callback) {
    return dispatch => {
        eventFunctions.uploadSurveyFile(userId, eventId, elementId, itemId, type, file, callbackUrl => {
            dispatch(success());
            callback('success')
        });

        function success() {
            return {type: actionTypes.SURVEY_FILE_UPLOAD_SUCCESS}
        }

    }
}

function removeLibraryFile(eventId, libraryId, fileId) {
    return dispatch => {
        eventFunctions.removeLibraryFile(eventId, libraryId, fileId).then(
            result => {
                dispatch(success());
            });

        function success() {
            return {type: actionTypes.DELETE_LIBRARY_FILE_SUCCESS}
        }

    }
}

function updateEventsIndexes(newSortedEventList) {
    return dispatch => {
        eventFunctions.updateEventsIndexes(newSortedEventList,
            callback => {
                dispatch(success());
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.EVENTS_INDEXES_UPDATED_SUCCESS}
    }
}

function sendNewMessage(eventId, message, callback) {
    return dispatch => {
        eventFunctions.sendNewMessage(eventId, message).then(
            result => {
                callback(result);
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

}

function deleteMessage(eventId, messageId) {
    return dispatch => {
        eventFunctions.deleteMessage(eventId, messageId).then(
            result => {
                dispatch(success());
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success() {
        return {type: actionTypes.DELETE_MESSAGE_SUCCESS}
    }
}

function pushMessage(eventId, messageId, bPush) {
    return dispatch => {
        eventFunctions.pushMessage(eventId, messageId, bPush).then(
            result => {
                dispatch(success());
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success() {
        return {type: actionTypes.PUSH_MESSAGE_SUCCESS}
    }
}

function getParticipantsList(eventId, callback) {
    return dispatch => {
        eventFunctions.getParticipantsList(eventId,
            (participantsList, unsubscribe) => {
                dispatch(success(participantsList));
                callback(participantsList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(participantsList) {
        return {type: actionTypes.Fetch_ALL_PARTICIPANTS_LIST_SUCCESS, participantsList}
    }
}

function getMessageList(eventId, callback) {
    return dispatch => {
        eventFunctions.getMessageList(eventId,
            (messageList, unsubscribe) => {
                dispatch(success(messageList));
                callback(messageList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(messageList) {
        return {type: actionTypes.FETCH_ALL_MESSAGES_LIST, messageList}
    }
}

function getListOfSharedEmails(event, callback) {
    return dispatch => {
        eventFunctions.getListOfSharedEmails(event.id,
            (sharedEmailsList, unsubscribe) => {
                callback(unsubscribe);
                dispatch(success({sharedEmailsList, event}));
            });

        function success(data) {
            return {type: actionTypes.FETCH_SHARED_EMAILS_SUCCESS, data}
        }
    }
}

function shareEventEmailCheck(email, callback) {
    return dispatch => {
        eventFunctions.shareEventEmailCheck(email)
            .then(emailsList => {
                if (emailsList.docs.length > 0) {
                    emailsList.forEach(function (doc) {
                        let shareUserData = doc.data();
                        shareUserData.id = doc.id;
                        dispatch(success(shareUserData));
                        callback({message: "Document Exists", data: shareUserData});
                    });
                } else {
                    dispatch(success(''));
                    callback({message: "No such document", data: ''});
                }
            })
            .catch(function (error) {
            });

    }

    function success(shareUserData) {
        return {type: actionTypes.EMAIL_AVAILABILITY_CHECK_SUCCESS, shareUserData}
    }
}

function updateSharedEventsData(type, eventId, userData, sharedUserData, callback) {
    return dispatch => {
        eventFunctions.updateSharedEventsData(type, eventId, userData, sharedUserData,
            result => {
                if (result === "success") {
                    dispatch(success(''));
                    callback("Success");
                }

            });
    }

    function success() {
        return {type: actionTypes.UPDATE_SHARED_EVENT_DATA_SUCCESS}
    }
}

function sharedEventsList(userId, callback) {
    return dispatch => {
        eventFunctions.sharedEventsList(userId,
            (sharedEventsList, unsubscribe) => {
                dispatch(success(sharedEventsList));
                callback(sharedEventsList, unsubscribe);
            });
    }

    function success(sharedEventsList) {
        return {type: actionTypes.FETCH_SHARED_EVENTS_SUCCESS, sharedEventsList}
    }
}


function updateEventElementIndexes(eventId, newSortedEventElementsList) {
    return dispatch => {
        eventFunctions.updateEventElementIndexes(eventId, newSortedEventElementsList,
            callback => {
                dispatch(success());
            },
            error => {
                // dispatch(failure(question.id, error));
            }
        );
    };

    function success() {
        return {type: actionTypes.EVENTS_ELEMENTS_INDEXES_UPDATED_SUCCESS}
    }
}

function GetElementItemInfoOnChange(eventId, elementItemId, callback) {
    return dispatch => {
        eventFunctions.getElementItemInfoOnChange(eventId, elementItemId,
            (elementItemData, unsubscribe) => {
                dispatch(success(elementItemData));
                callback(elementItemData, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };


    function success(elementItemData) {
        return {type: actionTypes.GET_ELEMENT_ITEM_ON_CHANGE_SUCCESS, elementItemData}
    }

    // function failure(error) { return { type: actionTypes.Fetch_ALL_EVENTS_ERROR, error } }
}


function updateServerHeartBeatCounter(eventId) {
    return dispatch => {
        eventFunctions.updateServerHeartBeatCounter(eventId);
    };
}
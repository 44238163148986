import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventActions } from '../store/actions/EventActions';
// import htmlSample from "../assets/htmlSample.html";


class PreviewHtmlFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
        };
    }

    render(){
        return(
            <main role="main">
            <div className="container">
            {/* <div dangerouslySetInnerHTML={ {__html: htmlSample} } /> */}
            </div>
            </main>
        )
    }
}

export default PreviewHtmlFile;
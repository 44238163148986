import React, { Component } from 'react';

class RatingModal extends Component {

    render() {

        return (
            <div className="modal fade" id="RatingQuestion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">        
                <div className="modal-dialog modal-dialog-centered questionModalsDialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Rating Question</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="card-body text-center">
                                <form id='ratingQuestionModalsForm'>
                                    <div className="form-row align-items-center">

                                        <div className="col-12 my-1 ">
                                            <input type="text" id="ratingTextQuestion" className="form-control text-dark mb-2 mr-sm-2" placeholder="What would you like to ask?" defaultValue={this.props.question.text} />
                                        </div>

                                        <div className="col-4 text-left ml-2">
                                            Max Rating Grade: <br/> (Star Numbers)* 
                                        </div>
                                        <div className="col-3 text-left" id="startNumbers">
                                            <input type="number" min="3" max="10" className="form-control" id="ratingNumberofStars" defaultValue={this.props.question.numberOfStars} />
                                        </div>
                                        <div className="col-12 mt-4 text-left ml-2">
                                            <label className="text-secondary " style={{fontSize:"15px"}}>*The minimum number of starts is 3 and the maximum is 10.</label>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { this.props.saveQuestionFunction('RatingQuestion', this.props.question, '', '','',"default") }} >{this.props.saveBtnTitle}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatingModal;

























import React, {Component} from 'react';
import moment from 'moment';


class AnswetTextLive extends Component {
    render() {
        let answerListComponentsLive = [];
        if (this.props.responsesArrayLive) {
            answerListComponentsLive = (this.props.responsesArrayLive || []).map((option, i) => {
                return (
                    <div key={i}>
                        {(this.props.page === "LiveScreen") ? (

                            (option.isHidden === false) ? (

                                <div className="card mt-3 cardAnswetText" key={option.id}>
                                    <div className="card-header cardHeaderAnswer"
                                         style={{backgroundColor: this.props.event.textColor}}>
                                        <div className="row pt-2">
                                            <div className="col-6 text-left">
                                                {(option.sName && option.sName !== "") ? (

                                                    <h4 style={{color: this.props.event.primaryColor,}}>
                                                        <i className="fas fa-user mr-2"
                                                           style={{color: this.props.event.primaryColor}}>
                                                        </i><b>{option.sName}</b>
                                                    </h4>

                                                ) : (

                                                    <h4><i className="fas fa-user mr-2"
                                                           style={{color: this.props.event.primaryColor}}></i></h4>

                                                )}
                                            </div>
                                            <div className="col-6 text-right">
                                                {(option.dtmCreated) ? (

                                                    <label htmlFor="inlineCheckbox1"
                                                           style={{color: this.props.event.primaryColor}}>
                                                        <b>{moment(option.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</b>
                                                    </label>

                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-text cardBodyAnswer">
                                        <h3 style={{color: this.props.event.primaryColor}}>{option.sAnswer}</h3>
                                    </div>
                                </div>

                            ) : (
                                <div></div>
                            )

                        ) : (

                            <div className="card mt-3 cardAnswetText" key={option.id}>
                                <div className="card-header cardHeaderAnswer"
                                     style={{backgroundColor: this.props.event.textColor}}>
                                    <div className="row pt-2">
                                        <div className="col-6 text-left">
                                            {(option.sName && option.sName !== "") ? (

                                                <h6 style={{color: this.props.event.primaryColor,}}>
                                                    <i className="fas fa-user mr-2"
                                                       style={{color: this.props.event.primaryColor}}>
                                                    </i><b>{option.sName}</b>
                                                </h6>

                                            ) : (

                                                <i className="fas fa-user mr-2"
                                                   style={{color: this.props.event.primaryColor}}></i>

                                            )}
                                        </div>
                                        <div className="col-5 text-right">
                                            {(option.dtmCreated) ? (

                                                <label htmlFor="inlineCheckbox1"
                                                       style={{color: this.props.event.primaryColor}}>
                                                    <b>{moment(option.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</b></label>

                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        <div className="col-1 text-left pl-0">
                                            {(option.isHidden || option.isHidden === true) ? (

                                                <div className="btn bg-secondary text-white  pt-0 pb-0 px-1"
                                                     onClick={() => {
                                                         this.props.setAnswerTextstatus(option, false)
                                                     }}>
                                                    <i className="far fa-eye-slash"></i>
                                                </div>

                                            ) : (

                                                <div className="btn bg-success text-white pt-0 pb-0 px-1"
                                                     onClick={() => {
                                                         this.props.setAnswerTextstatus(option, true)
                                                     }}>
                                                    <i className="fas fa-eye"></i>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-text cardBodyAnswer">
                                    <h6 style={{color: this.props.event.primaryColor}}>{option.sAnswer}</h6>
                                </div>
                            </div>
                        )}
                    </div>
                )
            });
        }

        return (
            <div>
                <div className="container-tweet-live">
                    {answerListComponentsLive}
                </div>
            </div>
        );
    }
}

export default AnswetTextLive;





